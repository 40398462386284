import React from "react";
import ReactCountryFlag from "react-country-flag";

const AiTimingCard = ({ data }) => {
  return (
    <div class="flex flex-col">
      <h1 className="flex font-semibold text-base p-2  items-center  gap-x-2">
        <ReactCountryFlag countryCode={data?.isoCode} svg />{" "}
        <div>{data?.country}</div>
      </h1>
      <div class="-m-1.5 overflow-x-auto">
        <div class="p-1.5 min-w-full inline-block align-middle ">
          <div class="border overflow-hidden dark:border-neutral-700 rounded-lg">
            <table class="min-w-full divide-y divide-gray-200 dark:divide-neutral-700 ">
              <thead>
                <tr className="text-neutral-500">
                  <th
                    scope="col"
                    class="px-6 py-3 text-start text-xs font-medium uppercase dark:text-neutral-500"
                  >
                    Days
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-start text-xs font-medium uppercase dark:text-neutral-500"
                  >
                    Best Time to market
                  </th>
                  <th
                    scope="col"
                    class="px-6 py-3 text-start text-xs font-medium uppercase dark:text-neutral-500"
                  >
                    CTR (Click through rate)
                  </th>
                </tr>
              </thead>
              <tbody class="divide-y divide-gray-200 dark:divide-neutral-700">
                {data?.data?.map((data) => (
                  <tr className="hover:bg-neutral-50 dark:hover:bg-neutral-600">
                    <td class="px-6 py-3 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200">
                      {data.days}
                    </td>
                    <td class="px-6 py-3 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200">
                      {data.time}
                    </td>
                    <td class="px-6 py-3 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200">
                      {data.ctr}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AiTimingCard;
