import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import DashboardHeader from "../Dashboard/DashboardHeader";
import DashboardSidebar from "../Dashboard/DashboardSidebar";
import axios from "axios";
import TnxDetailsCard from "../AiCredits/TnxDetailsCard";
import SmallLoader from "../subcomponents/SmallLoader";
import DashboardFooter from "../Dashboard/DashboardFooter";
import TopupPlanCard from "../AiCredits/TopupPlanCard";
const Transactions = () => {
  const location = useLocation();
  const [packageData, setPackageData] = useState({});
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [tab1, setTab1] = useState(false);
  const [tab2, setTab2] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [index, setIndex] = useState(0);
  const [index1, setIndex1] = useState(0);
  const [searchInput, setSearchInput] = useState("");
  const [searchInput1, setSearchInput1] = useState("");
  const [loadingTxn, setLoadingTxn] = useState(false);
  const [loadingTxn1, setLoadingTxn1] = useState(false);
  const [noDataFound, setNoDataFound] = useState(false);
  const [noDataFound1, setNoDataFound1] = useState(false);
  const [txnData, setTxnData] = useState([]);
  const [txnData1, setTxnData1] = useState([]);
  const [totalTxn, setTotalTxn] = useState(0);
  const [totalTxn1, setTotalTxn1] = useState(0);
  const [planSelected, setPlanSelected] = useState(false);
  const [loadingPayment, setLoadingPayment] = useState(false);
  useEffect(() => {
    const fetchPackages = async () => {
      const tokenData = localStorage.getItem("token");
      if (!tokenData) {
        console.log("Token not found in localStorage");
        return;
      }

      let access_token;
      try {
        const data = JSON.parse(tokenData);
        access_token = data.access_token;
        if (!access_token) {
          console.log("Access token is missing");
          return;
        }
      } catch (e) {
        console.log("Failed to parse token data");
        return;
      }

      const config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_GATEWAY_URL}/atz/subscription/ai-credits-packages`,
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
        data: {},
      };
      try {
        const response = await axios(config);
        console.log(response.data);
        setPackageData(response.data);
      } catch (e) {
        console.log(e);
      }
    };
    fetchPackages();
  }, []);

  const handleRadio = (plan) => {
    setPlanSelected(true);
    setSelectedPlan(plan);
  };
  const handleSearchInput = (e) => {
    setSearchInput(e.target.value);
  };
  const handleSearchInput1 = (e) => {
    setSearchInput1(e.target.value);
  };
  useEffect(() => {
    localStorage.setItem("activeLink", "/transactions");
  });
  useEffect(() => {
    // Reinitialize Preline components every time the location changes
    if (
      window.HSStaticMethods &&
      typeof window.HSStaticMethods.autoInit === "function"
    ) {
      window.HSStaticMethods.autoInit();
    }
  }, [location.pathname, txnData, txnData1]);
  useEffect(() => {
    if (!localStorage.getItem("activeTab")) {
      localStorage.setItem("activeTab", "one");
    }
  });
  const handleTab = (tab) => {
    if (tab == "one") {
      localStorage.setItem("activeTab", "one");
      setTab1(true);
      setTab2(false);
    } else if (tab == "two") {
      localStorage.setItem("activeTab", "two");
      setTab1(false);
      setTab2(true);
    }
  };
  useEffect(() => {
    const fetchTransactions = async () => {
      const tokenData = localStorage.getItem("token");
      if (!tokenData) {
        console.log("Token not found in localStorage");
        return;
      }

      let access_token;
      try {
        const data = JSON.parse(tokenData);
        access_token = data.access_token;
        if (!access_token) {
          console.log("Access token is missing");
          return;
        }
      } catch (e) {
        console.log("Failed to parse token data");
        return;
      }

      const requestData = {
        data: {
          index: index, // MANDATORY
          itemsPerIndex: 10, // MANDATORY

          search: searchInput1, // OPTIONAL search works for only paymentId received from razorpay
          fromDate: startDate, // OPTIONAL PATTERN MUST BE: dd-mm-yyyy
          toDate: endDate, // OPTIONAL PATTERN MUST BE: dd-mm-yyyy},
        },
      };
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_GATEWAY_URL}/atz/payments/ai-credits-transactions`,
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
        data: requestData,
      };
      try {
        setLoadingTxn(true);
        const response = await axios(config);
        console.log(response.data.data);
        setTxnData(response.data.data);
        setTotalTxn(response.data.data.total);
        setNoDataFound(false);
        if (response.status === 200) {
          setLoadingTxn(false);
        }
      } catch (e) {
        setLoadingTxn(false);
        setNoDataFound(true);
        console.log(e);
      }
    };
    fetchTransactions();
  }, [startDate, endDate, index, searchInput1]);
  useEffect(() => {
    const fetchTransactionsSub = async () => {
      const tokenData = localStorage.getItem("token");
      if (!tokenData) {
        console.log("Token not found in localStorage");
        return;
      }

      let access_token;
      try {
        const data = JSON.parse(tokenData);
        access_token = data.access_token;
        if (!access_token) {
          console.log("Access token is missing");
          return;
        }
      } catch (e) {
        console.log("Failed to parse token data");
        return;
      }

      const requestData = {
        data: {
          index: index1, // MANDATORY
          itemsPerIndex: 10, // MANDATORY

          search: searchInput, // OPTIONAL search works for only paymentId received from razorpay
          fromDate: startDate, // OPTIONAL PATTERN MUST BE: dd-mm-yyyy
          toDate: endDate, // OPTIONAL PATTERN MUST BE: dd-mm-yyyy},
        },
      };
      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_GATEWAY_URL}/atz/payments/subscription-transactions`,
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
        data: requestData,
      };
      try {
        setLoadingTxn1(true);
        const response = await axios(config);
        console.log(response.data.data);
        setTxnData1(response.data.data);
        setTotalTxn1(response.data.data.total);
        setNoDataFound1(false);
        if (response.status === 200) {
          setLoadingTxn1(false);
        }
      } catch (e) {
        setLoadingTxn1(false);
        setNoDataFound1(true);
        console.log(e);
      }
    };
    fetchTransactionsSub();
  }, [startDate, endDate, index1, searchInput]);
  const generateOrderId = async () => {
    const tokenData = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));
    if (!tokenData) {
      console.log("Token not found in localStorage");
      return;
    }

    let access_token;
    try {
      const data = JSON.parse(tokenData);
      access_token = data.access_token;
      if (!access_token) {
        console.log("Access token is missing");
        return;
      }
    } catch (e) {
      console.log("Failed to parse token data");
      return;
    }
    const requestData = {
      data: {
        packageId: selectedPlan && selectedPlan.id,
      },
    };

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_GATEWAY_URL}/atz/payments/ai-credit-payment-request`,
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
      data: requestData,
    };
    try {
      // logEvent(analytics, `CTA_AI_CREDIT_MAKE_PAYMENT`);
      setLoadingPayment(true);
      const response = await axios(config);
      console.log(response.data);
      console.log("passedData : ", selectedPlan.amount);
      if (response.status === 200) {
        // setLoadingTxn1(false);
        window.location.href = `${response.data.data}`;
      }
    } catch (e) {
      if (e.response.status === 400) {
        setLoadingPayment(false);
        document.getElementById("failureButton").click();
      }
      console.log(e);
    }
  };
  useEffect(() => {
    setLoadingPayment(false);
  }, []);
  return (
    <>
      <div
        id="hs-scale-animation-modal"
        class="hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none"
        role="dialog"
        tabindex="-1"
        aria-labelledby="hs-scale-animation-modal-label"
      >
        <div class="hs-overlay-animation-target hs-overlay-open:scale-100 hs-overlay-open:opacity-100 scale-95 opacity-0 ease-in-out transition-all duration-200 sm:max-w-xl sm:w-full m-3 sm:mx-auto min-h-[calc(100%-3.5rem)] flex items-center">
          <div class="w-full flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
            {/* <!-- Header --> */}
            <div class="py-2.5 px-4 flex justify-between items-center border-b dark:border-neutral-700">
              <h3 class="font-medium text-gray-800 dark:text-neutral-200">
                Get AI credits now!
              </h3>

              {/* <!-- End Header --> */}
              <button
                type="button"
                class="size-8 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700 dark:hover:bg-neutral-600 dark:text-neutral-400 dark:focus:bg-neutral-600"
                aria-label="Close"
                data-hs-overlay="#hs-scale-animation-modal"
              >
                <span class="sr-only">Close</span>
                <svg
                  class="shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M18 6 6 18"></path>
                  <path d="m6 6 12 12"></path>
                </svg>
              </button>
            </div>
            <div
              class="max-h-[400px] overflow-y-auto
  [&::-webkit-scrollbar]:w-2
  [&::-webkit-scrollbar-track]:rounded-full
  [&::-webkit-scrollbar-track]:bg-gray-100
  [&::-webkit-scrollbar-thumb]:rounded-full
  [&::-webkit-scrollbar-thumb]:bg-gray-300
  dark:[&::-webkit-scrollbar-track]:bg-neutral-700
  dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500"
            >
              <div class="p-2 my-2 ">
                <div class="p-4 space-y-6">
                  <nav class="p-1 flex  bg-gray-100 rounded-xl dark:bg-neutral-900/50">
                    <div class=" py-2 px-3 inline-flex justify-center items-center gap-x-2 w-full text-sm font-medium text-gray-800 rounded-lg disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:text-gray-500 dark:text-neutral-200 dark:focus:text-neutral-500">
                      Choose your plan
                    </div>
                  </nav>
                  {/* <!-- End Nav Tab --> */}

                  <div className="space-y-4 max-h-[450px]">
                    {packageData &&
                      packageData?.data?.map((p) => (
                        <TopupPlanCard
                          data={p}
                          plan={p.name}
                          isSelected={
                            selectedPlan && selectedPlan.name == p.name
                          }
                          onSelect={handleRadio}
                        />
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="p-4">
              <button
                type="button"
                class="py-3 px-4 w-full inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-[#019ca2] text-white hover:bg-[#438688] disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-2 focus:ring-[#019ca2]"
                data-hs-overlay="#hs-scale-animation-modal"
                onClick={generateOrderId}
                disabled={!planSelected}
              >
                Make payment of ${selectedPlan && selectedPlan.amount}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        id="hs-scale-animation-modal1"
        class="hs-overlay size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none hidden"
        role="dialog"
        tabindex="-1"
        aria-labelledby="hs-scale-animation-modal1-label"
      >
        <div class="hs-overlay-animation-target hs-overlay-open:scale-100 hs-overlay-open:opacity-100 scale-95 opacity-0 ease-in-out transition-all duration-200 sm:max-w-xl sm:w-full m-3 sm:mx-auto min-h-[calc(100%-3.5rem)] flex items-center">
          <div class="w-full flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
            {/* <!-- Header --> */}
            <div class="py-2.5 px-4 flex justify-between items-center border-b dark:border-neutral-700">
              <h3 class="font-medium text-gray-800 dark:text-neutral-200">
                Invoice not found
              </h3>

              {/* <!-- End Header --> */}
              <button
                type="button"
                class="size-8 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700 dark:hover:bg-neutral-600 dark:text-neutral-400 dark:focus:bg-neutral-600"
                aria-label="Close"
                data-hs-overlay="#hs-scale-animation-modal1"
              >
                <span class="sr-only">Close</span>
                <svg
                  class="shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M18 6 6 18"></path>
                  <path d="m6 6 12 12"></path>
                </svg>
              </button>
            </div>
            <div
              class="min-h-[200px] flex overflow-y-auto
  [&::-webkit-scrollbar]:w-2
  [&::-webkit-scrollbar-track]:rounded-full
  [&::-webkit-scrollbar-track]:bg-gray-100
  [&::-webkit-scrollbar-thumb]:rounded-full
  [&::-webkit-scrollbar-thumb]:bg-gray-300
  dark:[&::-webkit-scrollbar-track]:bg-neutral-700
  dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500"
            >
              <div className="flex flex-col flex-1 justify-center items-center gap-y-2 dark:text-neutral-100">
                <div>
                  <svg
                    viewBox="0 0 16 16"
                    fill="none"
                    className="size-10"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        d="M7.493 0.015 C 7.442 0.021,7.268 0.039,7.107 0.055 C 5.234 0.242,3.347 1.208,2.071 2.634 C 0.660 4.211,-0.057 6.168,0.009 8.253 C 0.124 11.854,2.599 14.903,6.110 15.771 C 8.169 16.280,10.433 15.917,12.227 14.791 C 14.017 13.666,15.270 11.933,15.771 9.887 C 15.943 9.186,15.983 8.829,15.983 8.000 C 15.983 7.171,15.943 6.814,15.771 6.113 C 14.979 2.878,12.315 0.498,9.000 0.064 C 8.716 0.027,7.683 -0.006,7.493 0.015 M8.853 1.563 C 9.967 1.707,11.010 2.136,11.944 2.834 C 12.273 3.080,12.920 3.727,13.166 4.056 C 13.727 4.807,14.142 5.690,14.330 6.535 C 14.544 7.500,14.544 8.500,14.330 9.465 C 13.916 11.326,12.605 12.978,10.867 13.828 C 10.239 14.135,9.591 14.336,8.880 14.444 C 8.456 14.509,7.544 14.509,7.120 14.444 C 5.172 14.148,3.528 13.085,2.493 11.451 C 2.279 11.114,1.999 10.526,1.859 10.119 C 1.618 9.422,1.514 8.781,1.514 8.000 C 1.514 6.961,1.715 6.075,2.160 5.160 C 2.500 4.462,2.846 3.980,3.413 3.413 C 3.980 2.846,4.462 2.500,5.160 2.160 C 6.313 1.599,7.567 1.397,8.853 1.563 M7.706 4.290 C 7.482 4.363,7.355 4.491,7.293 4.705 C 7.257 4.827,7.253 5.106,7.259 6.816 C 7.267 8.786,7.267 8.787,7.325 8.896 C 7.398 9.033,7.538 9.157,7.671 9.204 C 7.803 9.250,8.197 9.250,8.329 9.204 C 8.462 9.157,8.602 9.033,8.675 8.896 C 8.733 8.787,8.733 8.786,8.741 6.816 C 8.749 4.664,8.749 4.662,8.596 4.481 C 8.472 4.333,8.339 4.284,8.040 4.276 C 7.893 4.272,7.743 4.278,7.706 4.290 M7.786 10.530 C 7.597 10.592,7.410 10.753,7.319 10.932 C 7.249 11.072,7.237 11.325,7.294 11.495 C 7.388 11.780,7.697 12.000,8.000 12.000 C 8.303 12.000,8.612 11.780,8.706 11.495 C 8.763 11.325,8.751 11.072,8.681 10.932 C 8.616 10.804,8.460 10.646,8.333 10.580 C 8.217 10.520,7.904 10.491,7.786 10.530 "
                        stroke="none"
                        fill-rule="evenodd"
                        fill="#ff0000"
                      ></path>
                    </g>
                  </svg>
                </div>
                <div className="text-sm font-semibold -mb-1 px-8 text-center">
                  We couldn't retrieve the invoice for the selected payment.
                  This may occur if the payment was either not successfully
                  created, canceled, or failed to process.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DashboardHeader />
      <DashboardSidebar />
      <DashboardFooter />
      <main
        id="content"
        className="lg:ps-[260px] pt-[59px] dark:text-neutral-100"
      >
        <div class="p-2 sm:p-5 sm:py-0 md:pt-5 space-y-5">
          <div class="p-5 bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700 space-y-10">
            <div class="border-b-[1.5px] border-gray-200 dark:border-neutral-700">
              <nav class="-mb-0.5 flex gap-x-2 flex-wrap justify-between items-center">
                <div>
                  {
                    <button
                      class={`${
                        localStorage.getItem("activeTab") == "one"
                          ? `py-1.5 inline-flex items-center gap-0 border-b-[1.5px] border-[#000] text-sm font-medium whitespace-nowrap  text-gray-600 hover:text-gray-800 focus:outline-none  dark:text-neutral-200 dark:border-neutral-400    `
                          : `py-1.5 inline-flex items-center gap-0 border-b-[1.5px] border-transparent text-sm whitespace-nowrap text-gray-500 
                       focus:outline-none  dark:text-neutral-500`
                      }`}
                      onClick={() => handleTab("one")}
                    >
                      <div
                        className={`${
                          localStorage.getItem("activeTab") == "one"
                            ? `flex gap-x-2 items-center hover:bg-neutral-50 p-2 rounded-lg focus:bg-neutral-50 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 
                      hover:text-[#000]
                      dark:hover:text-neutral-200 bg-neutral-100 dark:bg-neutral-700`
                            : `flex gap-x-2 items-center hover:bg-neutral-50 p-2 rounded-lg focus:bg-neutral-50 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 
                      hover:text-[#000]
                      dark:hover:text-neutral-200`
                        }`}
                      >
                        Subscriptions
                      </div>
                    </button>
                  }
                  {
                    <button
                      class={`${
                        localStorage.getItem("activeTab") == "two"
                          ? `py-1.5 inline-flex items-center gap-0 border-b-[1.5px] border-[#000] text-sm font-medium whitespace-nowrap  text-gray-600 hover:text-gray-800 focus:outline-none  dark:text-neutral-200 dark:border-neutral-400  `
                          : `py-1.5 inline-flex items-center gap-0 border-b-[1.5px] border-transparent text-sm whitespace-nowrap text-gray-500 
                       focus:outline-none  dark:text-neutral-500`
                      }`}
                      onClick={() => handleTab("two")}
                    >
                      <div
                        className={`${
                          localStorage.getItem("activeTab") == "two"
                            ? `flex gap-x-2 items-center hover:bg-neutral-50 p-2 rounded-lg focus:bg-neutral-50 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 
                      hover:text-[#000]
                      dark:hover:text-neutral-200 bg-neutral-100 dark:bg-neutral-700`
                            : `flex gap-x-2 items-center hover:bg-neutral-50 p-2 rounded-lg focus:bg-neutral-50 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 
                      hover:text-[#000]
                      dark:hover:text-neutral-200`
                        }`}
                      >
                        Ai Credits
                      </div>
                    </button>
                  }
                </div>
                <div>
                  {localStorage.getItem("activeTab") == "one" && (
                    <button
                      className="px-4 py-1.5 text-sm font-semibold bg-inherit  border-[1px] border-primary-950 text-primary-950 hover:text-white hover:bg-primary-950 rounded-full"
                      onClick={() =>
                        (window.location.href = "/billing/subscriptions")
                      }
                    >
                      + New Subscription
                    </button>
                  )}
                  {localStorage.getItem("activeTab") == "two" && (
                    <button
                      className="px-4 py-1.5 text-sm inline-flex items-center gap-x-1 font-semibold bg-inherit  border-[1px] border-primary-950 text-primary-950 hover:text-white hover:bg-primary-950 rounded-full"
                      aria-haspopup="dialog"
                      aria-expanded="false"
                      aria-controls="hs-scale-animation-modal"
                      data-hs-overlay="#hs-scale-animation-modal"
                    >
                      <svg
                        className="flex-shrink-0 size-4"
                        height="30"
                        width="30"
                        version="1.1"
                        id="_x32_"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 0 512 512"
                        xmlSpace="preserve"
                        fill="currentColor"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <g>
                            {" "}
                            <path
                              class="st0"
                              d="M247.355,106.9C222.705,82.241,205.833,39.18,197.46,0c-8.386,39.188-25.24,82.258-49.899,106.917 c-24.65,24.642-67.724,41.514-106.896,49.904c39.188,8.373,82.254,25.235,106.904,49.895c24.65,24.65,41.522,67.72,49.908,106.9 c8.373-39.188,25.24-82.258,49.886-106.917c24.65-24.65,67.724-41.514,106.896-49.904 C315.08,148.422,272.014,131.551,247.355,106.9z"
                            ></path>{" "}
                            <path
                              class="st0"
                              d="M407.471,304.339c-14.714-14.721-24.81-40.46-29.812-63.864c-5.011,23.404-15.073,49.142-29.803,63.872 c-14.73,14.714-40.464,24.801-63.864,29.812c23.408,5.01,49.134,15.081,63.864,29.811c14.73,14.722,24.81,40.46,29.82,63.864 c5.001-23.413,15.081-49.142,29.802-63.872c14.722-14.722,40.46-24.802,63.856-29.82 C447.939,329.14,422.201,319.061,407.471,304.339z"
                            ></path>{" "}
                            <path
                              class="st0"
                              d="M146.352,354.702c-4.207,19.648-12.655,41.263-25.019,53.626c-12.362,12.354-33.968,20.82-53.613,25.027 c19.645,4.216,41.251,12.656,53.613,25.027c12.364,12.362,20.829,33.96,25.036,53.618c4.203-19.658,12.655-41.255,25.023-53.626 c12.354-12.362,33.964-20.82,53.605-25.035c-19.64-4.2-41.251-12.656-53.613-25.019 C159.024,395.966,150.555,374.351,146.352,354.702z"
                            ></path>{" "}
                          </g>{" "}
                        </g>
                      </svg>
                      Get AI Credits
                    </button>
                  )}
                </div>
              </nav>
            </div>

            {localStorage.getItem("activeTab") === "one" && (
              <div className="">
                <div className="flex justify-between items-center">
                  <div class="flex flex-1 relative">
                    <div class="absolute inset-y-0 start-0 flex items-center pointer-events-none z-20 ps-3.5">
                      <svg
                        class="flex-shrink-0 size-4 text-gray-500 dark:text-neutral-400"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <circle cx="11" cy="11" r="8" />
                        <path d="m21 21-4.3-4.3" />
                      </svg>
                    </div>
                    <input
                      type="text"
                      value={searchInput}
                      onChange={handleSearchInput}
                      class="input-style py-[9px] px-3 ps-10 block w-full border-gray-200 focus:border-gray-200 focus:ring-0 dark:focus:border-neutral-700 rounded-lg dark:border-neutral-700 bg-inherit text-sm
                               disabled:opacity-50 disabled:pointer-events-none dark:bg-inherit  dark:text-neutral-400 dark:placeholder:text-neutral-400 "
                      placeholder="Search transactions using Subscription ID"
                    />
                  </div>
                </div>
                {loadingTxn1 && (
                  <div className="min-h-72 flex flex-1 justify-center items-center">
                    <SmallLoader />
                  </div>
                )}
                {noDataFound1 && !loadingTxn1 && (
                  <div className="text-sm sm:p-5 p-2">
                    <div class="p-5 min-h-96 flex flex-col justify-center items-center text-center">
                      <svg
                        class="w-48 mx-auto mb-4"
                        width="178"
                        height="90"
                        viewBox="0 0 178 90"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="27"
                          y="50.5"
                          width="124"
                          height="39"
                          rx="7.5"
                          fill="currentColor"
                          class="fill-white dark:fill-neutral-800"
                        />
                        <rect
                          x="27"
                          y="50.5"
                          width="124"
                          height="39"
                          rx="7.5"
                          stroke="currentColor"
                          class="stroke-gray-50 dark:stroke-neutral-700/10"
                        />
                        <rect
                          x="34.5"
                          y="58"
                          width="24"
                          height="24"
                          rx="4"
                          fill="currentColor"
                          class="fill-gray-50 dark:fill-neutral-700/30"
                        />
                        <rect
                          x="66.5"
                          y="61"
                          width="60"
                          height="6"
                          rx="3"
                          fill="currentColor"
                          class="fill-gray-50 dark:fill-neutral-700/30"
                        />
                        <rect
                          x="66.5"
                          y="73"
                          width="77"
                          height="6"
                          rx="3"
                          fill="currentColor"
                          class="fill-gray-50 dark:fill-neutral-700/30"
                        />
                        <rect
                          x="19.5"
                          y="28.5"
                          width="139"
                          height="39"
                          rx="7.5"
                          fill="currentColor"
                          class="fill-white dark:fill-neutral-800"
                        />
                        <rect
                          x="19.5"
                          y="28.5"
                          width="139"
                          height="39"
                          rx="7.5"
                          stroke="currentColor"
                          class="stroke-gray-100 dark:stroke-neutral-700/30"
                        />
                        <rect
                          x="27"
                          y="36"
                          width="24"
                          height="24"
                          rx="4"
                          fill="currentColor"
                          class="fill-gray-100 dark:fill-neutral-700/70"
                        />
                        <rect
                          x="59"
                          y="39"
                          width="60"
                          height="6"
                          rx="3"
                          fill="currentColor"
                          class="fill-gray-100 dark:fill-neutral-700/70"
                        />
                        <rect
                          x="59"
                          y="51"
                          width="92"
                          height="6"
                          rx="3"
                          fill="currentColor"
                          class="fill-gray-100 dark:fill-neutral-700/70"
                        />
                        <g filter="url(#filter19)">
                          <rect
                            x="12"
                            y="6"
                            width="154"
                            height="40"
                            rx="8"
                            fill="currentColor"
                            class="fill-white dark:fill-neutral-800"
                            shape-rendering="crispEdges"
                          />
                          <rect
                            x="12.5"
                            y="6.5"
                            width="153"
                            height="39"
                            rx="7.5"
                            stroke="currentColor"
                            class="stroke-gray-100 dark:stroke-neutral-700/60"
                            shape-rendering="crispEdges"
                          />
                          <rect
                            x="20"
                            y="14"
                            width="24"
                            height="24"
                            rx="4"
                            fill="currentColor"
                            class="fill-gray-200 dark:fill-neutral-700 "
                          />
                          <rect
                            x="52"
                            y="17"
                            width="60"
                            height="6"
                            rx="3"
                            fill="currentColor"
                            class="fill-gray-200 dark:fill-neutral-700"
                          />
                          <rect
                            x="52"
                            y="29"
                            width="106"
                            height="6"
                            rx="3"
                            fill="currentColor"
                            class="fill-gray-200 dark:fill-neutral-700"
                          />
                        </g>
                        <defs>
                          <filter
                            id="filter19"
                            x="0"
                            y="0"
                            width="178"
                            height="64"
                            filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB"
                          >
                            <feFlood
                              flood-opacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset dy="6" />
                            <feGaussianBlur stdDeviation="6" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_1187_14810"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_1187_14810"
                              result="shape"
                            />
                          </filter>
                        </defs>
                      </svg>

                      <div class="max-w-sm mx-auto">
                        <p class="mt-2 font-medium text-gray-800 dark:text-neutral-200">
                          Uh oh!
                        </p>
                        <p class="mb-5 text-sm text-gray-500 dark:text-neutral-500">
                          It looks like you don't have any transactions yet.
                          Make a transaction to view it here.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                <div className="overflow-auto">
                  {!noDataFound1 && !loadingTxn1 && (
                    <>
                      <table class="min-w-full divide-y divide-gray-200 dark:divide-neutral-700 ">
                        <thead>
                          <tr>
                            <th scope="col" class="w-1/6 hidden">
                              <div class="pe-4 py-3 text-start flex items-center gap-x-1 text-sm font-medium text-gray-800 dark:text-neutral-200 px-2 sm:px-5">
                                ID
                              </div>
                            </th>

                            <th scope="col" class="w-1/6">
                              <div class=" py-3 text-start flex items-center gap-x-1 text-sm font-medium text-gray-800 dark:text-neutral-200 px-2 sm:px-5">
                                Subscription ID
                              </div>
                            </th>

                            <th scope="col" class="w-1/6">
                              <div class="py-3 text-start flex items-center gap-x-1 text-sm font-medium text-gray-800 dark:text-neutral-200 px-2 sm:px-5">
                                Subscription Package
                              </div>
                            </th>

                            <th scope="col" class="w-1/6">
                              <div class=" py-3 text-start flex items-center gap-x-1 text-sm font-medium text-gray-800 dark:text-neutral-200 px-2 sm:px-5">
                                Amount
                              </div>
                            </th>
                            <th scope="col" class="w-1/6">
                              <div class=" py-3 text-start flex items-center gap-x-1 text-sm font-medium text-gray-800 dark:text-neutral-200 px-2 sm:px-5">
                                Status
                              </div>
                            </th>
                            <th scope="col" class="w-1/6">
                              <div class=" py-3 text-start flex items-center gap-x-1 text-sm font-medium text-gray-800 dark:text-neutral-200 px-2 sm:px-5">
                                Created on
                              </div>
                            </th>
                            <th scope="col" class="w-1/6">
                              <div class=" py-3 text-start flex items-center gap-x-1 text-sm font-medium text-gray-800 dark:text-neutral-200 px-2 sm:px-5">
                                Actions
                              </div>
                            </th>
                          </tr>
                        </thead>

                        <tbody class="divide-y divide-gray-200 dark:divide-neutral-700">
                          {txnData1 &&
                            txnData1?.transactions?.map((data) => (
                              <TnxDetailsCard
                                data={data}
                                subscriptions={true}
                              />
                            ))}
                        </tbody>
                      </table>
                    </>
                  )}
                </div>

                {!noDataFound1 && (
                  <nav
                    class="flex justify-end items-center gap-x-1 p-2"
                    aria-label="Pagination"
                  >
                    <button
                      type="button"
                      onClick={() => setIndex1(index1 - 1)}
                      disabled={index1 === 0}
                      class="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-2 text-sm rounded-lg text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10"
                      aria-label="Previous"
                    >
                      <svg
                        class="shrink-0 size-3.5"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="m15 18-6-6 6-6"></path>
                      </svg>
                      <span class="sr-only">Previous</span>
                    </button>
                    <div class="flex items-center gap-x-1">
                      <span class="min-h-[38px] min-w-[38px] flex justify-center items-center border border-gray-200 text-gray-800 py-2 px-3 text-sm rounded-lg focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:border-neutral-700 dark:text-white dark:focus:bg-white/10">
                        {index1 + 1}
                      </span>
                      <span class="min-h-[38px] flex justify-center items-center text-gray-500 py-2 px-1.5 text-sm dark:text-neutral-500">
                        of
                      </span>
                      <span class="min-h-[38px] flex justify-center items-center text-gray-500 py-2 px-1.5 text-sm dark:text-neutral-500">
                        {Math.ceil(totalTxn1 / 10)}
                      </span>
                    </div>
                    <button
                      type="button"
                      onClick={() => setIndex1(index1 + 1)}
                      disabled={index1 + 1 === Math.ceil(totalTxn1 / 10)}
                      class="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-2 text-sm rounded-lg text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10"
                      aria-label="Next"
                    >
                      <span class="sr-only">Next</span>
                      <svg
                        class="shrink-0 size-3.5"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="m9 18 6-6-6-6"></path>
                      </svg>
                    </button>
                  </nav>
                )}
              </div>
            )}
            {localStorage.getItem("activeTab") === "two" && (
              <div>
                <div className="flex justify-between items-center">
                  <div class="flex flex-1 relative">
                    <div class="absolute inset-y-0 start-0 flex items-center pointer-events-none z-20 ps-3.5">
                      <svg
                        class="flex-shrink-0 size-4 text-gray-500 dark:text-neutral-400"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <circle cx="11" cy="11" r="8" />
                        <path d="m21 21-4.3-4.3" />
                      </svg>
                    </div>
                    <input
                      type="text"
                      value={searchInput1}
                      onChange={handleSearchInput1}
                      class="input-style py-[9px] px-3 ps-10 block w-full border-gray-200 border-gray-200 focus:border-gray-200 focus:ring-0 dark:focus:border-neutral-700  rounded-lg dark:border-neutral-700 bg-inherit text-sm
                               disabled:opacity-50 disabled:pointer-events-none dark:bg-inherit  dark:text-neutral-400 dark:placeholder:text-neutral-400 "
                      placeholder="Search transactions using Payment ID"
                    />
                  </div>
                </div>
                {loadingPayment && (
                  <div className="min-h-72 flex flex-1 justify-center items-center">
                    <SmallLoader />
                  </div>
                )}
                {loadingTxn && !loadingPayment && (
                  <div className="min-h-72 flex flex-1 justify-center items-center">
                    <SmallLoader />
                  </div>
                )}
                {noDataFound && !loadingTxn && !loadingPayment && (
                  <div className="text-sm sm:p-5 p-2">
                    <div class="p-5 min-h-96 flex flex-col justify-center items-center text-center">
                      <svg
                        class="w-48 mx-auto mb-4"
                        width="178"
                        height="90"
                        viewBox="0 0 178 90"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="27"
                          y="50.5"
                          width="124"
                          height="39"
                          rx="7.5"
                          fill="currentColor"
                          class="fill-white dark:fill-neutral-800"
                        />
                        <rect
                          x="27"
                          y="50.5"
                          width="124"
                          height="39"
                          rx="7.5"
                          stroke="currentColor"
                          class="stroke-gray-50 dark:stroke-neutral-700/10"
                        />
                        <rect
                          x="34.5"
                          y="58"
                          width="24"
                          height="24"
                          rx="4"
                          fill="currentColor"
                          class="fill-gray-50 dark:fill-neutral-700/30"
                        />
                        <rect
                          x="66.5"
                          y="61"
                          width="60"
                          height="6"
                          rx="3"
                          fill="currentColor"
                          class="fill-gray-50 dark:fill-neutral-700/30"
                        />
                        <rect
                          x="66.5"
                          y="73"
                          width="77"
                          height="6"
                          rx="3"
                          fill="currentColor"
                          class="fill-gray-50 dark:fill-neutral-700/30"
                        />
                        <rect
                          x="19.5"
                          y="28.5"
                          width="139"
                          height="39"
                          rx="7.5"
                          fill="currentColor"
                          class="fill-white dark:fill-neutral-800"
                        />
                        <rect
                          x="19.5"
                          y="28.5"
                          width="139"
                          height="39"
                          rx="7.5"
                          stroke="currentColor"
                          class="stroke-gray-100 dark:stroke-neutral-700/30"
                        />
                        <rect
                          x="27"
                          y="36"
                          width="24"
                          height="24"
                          rx="4"
                          fill="currentColor"
                          class="fill-gray-100 dark:fill-neutral-700/70"
                        />
                        <rect
                          x="59"
                          y="39"
                          width="60"
                          height="6"
                          rx="3"
                          fill="currentColor"
                          class="fill-gray-100 dark:fill-neutral-700/70"
                        />
                        <rect
                          x="59"
                          y="51"
                          width="92"
                          height="6"
                          rx="3"
                          fill="currentColor"
                          class="fill-gray-100 dark:fill-neutral-700/70"
                        />
                        <g filter="url(#filter19)">
                          <rect
                            x="12"
                            y="6"
                            width="154"
                            height="40"
                            rx="8"
                            fill="currentColor"
                            class="fill-white dark:fill-neutral-800"
                            shape-rendering="crispEdges"
                          />
                          <rect
                            x="12.5"
                            y="6.5"
                            width="153"
                            height="39"
                            rx="7.5"
                            stroke="currentColor"
                            class="stroke-gray-100 dark:stroke-neutral-700/60"
                            shape-rendering="crispEdges"
                          />
                          <rect
                            x="20"
                            y="14"
                            width="24"
                            height="24"
                            rx="4"
                            fill="currentColor"
                            class="fill-gray-200 dark:fill-neutral-700 "
                          />
                          <rect
                            x="52"
                            y="17"
                            width="60"
                            height="6"
                            rx="3"
                            fill="currentColor"
                            class="fill-gray-200 dark:fill-neutral-700"
                          />
                          <rect
                            x="52"
                            y="29"
                            width="106"
                            height="6"
                            rx="3"
                            fill="currentColor"
                            class="fill-gray-200 dark:fill-neutral-700"
                          />
                        </g>
                        <defs>
                          <filter
                            id="filter19"
                            x="0"
                            y="0"
                            width="178"
                            height="64"
                            filterUnits="userSpaceOnUse"
                            color-interpolation-filters="sRGB"
                          >
                            <feFlood
                              flood-opacity="0"
                              result="BackgroundImageFix"
                            />
                            <feColorMatrix
                              in="SourceAlpha"
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"
                            />
                            <feOffset dy="6" />
                            <feGaussianBlur stdDeviation="6" />
                            <feComposite in2="hardAlpha" operator="out" />
                            <feColorMatrix
                              type="matrix"
                              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.03 0"
                            />
                            <feBlend
                              mode="normal"
                              in2="BackgroundImageFix"
                              result="effect1_dropShadow_1187_14810"
                            />
                            <feBlend
                              mode="normal"
                              in="SourceGraphic"
                              in2="effect1_dropShadow_1187_14810"
                              result="shape"
                            />
                          </filter>
                        </defs>
                      </svg>

                      <div class="max-w-sm mx-auto">
                        <p class="mt-2 font-medium text-gray-800 dark:text-neutral-200">
                          Uh oh!
                        </p>
                        <p class="mb-5 text-sm text-gray-500 dark:text-neutral-500">
                          It looks like you don't have any transactions yet.
                          Make a transaction to view it here.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                <div className="overflow-auto">
                  {!noDataFound && !loadingTxn && !loadingPayment && (
                    <table class="min-w-full divide-y divide-gray-200 dark:divide-neutral-700">
                      <thead>
                        <tr>
                          <th scope="col" class="w-1/6 ">
                            <div class="pe-4 py-3 text-start flex items-center gap-x-1 text-sm font-medium text-gray-800 dark:text-neutral-200 px-2 sm:px-5">
                              ID
                            </div>
                          </th>

                          <th scope="col" class="w-1/6">
                            <div class=" py-3 text-start flex items-center gap-x-1 text-sm font-medium text-gray-800 dark:text-neutral-200 px-2 sm:px-5">
                              Payment ID
                            </div>
                          </th>

                          <th scope="col" class="w-1/6">
                            <div class="py-3 text-start flex items-center gap-x-1 text-sm font-medium text-gray-800 dark:text-neutral-200 px-2 sm:px-5">
                              Amount
                            </div>
                          </th>

                          <th scope="col" class="w-1/6">
                            <div class=" py-3 text-start flex items-center gap-x-1 text-sm font-medium text-gray-800 dark:text-neutral-200 px-2 sm:px-5">
                              Status
                            </div>
                          </th>
                          <th scope="col" class="w-1/6">
                            <div class=" py-3 text-start flex items-center gap-x-1 text-sm font-medium text-gray-800 dark:text-neutral-200 px-2 sm:px-5">
                              Created on
                            </div>
                          </th>
                          <th scope="col" class="w-1/6">
                            <div class=" py-3 text-start flex items-center gap-x-1 text-sm font-medium text-gray-800 dark:text-neutral-200 px-2 sm:px-5">
                              Actions
                            </div>
                          </th>
                        </tr>
                      </thead>

                      <tbody class="divide-y divide-gray-200 dark:divide-neutral-700">
                        {txnData &&
                          txnData?.transactions?.map((data) => (
                            <TnxDetailsCard data={data} aiCredits={true} />
                          ))}
                      </tbody>
                    </table>
                  )}
                </div>
                {!noDataFound && !loadingPayment && (
                  <nav
                    class="flex justify-end items-center gap-x-1 p-2"
                    aria-label="Pagination"
                  >
                    <button
                      type="button"
                      onClick={() => setIndex(index - 1)}
                      disabled={index === 0}
                      class="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-2 text-sm rounded-lg text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10"
                      aria-label="Previous"
                    >
                      <svg
                        class="shrink-0 size-3.5"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="m15 18-6-6 6-6"></path>
                      </svg>
                      <span class="sr-only">Previous</span>
                    </button>
                    <div class="flex items-center gap-x-1">
                      <span class="min-h-[38px] min-w-[38px] flex justify-center items-center border border-gray-200 text-gray-800 py-2 px-3 text-sm rounded-lg focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:border-neutral-700 dark:text-white dark:focus:bg-white/10">
                        {index + 1}
                      </span>
                      <span class="min-h-[38px] flex justify-center items-center text-gray-500 py-2 px-1.5 text-sm dark:text-neutral-500">
                        of
                      </span>
                      <span class="min-h-[38px] flex justify-center items-center text-gray-500 py-2 px-1.5 text-sm dark:text-neutral-500">
                        {Math.ceil(totalTxn / 10)}
                      </span>
                    </div>
                    <button
                      type="button"
                      onClick={() => setIndex(index + 1)}
                      disabled={index + 1 === Math.ceil(totalTxn / 10)}
                      class="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-2 text-sm rounded-lg text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10"
                      aria-label="Next"
                    >
                      <span class="sr-only">Next</span>
                      <svg
                        class="shrink-0 size-3.5"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="m9 18 6-6-6-6"></path>
                      </svg>
                    </button>
                  </nav>
                )}
              </div>
            )}
          </div>
        </div>
      </main>
    </>
  );
};

export default Transactions;
