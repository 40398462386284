import React, { useState, useEffect, useRef } from "react";
import * as echarts from "echarts";
import axios from "axios";

const PieChart = ({
  id,
  refreshBrowser,
  startTime,
  endTime,
  setBrowserLoading,
  setNoBrowserData,
}) => {
  const chartRef = useRef(null);
  console.log(chartRef);

  useEffect(() => {
    const fetchData = async () => {
      const tokenData = localStorage.getItem("token");
      const myChart = echarts.init(chartRef.current);

      if (!tokenData) {
        console.log("Token not found in localStorage");
        return;
      }

      let access_token;
      try {
        const data = JSON.parse(tokenData);
        access_token = data.access_token;
        if (!access_token) {
          console.log("Access token is missing");
          return;
        }
      } catch (e) {
        console.log("Failed to parse token data");
        return;
      }

      try {
        const requestData = {
          data: {
            linkId: id,
            fromDate: startTime,
            toDate: endTime,
          },
        };

        const config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_GATEWAY_URL}/atz/links/click-scan-by-browser`,
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
          data: requestData,
        };
        setBrowserLoading(true);
        setNoBrowserData(false);
        const response = await axios(config);
        const countNo = response.data.data.reduce(
          (acc, data) => (acc += data.count),
          0
        );
        if (response.status === 200) {
          setBrowserLoading(false);
        }
        if (countNo == 0) {
          setNoBrowserData(true);
        }
        const data = response.data.data;

        console.log(countNo);

        console.log(data);

        // Transform the data for the pie chart

        const chartData = data.map((item) => ({
          value: item.count,
          name: item.browserName,
        }));

        const option = {
          toolbox: {
            bottom: "2%",
            right: "2%",
            show: true,
            feature: {
              saveAsImage: { show: true },
            },
          },
          tooltip: {
            trigger: "item",
          },
          legend: {
            top: "5%",
            textStyle: {
              color: "#8C8C8C",
            },
          },
          series: [
            {
              name: "Browser Data",
              type: "pie",
              radius: "50%",
              data: chartData,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        };

        myChart.setOption(option);
        return () => {
          myChart.dispose();
        };
      } catch (error) {
        setBrowserLoading(false);
        console.error("Error fetching the data:", error);
      }
    };

    fetchData();
  }, [refreshBrowser, startTime, endTime, id, setBrowserLoading]);

  return (
    <div
      ref={chartRef}
      style={{ height: "400px", width: "100%" }}
      className="dark:text-white"
    ></div>
  );
};

export default PieChart;
