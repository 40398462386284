import React from "react";

const Loader = ({ aiLoading }) => {
  return (
    <>
      {/* <div className="flex h-[500px] pt-72 justify-center items-center">
        <iframe src="tmp.html"></iframe>
      </div> */}
      {aiLoading && (
        <div
          className="absolute min-h-screen opacity-95 bg-neutral-800 top-0 left-0 z-[110]  flex flex-1 justify-center items-center text-white"
          id="aiLoader"
        >
          <div class="absolute loader"></div>
          <div>
            <img
              src={require("../../assets/images/logo/logo_white.png")}
              alt="logo"
              className="h-6 w-14"
            />
            <div className="absolute top-1/2 left-1/2 w-[100px] h-[100px] -mt-[50px] -ml-[50px] border-4 border-t-4 border-neutral-300 border-t-neutral-400 rounded-full animate-spin"></div>
          </div>
          <div className="absolute mt-56 text-neutral-500 animate-bounce">
            Generating AI powered marketing content... Hold on!
          </div>
        </div>
      )}
      {!aiLoading && (
        <div className="flex justify-center items-center h-screen">
          <div className="block dark:hidden">
            <div className="relative inline-block">
              <img
                src={require("../../assets/images/logo/logo.png")}
                alt="logo"
                className="block w-14 h-6 animate-pulse"
              />
              <div className="absolute top-1/2 left-1/2 w-28 h-28 -mt-14 -ml-14 border-4 border-t-4 border-gray-200 border-t-[#019ca2] rounded-full animate-spin"></div>
            </div>
          </div>
          <div className="hidden dark:block">
            <div className="relative inline-block">
              <img
                src={require("../../assets/images/logo/logo_white.png")}
                alt="logo"
                className="block w-14 h-6 animate-pulse"
              />
              <div className="absolute top-1/2 left-1/2 w-28 h-28 -mt-14 -ml-14 border-4 border-t-4 border-gray-200 border-t-[#019ca2] rounded-full animate-spin"></div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Loader;
