import React, { useState, useEffect, useRef } from "react";
import * as echarts from "echarts";
import axios from "axios";
const BarToPie = ({
  id,
  refreshDevice,
  startTime,
  endTime,
  setLoadingDevices,
  setNoDeviceData,
}) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      const tokenData = localStorage.getItem("token");
      const myChart = echarts.init(chartRef.current);

      if (!tokenData) {
        console.log("Token not found in localStorage");
        return;
      }

      let access_token;
      try {
        const data = JSON.parse(tokenData);
        access_token = data.access_token;
        if (!access_token) {
          console.log("Access token is missing");
          return;
        }
      } catch (e) {
        console.log("Failed to parse token data");
        return;
      }

      try {
        const requestData = {
          data: {
            linkId: id,
            fromDate: startTime,
            toDate: endTime,
          },
        };

        const config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_GATEWAY_URL}/atz/links/click-scan-by-devices`,
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
          data: requestData,
        };
        // Mock API call to get the employee data
        setLoadingDevices(true);
        setNoDeviceData(false);
        const response = await axios(config);
        const countNo = response.data.data.reduce(
          (acc, data) => (acc += data.count),
          0
        );
        if (response.status === 200) {
          setLoadingDevices(false);
        }
        const data = response.data.data;
        console.log(response);
        if (countNo == 0) {
          setNoDeviceData(true);
        }
        // Transform the data for the pie chart
        const chartData = data.map((data) => ({
          value: data.count,
          name: data.deviceName,
        }));

        const option = {
          toolbox: {
            right: "2%",
            show: true,
            feature: {
              saveAsImage: {},
            },
          },
          tooltip: {
            trigger: "item",
          },

          legend: {
            top: "5%",
            textStyle: {
              color: "#8C8C8C",
            },
          },
          series: [
            {
              name: "Browser Data",
              type: "pie",
              radius: "50%",
              data: chartData,
            },
          ],
        };
        myChart.setOption(option);

        return () => {
          myChart.dispose();
        };
      } catch (error) {
        setLoadingDevices(false);
        setNoDeviceData(true);
        console.error("Error fetching the data:", error);
      }
    };

    fetchData();
  }, [refreshDevice, startTime, endTime]);

  return (
    <div
      ref={chartRef}
      style={{ height: "400px", width: "100%" }}
      className="dark:text-white"
    ></div>
  );
};

export default BarToPie;
