import React, { useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import axios from "axios";

const BarTimeChart = ({
  id,
  options,
  series,
  refreshTime,
  setLoadingTimeChart,
}) => {
  const chartRef = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);

  useEffect(() => {
    if (chartRef.current) {
      const myChart = echarts.init(chartRef.current);
      setChartInstance(myChart);

      // Handle resize
      const handleResize = () => {
        myChart.resize();
      };

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
        myChart.dispose();
      };
    }
  }, [refreshTime]);

  useEffect(() => {
    if (chartInstance) {
      const option = {
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: options,
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "Count",
            type: "bar",
            barWidth: "60%",
            data: series,
            color: "#019ca2",
          },
        ],
      };

      chartInstance.setOption(option);
    }
  }, [options, series, chartInstance]);

  return <div ref={chartRef} style={{ height: "400px", width: "100%" }}></div>;
};

export default BarTimeChart;
