import React, { useEffect, useState } from "react";
import DashboardUI from "./DashboardUI";

const Dashboard = () => {
  localStorage.setItem("activeLink", "/dashboard");
  return <DashboardUI />;
};

export default Dashboard;

/**
 * const [obj, setObj] = useState(null);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const { code } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    const getId = async () => {
      const data = JSON.stringify({
        data: {
          code: code,
          clientName: "atz",
          redirectUri: process.env.REACT_APP_IDP_CALLBACK_URL,
        },
      });

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_GATEWAY_URL}/core/get-token`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      try {
        const response = await axios(config);
        if (response.status === 200) {
          console.log("API Response:", response.data);
          const responseData = response.data.data;
          const responseStatus = response.data.status.code;
          const access_token_active = response.data.data.token.expires_in;
          const { user, token: tokens } = responseData;
          const { status } = responseStatus;
          setObj(responseData);
          // Dispatch to save the data to Redux store
          dispatch(
            setAuthData({
              user,
              tokens,
              status,
            })
          );
          localStorage.setItem(
            "token",
            JSON.stringify(response.data.data.token)
          );
          localStorage.setItem("user", JSON.stringify(response.data.data.user));
          localStorage.setItem("contentLoad", true);
        } else {
          setObj({ error: true });
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setObj({ error: true });
      }
    };

    if (code) {
      getId();
    }
  }, [code, dispatch]);

  if (obj?.error) return <h1>Please login again</h1>;

  if (
    localStorage.getItem("user") === null &&
    localStorage.getItem("token") === null
  ) {
    console.log("logged");
    navigate("/");
  }
  if (localStorage.getItem("contentLoad") == null)
    return (
      <div className="h-full w-full flex justify-center mt-60">
        <Loader />
      </div>
    );
 */
