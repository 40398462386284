import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  status: null,
  code: null,
  user: null,
  tokens: {
    accessToken: null,
    refreshToken: null,
    idToken: null,
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthData: (state, action) => {
      const { code, user, tokens, status } = action.payload;
      if (status) state.status = status;
      if (code) state.code = code;
      if (user) state.user = user;
      if (tokens) state.tokens = tokens;
    },
    setTokens: (state, action) => {
      const { accessToken, refreshToken, idToken } = action.payload;

      if (accessToken) state.tokens.accessToken = accessToken;
      if (refreshToken) state.tokens.refreshToken = refreshToken;
      if (idToken) state.tokens.idToken = idToken;
    },
    clearAuthData: (state) => {
      state.code = null;
      state.user = null;
      state.tokens = {
        accessToken: null,
        refreshToken: null,
        idToken: null,
      };
    },
  },
});

export const { setAuthData, setTokens, clearAuthData } = authSlice.actions;

export default authSlice.reducer;

// import { createSlice } from "@reduxjs/toolkit";

// const initialState = {
//   code: null,
//   user: null,
//   tokens: null,
// };

// const authSlice = createSlice({
//   name: "auth",
//   initialState,
//   reducers: {
//     setAuthData: (state, action) => {
//       const { code, user, tokens } = action.payload;

//       if (code) state.code = code;
//       if (user) state.user = user;
//       if (tokens) state.tokens = tokens;
//     },
//   },
// });

// export const { setAuthData } = authSlice.actions;

// export default authSlice.reducer;
