import React, { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Loader from "./subcomponents/Loader";
import { setAuthData } from "../features/auth/authSlice";
import Error from "./Error";

const Callback = () => {
  const [loading, setLoading] = useState(true);
  const [obj, setObj] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { code } = useSelector((state) => state.auth);

  useEffect(() => {
    const codeRegex = /code=([^&]+)/;
    const isMatch = window.location.href.match(codeRegex);
    if (isMatch) {
      const accessToken = isMatch[1];
      dispatch(setAuthData({ code: accessToken }));
    }
  }, [dispatch]);

  useEffect(() => {
    const getId = async () => {
      const data = JSON.stringify({
        data: {
          code: code,
          clientName: "atz",
          redirectUri: process.env.REACT_APP_ATZ_CALLBACK_URL,
        },
      });

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_GATEWAY_URL}/core/get-token`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      try {
        const response = await axios(config);
        if (response.status === 200) {
          console.log("API Response:", response.data);
          const responseData = response.data.data;
          const responseStatus = response.data.status.code;
          const access_token_active = response.data.data.token.expires_in;
          const { user, token: tokens } = responseData;
          const { status } = responseStatus;
          setObj(responseData);
          dispatch(
            setAuthData({
              user,
              tokens,
              status,
            })
          );
          localStorage.setItem(
            "token",
            JSON.stringify(response.data.data.token)
          );
          localStorage.setItem("user", JSON.stringify(response.data.data.user));
          localStorage.setItem("contentLoad", true);
          window.HSStaticMethods.autoInit();
        } else {
          setObj({ error: true });
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setObj({ error: true });
      }
    };

    if (code) {
      getId();
    }
  }, [code, dispatch]);

  useEffect(() => {
    if (!loading && !obj?.error) {
      navigate("/dashboard");
    }
  }, [loading, obj, navigate]);

  if (loading) return <Loader />;

  if (obj?.error) return <Error />;

  return null;
};

export default Callback;
