import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const SessionExpired = () => {
  const navigate = useNavigate();
  const user = JSON.parse(sessionStorage.getItem("email"));
  const { name, email, profilePic } = user;
  const url =
    process.env.REACT_APP_IDP_URL +
    "/oauth2/authorize?response_type=code&client_id=atz&scope=openid&redirect_uri=" +
    process.env.REACT_APP_ATZ_CALLBACK_URL;

  useEffect(() => {
    if (!!localStorage.getItem("auth")) {
      navigate("/dashboard");
    } else {
      localStorage.clear();
    }
  }, []);
  return (
    <div
      className="flex min-h-screen flex-1 flex-col items-center justify-center bg-white md:px-[550px] sm:px-0 px-4 "
      id="session"
    >
      <div className="flex flex-col p-10 bg-white border rounded-xl justify-evenly items-center gap-y-4">
        <div className="flex flex-none flex-col ">
          <img
            src={require("../assets/images/logo/logo.png")}
            className="w-12"
          />
        </div>
        <div className="flex flex-col flex-1 gap-y-4">
          <div className="flex flex-1 flex-col justify-center items-center text-center">
            <div className="text-2xl font-semibold ">Session Expired</div>
            <div className="text-lg ">
              For your security, your session has timed out due to inactivity.
            </div>
            <div className="text-lg">
              This helps protect your information and ensures that unauthorized
              access is prevented.
            </div>
            <div className="inline-flex items-center justify-between text-sm border rounded-r-full rounded-l-full px-3 py-0.5 gap-x-2">
              <div className="flex items-center justify-center border rounded-full p-1">
                {profilePic ? (
                  <img
                    className="flex-shrink-0 size-5 rounded-full"
                    src={profilePic}
                    alt="Image Description"
                  />
                ) : (
                  <svg
                    fill="#019ca2"
                    className="flex-shrink-0 size-5 rounded-full"
                    viewBox="0 0 512 512"
                    id="_x30_1"
                    version="1.1"
                    xmlSpace="preserve"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      {" "}
                      <g>
                        {" "}
                        <ellipse
                          cx="256"
                          cy="130"
                          rx="110"
                          ry="130"
                        ></ellipse>{" "}
                        <path d="M36,478.191C36,390.825,134.497,320,256,320s220,70.825,220,158.191v0C476,496.863,460.863,512,442.192,512H69.808 C51.137,512,36,496.863,36,478.191L36,478.191z"></path>{" "}
                      </g>{" "}
                    </g>
                  </svg>
                )}
              </div>
              <div className="flex items-center justify-center">
                {JSON.parse(sessionStorage.getItem("email")).email}
              </div>
              {/* {sessionStorage.getItem("email")} */}
            </div>
          </div>
          <div className="flex flex-col items-center justify-center">
            <div className="text-sm">
              To continue using the platform, please refresh the page or log in
              again
            </div>
            <div>
              <button
                className="px-10 py-1.5 bg-[#019ca2] hover:bg-[#266769] rounded-md text-xs  text-white font-semibold mt-4 flex flex-1 items-center gap-x-1"
                onClick={() => (window.location.href = url)}
              >
                <span>Sign in again</span>
              </button>
            </div>
          </div>
        </div>
        {/* <img
          src={require("../assets/images/error/expired.png")}
          className="w-44"
        /> */}
      </div>
    </div>
  );
};

export default SessionExpired;
