import React, { useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import axios from "axios";
import SmallLoader from "../SmallLoader";

const BarChart = ({
  id,
  refreshDevice,
  type,
  startTime,
  endTime,
  setLoadingDevices,
  setNoDeviceData,
}) => {
  const chartRef = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);
  const [options, setOptions] = useState([]);
  const [series, setSeries] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (chartRef.current) {
      const myChart = echarts.init(chartRef.current);
      setChartInstance(myChart);

      // Handle resize
      const handleResize = () => {
        myChart.resize();
      };

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
        myChart.dispose();
      };
    }
  }, []);

  useEffect(() => {
    if (chartInstance) {
      const fetchData = async () => {
        const tokenData = localStorage.getItem("token");
        if (!tokenData) {
          console.log("Token not found in localStorage");
          return;
        }

        let access_token;
        try {
          const data = JSON.parse(tokenData);
          access_token = data.access_token;
          if (!access_token) {
            console.log("Access token is missing");
            return;
          }
        } catch (e) {
          console.log("Failed to parse token data");
          return;
        }

        try {
          const requestData = {
            data: {
              linkId: id,
              fromDate: startTime,
              toDate: endTime,
            },
          };

          const config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_GATEWAY_URL}/atz/links/click-scan-by-devices`,
            headers: {
              Authorization: `Bearer ${access_token}`,
              "Content-Type": "application/json",
            },
            data: requestData,
          };
          setLoadingDevices(true);
          setNoDeviceData(false);
          const response = await axios(config);
          const data = response.data.data;
          const countNo = response.data.data.reduce(
            (acc, data) => (acc += data.count),
            0
          );
          if (response.status === 200) {
            setLoadingDevices(false);
            const deviceName = data.map((item) => item.deviceName);
            const count = data.map((item) => item.count);
            setOptions(deviceName);
            setSeries(count);
          }
          if (countNo == 0) {
            setNoDeviceData(true);
          }
        } catch (e) {
          setLoadingDevices(false);
          setNoDeviceData(true);
          console.error(e);
        }
      };

      fetchData();
    }
  }, [id, refreshDevice, chartInstance, startTime, endTime]);

  useEffect(() => {
    if (chartInstance) {
      const option = {
        toolbox: {
          right: "2%",
          show: true,
          feature: {
            saveAsImage: { show: true },
          },
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: options,
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "Count",
            type: type ? type : "bar",
            barWidth: "60%",
            color: "#019ca2",
            data: series,
          },
        ],
      };

      chartInstance.setOption(option);
    }
  }, [options, series, chartInstance]);

  return <div ref={chartRef} style={{ height: "400px", width: "100%" }}></div>;
};

export default BarChart;
