import React from "react";

const LinkDetailCard = ({ linkDetails, clickCounts }) => {
  console.log(linkDetails);
  return (
    <>
      <div className="flex flex-1 border p-2 border-gray-200 dark:border-neutral-700 rounded-lg gap-x-6 shadow-sm items-center dark:text-neutral-50">
        <div className="flex flex-none pl-2">
          <img
            class="flex-shrink-0 size-[35px] sm:size-[40px] mx-auto p-2 border border-gray-200 dark:border-neutral-700 rounded-full"
            src={`https://www.google.com/s2/favicons?sz=64&domain_url=${linkDetails.destinationUrl}`}
            alt="Image Description"
          />
        </div>
        <div className="flex flex-col flex-1 truncate">
          <div className="text-xl font-semibold truncate">
            {linkDetails.title}
          </div>
          <div className="text-sm text-neutral-600 truncate gap-x-2 inline-flex items-center">
            <div className="flex flex-none -ml-0.5">
              <svg
                fill="#019ca2"
                height="16"
                width="16"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                id="share"
                class="icon glyph"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  <path d="M18,9H16a1,1,0,0,0,0,2h2v9H6V11H8A1,1,0,0,0,8,9H6a2,2,0,0,0-2,2v9a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V11A2,2,0,0,0,18,9Z"></path>
                  <path d="M8.71,6.71,11,4.41V15a1,1,0,0,0,2,0V4.41l2.29,2.3a1,1,0,0,0,1.42,0,1,1,0,0,0,0-1.42l-4-4h0a1.15,1.15,0,0,0-.33-.21.94.94,0,0,0-.76,0,1.15,1.15,0,0,0-.33.21h0l-4,4A1,1,0,1,0,8.71,6.71Z"></path>
                </g>
              </svg>
            </div>
            <a
              className="truncate text-sm text-[#019ca2] hover:underline"
              href={linkDetails.shortenUrl}
              target="_blank"
            >
              {linkDetails.shortenUrl}
            </a>
          </div>
          <div className="text-sm text-neutral-600 truncate inline-flex gap-x-2 items-center">
            <div className="flex flex-none -ml-0.5">
              <svg
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 7H16C18.7614 7 21 9.23858 21 12C21 14.7614 18.7614 17 16 17H14M10 7H8C5.23858 7 3 9.23858 3 12C3 14.7614 5.23858 17 8 17H10M8 12H16"
                  stroke="#808080"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <a
              className="truncate text-sm  hover:underline"
              href={linkDetails.destinationUrl}
              target="_blank"
            >
              {linkDetails.destinationUrl}
            </a>
          </div>
        </div>
        <div className="flex flex-none gap-x-2">
          <div className="flex flex-col justify-center items-center">
            <div className="text-sm text-neutral-600">{clickCounts} clicks</div>
            <div className="mt-1">
              {!linkDetails.active && (
                <span class="inline-flex items-center gap-x-1.5 py-1 px-2.5 text-xs font-medium bg-red-200 text-red-600 rounded-full dark:bg-red-950 dark:text-red-400">
                  <span class="size-1.5 inline-block bg-red-600 rounded-full dark:bg-red-400"></span>
                  Inactive
                </span>
              )}
              {linkDetails.active && (
                <span class="inline-flex items-center gap-x-1.5 py-1 px-2.5 text-xs font-medium bg-green-200 text-green-700 rounded-full dark:bg-green-950 dark:text-green-400">
                  <span class="size-1.5 inline-block bg-green-600 rounded-full dark:bg-green-400"></span>
                  Active
                </span>
              )}
            </div>
          </div>
          {linkDetails?.qrCode && (
            <div className="flex flex-col ">
              <img
                src={linkDetails?.qrCode?.qrCodeUrl}
                className="size-24 rounded-md border p-1 border-gray-300 dark:border-neutral-700"
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default LinkDetailCard;
