import React, { useEffect, useRef, useState } from "react";
import * as echarts from "echarts";
import axios from "axios";
import SmallLoader from "../SmallLoader";
const UsageChart = ({ setLoadingUsage }) => {
  const chartRef = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);
  const [options, setOptions] = useState([]);
  const [series, setSeries] = useState([]);
  const [series1, setSeries1] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (chartRef.current) {
      const myChart = echarts.init(chartRef.current);
      setChartInstance(myChart);

      // Handle resize
      const handleResize = () => {
        myChart.resize();
      };

      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
        myChart.dispose();
      };
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const tokenData = localStorage.getItem("token");
      if (!tokenData) {
        console.log("Token not found in localStorage");
        return;
      }

      let access_token;
      try {
        const data = JSON.parse(tokenData);
        access_token = data.access_token;
        if (!access_token) {
          console.log("Access token is missing");
          return;
        }
      } catch (e) {
        console.log("Failed to parse token data");
        return;
      }

      try {
        const requestData = {
          data: {},
        };

        const config = {
          method: "post",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_GATEWAY_URL}/atz/subscription/usage`,
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
          data: requestData,
        };
        // setLoadingDevices(true);
        // setNoDeviceData(false);

        setLoadingUsage(true);
        const response = await axios(config);
        const data = response.data.data;
        console.log(data);

        if (response.status === 200) {
          // setLoadingDevices(false);
          setLoadingUsage(false);
          const deviceName = data.map((item) => item.label);
          const utilized = data.map((item) => item.utilizedCount);
          const total = data.map((item) => item.totalCount);
          setOptions(deviceName);
          setSeries(utilized);
          setSeries1(total);
        }
      } catch (e) {
        // setLoadingDevices(false);
        // setNoDeviceData(true);

        setLoadingUsage(false);
        console.error(e);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (chartInstance) {
      const option = {
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        legend: {},
        grid: {
          left: "6%",
          right: "6%",
          bottom: "3%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: [0, 0.01],
          data: options,
          axisLabel: {
            color: "#808080", // Change to your desired color
          },
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "Utilized",
            type: "bar",
            data: series ? series : [],
            barWidth: "5%",
            color: "#019ca2",
            itemStyle: {
              borderRadius: [10, 10, 0, 0], // Round top-left and top-right corners
            },
          },
          {
            name: "Total",
            type: "bar",
            data: series1 ? series1 : [],
            barWidth: "5%",
            color: "#b675c0",
            itemStyle: {
              borderRadius: [10, 10, 0, 0], // Round top-left and top-right corners
            },
          },
        ],
      };

      chartInstance.setOption(option);
      chartInstance.resize();
    }
  }, [options, series, series1, chartInstance]);

  return <div ref={chartRef} style={{ height: "400px", width: "100%" }}></div>;
};

export default UsageChart;
