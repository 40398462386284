import React from "react";
import moment from "moment";
const AiTabCards = ({ linkData, color }) => {
  //   console.log(linkData);
  console.log(color);
  const { title } = linkData;
  const date = moment(linkData.createdOn).format("DD MMM, YYYY hh:mm a");
  const colors = [
    "#007BFF",
    "#FF5733",
    "#32CD32",
    "#FFD700",
    "#FF00FF",
    "#40E0D0",
    "#FF7F50",
    "#6A0DAD",
    "#FF69B4",
    "#6F00FF",
    "#DC143C",
    "#00BFFF",
  ];
  const number = Math.floor((Math.random() * 10) % 6);

  const borderColor = colors[number];

  return (
    <>
      <div className="flex flex-1">
        <div class="flex flex-1 mt-5">
          {/* <!-- Events List Group --> */}
          <ul class="flex flex-col flex-1 bg-white border border-gray-200 rounded-xl -space-y-px dark:bg-neutral-800 dark:border-neutral-700 hover:bg-neutral-50 dark:hover:bg-neutral-600">
            {/* <!-- List Item --> */}
            <li
              class="border-t border-gray-200 first:border-t-0 dark:border-neutral-700 cursor-pointer"
              onClick={() =>
                (window.location.href = `/links-ai?id=${linkData.linkId}+${linkData.id}`)
              }
            >
              <div class="p-3 ps-0 grid sm:flex sm:items-center gap-y-2 sm:gap-y-0 sm:gap-x-3">
                <div class="sm:order-2 sm:ms-auto ps-3 sm:ps-0">
                  <button
                    type="button"
                    class="py-1.5 px-2  items-center gap-x-2 text-xs rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 hidden sm:inline-flex "
                    // onClick={() =>
                    //   (window.location.href = `/links-ai?id=${linkData.linkId}+${linkData.id}`)
                    // }
                  >
                    Details
                  </button>
                </div>
                <div class="sm:order-1 ">
                  <button
                    type="button"
                    class={`ps-[13px] -ms-px group block text-start border-s-[3px] focus:outline-none inline-flex items-center gap-x-4`}
                    data-hs-overlay="#hs-pro-deem"
                    style={{ borderColor: color }}
                    onClick={() =>
                      (window.location.href = `/links-ai?id=${linkData.linkId}+${linkData.id}`)
                    }
                  >
                    <div>
                      <span class="flex flex-shrink-0 justify-center items-center size-7 bg-white border border-gray-200 rounded-lg dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300">
                        <svg
                          height="16"
                          width="16"
                          fill={color}
                          viewBox="0 0 24 24"
                          data-name="Layer 1"
                        >
                          <path d="M10,21.236,6.755,14.745.264,11.5,6.755,8.255,10,1.764l3.245,6.491L19.736,11.5l-6.491,3.245ZM18,21l1.5,3L21,21l3-1.5L21,18l-1.5-3L18,18l-3,1.5ZM19.333,4.667,20.5,7l1.167-2.333L24,3.5,21.667,2.333,20.5,0,19.333,2.333,17,3.5Z"></path>
                        </svg>
                      </span>
                    </div>
                    <div>
                      <span class="block text-start text-sm font-medium text-gray-800 group-hover:text-[#019ca2] group-focus:text-[#019ca2] dark:group-hover:text-[#019ca2] dark:group-focus:text-[#019ca2] dark:text-neutral-200">
                        {date}
                      </span>
                      <span class="block text-sm text-gray-600 dark:text-neutral-400">
                        {title}
                      </span>
                    </div>
                  </button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default AiTabCards;
