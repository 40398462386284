import React, { useEffect } from "react";
import Header from "./Header";
import Footer1 from "./Footer1";
import { useLocation } from "react-router-dom";

const Pricing = () => {
  const url =
    process.env.REACT_APP_IDP_URL +
    "/oauth2/authorize?response_type=code&client_id=atz&scope=openid&redirect_uri=" +
    process.env.REACT_APP_ATZ_CALLBACK_URL;
  const location = useLocation();
  useEffect(() => {
    // Reinitialize Preline components every time the location changes
    if (
      window.HSStaticMethods &&
      typeof window.HSStaticMethods.autoInit === "function"
    ) {
      window.HSStaticMethods.autoInit();
    }
  }, [location.pathname]);
  return (
    <>
      <Header url={url} />
      <div className="bg-white dark:bg-neutral-950 pt-8">
        {/* <!-- Features --> */}
        <div className="grid grid-cols-1 place-items-center lg:px-28 py-10">
          <div className="flex flex-col flex-1 items-center justify-center gap-y-3">
            <div className="">
              <span className="border dark:border-none w-auto py-1 rounded-r-full rounded-l-full bg-primary-100 dark:bg-primary-950  px-4 text-sm font-bold dark:text-white text-[#019ca2]">
                Find the perfect plan to fit your needs
              </span>
            </div>
            <div>
              <span className="inline-flex text-[40px] font-black items-center gap-x-1.5 text-neutral-800 dark:text-neutral-50">
                Atz: Pricing Plans That Fit Your Budget
              </span>
            </div>
            <div className="text-center">
              <span className="text-xl font-medium text-[#353535] dark:text-neutral-50">
                Atz offers a variety of pricing plans to meet the needs of all
                businesses. Whether you're just starting out, or an agency, or a
                seasoned marketing pro, we have a plan that's right for you.
              </span>
            </div>
            <div className="flex flex-col gap-y-2.5">
              <div className="flex gap-x-2.5 items-center">
                <div className="flex flex-1  text-base dark:text-neutral-50 text-[#353535]">
                  No credit card required for Freemium users. Scale your plan as
                  your needs grow.
                </div>
              </div>
            </div>
            <div className="flex items-center">
              <button
                className="border-2 dark:border-primary-950 border-primary-100 text-primary-900 rounded-r-full font-semibold text-lg rounded-l-full px-6 py-2 hover:drop-shadow-lg pt-1"
                onClick={() => (window.location.href = url)}
              >
                <div className="inline-flex gap-x-2 items-center ">
                  <div className="bg-primary-900 size-1 animate-ping rounded-full"></div>
                  <span className="italic ">Sign Up Now!</span>
                </div>
              </button>
            </div>
          </div>
        </div>
        <div class="overflow-hidden">
          <div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-8 mx-auto">
            {/* <!-- Title --> */}
            <div class="mx-auto max-w-2xl mb-8 lg:mb-14 text-center">
              <h2 class="text-xl lg:text-xl text-gray-800 font-bold dark:text-neutral-200 underline">
                Choose the Atz Plan that Works for you
              </h2>
            </div>
            {/* <!-- End Title --> */}

            <div class="relative xl:w-10/12 xl:mx-auto">
              {/* <!-- Grid --> */}
              <div class="grid grid-cols-1 lg:grid-cols-2 gap-6 lg:gap-8">
                <div>
                  {/* <!-- Card --> */}
                  <div class="p-4 relative z-10 bg-white border rounded-xl md:p-10 dark:bg-neutral-900 dark:border-neutral-800">
                    <h3 class="text-xl font-bold text-gray-800 dark:text-neutral-200">
                      Freemium
                    </h3>
                    <div class="text-sm text-gray-500 dark:text-neutral-500">
                      Ideal for individuals or small businesses who need basic
                      link shortening and QR code capabilities. The Freemium
                      plan offers access to essential analytics and includes one
                      gallery to showcase your shortened URLs.
                    </div>

                    <div class="mt-5">
                      <span class="text-6xl font-bold text-gray-800 dark:text-neutral-200">
                        $0
                      </span>
                      <span class="text-lg font-bold text-gray-800 dark:text-neutral-200">
                        .00
                      </span>
                      <span class="ms-3 text-gray-500 dark:text-neutral-500">
                        USD / monthly
                      </span>
                    </div>

                    <div class="mt-5 grid sm:grid-cols-1 gap-y-2 py-4 first:pt-0 last:pb-0 sm:gap-x-6 sm:gap-y-0">
                      {/* <!-- List --> */}
                      <ul class="space-y-2 text-sm sm:text-base">
                        <li class="flex gap-x-3">
                          <span class="mt-0.5 size-5 flex justify-center items-center rounded-full bg-primary-50 text-primary-600 dark:bg-primary-800/30 dark:text-primary-500">
                            <svg
                              class="shrink-0 size-3.5"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <polyline points="20 6 9 17 4 12" />
                            </svg>
                          </span>
                          <span class="text-gray-800 dark:text-neutral-200">
                            Link shortening: 10 links/month
                          </span>
                        </li>

                        <li class="flex gap-x-3">
                          <span class="mt-0.5 size-5 flex justify-center items-center rounded-full bg-primary-50 text-primary-600 dark:bg-primary-800/30 dark:text-primary-500">
                            <svg
                              class="shrink-0 size-3.5"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <polyline points="20 6 9 17 4 12" />
                            </svg>
                          </span>
                          <span class="text-gray-800 dark:text-neutral-200">
                            QR codes: 3 codes/month
                          </span>
                        </li>

                        <li class="flex gap-x-3">
                          <span class="mt-0.5 size-5 flex justify-center items-center rounded-full bg-primary-50 text-primary-600 dark:bg-primary-800/30 dark:text-primary-500">
                            <svg
                              class="shrink-0 size-3.5"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <polyline points="20 6 9 17 4 12" />
                            </svg>
                          </span>
                          <span class="text-gray-800 dark:text-neutral-200">
                            AI Credits: 30 one-time
                          </span>
                        </li>
                      </ul>
                      {/* <!-- End List --> */}
                    </div>

                    <div class="mt-5 grid grid-cols-2 gap-x-4 py-4 first:pt-0 last:pb-0">
                      <div>
                        <p class="text-sm text-gray-500 dark:text-neutral-500">
                          Cancel anytime.
                        </p>
                        <p class="text-sm text-gray-500 dark:text-neutral-500">
                          No card required.
                        </p>
                      </div>

                      <div class="flex justify-end">
                        <button
                          type="button"
                          class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                          onClick={() => (window.location.href = url)}
                        >
                          Get stared
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* <!-- End Card --> */}
                </div>

                <div>
                  {/* <!-- Card --> */}
                  <div class=" shadow-gray-200 p-5 relative z-10 bg-white border rounded-xl md:p-10 dark:bg-neutral-900 dark:border-neutral-800 dark:shadow-gray-900/20">
                    <h3 class="text-xl font-bold text-gray-800 dark:text-neutral-200">
                      Startup
                    </h3>
                    <div class="text-sm text-gray-500 dark:text-neutral-500">
                      Perfect for small businesses looking to scale their
                      digital presence. With 100 links per month and 5 QR codes,
                      the Startup plan also includes a branded domain and more
                      AI credits to enhance your campaigns.
                    </div>

                    <div class="mt-5">
                      <span class="text-6xl font-bold text-gray-800 dark:text-neutral-200">
                        $5
                      </span>
                      <span class="text-lg font-bold text-gray-800 dark:text-neutral-200">
                        .00
                      </span>
                      <span class="ms-3 text-gray-500 dark:text-neutral-500">
                        USD / monthly
                      </span>
                    </div>

                    <div class="mt-5 grid sm:grid-cols-1 gap-y-2 py-4 first:pt-0 last:pb-0 sm:gap-x-6 sm:gap-y-0">
                      {/* <!-- List --> */}
                      <ul class="space-y-2 text-sm sm:text-base">
                        <li class="flex gap-x-3">
                          <span class="mt-0.5 size-5 flex justify-center items-center rounded-full bg-primary-50 text-primary-600 dark:bg-primary-800/30 dark:text-primary-500">
                            <svg
                              class="shrink-0 size-3.5"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <polyline points="20 6 9 17 4 12" />
                            </svg>
                          </span>
                          <span class="text-gray-800 dark:text-neutral-200">
                            Link shortening: 100 links/month
                          </span>
                        </li>

                        <li class="flex gap-x-3">
                          <span class="mt-0.5 size-5 flex justify-center items-center rounded-full bg-primary-50 text-primary-600 dark:bg-primary-800/30 dark:text-primary-500">
                            <svg
                              class="shrink-0 size-3.5"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <polyline points="20 6 9 17 4 12" />
                            </svg>
                          </span>
                          <span class="text-gray-800 dark:text-neutral-200">
                            Custom Domain: 1
                          </span>
                        </li>

                        <li class="flex gap-x-3">
                          <span class="mt-0.5 size-5 flex justify-center items-center rounded-full bg-primary-50 text-primary-600 dark:bg-primary-800/30 dark:text-primary-500">
                            <svg
                              class="shrink-0 size-3.5"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <polyline points="20 6 9 17 4 12" />
                            </svg>
                          </span>
                          <span class="text-gray-800 dark:text-neutral-200">
                            AI Credits: 100 one-time
                          </span>
                        </li>
                      </ul>
                      {/* <!-- End List --> */}
                    </div>

                    <div class="mt-5 grid grid-cols-2 gap-x-4 py-4 first:pt-0 last:pb-0">
                      <div>
                        <p class="text-sm text-gray-500 dark:text-neutral-500">
                          Cancel anytime.
                        </p>
                        <p class="text-sm text-gray-500 dark:text-neutral-500">
                          No card required.
                        </p>
                      </div>

                      <div class="flex justify-end">
                        <button
                          type="button"
                          class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-primary-600 text-white hover:bg-primary-700 focus:outline-none focus:bg-primary-700 disabled:opacity-50 disabled:pointer-events-none"
                          onClick={() => (window.location.href = url)}
                        >
                          Get stared
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* <!-- End Card --> */}
                </div>
                <div>
                  {/* <!-- Card --> */}
                  <div class="shadow-2xl shadow-gray-200 p-5 relative z-10 bg-white border rounded-xl md:p-10 dark:bg-neutral-900 dark:border-neutral-800 dark:shadow-gray-900/20">
                    <h3 class="text-xl font-bold text-gray-800 dark:text-neutral-200">
                      Standard
                    </h3>
                    <div class="text-sm text-gray-500 dark:text-neutral-500">
                      For growing businesses that need more bandwidth for their
                      campaigns, the Standard plan offers 500 links per month
                      and expanded features such as up to 10 QR codes and access
                      to 5 galleries.
                    </div>
                    <span class="absolute top-0 end-0 rounded-se-xl rounded-es-xl text-xs font-medium bg-primary-800 text-white py-1.5 px-3 dark:bg-primary-950 dark:text-neutral-50">
                      Most popular
                    </span>

                    <div class="mt-5">
                      <span class="text-6xl font-bold text-gray-800 dark:text-neutral-200">
                        $21
                      </span>
                      <span class="text-lg font-bold text-gray-800 dark:text-neutral-200">
                        .00
                      </span>
                      <span class="ms-3 text-gray-500 dark:text-neutral-500">
                        USD / monthly
                      </span>
                    </div>

                    <div class="mt-5 grid sm:grid-cols-1 gap-y-2 py-4 first:pt-0 last:pb-0 sm:gap-x-6 sm:gap-y-0">
                      {/* <!-- List --> */}
                      <ul class="space-y-2 text-sm sm:text-base">
                        <li class="flex gap-x-3">
                          <span class="mt-0.5 size-5 flex justify-center items-center rounded-full bg-primary-50 text-primary-600 dark:bg-primary-800/30 dark:text-primary-500">
                            <svg
                              class="shrink-0 size-3.5"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <polyline points="20 6 9 17 4 12" />
                            </svg>
                          </span>
                          <span class="text-gray-800 dark:text-neutral-200">
                            Link shortening: 500 links/month
                          </span>
                        </li>

                        <li class="flex gap-x-3">
                          <span class="mt-0.5 size-5 flex justify-center items-center rounded-full bg-primary-50 text-primary-600 dark:bg-primary-800/30 dark:text-primary-500">
                            <svg
                              class="shrink-0 size-3.5"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <polyline points="20 6 9 17 4 12" />
                            </svg>
                          </span>
                          <span class="text-gray-800 dark:text-neutral-200">
                            Custom Domains: 3
                          </span>
                        </li>

                        <li class="flex gap-x-3">
                          <span class="mt-0.5 size-5 flex justify-center items-center rounded-full bg-primary-50 text-primary-600 dark:bg-primary-800/30 dark:text-primary-500">
                            <svg
                              class="shrink-0 size-3.5"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <polyline points="20 6 9 17 4 12" />
                            </svg>
                          </span>
                          <span class="text-gray-800 dark:text-neutral-200">
                            AI Credits: 350 one-time
                          </span>
                        </li>
                      </ul>
                      {/* <!-- End List --> */}
                    </div>

                    <div class="mt-5 grid grid-cols-2 gap-x-4 py-4 first:pt-0 last:pb-0">
                      <div>
                        <p class="text-sm text-gray-500 dark:text-neutral-500">
                          Cancel anytime.
                        </p>
                        <p class="text-sm text-gray-500 dark:text-neutral-500">
                          No card required.
                        </p>
                      </div>

                      <div class="flex justify-end">
                        <button
                          type="button"
                          class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-primary-600 text-white hover:bg-primary-700 focus:outline-none focus:bg-primary-700 disabled:opacity-50 disabled:pointer-events-none"
                          onClick={() => (window.location.href = url)}
                        >
                          Get stared
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* <!-- End Card --> */}
                </div>
                <div>
                  {/* <!-- Card --> */}
                  <div class=" shadow-gray-200 p-5 relative z-10 bg-white border rounded-xl md:p-10 dark:bg-neutral-900 dark:border-neutral-800 dark:shadow-gray-900/20">
                    <h3 class="text-xl font-bold text-gray-800 dark:text-neutral-200">
                      Pro
                    </h3>
                    <div class="text-sm text-gray-500 dark:text-neutral-500">
                      If you’re running high-traffic campaigns, the Pro plan
                      gives you more capacity with 5000 links/month, 25 QR
                      codes, and five branded domains. Includes 2500 AI credits
                      for enhancing your marketing efforts.
                    </div>

                    <div class="mt-5">
                      <span class="text-6xl font-bold text-gray-800 dark:text-neutral-200">
                        $250
                      </span>
                      <span class="text-lg font-bold text-gray-800 dark:text-neutral-200">
                        .00
                      </span>
                      <span class="ms-3 text-gray-500 dark:text-neutral-500">
                        USD / monthly
                      </span>
                    </div>

                    <div class="mt-5 grid sm:grid-cols-1 gap-y-2 py-4 first:pt-0 last:pb-0 sm:gap-x-6 sm:gap-y-0">
                      {/* <!-- List --> */}
                      <ul class="space-y-2 text-sm sm:text-base">
                        <li class="flex gap-x-3">
                          <span class="mt-0.5 size-5 flex justify-center items-center rounded-full bg-primary-50 text-primary-600 dark:bg-primary-800/30 dark:text-primary-500">
                            <svg
                              class="shrink-0 size-3.5"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <polyline points="20 6 9 17 4 12" />
                            </svg>
                          </span>
                          <span class="text-gray-800 dark:text-neutral-200">
                            Link shortening: 5000 links/month
                          </span>
                        </li>

                        <li class="flex gap-x-3">
                          <span class="mt-0.5 size-5 flex justify-center items-center rounded-full bg-primary-50 text-primary-600 dark:bg-primary-800/30 dark:text-primary-500">
                            <svg
                              class="shrink-0 size-3.5"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <polyline points="20 6 9 17 4 12" />
                            </svg>
                          </span>
                          <span class="text-gray-800 dark:text-neutral-200">
                            Custom Domains: 5
                          </span>
                        </li>

                        <li class="flex gap-x-3">
                          <span class="mt-0.5 size-5 flex justify-center items-center rounded-full bg-primary-50 text-primary-600 dark:bg-primary-800/30 dark:text-primary-500">
                            <svg
                              class="shrink-0 size-3.5"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <polyline points="20 6 9 17 4 12" />
                            </svg>
                          </span>
                          <span class="text-gray-800 dark:text-neutral-200">
                            AI Credits: 2500 one-time
                          </span>
                        </li>
                      </ul>
                      {/* <!-- End List --> */}
                    </div>

                    <div class="mt-5 grid grid-cols-2 gap-x-4 py-4 first:pt-0 last:pb-0">
                      <div>
                        <p class="text-sm text-gray-500 dark:text-neutral-500">
                          Cancel anytime.
                        </p>
                        <p class="text-sm text-gray-500 dark:text-neutral-500">
                          No card required.
                        </p>
                      </div>

                      <div class="flex justify-end">
                        <button
                          type="button"
                          class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-primary-600 text-white hover:bg-primary-700 focus:outline-none focus:bg-primary-700 disabled:opacity-50 disabled:pointer-events-none"
                          onClick={() => (window.location.href = url)}
                        >
                          Get stared
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* <!-- End Card --> */}
                </div>
                <div className="lg:col-span-2">
                  {/* <!-- Card --> */}
                  <div class=" shadow-gray-200 p-5 relative z-10 bg-white border rounded-xl md:p-10 dark:bg-neutral-900 dark:border-neutral-800 dark:shadow-gray-900/20">
                    <h3 class="text-xl font-bold text-gray-800 dark:text-neutral-200">
                      Enterprise
                    </h3>
                    <div class="text-sm text-gray-500 dark:text-neutral-500">
                      For large-scale enterprises that require maximum capacity
                      and performance, the Enterprise plan offers 20,000 links
                      per month, 50 QR codes, and 10 branded domains. With 5000
                      AI credits and unlimited analytics, this plan supports
                      even the most demanding campaigns.
                    </div>

                    <div class="mt-5">
                      <span class="text-6xl font-bold text-gray-800 dark:text-neutral-200">
                        $599
                      </span>
                      <span class="text-lg font-bold text-gray-800 dark:text-neutral-200">
                        .00
                      </span>
                      <span class="ms-3 text-gray-500 dark:text-neutral-500">
                        USD / monthly
                      </span>
                    </div>

                    <div class="mt-5 grid sm:grid-cols-1 gap-y-2 py-4 first:pt-0 last:pb-0 sm:gap-x-6 sm:gap-y-0">
                      {/* <!-- List --> */}
                      <ul class="space-y-2 text-sm sm:text-base">
                        <li class="flex gap-x-3">
                          <span class="mt-0.5 size-5 flex justify-center items-center rounded-full bg-primary-50 text-primary-600 dark:bg-primary-800/30 dark:text-primary-500">
                            <svg
                              class="shrink-0 size-3.5"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <polyline points="20 6 9 17 4 12" />
                            </svg>
                          </span>
                          <span class="text-gray-800 dark:text-neutral-200">
                            Link shortening: 20,000 links/month
                          </span>
                        </li>

                        <li class="flex gap-x-3">
                          <span class="mt-0.5 size-5 flex justify-center items-center rounded-full bg-primary-50 text-primary-600 dark:bg-primary-800/30 dark:text-primary-500">
                            <svg
                              class="shrink-0 size-3.5"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <polyline points="20 6 9 17 4 12" />
                            </svg>
                          </span>
                          <span class="text-gray-800 dark:text-neutral-200">
                            Custom Domains: 10
                          </span>
                        </li>

                        <li class="flex gap-x-3">
                          <span class="mt-0.5 size-5 flex justify-center items-center rounded-full bg-primary-50 text-primary-600 dark:bg-primary-800/30 dark:text-primary-500">
                            <svg
                              class="shrink-0 size-3.5"
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            >
                              <polyline points="20 6 9 17 4 12" />
                            </svg>
                          </span>
                          <span class="text-gray-800 dark:text-neutral-200">
                            AI Credits: 5000 one-time
                          </span>
                        </li>
                      </ul>
                      {/* <!-- End List --> */}
                    </div>

                    <div class="mt-5 grid grid-cols-2 gap-x-4 py-4 first:pt-0 last:pb-0">
                      <div>
                        <p class="text-sm text-gray-500 dark:text-neutral-500">
                          Cancel anytime.
                        </p>
                        <p class="text-sm text-gray-500 dark:text-neutral-500">
                          No card required.
                        </p>
                      </div>

                      <div class="flex justify-end">
                        <button
                          type="button"
                          class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-primary-600 text-white hover:bg-primary-700 focus:outline-none focus:bg-primary-700 disabled:opacity-50 disabled:pointer-events-none"
                          onClick={() => (window.location.href = url)}
                        >
                          Get stared
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* <!-- End Card --> */}
                </div>
              </div>
              {/* <!-- End Grid --> */}

              {/* <!-- SVG Element --> */}
              <div class="hidden md:block absolute top-0 end-0 translate-y-16 translate-x-16">
                <svg
                  class="w-16 h-auto text-orange-500"
                  width="121"
                  height="135"
                  viewBox="0 0 121 135"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 16.4754C11.7688 27.4499 21.2452 57.3224 5 89.0164"
                    stroke="currentColor"
                    stroke-width="10"
                    stroke-linecap="round"
                  />
                  <path
                    d="M33.6761 112.104C44.6984 98.1239 74.2618 57.6776 83.4821 5"
                    stroke="currentColor"
                    stroke-width="10"
                    stroke-linecap="round"
                  />
                  <path
                    d="M50.5525 130C68.2064 127.495 110.731 117.541 116 78.0874"
                    stroke="currentColor"
                    stroke-width="10"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              {/* <!-- End SVG Element --> */}

              {/* <!-- SVG Element --> */}
              <div class="hidden md:block absolute bottom-0 start-0 translate-y-16 -translate-x-16">
                <svg
                  class="w-56 h-auto text-cyan-500"
                  width="347"
                  height="188"
                  viewBox="0 0 347 188"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 82.4591C54.7956 92.8751 30.9771 162.782 68.2065 181.385C112.642 203.59 127.943 78.57 122.161 25.5053C120.504 2.2376 93.4028 -8.11128 89.7468 25.5053C85.8633 61.2125 130.186 199.678 180.982 146.248L214.898 107.02C224.322 95.4118 242.9 79.2851 258.6 107.02C274.299 134.754 299.315 125.589 309.861 117.539L343 93.4426"
                    stroke="currentColor"
                    stroke-width="7"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
              {/* <!-- End SVG Element --> */}
            </div>

            <div class="mt-7 text-center">
              <p class="text-xs text-gray-400">
                Prices in USD. Taxes may apply.
              </p>
            </div>
          </div>
        </div>
        {/* <!-- End Features --> */}
        <div className="grid grid-cols-1 gap-y-6 lg:px-28 py-10 p-4 bg-white dark:bg-neutral-950">
          <div className="flex flex-col w-auto rounded-[50px]">
            <div className="flex flex-1 lg:flex-row flex-col bg-[#C4E0FD] rounded-[50px] bg-opacity-30 p-10 items-center">
              <div className="flex flex-col flex-1 gap-y-5">
                <div className="flex flex-1 justify-center">
                  <div className="font-black text-[40px] text-[#353535] dark:text-neutral-50 ">
                    All Plans Include:
                  </div>
                </div>
                <div className="flex flex-col gap-y-2.5">
                  <div className="flex gap-x-2.5 items-center">
                    <div className="flex flex-none">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.5"
                          y="0.5"
                          width="23"
                          height="23"
                          rx="5.5"
                          fill="url(#paint0_linear_1867_1372)"
                          stroke="#353535"
                        />
                        <path
                          d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                          fill="white"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_1867_1372"
                            x1="24"
                            y1="12"
                            x2="0"
                            y2="12"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#9966FF" />
                            <stop offset="1" stop-color="#00CCFF" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                      Unlimited Analytics: Get detailed insights into how your
                      links and QR codes are performing.
                    </div>
                  </div>
                  <div className="flex gap-x-2.5 items-center">
                    <div className="flex flex-none">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.5"
                          y="0.5"
                          width="23"
                          height="23"
                          rx="5.5"
                          fill="url(#paint0_linear_1867_1372)"
                          stroke="#353535"
                        />
                        <path
                          d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                          fill="white"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_1867_1372"
                            x1="24"
                            y1="12"
                            x2="0"
                            y2="12"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#9966FF" />
                            <stop offset="1" stop-color="#00CCFF" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                      Customizable Galleries: Showcase your URLs and content in
                      visually appealing galleries.
                    </div>
                  </div>
                  <div className="flex gap-x-2.5 items-center">
                    <div className="flex flex-none">
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="0.5"
                          y="0.5"
                          width="23"
                          height="23"
                          rx="5.5"
                          fill="url(#paint0_linear_1867_1372)"
                          stroke="#353535"
                        />
                        <path
                          d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                          fill="white"
                        />
                        <defs>
                          <linearGradient
                            id="paint0_linear_1867_1372"
                            x1="24"
                            y1="12"
                            x2="0"
                            y2="12"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#9966FF" />
                            <stop offset="1" stop-color="#00CCFF" />
                          </linearGradient>
                        </defs>
                      </svg>
                    </div>
                    <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                      AI-powered Shortening Tools: Optimize your links and
                      marketing with our AI tools.
                    </div>
                  </div>
                </div>
                <div className="flex gap-x-2.5 items-center">
                  <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                    Ready to get started? Choose a plan that suits your needs
                    and take your link shortening and analytics to the next
                    level.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Comparison Table --> */}
        <div class="relative">
          <div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 md:py-14 lg:py-20 mx-auto">
            <div class="max-w-2xl mx-auto text-center mb-10 lg:mb-14">
              <h2 class="text-2xl font-bold md:text-3xl md:leading-tight dark:text-white">
                Compare plans
              </h2>
            </div>

            {/* <!-- Header --> */}
            <div class="hidden lg:block sticky top-0 start-0 py-2 bg-white/60 backdrop-blur-md dark:bg-neutral-900/60">
              {/* <!-- Grid --> */}
              <div class="grid grid-cols-7 gap-6">
                <div class="col-span-2">
                  <span class="font-semibold text-lg text-gray-800 dark:text-neutral-200">
                    Features
                  </span>
                </div>
                {/* <!-- End Col --> */}

                <div class="col-span-1">
                  <span class="font-semibold text-lg text-gray-800 dark:text-neutral-200">
                    Freemium
                  </span>
                  <p class="mt-2 text-sm text-gray-500 dark:text-neutral-500">
                    $0/month
                  </p>
                </div>
                {/* <!-- End Col --> */}

                <div class="col-span-1">
                  <span class="font-semibold text-lg text-gray-800 dark:text-neutral-200">
                    Startup
                  </span>
                  <p class="mt-2 text-sm text-gray-500 dark:text-neutral-500">
                    $5/month
                  </p>
                </div>
                {/* <!-- End Col --> */}

                <div class="col-span-1">
                  <span class="font-semibold text-lg text-gray-800 dark:text-neutral-200">
                    Standard
                  </span>
                  <p class="mt-2 text-sm text-gray-500 dark:text-neutral-500">
                    $21/month
                  </p>
                </div>
                {/* <!-- End Col --> */}

                <div class="col-span-1">
                  <span class="font-semibold text-lg text-gray-800 dark:text-neutral-200">
                    Pro
                  </span>
                  <p class="mt-2 text-sm text-gray-500 dark:text-neutral-500">
                    $250/month
                  </p>
                </div>
                <div class="col-span-1">
                  <span class="font-semibold text-lg text-gray-800 dark:text-neutral-200">
                    Enterprise
                  </span>
                  <p class="mt-2 text-sm text-gray-500 dark:text-neutral-500">
                    $599/month
                  </p>
                </div>
                {/* <!-- End Col --> */}
              </div>
              {/* <!-- End Grid --> */}
            </div>
            {/* <!-- End Header --> */}

            {/* <!-- Section --> */}
            <div class="space-y-4 lg:space-y-0">
              {/* <!-- List --> */}
              <ul class="grid lg:grid-cols-7 lg:gap-6">
                {/* <!-- Item --> */}

                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"></li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"></li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"></li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"></li>
                {/* <!-- End Item --> */}
              </ul>
              {/* <!-- End List --> */}

              {/* <!-- List --> */}
              <ul class="grid lg:grid-cols-7 lg:gap-6">
                {/* <!-- Item --> */}
                <li class="lg:col-span-2 pb-1.5 lg:py-3">
                  <span class="font-semibold lg:font-normal text-sm text-gray-800 dark:text-neutral-200">
                    Links Shortening
                  </span>
                </li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="col-span-1 py-1.5 lg:py-3 border-b border-gray-200 dark:border-neutral-800">
                  <div class="grid grid-cols-2 md:grid-cols-6 lg:block">
                    <span class="lg:hidden md:col-span-2 text-sm text-gray-800 dark:text-neutral-200">
                      Freemium
                    </span>
                    <span class="text-sm text-gray-800 dark:text-neutral-200">
                      10 links/month
                    </span>
                  </div>
                </li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="col-span-1 py-1.5 lg:py-3 border-b border-gray-200 dark:border-neutral-800">
                  <div class="grid grid-cols-2 md:grid-cols-6 lg:block">
                    <span class="lg:hidden md:col-span-2 text-sm text-gray-800 dark:text-neutral-200">
                      Startup
                    </span>
                    <span class="text-sm text-gray-800 dark:text-neutral-200">
                      100 links/month
                    </span>
                  </div>
                </li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="col-span-1 py-1.5 lg:py-3 border-b border-gray-200 dark:border-neutral-800">
                  <div class="grid grid-cols-2 md:grid-cols-6 lg:block">
                    <span class="lg:hidden md:col-span-2 text-sm text-gray-800 dark:text-neutral-200">
                      Standard
                    </span>
                    <span class="text-sm text-gray-800 dark:text-neutral-200">
                      500 links/month
                    </span>
                  </div>
                </li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="col-span-1 py-1.5 lg:py-3 border-b border-gray-200 dark:border-neutral-800">
                  <div class="grid grid-cols-2 md:grid-cols-6 lg:block">
                    <span class="lg:hidden md:col-span-2 text-sm text-gray-800 dark:text-neutral-200">
                      Pro
                    </span>
                    <span class="text-sm text-gray-800 dark:text-neutral-200">
                      5000 links/month
                    </span>
                  </div>
                </li>
                <li class="col-span-1 py-1.5 lg:py-3 border-b border-gray-200 dark:border-neutral-800">
                  <div class="grid grid-cols-2 md:grid-cols-6 lg:block">
                    <span class="lg:hidden md:col-span-2 text-sm text-gray-800 dark:text-neutral-200">
                      Enterprise
                    </span>
                    <span class="text-sm text-gray-800 dark:text-neutral-200">
                      20000 links/month
                    </span>
                  </div>
                </li>
                {/* <!-- End Item --> */}
              </ul>
              {/* <!-- End List --> */}

              {/* <!-- List --> */}
            </div>
            {/* <!-- End Section --> */}
            {/* <!-- Section --> */}
            <div class="space-y-4 lg:space-y-0">
              {/* <!-- List --> */}
              <ul class="grid lg:grid-cols-7 lg:gap-6">
                {/* <!-- Item --> */}

                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"></li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"></li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"></li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"></li>
                {/* <!-- End Item --> */}
              </ul>
              {/* <!-- End List --> */}

              {/* <!-- List --> */}
              <ul class="grid lg:grid-cols-7 lg:gap-6">
                {/* <!-- Item --> */}
                <li class="lg:col-span-2 pb-1.5 lg:py-3">
                  <span class="font-semibold lg:font-normal text-sm text-gray-800 dark:text-neutral-200">
                    QR Codes
                  </span>
                </li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="col-span-1 py-1.5 lg:py-3 border-b border-gray-200 dark:border-neutral-800">
                  <div class="grid grid-cols-2 md:grid-cols-6 lg:block">
                    <span class="lg:hidden md:col-span-2 text-sm text-gray-800 dark:text-neutral-200">
                      Freemium
                    </span>
                    <span class="text-sm text-gray-800 dark:text-neutral-200">
                      3 codes/month
                    </span>
                  </div>
                </li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="col-span-1 py-1.5 lg:py-3 border-b border-gray-200 dark:border-neutral-800">
                  <div class="grid grid-cols-2 md:grid-cols-6 lg:block">
                    <span class="lg:hidden md:col-span-2 text-sm text-gray-800 dark:text-neutral-200">
                      Startup
                    </span>
                    <span class="text-sm text-gray-800 dark:text-neutral-200">
                      5 codes/month
                    </span>
                  </div>
                </li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="col-span-1 py-1.5 lg:py-3 border-b border-gray-200 dark:border-neutral-800">
                  <div class="grid grid-cols-2 md:grid-cols-6 lg:block">
                    <span class="lg:hidden md:col-span-2 text-sm text-gray-800 dark:text-neutral-200">
                      Standard
                    </span>
                    <span class="text-sm text-gray-800 dark:text-neutral-200">
                      10 codes/month
                    </span>
                  </div>
                </li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="col-span-1 py-1.5 lg:py-3 border-b border-gray-200 dark:border-neutral-800">
                  <div class="grid grid-cols-2 md:grid-cols-6 lg:block">
                    <span class="lg:hidden md:col-span-2 text-sm text-gray-800 dark:text-neutral-200">
                      Pro
                    </span>
                    <span class="text-sm text-gray-800 dark:text-neutral-200">
                      25 codes/month
                    </span>
                  </div>
                </li>
                <li class="col-span-1 py-1.5 lg:py-3 border-b border-gray-200 dark:border-neutral-800">
                  <div class="grid grid-cols-2 md:grid-cols-6 lg:block">
                    <span class="lg:hidden md:col-span-2 text-sm text-gray-800 dark:text-neutral-200">
                      Enterprise
                    </span>
                    <span class="text-sm text-gray-800 dark:text-neutral-200">
                      50 codes/month
                    </span>
                  </div>
                </li>
                {/* <!-- End Item --> */}
              </ul>
              {/* <!-- End List --> */}

              {/* <!-- List --> */}
            </div>
            {/* <!-- End Section --> */}
            {/* <!-- Section --> */}
            <div class="space-y-4 lg:space-y-0">
              {/* <!-- List --> */}
              <ul class="grid lg:grid-cols-7 lg:gap-6">
                {/* <!-- Item --> */}

                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"></li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"></li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"></li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"></li>
                {/* <!-- End Item --> */}
              </ul>
              {/* <!-- End List --> */}

              {/* <!-- List --> */}
              <ul class="grid lg:grid-cols-7 lg:gap-6">
                {/* <!-- Item --> */}
                <li class="lg:col-span-2 pb-1.5 lg:py-3">
                  <span class="font-semibold lg:font-normal text-sm text-gray-800 dark:text-neutral-200">
                    Custom Domains
                  </span>
                </li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="col-span-1 py-1.5 lg:py-3 border-b border-gray-200 dark:border-neutral-800">
                  <div class="grid grid-cols-2 md:grid-cols-6 lg:block">
                    <span class="lg:hidden md:col-span-2 text-sm text-gray-800 dark:text-neutral-200">
                      Freemium
                    </span>
                    <span class="text-sm text-gray-800 dark:text-neutral-200">
                      0
                    </span>
                  </div>
                </li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="col-span-1 py-1.5 lg:py-3 border-b border-gray-200 dark:border-neutral-800">
                  <div class="grid grid-cols-2 md:grid-cols-6 lg:block">
                    <span class="lg:hidden md:col-span-2 text-sm text-gray-800 dark:text-neutral-200">
                      Startup
                    </span>
                    <span class="text-sm text-gray-800 dark:text-neutral-200">
                      1
                    </span>
                  </div>
                </li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="col-span-1 py-1.5 lg:py-3 border-b border-gray-200 dark:border-neutral-800">
                  <div class="grid grid-cols-2 md:grid-cols-6 lg:block">
                    <span class="lg:hidden md:col-span-2 text-sm text-gray-800 dark:text-neutral-200">
                      Standard
                    </span>
                    <span class="text-sm text-gray-800 dark:text-neutral-200">
                      3
                    </span>
                  </div>
                </li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="col-span-1 py-1.5 lg:py-3 border-b border-gray-200 dark:border-neutral-800">
                  <div class="grid grid-cols-2 md:grid-cols-6 lg:block">
                    <span class="lg:hidden md:col-span-2 text-sm text-gray-800 dark:text-neutral-200">
                      Pro
                    </span>
                    <span class="text-sm text-gray-800 dark:text-neutral-200">
                      5
                    </span>
                  </div>
                </li>
                <li class="col-span-1 py-1.5 lg:py-3 border-b border-gray-200 dark:border-neutral-800">
                  <div class="grid grid-cols-2 md:grid-cols-6 lg:block">
                    <span class="lg:hidden md:col-span-2 text-sm text-gray-800 dark:text-neutral-200">
                      Enterprise
                    </span>
                    <span class="text-sm text-gray-800 dark:text-neutral-200">
                      10
                    </span>
                  </div>
                </li>
                {/* <!-- End Item --> */}
              </ul>
              {/* <!-- End List --> */}

              {/* <!-- List --> */}
            </div>
            {/* <!-- End Section --> */}
            {/* <!-- Section --> */}
            <div class="space-y-4 lg:space-y-0">
              {/* <!-- List --> */}
              <ul class="grid lg:grid-cols-7 lg:gap-6">
                {/* <!-- Item --> */}

                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"></li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"></li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"></li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"></li>
                {/* <!-- End Item --> */}
              </ul>
              {/* <!-- End List --> */}

              {/* <!-- List --> */}
              <ul class="grid lg:grid-cols-7 lg:gap-6">
                {/* <!-- Item --> */}
                <li class="lg:col-span-2 pb-1.5 lg:py-3">
                  <span class="font-semibold lg:font-normal text-sm text-gray-800 dark:text-neutral-200">
                    AI Credits
                  </span>
                </li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="col-span-1 py-1.5 lg:py-3 border-b border-gray-200 dark:border-neutral-800">
                  <div class="grid grid-cols-2 md:grid-cols-6 lg:block">
                    <span class="lg:hidden md:col-span-2 text-sm text-gray-800 dark:text-neutral-200">
                      Freemium
                    </span>
                    <span class="text-sm text-gray-800 dark:text-neutral-200">
                      30 one-time
                    </span>
                  </div>
                </li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="col-span-1 py-1.5 lg:py-3 border-b border-gray-200 dark:border-neutral-800">
                  <div class="grid grid-cols-2 md:grid-cols-6 lg:block">
                    <span class="lg:hidden md:col-span-2 text-sm text-gray-800 dark:text-neutral-200">
                      Startup
                    </span>
                    <span class="text-sm text-gray-800 dark:text-neutral-200">
                      100 one-time
                    </span>
                  </div>
                </li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="col-span-1 py-1.5 lg:py-3 border-b border-gray-200 dark:border-neutral-800">
                  <div class="grid grid-cols-2 md:grid-cols-6 lg:block">
                    <span class="lg:hidden md:col-span-2 text-sm text-gray-800 dark:text-neutral-200">
                      Standard
                    </span>
                    <span class="text-sm text-gray-800 dark:text-neutral-200">
                      350 one-time
                    </span>
                  </div>
                </li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="col-span-1 py-1.5 lg:py-3 border-b border-gray-200 dark:border-neutral-800">
                  <div class="grid grid-cols-2 md:grid-cols-6 lg:block">
                    <span class="lg:hidden md:col-span-2 text-sm text-gray-800 dark:text-neutral-200">
                      Pro
                    </span>
                    <span class="text-sm text-gray-800 dark:text-neutral-200">
                      2500 one-time
                    </span>
                  </div>
                </li>
                <li class="col-span-1 py-1.5 lg:py-3 border-b border-gray-200 dark:border-neutral-800">
                  <div class="grid grid-cols-2 md:grid-cols-6 lg:block">
                    <span class="lg:hidden md:col-span-2 text-sm text-gray-800 dark:text-neutral-200">
                      Enterprise
                    </span>
                    <span class="text-sm text-gray-800 dark:text-neutral-200">
                      5000 one-time
                    </span>
                  </div>
                </li>
                {/* <!-- End Item --> */}
              </ul>
              {/* <!-- End List --> */}

              {/* <!-- List --> */}
            </div>
            {/* <!-- End Section --> */}
            {/* <!-- Section --> */}
            <div class="space-y-4 lg:space-y-0">
              {/* <!-- List --> */}
              <ul class="grid lg:grid-cols-7 lg:gap-6">
                {/* <!-- Item --> */}

                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"></li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"></li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"></li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"></li>
                {/* <!-- End Item --> */}
              </ul>
              {/* <!-- End List --> */}

              {/* <!-- List --> */}
              <ul class="grid lg:grid-cols-7 lg:gap-6">
                {/* <!-- Item --> */}
                <li class="lg:col-span-2 pb-1.5 lg:py-3">
                  <span class="font-semibold lg:font-normal text-sm text-gray-800 dark:text-neutral-200">
                    Analytics
                  </span>
                </li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="col-span-1 py-1.5 lg:py-3 border-b border-gray-200 dark:border-neutral-800">
                  <div class="grid grid-cols-2 md:grid-cols-6 lg:block">
                    <span class="lg:hidden md:col-span-2 text-sm text-gray-800 dark:text-neutral-200">
                      Freemium
                    </span>
                    <span class="text-sm text-gray-800 dark:text-neutral-200">
                      Unlimited
                    </span>
                  </div>
                </li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="col-span-1 py-1.5 lg:py-3 border-b border-gray-200 dark:border-neutral-800">
                  <div class="grid grid-cols-2 md:grid-cols-6 lg:block">
                    <span class="lg:hidden md:col-span-2 text-sm text-gray-800 dark:text-neutral-200">
                      Startup
                    </span>
                    <span class="text-sm text-gray-800 dark:text-neutral-200">
                      Unlimited
                    </span>
                  </div>
                </li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="col-span-1 py-1.5 lg:py-3 border-b border-gray-200 dark:border-neutral-800">
                  <div class="grid grid-cols-2 md:grid-cols-6 lg:block">
                    <span class="lg:hidden md:col-span-2 text-sm text-gray-800 dark:text-neutral-200">
                      Standard
                    </span>
                    <span class="text-sm text-gray-800 dark:text-neutral-200">
                      Unlimited
                    </span>
                  </div>
                </li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="col-span-1 py-1.5 lg:py-3 border-b border-gray-200 dark:border-neutral-800">
                  <div class="grid grid-cols-2 md:grid-cols-6 lg:block">
                    <span class="lg:hidden md:col-span-2 text-sm text-gray-800 dark:text-neutral-200">
                      Pro
                    </span>
                    <span class="text-sm text-gray-800 dark:text-neutral-200">
                      Unlimited
                    </span>
                  </div>
                </li>
                <li class="col-span-1 py-1.5 lg:py-3 border-b border-gray-200 dark:border-neutral-800">
                  <div class="grid grid-cols-2 md:grid-cols-6 lg:block">
                    <span class="lg:hidden md:col-span-2 text-sm text-gray-800 dark:text-neutral-200">
                      Enterprise
                    </span>
                    <span class="text-sm text-gray-800 dark:text-neutral-200">
                      Unlimited
                    </span>
                  </div>
                </li>
                {/* <!-- End Item --> */}
              </ul>
              {/* <!-- End List --> */}

              {/* <!-- List --> */}
            </div>
            {/* <!-- End Section --> */}
            {/* <!-- Section --> */}
            <div class="space-y-4 lg:space-y-0">
              {/* <!-- List --> */}
              <ul class="grid lg:grid-cols-7 lg:gap-6">
                {/* <!-- Item --> */}

                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"></li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"></li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"></li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"></li>
                {/* <!-- End Item --> */}
              </ul>
              {/* <!-- End List --> */}

              {/* <!-- List --> */}
              <ul class="grid lg:grid-cols-7 lg:gap-6">
                {/* <!-- Item --> */}
                <li class="lg:col-span-2 pb-1.5 lg:py-3">
                  <span class="font-semibold lg:font-normal text-sm text-gray-800 dark:text-neutral-200">
                    Gallery
                  </span>
                </li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="col-span-1 py-1.5 lg:py-3 border-b border-gray-200 dark:border-neutral-800">
                  <div class="grid grid-cols-2 md:grid-cols-6 lg:block">
                    <span class="lg:hidden md:col-span-2 text-sm text-gray-800 dark:text-neutral-200">
                      Freemium
                    </span>
                    <span class="text-sm text-gray-800 dark:text-neutral-200">
                      1 gallery/month
                    </span>
                  </div>
                </li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="col-span-1 py-1.5 lg:py-3 border-b border-gray-200 dark:border-neutral-800">
                  <div class="grid grid-cols-2 md:grid-cols-6 lg:block">
                    <span class="lg:hidden md:col-span-2 text-sm text-gray-800 dark:text-neutral-200">
                      Startup
                    </span>
                    <span class="text-sm text-gray-800 dark:text-neutral-200">
                      3 gallery/month
                    </span>
                  </div>
                </li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="col-span-1 py-1.5 lg:py-3 border-b border-gray-200 dark:border-neutral-800">
                  <div class="grid grid-cols-2 md:grid-cols-6 lg:block">
                    <span class="lg:hidden md:col-span-2 text-sm text-gray-800 dark:text-neutral-200">
                      Standard
                    </span>
                    <span class="text-sm text-gray-800 dark:text-neutral-200">
                      5 gallery/month
                    </span>
                  </div>
                </li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="col-span-1 py-1.5 lg:py-3 border-b border-gray-200 dark:border-neutral-800">
                  <div class="grid grid-cols-2 md:grid-cols-6 lg:block">
                    <span class="lg:hidden md:col-span-2 text-sm text-gray-800 dark:text-neutral-200">
                      Pro
                    </span>
                    <span class="text-sm text-gray-800 dark:text-neutral-200">
                      10 gallery/month
                    </span>
                  </div>
                </li>
                <li class="col-span-1 py-1.5 lg:py-3 border-b border-gray-200 dark:border-neutral-800">
                  <div class="grid grid-cols-2 md:grid-cols-6 lg:block">
                    <span class="lg:hidden md:col-span-2 text-sm text-gray-800 dark:text-neutral-200">
                      Enterprise
                    </span>
                    <span class="text-sm text-gray-800 dark:text-neutral-200">
                      25 gallery/month
                    </span>
                  </div>
                </li>
                {/* <!-- End Item --> */}
              </ul>
              {/* <!-- End List --> */}

              {/* <!-- List --> */}
            </div>
            {/* <!-- End Section --> */}
            {/* <!-- Section --> */}
            <div class="space-y-4 lg:space-y-0">
              {/* <!-- List --> */}
              <ul class="grid lg:grid-cols-7 lg:gap-6">
                {/* <!-- Item --> */}

                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"></li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"></li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"></li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="hidden lg:block lg:col-span-1 py-1.5 lg:py-3 px-4 lg:px-0 lg:text-center"></li>
                {/* <!-- End Item --> */}
              </ul>
              {/* <!-- End List --> */}

              {/* <!-- List --> */}
              <ul class="grid lg:grid-cols-7 lg:gap-6">
                {/* <!-- Item --> */}
                <li class="lg:col-span-2 pb-1.5 lg:py-3">
                  <span class="font-semibold lg:font-normal text-sm text-gray-800 dark:text-neutral-200">
                    Bulk Shortening
                  </span>
                </li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="col-span-1 py-1.5 lg:py-3 border-b border-gray-200 dark:border-neutral-800">
                  <div class="grid grid-cols-2 md:grid-cols-6 lg:block">
                    <span class="lg:hidden md:col-span-2 text-sm text-gray-800 dark:text-neutral-200">
                      Freemium
                    </span>
                    <span class="text-sm text-gray-800 dark:text-neutral-200">
                      Not available
                    </span>
                  </div>
                </li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="col-span-1 py-1.5 lg:py-3 border-b border-gray-200 dark:border-neutral-800">
                  <div class="grid grid-cols-2 md:grid-cols-6 lg:block">
                    <span class="lg:hidden md:col-span-2 text-sm text-gray-800 dark:text-neutral-200">
                      Startup
                    </span>
                    <span class="text-sm text-gray-800 dark:text-neutral-200">
                      Not available
                    </span>
                  </div>
                </li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="col-span-1 py-1.5 lg:py-3 border-b border-gray-200 dark:border-neutral-800">
                  <div class="grid grid-cols-2 md:grid-cols-6 lg:block">
                    <span class="lg:hidden md:col-span-2 text-sm text-gray-800 dark:text-neutral-200">
                      Standard
                    </span>
                    <span class="text-sm text-gray-800 dark:text-neutral-200">
                      Not available
                    </span>
                  </div>
                </li>
                {/* <!-- End Item --> */}

                {/* <!-- Item --> */}
                <li class="col-span-1 py-1.5 lg:py-3 border-b border-gray-200 dark:border-neutral-800">
                  <div class="grid grid-cols-2 md:grid-cols-6 lg:block">
                    <span class="lg:hidden md:col-span-2 text-sm text-gray-800 dark:text-neutral-200">
                      Pro
                    </span>
                    <span class="text-sm text-gray-800 dark:text-neutral-200">
                      Not available
                    </span>
                  </div>
                </li>
                <li class="col-span-1 py-1.5 lg:py-3 border-b border-gray-200 dark:border-neutral-800">
                  <div class="grid grid-cols-2 md:grid-cols-6 lg:block">
                    <span class="lg:hidden md:col-span-2 text-sm text-gray-800 dark:text-neutral-200">
                      Enterprise
                    </span>
                    <span class="text-sm text-gray-800 dark:text-neutral-200">
                      Not available
                    </span>
                  </div>
                </li>
                {/* <!-- End Item --> */}
              </ul>
              {/* <!-- End List --> */}

              {/* <!-- List --> */}
            </div>
            {/* <!-- End Section --> */}

            {/* <!-- Header --> */}
            <div class="hidden lg:block mt-6">
              {/* <!-- Grid --> */}
              <div class="grid grid-cols-7 gap-6">
                <div class="col-span-2"></div>
                {/* <!-- End Col --> */}

                <div class="col-span-1">
                  <button
                    class="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                    onClick={() => (window.location.href = url)}
                  >
                    Get started
                  </button>
                </div>
                <div class="col-span-1">
                  <button
                    class="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                    onClick={() => (window.location.href = url)}
                  >
                    Get started
                  </button>
                </div>
                {/* <!-- End Col --> */}

                <div class="col-span-1">
                  <button
                    class="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-primary-600 text-white hover:bg-primary-700 focus:outline-none focus:bg-primary-700 disabled:opacity-50 disabled:pointer-events-none"
                    onClick={() => (window.location.href = url)}
                  >
                    Get started
                  </button>
                </div>
                {/* <!-- End Col --> */}

                <div class="col-span-1">
                  <a
                    class="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                    href={url}
                  >
                    Get started
                  </a>
                </div>
                {/* <!-- End Col --> */}

                <div class="col-span-1">
                  <a
                    class="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                    href={url}
                  >
                    Get started
                  </a>
                </div>
                {/* <!-- End Col --> */}
              </div>
              {/* <!-- End Grid --> */}
            </div>
            {/* <!-- End Header --> */}

            {/* <!-- Button Group --> */}
            <div class="mt-8 md:mt-12 flex justify-center items-center gap-x-3">
              <p class="text-sm text-gray-500 dark:text-neutral-400">
                Looking for a plan that's not listed?
              </p>

              <button
                type="button"
                class="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                onClick={() => {
                  document.querySelector('[aria-label="Open live chat"]');
                }}
              >
                Contact us
              </button>
            </div>
            {/* <!-- End Button Group --> */}
          </div>
        </div>
        {/* <!-- End Comparison Table --> */}
      </div>

      {/* faqs */}
      <div className="min-h-96 md:px-28 md:py-10 p-4 pb-12 flex flex-col items-center bg-white dark:bg-neutral-950 gap-y-8">
        <span className="relative text-5xl dark:text-neutral-50 font-black text-center ">
          FAQs
          <img
            src={require("../../assets/landing/Vector3.png")}
            className="absolute inset-0"
          />
        </span>
        <div class="hs-accordion-group space-y-6">
          <div
            class="hs-accordion  bg-white border border-transparent rounded-xl dark:bg-neutral-800 dark:border-transparent drop-shadow-lg rounded-r-[24.06px] rounded-l-[24.06px]"
            id="hs-active-bordered-heading-one"
          >
            <button
              class="hs-accordion-toggle inline-flex justify-between items-center gap-x-3 w-full font-semibold text-2xl  text-start text-[#0A0B1E] py-7 px-6 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
              aria-expanded="false"
              aria-controls="hs-basic-active-bordered-collapse-one"
            >
              What’s included in the Freemium plan?
              <svg
                class="hs-accordion-active:hidden block size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5v14"></path>
              </svg>
              <svg
                class="hs-accordion-active:block hidden size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
              </svg>
            </button>
            <div
              id="hs-basic-active-bordered-collapse-one"
              class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
              role="region"
              aria-labelledby="hs-active-bordered-heading-one"
            >
              <div class="pb-4 px-5">
                <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] ">
                  The Freemium plan includes up to 10 link shortens per month, 3
                  QR codes, and 1 gallery to showcase your URLs. It also offers
                  unlimited analytics to track the performance of your links.
                  While this plan does not offer custom branded domains or bulk
                  shortening, it’s a great way to get started for free.
                </p>
              </div>
            </div>
          </div>

          <div
            class="hs-accordion  bg-white border border-transparent rounded-xl dark:bg-neutral-800 dark:border-transparent drop-shadow-lg rounded-r-[24.06px] rounded-l-[24.06px]"
            id="hs-active-bordered-heading-two"
          >
            <button
              class="hs-accordion-toggle inline-flex justify-between items-center gap-x-3 w-full font-semibold text-2xl  text-start text-[#0A0B1E] py-7 px-6 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
              aria-expanded="true"
              aria-controls="hs-basic-active-bordered-collapse-two"
            >
              Can I upgrade my plan at any time?
              <svg
                class="hs-accordion-active:hidden block size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5v14"></path>
              </svg>
              <svg
                class="hs-accordion-active:block hidden size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
              </svg>
            </button>
            <div
              id="hs-basic-active-bordered-collapse-two"
              class="hs-accordion-content w-full overflow-hidden transition-[height] duration-300"
              role="region"
              aria-labelledby="hs-active-bordered-heading-two"
            >
              <div class="pb-4 px-5">
                <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] ">
                  Yes! You can upgrade or downgrade your plan at any time based
                  on your needs. As your business or campaigns grow, you can
                  easily switch to a higher plan for more link shortening
                  capacity, QR codes, and custom domain support.
                </p>
              </div>
            </div>
          </div>

          <div
            class="hs-accordion  bg-white border border-transparent rounded-xl dark:bg-neutral-800 dark:border-transparent drop-shadow-lg rounded-r-[24.06px] rounded-l-[24.06px]"
            id="hs-active-bordered-heading-three"
          >
            <button
              class="hs-accordion-toggle inline-flex justify-between items-center gap-x-3 w-full font-semibold text-2xl  text-start text-[#0A0B1E] py-7 px-6 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
              aria-expanded="false"
              aria-controls="hs-basic-active-bordered-collapse-three"
            >
              What are AI Credits, and how do they work?
              <svg
                class="hs-accordion-active:hidden block size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5v14"></path>
              </svg>
              <svg
                class="hs-accordion-active:block hidden size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
              </svg>
            </button>
            <div
              id="hs-basic-active-bordered-collapse-three"
              class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
              role="region"
              aria-labelledby="hs-active-bordered-heading-three"
            >
              <div class="pb-4 px-5">
                <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] ">
                  AI Credits allow you to leverage our AI-powered tools for
                  optimizing your links, enhancing marketing campaigns, and
                  improving audience targeting. Each plan provides a certain
                  amount of AI Credits, with higher plans offering more to suit
                  your campaign needs.
                </p>
              </div>
            </div>
          </div>
          <div
            class="hs-accordion  bg-white border border-transparent rounded-xl dark:bg-neutral-800 dark:border-transparent drop-shadow-lg rounded-r-[24.06px] rounded-l-[24.06px]"
            id="hs-active-bordered-heading-four"
          >
            <button
              class="hs-accordion-toggle inline-flex justify-between items-center gap-x-3 w-full font-semibold text-2xl  text-start text-[#0A0B1E] py-7 px-6 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
              aria-expanded="false"
              aria-controls="hs-basic-active-bordered-collapse-four"
            >
              Do all plans come with analytics?
              <svg
                class="hs-accordion-active:hidden block size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5v14"></path>
              </svg>
              <svg
                class="hs-accordion-active:block hidden size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
              </svg>
            </button>
            <div
              id="hs-basic-active-bordered-collapse-four"
              class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
              role="region"
              aria-labelledby="hs-active-bordered-heading-four"
            >
              <div class="pb-4 px-5">
                <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] ">
                  Yes, all our plans include unlimited analytics. You’ll have
                  access to detailed reports on your shortened links, including
                  click-through rates, geographic data, device usage, and more
                  to track your marketing performance.
                </p>
              </div>
            </div>
          </div>
          <div
            class="hs-accordion  bg-white border border-transparent rounded-xl dark:bg-neutral-800 dark:border-transparent drop-shadow-lg rounded-r-[24.06px] rounded-l-[24.06px]"
            id="hs-active-bordered-heading-five"
          >
            <button
              class="hs-accordion-toggle inline-flex justify-between items-center gap-x-3 w-full font-semibold text-2xl  text-start text-[#0A0B1E] py-7 px-6 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
              aria-expanded="false"
              aria-controls="hs-basic-active-bordered-collapse-five"
            >
              Can I use a custom or branded domain?
              <svg
                class="hs-accordion-active:hidden block size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5v14"></path>
              </svg>
              <svg
                class="hs-accordion-active:block hidden size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
              </svg>
            </button>
            <div
              id="hs-basic-active-bordered-collapse-five"
              class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
              role="region"
              aria-labelledby="hs-active-bordered-heading-five"
            >
              <div class="pb-4 px-5">
                <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] ">
                  Yes, starting from the Startup plan, you can use a
                  custom/branded domain to enhance your brand identity. The
                  higher your plan, the more branded domains you can connect to
                  your account. The Freemium plan does not offer this feature.
                </p>
              </div>
            </div>
          </div>
          <div
            class="hs-accordion  bg-white border border-transparent rounded-xl dark:bg-neutral-800 dark:border-transparent drop-shadow-lg rounded-r-[24.06px] rounded-l-[24.06px]"
            id="hs-active-bordered-heading-six"
          >
            <button
              class="hs-accordion-toggle inline-flex justify-between items-center gap-x-3 w-full font-semibold text-2xl  text-start text-[#0A0B1E] py-7 px-6 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
              aria-expanded="false"
              aria-controls="hs-basic-active-bordered-collapse-six"
            >
              What is the difference between the Startup and Standard plans?
              <svg
                class="hs-accordion-active:hidden block size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5v14"></path>
              </svg>
              <svg
                class="hs-accordion-active:block hidden size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
              </svg>
            </button>
            <div
              id="hs-basic-active-bordered-collapse-six"
              class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
              role="region"
              aria-labelledby="hs-active-bordered-heading-six"
            >
              <div class="pb-4 px-5">
                <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] ">
                  The Startup plan is ideal for small businesses or individuals
                  looking to manage up to 100 links and 5 QR codes per month,
                  with 1 custom domain. The Standard plan is designed for
                  growing businesses and offers 500 links, 10 QR codes, and 3
                  custom domains, along with more AI Credits and galleries for
                  showcasing URLs.
                </p>
              </div>
            </div>
          </div>
          <div
            class="hs-accordion  bg-white border border-transparent rounded-xl dark:bg-neutral-800 dark:border-transparent drop-shadow-lg rounded-r-[24.06px] rounded-l-[24.06px]"
            id="hs-active-bordered-heading-seven"
          >
            <button
              class="hs-accordion-toggle inline-flex justify-between items-center gap-x-3 w-full font-semibold text-2xl  text-start text-[#0A0B1E] py-7 px-6 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
              aria-expanded="false"
              aria-controls="hs-basic-active-bordered-collapse-seven"
            >
              Is there a limit to how many links I can shorten per month?
              <svg
                class="hs-accordion-active:hidden block size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5v14"></path>
              </svg>
              <svg
                class="hs-accordion-active:block hidden size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
              </svg>
            </button>
            <div
              id="hs-basic-active-bordered-collapse-seven"
              class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
              role="region"
              aria-labelledby="hs-active-bordered-heading-seven"
            >
              <div class="pb-4 px-5">
                <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] ">
                  Yes, each plan has a defined limit on the number of links you
                  can shorten per month:
                  <br />
                  Freemium: 10 links
                  <br />
                  Startup: 100 links
                  <br />
                  Standard: 500 links
                  <br />
                  Pro: 5000 links
                  <br />
                  Enterprise: 20,000 links
                  <br /> If you exceed your limit, you can upgrade to a higher
                  plan to accommodate your needs.
                </p>
              </div>
            </div>
          </div>
          <div
            class="hs-accordion  bg-white border border-transparent rounded-xl dark:bg-neutral-800 dark:border-transparent drop-shadow-lg rounded-r-[24.06px] rounded-l-[24.06px]"
            id="hs-active-bordered-heading-eight"
          >
            <button
              class="hs-accordion-toggle inline-flex justify-between items-center gap-x-3 w-full font-semibold text-2xl  text-start text-[#0A0B1E] py-7 px-6 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
              aria-expanded="false"
              aria-controls="hs-basic-active-bordered-collapse-eight"
            >
              Do you offer bulk shortening features?
              <svg
                class="hs-accordion-active:hidden block size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5v14"></path>
              </svg>
              <svg
                class="hs-accordion-active:block hidden size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
              </svg>
            </button>
            <div
              id="hs-basic-active-bordered-collapse-eight"
              class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
              role="region"
              aria-labelledby="hs-active-bordered-heading-eight"
            >
              <div class="pb-4 px-5">
                <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] ">
                  Currently, bulk shortening is only available with Pro and
                  Enterprise plans. This feature allows you to shorten large
                  volumes of URLs at once, which is ideal for businesses
                  managing multiple campaigns simultaneously.
                </p>
              </div>
            </div>
          </div>
          <div
            class="hs-accordion  bg-white border border-transparent rounded-xl dark:bg-neutral-800 dark:border-transparent drop-shadow-lg rounded-r-[24.06px] rounded-l-[24.06px]"
            id="hs-active-bordered-heading-nine"
          >
            <button
              class="hs-accordion-toggle inline-flex justify-between items-center gap-x-3 w-full font-semibold text-2xl  text-start text-[#0A0B1E] py-7 px-6 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
              aria-expanded="false"
              aria-controls="hs-basic-active-bordered-collapse-nine"
            >
              How do the galleries work in each plan?
              <svg
                class="hs-accordion-active:hidden block size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5v14"></path>
              </svg>
              <svg
                class="hs-accordion-active:block hidden size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
              </svg>
            </button>
            <div
              id="hs-basic-active-bordered-collapse-nine"
              class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
              role="region"
              aria-labelledby="hs-active-bordered-heading-nine"
            >
              <div class="pb-4 px-5">
                <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] ">
                  Galleries allow you to visually present your shortened links
                  in an organized manner. The number of galleries you can create
                  depends on your plan:
                  <br />
                  Freemium: 1 gallery
                  <br />
                  Startup: 3 galleries
                  <br />
                  Standard: 5 galleries
                  <br />
                  Pro: 10 galleries
                  <br />
                  Enterprise: 25 galleries
                </p>
              </div>
            </div>
          </div>
          <div
            class="hs-accordion  bg-white border border-transparent rounded-xl dark:bg-neutral-800 dark:border-transparent drop-shadow-lg rounded-r-[24.06px] rounded-l-[24.06px]"
            id="hs-active-bordered-heading-ten"
          >
            <button
              class="hs-accordion-toggle inline-flex justify-between items-center gap-x-3 w-full font-semibold text-2xl  text-start text-[#0A0B1E] py-7 px-6 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
              aria-expanded="false"
              aria-controls="hs-basic-active-bordered-collapse-ten"
            >
              What payment methods do you accept?
              <svg
                class="hs-accordion-active:hidden block size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5v14"></path>
              </svg>
              <svg
                class="hs-accordion-active:block hidden size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
              </svg>
            </button>
            <div
              id="hs-basic-active-bordered-collapse-ten"
              class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
              role="region"
              aria-labelledby="hs-active-bordered-heading-ten"
            >
              <div class="pb-4 px-5">
                <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] ">
                  We accept all major credit and debit cards. Payments for the
                  Freemium plan are free, while other plans will be billed
                  monthly or annually depending on your subscription preference.
                </p>
              </div>
            </div>
          </div>
          <div
            class="hs-accordion  bg-white border border-transparent rounded-xl dark:bg-neutral-800 dark:border-transparent drop-shadow-lg rounded-r-[24.06px] rounded-l-[24.06px]"
            id="hs-active-bordered-heading-eleven"
          >
            <button
              class="hs-accordion-toggle inline-flex justify-between items-center gap-x-3 w-full font-semibold text-2xl  text-start text-[#0A0B1E] py-7 px-6 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
              aria-expanded="false"
              aria-controls="hs-basic-active-bordered-collapse-eleven"
            >
              Is there a discount for annual billing?
              <svg
                class="hs-accordion-active:hidden block size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5v14"></path>
              </svg>
              <svg
                class="hs-accordion-active:block hidden size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
              </svg>
            </button>
            <div
              id="hs-basic-active-bordered-collapse-eleven"
              class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
              role="region"
              aria-labelledby="hs-active-bordered-heading-eleven"
            >
              <div class="pb-4 px-5">
                <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] ">
                  Yes, if you opt for annual billing, you’ll receive a discount
                  compared to the monthly billing rate. This allows you to save
                  more in the long run while enjoying all the benefits of your
                  chosen plan.
                </p>
              </div>
            </div>
          </div>
          <div
            class="hs-accordion  bg-white border border-transparent rounded-xl dark:bg-neutral-800 dark:border-transparent drop-shadow-lg rounded-r-[24.06px] rounded-l-[24.06px]"
            id="hs-active-bordered-heading-twelve"
          >
            <button
              class="hs-accordion-toggle inline-flex justify-between items-center gap-x-3 w-full font-semibold text-2xl  text-start text-[#0A0B1E] py-7 px-6 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
              aria-expanded="false"
              aria-controls="hs-basic-active-bordered-collapse-twelve"
            >
              How do I cancel my subscription?
              <svg
                class="hs-accordion-active:hidden block size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5v14"></path>
              </svg>
              <svg
                class="hs-accordion-active:block hidden size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
              </svg>
            </button>
            <div
              id="hs-basic-active-bordered-collapse-twelve"
              class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
              role="region"
              aria-labelledby="hs-active-bordered-heading-twelve"
            >
              <div class="pb-4 px-5">
                <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] ">
                  You can cancel your subscription at any time from your account
                  settings. If you cancel in the middle of a billing cycle,
                  you’ll still have access to your features until the end of the
                  current period. No further payments will be processed once the
                  subscription is canceled.
                </p>
              </div>
            </div>
          </div>
          <div
            class="hs-accordion  bg-white border border-transparent rounded-xl dark:bg-neutral-800 dark:border-transparent drop-shadow-lg rounded-r-[24.06px] rounded-l-[24.06px]"
            id="hs-active-bordered-heading-thirteen"
          >
            <button
              class="hs-accordion-toggle inline-flex justify-between items-center gap-x-3 w-full font-semibold text-2xl  text-start text-[#0A0B1E] py-7 px-6 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
              aria-expanded="false"
              aria-controls="hs-basic-active-bordered-collapse-thirteen"
            >
              Can I get a refund if I cancel mid-month?
              <svg
                class="hs-accordion-active:hidden block size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5v14"></path>
              </svg>
              <svg
                class="hs-accordion-active:block hidden size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
              </svg>
            </button>
            <div
              id="hs-basic-active-bordered-collapse-thirteen"
              class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
              role="region"
              aria-labelledby="hs-active-bordered-heading-thirteen"
            >
              <div class="pb-4 px-5">
                <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] ">
                  Refunds are not available for mid-month cancellations.
                  However, you’ll retain access to all features until the end of
                  your billing cycle.
                </p>
              </div>
            </div>
          </div>
          <div
            class="hs-accordion  bg-white border border-transparent rounded-xl dark:bg-neutral-800 dark:border-transparent drop-shadow-lg rounded-r-[24.06px] rounded-l-[24.06px]"
            id="hs-active-bordered-heading-fourteen"
          >
            <button
              class="hs-accordion-toggle inline-flex justify-between items-center gap-x-3 w-full font-semibold text-2xl  text-start text-[#0A0B1E] py-7 px-6 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
              aria-expanded="false"
              aria-controls="hs-basic-active-bordered-collapse-fourteen"
            >
              Do I need to install any software to use Atz services?
              <svg
                class="hs-accordion-active:hidden block size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5v14"></path>
              </svg>
              <svg
                class="hs-accordion-active:block hidden size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
              </svg>
            </button>
            <div
              id="hs-basic-active-bordered-collapse-fourteen"
              class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
              role="region"
              aria-labelledby="hs-active-bordered-heading-fourteen"
            >
              <div class="pb-4 px-5">
                <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] ">
                  No, Atz is completely cloud-based. You can access all of your
                  shortening, analytics, and branding tools directly from your
                  browser without the need to install any software.
                </p>
              </div>
            </div>
          </div>
          <div
            class="hs-accordion  bg-white border border-transparent rounded-xl dark:bg-neutral-800 dark:border-transparent drop-shadow-lg rounded-r-[24.06px] rounded-l-[24.06px]"
            id="hs-active-bordered-heading-fifteen"
          >
            <button
              class="hs-accordion-toggle inline-flex justify-between items-center gap-x-3 w-full font-semibold text-2xl  text-start text-[#0A0B1E] py-7 px-6 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
              aria-expanded="false"
              aria-controls="hs-basic-active-bordered-collapse-fifteen"
            >
              How secure is my data on Atz?
              <svg
                class="hs-accordion-active:hidden block size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5v14"></path>
              </svg>
              <svg
                class="hs-accordion-active:block hidden size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
              </svg>
            </button>
            <div
              id="hs-basic-active-bordered-collapse-fifteen"
              class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
              role="region"
              aria-labelledby="hs-active-bordered-heading-fifteen"
            >
              <div class="pb-4 px-5">
                <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] ">
                  We take data security very seriously. All links, domains, and
                  analytics are secured with top-tier encryption protocols to
                  protect your information from unauthorized access.
                  Additionally, our platform complies with major privacy and
                  security standards.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer1 />
    </>
  );
};

export default Pricing;
