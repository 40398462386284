import React from "react";
import moment from "moment";
const ActivityCard = ({ activityData }) => {
  const { createdOn, description, title } = activityData;
  const local = moment(createdOn.split("T").join(" ")).local().format("LLL");
  console.log(local);
  return (
    <div>
      {/* <!-- Heading --> */}
      <div class="ps-2 my-2 first:mt-0">
        <h3 class="text-xs font-medium uppercase text-gray-500 dark:text-neutral-400">
          {local}
        </h3>
      </div>
      {/* <!-- End Heading --> */}

      {/* <!-- Item --> */}
      <div class="flex gap-x-3">
        {/* <!-- Icon --> */}
        <div class="relative last:after:hidden after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:-translate-x-[0.5px] after:bg-gray-200 dark:after:bg-neutral-700">
          <div class="relative z-10 size-7 flex justify-center items-center">
            <div class="size-2 rounded-full bg-gray-400 dark:bg-neutral-600"></div>
          </div>
        </div>
        {/* <!-- End Icon --> */}

        {/* <!-- Right Content --> */}
        <div class="grow pt-0.5 pb-8">
          <h3 class="flex gap-x-1.5 font-semibold text-gray-800 dark:text-white">
            {(title.includes("Created") || title.includes("Link")) && (
              <svg
                viewBox="0 0 24 24"
                class="shrink-0 size-4 mt-1"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M9.16488 17.6505C8.92513 17.8743 8.73958 18.0241 8.54996 18.1336C7.62175 18.6695 6.47816 18.6695 5.54996 18.1336C5.20791 17.9361 4.87912 17.6073 4.22153 16.9498C3.56394 16.2922 3.23514 15.9634 3.03767 15.6213C2.50177 14.6931 2.50177 13.5495 3.03767 12.6213C3.23514 12.2793 3.56394 11.9505 4.22153 11.2929L7.04996 8.46448C7.70755 7.80689 8.03634 7.47809 8.37838 7.28062C9.30659 6.74472 10.4502 6.74472 11.3784 7.28061C11.7204 7.47809 12.0492 7.80689 12.7068 8.46448C13.3644 9.12207 13.6932 9.45086 13.8907 9.7929C14.4266 10.7211 14.4266 11.8647 13.8907 12.7929C13.7812 12.9825 13.6314 13.1681 13.4075 13.4078M10.5919 10.5922C10.368 10.8319 10.2182 11.0175 10.1087 11.2071C9.57284 12.1353 9.57284 13.2789 10.1087 14.2071C10.3062 14.5492 10.635 14.878 11.2926 15.5355C11.9502 16.1931 12.279 16.5219 12.621 16.7194C13.5492 17.2553 14.6928 17.2553 15.621 16.7194C15.9631 16.5219 16.2919 16.1931 16.9495 15.5355L19.7779 12.7071C20.4355 12.0495 20.7643 11.7207 20.9617 11.3787C21.4976 10.4505 21.4976 9.30689 20.9617 8.37869C20.7643 8.03665 20.4355 7.70785 19.7779 7.05026C19.1203 6.39267 18.7915 6.06388 18.4495 5.8664C17.5212 5.3305 16.3777 5.3305 15.4495 5.8664C15.2598 5.97588 15.0743 6.12571 14.8345 6.34955"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                  ></path>{" "}
                </g>
              </svg>
            )}
            {title.includes("Slug") && (
              <svg
                class="shrink-0 size-4 mt-1"
                version="1.1"
                id="_x32_"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 512 512"
                xmlSpace="preserve"
                fill="currentColor"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <g>
                    {" "}
                    <path
                      class="st0"
                      d="M458.79,31.178c-29.329,0-53.195,23.858-53.195,53.199c0,14.192,5.596,27.482,15.226,37.273v24.449h-44.217 V121.65c9.632-9.791,15.23-23.081,15.23-37.273c0-29.341-23.866-53.199-53.202-53.199c-29.333,0-53.203,23.858-53.203,53.199 c0,14.799,6.233,28.726,16.676,38.626v74.156c0,2.177-0.027,5.101-0.054,8.274c-0.031,3.974-0.074,8.352-0.074,12.24 c0,42.646-62.472,93.364-163.042,132.356l-0.256,0.101l-0.256,0.101c-46.908,19.41-90.76,28.127-105.174,30.996l-1.543,0.304 c-19.352,3.872-32.09,18.656-31.697,36.79c0.506,23.423,21.98,43.059,52.231,47.772c7.8,1.205,11.536,3.04,16.712,5.56 c8.36,4.09,18.765,9.169,38.606,9.169c19.313,0,30.438-6.245,37.802-10.366c5.299-2.955,7.282-4.075,13.748-4.075 c6.451,0,8.43,1.12,13.726,4.075c7.364,4.122,18.492,10.366,37.805,10.366c19.313,0,30.437-6.245,37.802-10.366 c5.299-2.955,7.282-4.075,13.741-4.075c6.462,0,8.445,1.12,13.744,4.075c7.364,4.122,18.493,10.366,37.802,10.366 c84.771,0,183.758-40.664,183.758-155.266v-204.7c9.18-9.752,14.514-22.762,14.514-36.48C512,55.036,488.127,31.178,458.79,31.178z M472.873,109.231c0,16.976,0,114.228,0,216.326c0,114.773-112.28,130.652-159.146,130.652c-25.767,0-25.767-14.433-51.546-14.433 c-25.772,0-25.772,14.433-51.543,14.433c-25.767,0-25.767-14.433-51.531-14.433c-25.783,0-25.783,14.433-51.55,14.433 c-25.771,0-26.074-10.483-51.531-14.433c-32.49-5.062-41.153-31.774-19.495-36.098c12.745-2.551,60.31-11.594,111.3-32.692 c86.159-33.408,178.765-88.482,178.765-155.312c0-7.03,0.128-15.6,0.128-20.514c0-28.275,0-68.293,0-86.801 c-9.837-4.541-16.68-14.448-16.68-25.981c0-15.794,12.804-28.586,28.587-28.586c15.786,0,28.59,12.792,28.59,28.586 c0,10.957-6.186,20.476-15.238,25.266v48.789c0,6.781,5.502,12.287,12.283,12.287h68.899c6.778,0,12.271-5.506,12.271-12.287 v-48.789c-9.052-4.791-15.226-14.309-15.226-25.266c0-15.794,12.797-28.586,28.579-28.586c15.79,0,28.594,12.792,28.594,28.586 C487.384,95.039,481.528,104.324,472.873,109.231z"
                    ></path>{" "}
                  </g>{" "}
                </g>
              </svg>
            )}
            {title.includes("Password") && (
              <svg
                class="shrink-0 size-4 mt-1"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                <g
                  id="SVGRepo_tracerCarrier"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></g>
                <g id="SVGRepo_iconCarrier">
                  {" "}
                  <path
                    d="M15 9H15.01M15 15C18.3137 15 21 12.3137 21 9C21 5.68629 18.3137 3 15 3C11.6863 3 9 5.68629 9 9C9 9.27368 9.01832 9.54308 9.05381 9.80704C9.11218 10.2412 9.14136 10.4583 9.12172 10.5956C9.10125 10.7387 9.0752 10.8157 9.00469 10.9419C8.937 11.063 8.81771 11.1823 8.57913 11.4209L3.46863 16.5314C3.29568 16.7043 3.2092 16.7908 3.14736 16.8917C3.09253 16.9812 3.05213 17.0787 3.02763 17.1808C3 17.2959 3 17.4182 3 17.6627V19.4C3 19.9601 3 20.2401 3.10899 20.454C3.20487 20.6422 3.35785 20.7951 3.54601 20.891C3.75992 21 4.03995 21 4.6 21H6.33726C6.58185 21 6.70414 21 6.81923 20.9724C6.92127 20.9479 7.01881 20.9075 7.10828 20.8526C7.2092 20.7908 7.29568 20.7043 7.46863 20.5314L12.5791 15.4209C12.8177 15.1823 12.937 15.063 13.0581 14.9953C13.1843 14.9248 13.2613 14.8987 13.4044 14.8783C13.5417 14.8586 13.7588 14.8878 14.193 14.9462C14.4569 14.9817 14.7263 15 15 15Z"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></path>{" "}
                </g>
              </svg>
            )}
            {title.includes("Title") && (
              <svg
                class="shrink-0 size-4 mt-1"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M14.5 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.5L14.5 2z"></path>
                <polyline points="14 2 14 8 20 8"></polyline>
                <line x1="16" x2="8" y1="13" y2="13"></line>
                <line x1="16" x2="8" y1="17" y2="17"></line>
                <line x1="10" x2="8" y1="9" y2="9"></line>
              </svg>
            )}
            {title}
          </h3>
          <p class="mt-1 text-sm text-gray-600 dark:text-neutral-400">
            {description}
          </p>
          <button
            type="button"
            class="mt-1 -ms-1 p-1 inline-flex items-center gap-x-2 text-xs rounded-lg border border-transparent text-gray-500 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-400 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
          >
            <img
              class="shrink-0 size-4 rounded-full"
              src={activityData?.user?.profilePic}
              alt="Avatar"
            />
            {activityData?.user?.name}
          </button>
        </div>
        {/* <!-- End Right Content --> */}
      </div>
      {/* <!-- End Item --> */}

      {/* <!-- Item --> */}
    </div>
  );
};

export default ActivityCard;

//
