import React from "react";
import Header from "../Landing/Header";
import Footer1 from "../Landing/Footer1";

const Cookies = () => {
  const url =
    process.env.REACT_APP_IDP_URL +
    "/oauth2/authorize?response_type=code&client_id=atz&scope=openid&redirect_uri=" +
    process.env.REACT_APP_ATZ_CALLBACK_URL;
  return (
    <>
      <Header url={url} />
      <main id="content" className="bg-white dark:bg-neutral-950">
        {/* <!-- Heading --> */}
        <div class="md:max-w-screen-sm text-center px-4 sm:px-6 lg:px-8 pt-24 pb-6 mx-auto">
          <h1 class="mb-2 text-2xl font-bold md:text-4xl dark:text-white">
            Cookie Policy
          </h1>
          <p class="text-gray-700 dark:text-neutral-400">
            Last updated: October 18, 2024
          </p>
        </div>
        {/* <!-- End Heading --> */}
        {/*  */}
        {/* <!-- Content --> */}
        <div class="md:max-w-screen-sm lg:max-w-[992px] px-4 sm:px-6 lg:px-8 pb-12 md:pt-6 sm:pb-20 mx-auto">
          <div class="grid gap-4 md:gap-8">
            <div>
              <p class="mb-8 dark:text-neutral-400 text-xl">
                AtoZ Link Shortening Services uses cookies and similar tracking
                technologies to enhance your experience on our website. This
                Cookie Policy explains what cookies are, how we use them, and
                your options regarding their use.
              </p>

              <h2 class="text-lg font-semibold mb-2 dark:text-white">
                1. What Are Cookies?
              </h2>
              <p class="mb-5 dark:text-neutral-400">
                Cookies are small text files stored on your device that help us
                recognize your preferences and improve your experience on our
                site. They can be "session" or "persistent" cookies, depending
                on whether they expire when you close your browser or remain on
                your device for a set period.
              </p>

              <h2 class="text-lg font-semibold mb-2 dark:text-white">
                2. How We Use Cookies
              </h2>
              <p class="mb-5 dark:text-neutral-400">
                We use the following types of cookies:
                <br />
                Essential Cookies: These are necessary for the basic
                functionality of our website.
                <br />
                Performance Cookies: Help us understand how visitors interact
                with our website by collecting anonymous usage data.
                <br />
                Functionality Cookies: Enable us to remember your preferences,
                such as language settings.
                <br />
                Targeting/Advertising Cookies: Used to deliver relevant ads to
                users and measure the effectiveness of our marketing campaigns.
              </p>

              <h2 class="text-lg font-semibold mb-2 dark:text-white">
                3. Managing Cookies
              </h2>
              <p class="mb-5 dark:text-neutral-400">
                You can control or disable cookies through your browser
                settings. However, please note that disabling cookies may affect
                your ability to use some features of our website.
              </p>

              <h2 class="text-lg font-semibold mb-2 dark:text-white">
                4. Third-Party Cookies
              </h2>
              <p class="mb-5 dark:text-neutral-400">
                Some of the cookies we use are from third-party providers, such
                as Google Analytics, to track and analyze website performance.
              </p>

              <h2 class="mb-2 dark:text-white">
                For more information about how we use cookies or how to manage
                them, please contact us at atz@nidavellirs.com
              </h2>
            </div>
          </div>
        </div>
        {/* <!-- End Content --> */}
      </main>
      {/* <!-- ========== END MAIN CONTENT ========== --> */}
      <Footer1 />
    </>
  );
};

export default Cookies;
