import React from "react";
import axios from "axios";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_ATZ_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_ATZ_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_ATZ_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_ATZ_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_ATZ_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ATZ_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_ATZ_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const SubscriptionCard = ({
  planData,
  plan,
  activePlan,
  setSelectedPlan,
  setLoadingPayment,
}) => {
  // const generateTxnId = async (res) => {
  //   const tokenData = localStorage.getItem("token");

  //   if (!tokenData) {
  //     console.log("Token not found in localStorage");
  //     return;
  //   }

  //   let access_token;
  //   try {
  //     const data = JSON.parse(tokenData);
  //     access_token = data.access_token;
  //     if (!access_token) {
  //       console.log("Access token is missing");
  //       return;
  //     }
  //   } catch (e) {
  //     console.log("Failed to parse token data");
  //     return;
  //   }
  //   const requestData = {
  //     data: {
  //       razorpayPaymentId: res.razorpay_payment_id,
  //       razorpaySubscriptionId: res.razorpay_subscription_id,
  //       razorpaySignature: res.razorpay_signature,
  //     },
  //   };

  //   const config = {
  //     method: "post",
  //     maxBodyLength: Infinity,
  //     url: `${process.env.REACT_APP_GATEWAY_URL}/atz/payments/pay-for-subscription`,
  //     headers: {
  //       Authorization: `Bearer ${access_token}`,
  //       "Content-Type": "application/json",
  //     },
  //     data: requestData,
  //   };
  //   try {
  //     const response = await axios(config);
  //     console.log(response.data);
  //     if (response.status === 200) {
  //       setLoadingPayment(false);
  //       document.getElementById("successButton").click();
  //       setTimeout(() => {
  //         window.location.reload();
  //       }, 3000);
  //     }
  //   } catch (e) {
  //     setLoadingPayment(false);
  //     document.getElementById("failureButton").click();
  //   }
  // };

  //active sub -- subid, subs--id, priority--id, idx
  const handlePayment = async () => {
    const tokenData = localStorage.getItem("token");
    const user = JSON.parse(localStorage.getItem("user"));
    if (!tokenData) {
      console.log("Token not found in localStorage");
      return;
    }

    let access_token;
    try {
      const data = JSON.parse(tokenData);
      access_token = data.access_token;
      if (!access_token) {
        console.log("Access token is missing");
        return;
      }
    } catch (e) {
      console.log("Failed to parse token data");
      return;
    }
    const requestData = {
      data: {
        packageId: planData?.id,
      },
    };

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_GATEWAY_URL}/atz/payments/subscription-request`,
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
      data: requestData,
    };
    try {
      logEvent(analytics, `CTA_SUBSCRIPTION_UPGRADE_CLICK`);
      setLoadingPayment(true);
      const response = await axios(config);
      console.log(response.data);
      if (response.status === 200) {
        // setLoadingPayment(false);
        window.location.href = response.data.data;
      }
    } catch (e) {
      setLoadingPayment(false);
      console.log(e);
    } finally {
      setLoadingPayment(false);
    }
  };

  return (
    <div
      class={`p-4 sm:p-6 bg-white  rounded-xl dark:bg-neutral-800 hover:shadow-lg dark:hover:bg-neutral-900  ${
        planData.id === activePlan.subscriptionId
          ? `border border-[#019ca2] dark:border-[#019ca2] shadow-xl dark:bg-neutral-950`
          : `border border-gray-200 dark:border-neutral-700`
      }`}
    >
      <div class="flex justify-between items-center gap-x-2 mb-3">
        {planData.id == "66bb045d703df4a2abd3452e" && (
          <svg
            class="w-[34px] h-[30px]"
            width="34"
            height="30"
            viewBox="0 0 34 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              y="5"
              width="20"
              height="20"
              rx="10"
              fill="currentColor"
              class="fill-neutral-300 dark:fill-neutral-300"
            />
          </svg>
        )}
        {planData.id === "66bb045d703df4a2abd3452f" && (
          <svg
            class="w-[34px] h-[30px]"
            width="34"
            height="30"
            viewBox="0 0 34 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              y="5"
              width="20"
              height="20"
              rx="10"
              fill="currentColor"
              class="fill-[#b3e0e1] dark:fill-[#b3e0e1]"
            />
          </svg>
        )}
        {planData.id === "66bb045d703df4a2abd34530" && (
          <svg
            class="w-[34px] h-[30px]"
            width="34"
            height="30"
            viewBox="0 0 34 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              y="5"
              width="20"
              height="20"
              rx="10"
              fill="currentColor"
              class="fill-[#b3e0e1] dark:fill-[#b3e0e1]"
            />
            <rect
              x="14"
              y="5"
              width="20"
              height="20"
              rx="10"
              fill="currentColor"
              class="fill-[#66c3d3] dark:fill-[#66c3d3]"
            />
          </svg>
        )}
        {planData.id === "66bb045d703df4a2abd34531" && (
          <svg
            class="w-[34px] h-[30px]"
            width="34"
            height="30"
            viewBox="0 0 34 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              x="7"
              width="20"
              height="20"
              rx="10"
              fill="currentColor"
              class="fill-[#b3e0e1] dark:fill-[#b3e0e1]"
            />
            <rect
              y="10"
              width="20"
              height="20"
              rx="10"
              fill="currentColor"
              class="fill-[#66c3d3] dark:fill-[#66c3d3]"
            />
            <rect
              x="14"
              y="10"
              width="20"
              height="20"
              rx="10"
              fill="currentColor"
              class="fill-[#019ca2] dark:fill-[#019ca2]"
            />
          </svg>
        )}
        {planData.id === "66bb045d703df4a2abd34532" && (
          <svg
            class="w-[34px] h-[30px]"
            width="34"
            height="30"
            viewBox="0 0 34 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="20"
              height="20"
              rx="10"
              fill="currentColor"
              class="fill-[#b3e0e1] dark:fill-[#b3e0e1]"
            />
            <rect
              y="10"
              width="20"
              height="20"
              rx="10"
              fill="currentColor"
              class="fill-[#66c3d3] dark:fill-[#66c3d3]"
            />
            <rect
              x="14"
              y="10"
              width="20"
              height="20"
              rx="10"
              fill="currentColor"
              class="fill-[#019ca2] dark:fill-[#019ca2]"
            />
            <rect
              x="14"
              width="20"
              height="20"
              rx="10"
              fill="currentColor"
              class="fill-[#017a7f] dark:fill-[#017a7f]"
            />
          </svg>
        )}
        {planData.popular && (
          <span class="inline-flex items-center gap-1.5 py-1.5 px-2 text-xs font-medium bg-[#b3e0e1] text-[#005f62] rounded-md dark:bg-[#005f62] dark:text-[#b3e0e1]">
            Most popular
          </span>
        )}
      </div>
      <h3 class="text-xl font-semibold text-gray-800 dark:text-neutral-200">
        {planData.name}
      </h3>
      <div class="mt-4 flex items-center gap-x-0.5">
        <span class="text-xl font-normal text-gray-800 dark:text-neutral-200">
          {planData.price.mascot}
        </span>
        <p
          data-hs-toggle-count='{
    "target": "#hs-pro-toggle-count",
    "min": 0,
    "max": 0
  }'
          class="text-gray-800 font-semibold text-3xl dark:text-neutral-200"
        >
          {planData.price.amount}
        </p>
      </div>
      <p class="mt-1 text-xs text-gray-500 dark:text-neutral-500">
        {planData.price.cycleType}, billed {planData.price.cycleType}
      </p>

      <div class="mt-5">
        {planData.id == "66bb045d703df4a2abd3452e" &&
          activePlan.subscriptionId !== planData.id && (
            <button
              type="button"
              class="w-full py-2 px-3 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
              disabled
            >
              Upgrade to {planData.name}
            </button>
          )}
        {activePlan.subscriptionId == planData.id && (
          <button
            type="button"
            class="w-full py-2 px-3 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
            onClick={handlePayment}
            disabled={activePlan.subscriptionId == planData.id}
          >
            Current Plan
          </button>
        )}
        {activePlan.subscriptionId !== planData.id &&
          planData.id !== "66bb045d703df4a2abd3452e" && (
            <button
              type="button"
              class="w-full py-2 px-3 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg bg-[#019ca2] text-white shadow-sm hover:bg-[#31878a] disabled:opacity-50 disabled:pointer-events-none focus:outline-none "
              onClick={handlePayment}
              disabled={
                planData.price.amount <
                plan?.find((plan) => plan.id == activePlan.subscriptionId).price
                  .amount
              }
            >
              Upgrade to {planData.name}
            </button>
          )}
      </div>

      <ul class="mt-5 space-y-1">
        <li class="flex space-x-2">
          <svg
            class="flex-shrink-0 mt-0.5 size-4 text-gray-500 dark:text-neutral-500"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <polyline points="20 6 9 17 4 12" />
          </svg>
          <span class="text-sm text-gray-800 dark:text-neutral-200">
            {planData.link.label +
              " - " +
              planData.link.limit +
              " / " +
              planData.link.cycleType}
          </span>
        </li>
        <li class="flex space-x-2">
          <svg
            class="flex-shrink-0 mt-0.5 size-4 text-gray-500 dark:text-neutral-500"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <polyline points="20 6 9 17 4 12" />
          </svg>
          <span class="text-sm text-gray-800 dark:text-neutral-200">
            {planData.qrCodes.label +
              " - " +
              planData.qrCodes.limit +
              " / " +
              planData.qrCodes.cycleType}
          </span>
        </li>
        <li class="flex space-x-2">
          <svg
            class="flex-shrink-0 mt-0.5 size-4 text-gray-500 dark:text-neutral-500"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <polyline points="20 6 9 17 4 12" />
          </svg>
          <span class="text-sm text-gray-800 dark:text-neutral-200">
            {planData.domain.label +
              " - " +
              planData.domain.limit +
              " / " +
              planData.domain.cycleType}
          </span>
        </li>
        <li class="flex space-x-2">
          <svg
            class="flex-shrink-0 mt-0.5 size-4 text-gray-500 dark:text-neutral-500"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <polyline points="20 6 9 17 4 12" />
          </svg>
          <span class="text-sm text-gray-800 dark:text-neutral-200">
            {planData.aiCredit.label +
              " " +
              planData.aiCredit.limit +
              " " +
              planData.aiCredit.cycleType}
          </span>
        </li>
        <li class="flex space-x-2">
          <svg
            class="flex-shrink-0 mt-0.5 size-4 text-gray-500 dark:text-neutral-500"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <polyline points="20 6 9 17 4 12" />
          </svg>
          <span class="text-sm text-gray-800 dark:text-neutral-200">
            {planData.analytics.label + " - " + planData.analytics.description}
          </span>
        </li>

        <li class="flex space-x-2">
          <svg
            class="flex-shrink-0 mt-0.5 size-4 text-gray-500 dark:text-neutral-500"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <polyline points="20 6 9 17 4 12" />
          </svg>
          <span class="text-sm text-gray-800 dark:text-neutral-200">
            {planData.gallery.label +
              " - " +
              planData.gallery.limit +
              " / " +
              planData.gallery.cycleType}
          </span>
        </li>
      </ul>
    </div>
  );
};

export default SubscriptionCard;
