import React from "react";

const NoLink = ({
  noLinks,
  noMatch,
  invalidLink,
  noData,
  noActivity,
  noConfig,
  noInvoice,
}) => {
  return (
    <>
      {noActivity && (
        <section class="bg-white dark:bg-neutral-800 ">
          <div class="container md:flex-row flex flex-col  px-6 py-12 mx-auto">
            <div>
              <img
                src={require("../../assets/images/error/404.png")}
                className="-ml-7 -mb-7"
              />
              <h1 class="mt-3 text-2xl font-semibold text-gray-800 dark:text-white md:text-3xl">
                Uh Oh!
              </h1>
              <p class="mt-4 text-gray-500 dark:text-gray-400">
                Oops, looks like the link doesn't have any activities as of now.
              </p>
            </div>
            <div className="w-fit">
              <img
                src={require("../../assets/images/svg/404-illustration.png")}
                className="md:h-[450px]"
              />
            </div>
          </div>
        </section>
      )}
      {noData && (
        <section class="bg-white dark:bg-neutral-800 ">
          <div class="container md:flex-row flex flex-col  px-6 py-12 mx-auto">
            <div>
              <img
                src={require("../../assets/images/error/404.png")}
                className="-ml-7 -mb-7"
              />
              <h1 class="mt-3 text-2xl font-semibold text-gray-800 dark:text-white md:text-3xl">
                Uh Oh!
              </h1>
              <p class="mt-4 text-gray-500 dark:text-gray-400">
                Oops, looks like the link doesn't have any analytical data now.
              </p>
            </div>
            <div className="w-fit">
              <img
                src={require("../../assets/images/svg/404-illustration.png")}
                className="md:h-[450px]"
              />
            </div>
          </div>
        </section>
      )}
      {noInvoice && (
        <section class="bg-white dark:bg-neutral-800 ">
          <div class="container md:flex-row flex flex-col  px-6 py-12 mx-auto">
            <div>
              <img
                src={require("../../assets/images/error/404.png")}
                className="-ml-7 -mb-7"
              />
              <h1 class="mt-3 text-2xl font-semibold text-gray-800 dark:text-white md:text-3xl">
                Uh Oh!
              </h1>
              <p class="mt-4 text-gray-500 dark:text-gray-400">
                Oops, looks like the invoice for this transaction does not
                exists.
              </p>
            </div>
            <div className="w-fit">
              <img
                src={require("../../assets/images/svg/404-illustration.png")}
                className="md:h-[450px]"
              />
            </div>
          </div>
        </section>
      )}
      {noMatch && (
        <section class="bg-white dark:bg-neutral-800 ">
          <div class="container md:flex-row flex flex-col  px-6 py-12 mx-auto">
            <div>
              <img
                src={require("../../assets/images/error/404.png")}
                className="-ml-7 -mb-7"
              />
              <h1 class="mt-3 text-2xl font-semibold text-gray-800 dark:text-white md:text-3xl">
                Uh Oh!
              </h1>
              <p class="mt-4 text-gray-500 dark:text-gray-400">
                Oops, looks like the filter couldn't find any matching records.
              </p>
            </div>
            <div className="w-fit">
              <img
                src={require("../../assets/images/svg/404-illustration.png")}
                className="md:h-[450px]"
              />
            </div>
          </div>
        </section>
      )}
      {invalidLink && (
        <section class="bg-white dark:bg-neutral-950 ">
          <div class=" container md:flex-row flex flex-col mt-16  justify-center items-center  px-6 py-12 mx-auto">
            <div>
              <img
                src={require("../../assets/images/error/404.png")}
                className="-ml-7 -mb-7"
              />
              <h1 class="mt-3 text-2xl font-semibold text-neutral-800 dark:text-white md:text-3xl">
                Uh Oh!
              </h1>
              <p class="mt-4 text-neutral-500 dark:text-neutral-400">
                Oops, looks like the link you searched for doesn't exists.
              </p>
            </div>
            <div className="w-fit">
              <img
                src={require("../../assets/images/svg/404-illustration.png")}
                className="md:h-[450px]"
              />
            </div>
          </div>
        </section>
      )}
      {noConfig && (
        <section class="bg-white dark:bg-neutral-800 ">
          <div class=" container md:flex-row flex flex-col  justify-center items-center  px-6 py-2 mx-auto">
            <div>
              <img
                src={require("../../assets/images/error/404.png")}
                className="-ml-7 -mb-7 w-56"
              />
              <h1 class="mt-3 text-2xl font-semibold text-neutral-800 dark:text-white md:text-3xl">
                Uh Oh!
              </h1>
              <p class="mt-4 text-neutral-500 dark:text-neutral-400">
                Oops, looks like the domain does not have any current
                configurations.
              </p>
            </div>
            <div className="w-fit">
              <img
                src={require("../../assets/images/svg/404-illustration.png")}
                className="md:h-[300px]"
              />
            </div>
          </div>
        </section>
      )}
      {noLinks && (
        <main id=" content " className="lg:ps-[260px] pt-[59px] pr-2">
          <div class="flex flex-col h-screen bg-white dark:bg-neutral-800 rounded-lg m-3 justify-center text-center px-4 sm:px-6  border border-gray-200 dark:border-neutral-700">
            <h1 class="block text-7xl font-bold bg-gradient-to-r from-[#019ca2] via-[#4dbabe] to-[#99d7da] text-transparent bg-clip-text sm:text-9xl">
              404
            </h1>
            <p class="mt-3 text-gray-600 dark:text-neutral-400">
              Oops, looks like you have no shortened links yet.
            </p>
            <p class="text-gray-600 dark:text-neutral-400">
              Go ahead and create one.
            </p>
            <div class="mt-5 flex flex-col justify-center items-center gap-2 sm:flex-row sm:gap-3">
              <a
                class="w-full sm:w-auto py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-[#019ca2] text-white hover:bg-[#016d71] disabled:opacity-50 disabled:pointer-events-none"
                href="/create-link"
              >
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  class="flex-shrink-0 size-4 md:size-6 text-neutral-100"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g opacity="1">
                    <path
                      d="M19.7388 4.26118C17.0572 1.57961 12.7095 1.57961 10.0279 4.26118L9.30707 4.98203C9.01418 5.27492 9.01418 5.7498 9.30707 6.04269C9.59997 6.33558 10.0748 6.33558 10.3677 6.04269L11.0886 5.32184C13.1844 3.22605 16.5823 3.22605 18.6781 5.32184C20.7739 7.41763 20.7739 10.8156 18.6781 12.9114L17.9573 13.6322C17.6644 13.9251 17.6644 14.4 17.9573 14.6929C18.2502 14.9858 18.725 14.9858 19.0179 14.6929L19.7388 13.972C22.4203 11.2905 22.4203 6.94276 19.7388 4.26118Z"
                      fill="currentColor"
                    />
                    <path
                      d="M6.04269 9.30707C6.33558 9.59997 6.33558 10.0748 6.04269 10.3677L5.32184 11.0886C3.22605 13.1844 3.22605 16.5823 5.32184 18.6781C7.41763 20.7739 10.8156 20.7739 12.9114 18.6781L13.6322 17.9573C13.9251 17.6644 14.4 17.6644 14.6929 17.9573C14.9858 18.2501 14.9858 18.725 14.6929 19.0179L13.972 19.7388C11.2905 22.4203 6.94276 22.4203 4.26118 19.7388C1.57961 17.0572 1.57961 12.7095 4.26118 10.0279L4.98203 9.30707C5.27492 9.01418 5.7498 9.01418 6.04269 9.30707Z"
                      fill="currentColor"
                    />
                  </g>
                  <path
                    d="M14.6933 9.30707C14.9862 9.59997 14.9862 10.0748 14.6933 10.3677L10.3682 14.6928C10.0753 14.9857 9.60045 14.9857 9.30756 14.6928C9.01467 14.3999 9.01467 13.9251 9.30756 13.6322L13.6327 9.30707C13.9255 9.01418 14.4004 9.01418 14.6933 9.30707Z"
                    fill="currentColor"
                  />
                </svg>
                Create Link
              </a>
            </div>
          </div>
        </main>
      )}
    </>
  );
};

export default NoLink;
