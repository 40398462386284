import React, { useEffect, useState } from "react";
import moment from "moment";
import { useLocation } from "react-router-dom";
import "preline/preline";
import axios from "axios";
import SmallLoader from "../subcomponents/SmallLoader";
import NoLink from "../Links/NoLink";
const TnxDetailsCard = ({ data, aiCredits, subscriptions }) => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [noData, setNoData] = useState(false);

  const handlePayments = async () => {
    const tokenData = localStorage.getItem("token");
    if (!tokenData) {
      console.log("Token not found in localStorage");
      return;
    }

    let access_token;
    try {
      const data = JSON.parse(tokenData);
      access_token = data.access_token;
      if (!access_token) {
        console.log("Access token is missing");
        return;
      }
    } catch (e) {
      console.log("Failed to parse token data");
      return;
    }

    const requestData = {
      data: {
        subscriptionId: data.subscriptionId,
      },
    };

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_GATEWAY_URL}/atz/payments/subscription-payments`,
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
      data: requestData,
    };

    try {
      setLoading(true);
      const response = await axios(config);
      const data = response.data.data;
      if (response.status === 200) {
        setNoData(false);
        setModalData(response.data.data);
      }
    } catch (error) {
      console.log("Error response:", error.response);
      console.log("Error message:", error.message);
      if (error.response.status === 404) {
        setNoData(true);
      }
      // setObj({ error: true });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Reinitialize Preline components every time the location changes
    if (
      window.HSStaticMethods &&
      typeof window.HSStaticMethods.autoInit === "function"
    ) {
      window.HSStaticMethods.autoInit();
    }
  }, [location.pathname]);
  return (
    <>
      <div
        id={`hs-large-modal-${data.id}`}
        class="hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none"
        role="dialog"
        tabindex="-1"
        aria-labelledby={`hs-large-modal-${data.id}-label`}
      >
        <div class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all lg:max-w-6xl lg:w-full m-3 lg:mx-auto">
          <div class="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
            <div class="flex justify-between items-center py-3 px-4 border-b dark:border-neutral-700">
              <h3
                id={`hs-large-modal-${data.id}-label`}
                class="font-bold text-gray-800 dark:text-white"
              >
                {data?.subscriptionName} | Subscription ID ({" "}
                {data.subscriptionId} )
              </h3>
              <button
                type="button"
                class="size-8 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700 dark:hover:bg-neutral-600 dark:text-neutral-400 dark:focus:bg-neutral-600"
                aria-label="Close"
                data-hs-overlay={`#hs-large-modal-${data.id}`}
              >
                <span class="sr-only">Close</span>
                <svg
                  class="shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M18 6 6 18"></path>
                  <path d="m6 6 12 12"></path>
                </svg>
              </button>
            </div>
            <div class="p-4 overflow-y-auto">
              <div className="overflow-auto">
                {loading && (
                  <div className="min-w-full min-h-48 items-center justify-center flex flex=1 ">
                    {" "}
                    <SmallLoader />
                  </div>
                )}
                {!loading && noData && (
                  <div className="min-w-full min-h-48 items-center justify-center flex flex=1 ">
                    {" "}
                    <NoLink noInvoice={true} />
                  </div>
                )}
                {!loading && !noData && (
                  <>
                    <table class="min-w-full divide-y divide-gray-200 dark:divide-neutral-700 ">
                      <thead>
                        <tr>
                          <th scope="col" class="w-1/6">
                            <div class="pe-4 py-3 text-start flex items-center gap-x-1 text-sm font-medium text-gray-800 dark:text-neutral-200 px-2 sm:px-5">
                              ID
                            </div>
                          </th>

                          <th scope="col" class="w-1/6">
                            <div class=" py-3 text-start flex items-center gap-x-1 text-sm font-medium text-gray-800 dark:text-neutral-200 px-2 sm:px-5">
                              Transaction ID
                            </div>
                          </th>

                          <th scope="col" class="w-1/6">
                            <div class="py-3 text-start flex items-center gap-x-1 text-sm font-medium text-gray-800 dark:text-neutral-200 px-2 sm:px-5">
                              Amount
                            </div>
                          </th>

                          <th scope="col" class="w-1/6">
                            <div class=" py-3 text-start flex items-center gap-x-1 text-sm font-medium text-gray-800 dark:text-neutral-200 px-2 sm:px-5">
                              Status
                            </div>
                          </th>
                          <th scope="col" class="w-1/6">
                            <div class=" py-3 text-start flex items-center gap-x-1 text-sm font-medium text-gray-800 dark:text-neutral-200 px-2 sm:px-5">
                              Created on
                            </div>
                          </th>
                          <th scope="col" class="w-1/6">
                            <div class=" py-3 text-start flex items-center gap-x-1 text-sm font-medium text-gray-800 dark:text-neutral-200 px-2 sm:px-5">
                              Actions
                            </div>
                          </th>
                        </tr>
                      </thead>

                      <tbody class="divide-y divide-gray-200 dark:divide-neutral-700">
                        {modalData &&
                          modalData?.map((data) => (
                            <tr>
                              <td class="size-px whitespace-nowrap pe-4 py-2 px-2 sm:px-5">
                                <div class="grow">
                                  <span class="text-sm font-medium text-gray-800 dark:text-neutral-200">
                                    {data.id}
                                  </span>
                                </div>
                              </td>
                              <td class="size-px whitespace-nowrap py-2 px-2 sm:px-5">
                                <span class="text-sm text-gray-600 dark:text-neutral-400">
                                  {data.transactionId}
                                </span>
                              </td>

                              <td class="size-px whitespace-nowrap  py-2 px-2 sm:px-5">
                                <span class="text-sm text-gray-600 dark:text-neutral-400">
                                  {data.amount}
                                </span>
                              </td>
                              <td class="size-px whitespace-nowrap  py-2 px-2 sm:px-5">
                                {data.status == "approved" && (
                                  <span class="inline-flex text-xs px-4 bg-green-100 text-green-800 rounded-full dark:bg-green-500/10 dark:text-green-500 gap-1.5 py-1.5">
                                    {/* <span class=" items-center gap-1.5 py-1.5 px-2 text-xs font-medium ">
          </span> */}
                                    {data.status?.[0].toUpperCase() +
                                      data.status?.slice(1, data.status.length)}
                                  </span>
                                )}
                                {data.status == "failed" && (
                                  <span class="inline-flex text-xs  bg-red-100 text-red-800 rounded-full dark:bg-red-500/10 dark:text-red-500 gap-1.5 py-1.5 px-4">
                                    {/* <span class=" items-center gap-1.5 py-1.5 px-2 text-xs font-medium ">
          </span> */}
                                    {data.status?.[0].toUpperCase() +
                                      data.status?.slice(1, data.status.length)}
                                  </span>
                                )}
                                {data.status == "created" && (
                                  <span class="inline-flex text-xs  bg-yellow-100 text-yellow-800 rounded-full dark:bg-yellow-500/10 dark:text-yellow-500 gap-1.5 py-1.5 px-4">
                                    {/* <span class=" items-center gap-1.5 py-1.5 px-2 text-xs font-medium ">
          </span> */}
                                    {data.status?.[0].toUpperCase() +
                                      data.status?.slice(1, data.status.length)}
                                  </span>
                                )}
                                {data.status == "canceled" && (
                                  <span class="inline-flex text-xs  bg-orange-100 text-orange-800 rounded-full dark:bg-orange-500/10 dark:text-orange-500 gap-1.5 py-1.5 px-4">
                                    {/* <span class=" items-center gap-1.5 py-1.5 px-2 text-xs font-medium ">
          </span> */}
                                    {data.status?.[0].toUpperCase() +
                                      data.status?.slice(1, data.status.length)}
                                  </span>
                                )}
                                {data.status == "pending" && (
                                  <span class="inline-flex text-xs  bg-amber-100 text-amber-800 rounded-full dark:bg-amber-500/10 dark:text-amber-500 gap-1.5 py-1.5 px-4">
                                    {/* <span class=" items-center gap-1.5 py-1.5 px-2 text-xs font-medium ">
          </span> */}
                                    {data.status?.[0].toUpperCase() +
                                      data.status?.slice(1, data.status.length)}
                                  </span>
                                )}
                                {data.status == "completed" && (
                                  <span class="inline-flex text-xs  bg-green-100 text-green-800 rounded-full dark:bg-green-500/10 dark:text-green-500 gap-1.5 py-1.5 px-4">
                                    {/* <span class=" items-center gap-1.5 py-1.5 px-2 text-xs font-medium ">
          </span> */}
                                    {data.status?.[0].toUpperCase() +
                                      data.status?.slice(1, data.status.length)}
                                  </span>
                                )}
                                {data.status == "refunded" && (
                                  <span class="inline-flex text-xs  bg-purple-100 text-purple-800 rounded-full dark:bg-purple-500/10 dark:text-purple-500 gap-1.5 py-1.5 px-4">
                                    {/* <span class=" items-center gap-1.5 py-1.5 px-2 text-xs font-medium ">
          </span> */}
                                    {data.status?.[0].toUpperCase() +
                                      data.status?.slice(1, data.status.length)}
                                  </span>
                                )}
                              </td>
                              <td class="size-px whitespace-nowrap  py-2 px-2 sm:px-5">
                                <span class="text-sm text-gray-600 dark:text-neutral-400">
                                  {moment(data.createdOn).format(
                                    "MMM DD, YYYY hh:mm A"
                                  )}
                                </span>
                              </td>
                              <td class="size-px whitespace-nowrap px-2 sm:px-5 py-2">
                                <div class="flex gap-x-2">
                                  <button
                                    type="button"
                                    class="py-2 px-2.5 inline-flex items-center gap-x-1.5 text-xs font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 hover:text-primary-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                                    onClick={() =>
                                      (window.location.href = `/billing/subscriptions/invoice?id=${data.id}`)
                                    }
                                  >
                                    View Invoice
                                    <svg
                                      viewBox="0 0 1024 1024"
                                      class="icon"
                                      className="size-4"
                                      version="1.1"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="currentColor"
                                    >
                                      <g
                                        id="SVGRepo_bgCarrier"
                                        stroke-width="0"
                                      ></g>
                                      <g
                                        id="SVGRepo_tracerCarrier"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></g>
                                      <g id="SVGRepo_iconCarrier">
                                        <path
                                          d="M811.3 938.7H217.5c-71.5 0-129.8-58.2-129.8-129.8V215.1c0-71.6 58.2-129.8 129.8-129.8h296.9c23.6 0 42.7 19.1 42.7 42.7s-19.1 42.7-42.7 42.7H217.5c-24.5 0-44.4 19.9-44.4 44.4v593.8c0 24.5 19.9 44.4 44.4 44.4h593.8c24.5 0 44.4-19.9 44.4-44.4V512c0-23.6 19.1-42.7 42.7-42.7S941 488.4 941 512v296.9c0 71.6-58.2 129.8-129.7 129.8z"
                                          fill="currentColor"
                                        ></path>
                                        <path
                                          d="M898.4 405.3c-23.6 0-42.7-19.1-42.7-42.7V212.9c0-23.3-19-42.3-42.3-42.3H663.7c-23.6 0-42.7-19.1-42.7-42.7s19.1-42.7 42.7-42.7h149.7c70.4 0 127.6 57.2 127.6 127.6v149.7c0 23.7-19.1 42.8-42.6 42.8z"
                                          fill="currentColor"
                                        ></path>
                                        <path
                                          d="M373.6 712.6c-10.9 0-21.8-4.2-30.2-12.5-16.7-16.7-16.7-43.7 0-60.3L851.2 132c16.7-16.7 43.7-16.7 60.3 0 16.7 16.7 16.7 43.7 0 60.3L403.8 700.1c-8.4 8.3-19.3 12.5-30.2 12.5z"
                                          fill="currentColor"
                                        ></path>
                                      </g>
                                    </svg>
                                  </button>
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {subscriptions && (
        <tr>
          <td class="size-px whitespace-nowrap pe-4 py-2 px-2 sm:px-5 hidden">
            <div class="grow">
              <span class="text-sm font-medium text-gray-800 dark:text-neutral-200">
                {data.id}
              </span>
            </div>
          </td>
          <td class="size-px whitespace-nowrap py-2 px-2 sm:px-5">
            <span class="text-sm text-gray-600 dark:text-neutral-400">
              {data.subscriptionId}
            </span>
          </td>
          <td class="size-px whitespace-nowrap  py-2 px-2 sm:px-5">
            <div class="text-sm text-gray-600 dark:text-neutral-400">
              <span className="font-semibold">{data.subscriptionName}</span>
              <br />
              <span className="text-xs">( {data.description} )</span>
            </div>
          </td>
          <td class="size-px whitespace-nowrap  py-2 px-2 sm:px-5">
            <span class="text-sm text-gray-600 dark:text-neutral-400">
              {data.amount}
            </span>
          </td>
          <td class="size-px whitespace-nowrap  py-2 px-2 sm:px-5">
            {data.status == "expired" && (
              <span class="inline-flex text-xs  bg-red-100 text-[#FF6347] rounded-full dark:bg-red-500/10 dark:text-red-500 gap-1.5 py-1.5 px-4">
                {/* <span class=" items-center gap-1.5 py-1.5 px-2 text-xs font-medium ">
      </span> */}
                {data.status?.[0].toUpperCase() +
                  data.status?.slice(1, data.status.length)}
              </span>
            )}
            {data.status == "created" && (
              <span class="inline-flex text-xs  bg-blue-100 text-[#00BFFF] rounded-full dark:bg-blue-500/10 dark:text-blue-500 gap-1.5 py-1.5 px-4">
                {/* <span class=" items-center gap-1.5 py-1.5 px-2 text-xs font-medium ">
      </span> */}
                {data.status?.[0].toUpperCase() +
                  data.status?.slice(1, data.status.length)}
              </span>
            )}
            {data.status == "cancelled" && (
              <span class="inline-flex text-xs  bg-red-100 text-[#FF4500] rounded-full dark:bg-red-500/10 dark:text-red-500 gap-1.5 py-1.5 px-4">
                {/* <span class=" items-center gap-1.5 py-1.5 px-2 text-xs font-medium ">
      </span> */}
                {data.status?.[0].toUpperCase() +
                  data.status?.slice(1, data.status.length)}
              </span>
            )}
            {data.status == "suspended" && (
              <span class="inline-flex text-xs  bg-amber-100 text-[#DAA520] rounded-full dark:bg-amber-500/10 dark:text-amber-500 gap-1.5 py-1.5 px-4">
                {/* <span class=" items-center gap-1.5 py-1.5 px-2 text-xs font-medium ">
      </span> */}
                {data.status?.[0].toUpperCase() +
                  data.status?.slice(1, data.status.length)}
              </span>
            )}
            {data.status == "updated" && (
              <span class="inline-flex text-xs  bg-yellow-100 text-[#ccae04] rounded-full dark:bg-yellow-500/10 dark:text-yellow-500 gap-1.5 py-1.5 px-4">
                {/* <span class=" items-center gap-1.5 py-1.5 px-2 text-xs font-medium ">
      </span> */}
                {data.status?.[0].toUpperCase() +
                  data.status?.slice(1, data.status.length)}
              </span>
            )}
            {data.status == "activated" && (
              <span class="inline-flex text-xs  bg-green-100 text-[#32CD32] rounded-full dark:bg-green-500/10 dark:text-green-500 gap-1.5 py-1.5 px-4">
                {/* <span class=" items-center gap-1.5 py-1.5 px-2 text-xs font-medium ">
      </span> */}
                {data.status?.[0].toUpperCase() +
                  data.status?.slice(1, data.status.length)}
              </span>
            )}
          </td>
          <td class="size-px whitespace-nowrap  py-2 px-2 sm:px-5">
            <span class="text-sm text-gray-600 dark:text-neutral-400">
              {moment(data.createdOn).format("MMM DD, YYYY hh:mm A")}
            </span>
          </td>
          <td class="size-px whitespace-nowrap px-2 sm:px-5 py-2">
            <div class="flex gap-x-2">
              <button
                type="button"
                class="py-2 px-2.5 inline-flex items-center gap-x-1.5 text-xs font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 hover:text-primary-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                aria-haspopup="dialog"
                aria-expanded="false"
                aria-controls={`hs-large-modal-${data.id}`}
                data-hs-overlay={`#hs-large-modal-${data.id}`}
                onClick={handlePayments}
              >
                View Payments
                <svg
                  viewBox="0 0 1024 1024"
                  class="icon"
                  className="size-4"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    <path
                      d="M811.3 938.7H217.5c-71.5 0-129.8-58.2-129.8-129.8V215.1c0-71.6 58.2-129.8 129.8-129.8h296.9c23.6 0 42.7 19.1 42.7 42.7s-19.1 42.7-42.7 42.7H217.5c-24.5 0-44.4 19.9-44.4 44.4v593.8c0 24.5 19.9 44.4 44.4 44.4h593.8c24.5 0 44.4-19.9 44.4-44.4V512c0-23.6 19.1-42.7 42.7-42.7S941 488.4 941 512v296.9c0 71.6-58.2 129.8-129.7 129.8z"
                      fill="currentColor"
                    ></path>
                    <path
                      d="M898.4 405.3c-23.6 0-42.7-19.1-42.7-42.7V212.9c0-23.3-19-42.3-42.3-42.3H663.7c-23.6 0-42.7-19.1-42.7-42.7s19.1-42.7 42.7-42.7h149.7c70.4 0 127.6 57.2 127.6 127.6v149.7c0 23.7-19.1 42.8-42.6 42.8z"
                      fill="currentColor"
                    ></path>
                    <path
                      d="M373.6 712.6c-10.9 0-21.8-4.2-30.2-12.5-16.7-16.7-16.7-43.7 0-60.3L851.2 132c16.7-16.7 43.7-16.7 60.3 0 16.7 16.7 16.7 43.7 0 60.3L403.8 700.1c-8.4 8.3-19.3 12.5-30.2 12.5z"
                      fill="currentColor"
                    ></path>
                  </g>
                </svg>
              </button>
            </div>
          </td>
        </tr>
      )}
      {aiCredits && (
        <tr>
          <td class="size-px whitespace-nowrap pe-4 py-2 px-2 sm:px-5">
            <div class="grow">
              <span class="text-sm font-medium text-gray-800 dark:text-neutral-200">
                {data.id}
              </span>
            </div>
          </td>
          <td class="size-px whitespace-nowrap py-2 px-2 sm:px-5">
            <span class="text-sm text-gray-600 dark:text-neutral-400">
              {data.paymentId}
            </span>
          </td>
          <td class="size-px whitespace-nowrap  py-2 px-2 sm:px-5">
            <span class="text-sm text-gray-600 dark:text-neutral-400">
              {data.amount}
            </span>
          </td>
          <td class="size-px whitespace-nowrap  py-2 px-2 sm:px-5">
            {data.status == "approved" && (
              <span class="inline-flex text-xs px-4 bg-green-100 text-green-800 rounded-full dark:bg-green-500/10 dark:text-green-500 gap-1.5 py-1.5">
                {/* <span class=" items-center gap-1.5 py-1.5 px-2 text-xs font-medium ">
          </span> */}
                {data.status?.[0].toUpperCase() +
                  data.status?.slice(1, data.status.length)}
              </span>
            )}
            {data.status == "failed" && (
              <span class="inline-flex text-xs  bg-red-100 text-red-800 rounded-full dark:bg-red-500/10 dark:text-red-500 gap-1.5 py-1.5 px-4">
                {/* <span class=" items-center gap-1.5 py-1.5 px-2 text-xs font-medium ">
          </span> */}
                {data.status?.[0].toUpperCase() +
                  data.status?.slice(1, data.status.length)}
              </span>
            )}
            {data.status == "created" && (
              <span class="inline-flex text-xs  bg-yellow-100 text-yellow-800 rounded-full dark:bg-yellow-500/10 dark:text-yellow-500 gap-1.5 py-1.5 px-4">
                {/* <span class=" items-center gap-1.5 py-1.5 px-2 text-xs font-medium ">
          </span> */}
                {data.status?.[0].toUpperCase() +
                  data.status?.slice(1, data.status.length)}
              </span>
            )}
            {data.status == "canceled" && (
              <span class="inline-flex text-xs  bg-orange-100 text-orange-800 rounded-full dark:bg-orange-500/10 dark:text-orange-500 gap-1.5 py-1.5 px-4">
                {/* <span class=" items-center gap-1.5 py-1.5 px-2 text-xs font-medium ">
          </span> */}
                {data.status?.[0].toUpperCase() +
                  data.status?.slice(1, data.status.length)}
              </span>
            )}
            {data.status == "pending" && (
              <span class="inline-flex text-xs  bg-amber-100 text-amber-800 rounded-full dark:bg-amber-500/10 dark:text-amber-500 gap-1.5 py-1.5 px-4">
                {/* <span class=" items-center gap-1.5 py-1.5 px-2 text-xs font-medium ">
          </span> */}
                {data.status?.[0].toUpperCase() +
                  data.status?.slice(1, data.status.length)}
              </span>
            )}
            {data.status == "completed" && (
              <span class="inline-flex text-xs  bg-blue-100 text-blue-800 rounded-full dark:bg-blue-500/10 dark:text-blue-500 gap-1.5 py-1.5 px-4">
                {/* <span class=" items-center gap-1.5 py-1.5 px-2 text-xs font-medium ">
          </span> */}
                {data.status?.[0].toUpperCase() +
                  data.status?.slice(1, data.status.length)}
              </span>
            )}
            {data.status == "refunded" && (
              <span class="inline-flex text-xs  bg-purple-100 text-purple-800 rounded-full dark:bg-purple-500/10 dark:text-purple-500 gap-1.5 py-1.5 px-4">
                {/* <span class=" items-center gap-1.5 py-1.5 px-2 text-xs font-medium ">
          </span> */}
                {data.status?.[0].toUpperCase() +
                  data.status?.slice(1, data.status.length)}
              </span>
            )}
          </td>
          <td class="size-px whitespace-nowrap  py-2 px-2 sm:px-5">
            <span class="text-sm text-gray-600 dark:text-neutral-400">
              {moment(data.createdOn).format("MMM DD, YYYY hh:mm A")}
            </span>
          </td>
          <td class="size-px whitespace-nowrap px-2 sm:px-5 py-2">
            <div class="flex gap-x-2">
              {(data.status == "completed" || data.status == "approved") && (
                <button
                  type="button"
                  class="py-2 px-2.5 inline-flex items-center gap-x-1.5 text-xs font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 hover:text-primary-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                  onClick={() =>
                    (window.location.href = `/billing/ai-credits/invoice?id=${data.id}`)
                  }
                >
                  View Invoice
                  <svg
                    viewBox="0 0 1024 1024"
                    class="icon"
                    className="size-4"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        d="M811.3 938.7H217.5c-71.5 0-129.8-58.2-129.8-129.8V215.1c0-71.6 58.2-129.8 129.8-129.8h296.9c23.6 0 42.7 19.1 42.7 42.7s-19.1 42.7-42.7 42.7H217.5c-24.5 0-44.4 19.9-44.4 44.4v593.8c0 24.5 19.9 44.4 44.4 44.4h593.8c24.5 0 44.4-19.9 44.4-44.4V512c0-23.6 19.1-42.7 42.7-42.7S941 488.4 941 512v296.9c0 71.6-58.2 129.8-129.7 129.8z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M898.4 405.3c-23.6 0-42.7-19.1-42.7-42.7V212.9c0-23.3-19-42.3-42.3-42.3H663.7c-23.6 0-42.7-19.1-42.7-42.7s19.1-42.7 42.7-42.7h149.7c70.4 0 127.6 57.2 127.6 127.6v149.7c0 23.7-19.1 42.8-42.6 42.8z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M373.6 712.6c-10.9 0-21.8-4.2-30.2-12.5-16.7-16.7-16.7-43.7 0-60.3L851.2 132c16.7-16.7 43.7-16.7 60.3 0 16.7 16.7 16.7 43.7 0 60.3L403.8 700.1c-8.4 8.3-19.3 12.5-30.2 12.5z"
                        fill="currentColor"
                      ></path>
                    </g>
                  </svg>
                </button>
              )}
              {data.status !== "completed" && data.status !== "approved" && (
                <button
                  type="button"
                  class="py-2 px-2.5 inline-flex items-center gap-x-1.5 text-xs font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 hover:text-primary-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                  aria-haspopup="dialog"
                  aria-expanded="false"
                  aria-controls="hs-scale-animation-modal1"
                  data-hs-overlay="#hs-scale-animation-modal1"
                >
                  View Invoice
                  <svg
                    viewBox="0 0 1024 1024"
                    class="icon"
                    className="size-4"
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        d="M811.3 938.7H217.5c-71.5 0-129.8-58.2-129.8-129.8V215.1c0-71.6 58.2-129.8 129.8-129.8h296.9c23.6 0 42.7 19.1 42.7 42.7s-19.1 42.7-42.7 42.7H217.5c-24.5 0-44.4 19.9-44.4 44.4v593.8c0 24.5 19.9 44.4 44.4 44.4h593.8c24.5 0 44.4-19.9 44.4-44.4V512c0-23.6 19.1-42.7 42.7-42.7S941 488.4 941 512v296.9c0 71.6-58.2 129.8-129.7 129.8z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M898.4 405.3c-23.6 0-42.7-19.1-42.7-42.7V212.9c0-23.3-19-42.3-42.3-42.3H663.7c-23.6 0-42.7-19.1-42.7-42.7s19.1-42.7 42.7-42.7h149.7c70.4 0 127.6 57.2 127.6 127.6v149.7c0 23.7-19.1 42.8-42.6 42.8z"
                        fill="currentColor"
                      ></path>
                      <path
                        d="M373.6 712.6c-10.9 0-21.8-4.2-30.2-12.5-16.7-16.7-16.7-43.7 0-60.3L851.2 132c16.7-16.7 43.7-16.7 60.3 0 16.7 16.7 16.7 43.7 0 60.3L403.8 700.1c-8.4 8.3-19.3 12.5-30.2 12.5z"
                        fill="currentColor"
                      ></path>
                    </g>
                  </svg>
                </button>
              )}
            </div>
          </td>
        </tr>
      )}
    </>
  );
};

export default TnxDetailsCard;
