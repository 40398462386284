import React, { useState, useEffect } from "react";
import axios from "axios";
import axiosInstance from "../../instances/axiosInstance";
import Toast from "../subcomponents/Toast";
import Loader from "../subcomponents/Loader";
import SmallLoader from "../subcomponents/SmallLoader";

const LinkDetailsTemp3 = ({ id, data, setToastVisible, setCountChild }) => {
  // const [isValid, setIsValid] = useState(false);
  const [input, setInput] = useState("");
  const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  const [utm, setUTM] = useState(false);
  const [password, setPassword] = useState(false);
  const [url, setUrl] = useState("");
  const [isValid, setIsValid] = useState(null);
  const [message, setMessage] = useState("");
  const [messageTitle, setMessageTitle] = useState("");
  const [title, setTitle] = useState("");
  const [isValidTitle, setIsValidTitle] = useState(null);
  const [isValidUTM, setIsValidUTM] = useState(null);
  const [utmL, setUTML] = useState("");
  const [messageUTM, setMessageUTM] = useState("");
  const [source, setSource] = useState("");
  const [messageSource, setMessageSource] = useState("");
  const [isValidSource, setIsValidSource] = useState(null);
  const [campaign, setCampaign] = useState("");
  const [messageCampaign, setMessageCampaign] = useState("");
  const [isValidCampaign, setIsValidCampaign] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [cnfPasswordVisible, setCnfPasswordVisible] = useState(false);

  const [content, setContent] = useState("");
  const [passwordL, setPasswordL] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isValidPassword, setIsValidPassword] = useState(null);
  const [passwordMessage, setPasswordMessage] = useState("");
  const [confirmPasswordMessage, setConfirmPasswordMessage] = useState("");

  const [medium, setMedium] = useState("");
  const [messageMedium, setMessageMedium] = useState("");
  const [isValidMedium, setIsValidMedium] = useState(null);
  const [DOMAINS, SETDOMAINS] = useState(null);
  const [slug, setSlug] = useState("");
  const [term, setTerm] = useState("");
  const [isValidConfirmPassword, setIsValidConfirmPassword] = useState(null);
  const [isValidTerm, setIsValidTerm] = useState(null);
  const [domainId, setDomainId] = useState("");
  const [slugValid, isSlugValid] = useState(true);

  const [toastMessage, setToastMessage] = useState("");
  const [toastColor, setToastColor] = useState("");
  const [loading, isLoading] = useState(false);
  const [slugMessage, setSlugMessage] = useState("");
  const [prevSlug, setPrevSlug] = useState("");
  const handleToastChange = () => {
    setToastVisible(false);
  };

  const handleDomainChange = (e) => {
    setDomainId(e.target.value);
  };

  useEffect(() => {
    if (data) {
      SETDOMAINS(data?.data?.domain?.domain);
      setSlug(data?.data?.slug);
      setPrevSlug(data?.data?.slug);
    }
  }, [data]);

  const handleChangeSlug = (e) => {
    const value = e.target.value;
    setSlug(value);
  };
  const updateLinkApi = async () => {
    isLoading(true);
    const data = {
      linkId: id,
      slug: slug ? slug : "",
    };

    console.log("data sent : " + JSON.stringify(data));

    const token = JSON.parse(localStorage.getItem("token"));

    const config = {
      method: "post",
      url: "/atz/links/update-link",
      headers: {
        Authorization: `Bearer ${token.access_token}`,
      },
      data: { data },
    };

    try {
      const updateLinkResponse = await axiosInstance(config);
      isLoading(false);

      console.log(updateLinkResponse.status);
      if (updateLinkResponse.status === 200) {
        setSlugMessage("");
        setToastVisible(true);
        isSlugValid(true);
        setCountChild((prev) => prev + 1);
        setTimeout(() => {
          setToastVisible(false);
        }, 5000);
      }
    } catch (e) {
      isLoading(false);
      if (e.response.status === 409) {
        isSlugValid(false);
        setSlugMessage("Provided slug already exists within the domain");
        console.log(e.response);
        setToastVisible(false);
      } else {
        console.error(e);
      }
    }
  };
  const handleUpdateSlug = () => {
    if (prevSlug !== slug) {
      updateLinkApi();
    }
  };
  /**
   *
   * VALIDATIONS
   *
   * destination url -- valid
   * title -- valid
   * if(utm) --> all 4 valid
   * if(password) --> password confirm password valid
   *
   *
   * if (isValid && isValidTitle) {
      if (utm || password) {
        if (utm && !password) {
          if (isValidCampaign && isValidUTM && isValidSource && isValidMedium) {
            //api call
            isLoading(false);
            createLinkApi();
          }
        } else if (password && !utm) {
          if (isValidPassword && isValidConfirmPassword) {
            //api call
            isLoading(false);
            createLinkApi();
          }
        } else {
          if (
            isValidCampaign &&
            isValidUTM &&
            isValidSource &&
            isValidMedium &&
            isValidPassword &&
            isValidConfirmPassword
          ) {
            //api call
            isLoading(false);
            createLinkApi();
          }
        }
      } else {
        isLoading(false);
        createLinkApi();
      }
    } else {
    }
   *
   */

  return (
    <>
      {loading && (
        <div className="flex justify-center items-center h-56 sm:p-5 sm:py-0 border-[0.5px] md:pt-5 space-y-5 m-2 sm:m-4  rounded-xl shadow-sm dark:text-neutral-100 bg-white dark:border-neutral-700 dark:bg-neutral-800">
          <SmallLoader />
        </div>
      )}
      {!loading && (
        <div>
          <div className=" sm:p-5 sm:py-0 border-[0.5px] md:pt-5 space-y-5 m-2 sm:m-4  rounded-xl shadow-sm dark:text-neutral-100 bg-white dark:border-neutral-700 dark:bg-neutral-800 ">
            {/* <!-- Page Header --> */}

            <div className="flex flex-col py-2 pl-1">
              {/* top section */}

              {/* end top section */}

              {/* mid section */}
              <div className="flex flex-col md:flex-row justify-between border-neutral-200 rounded-md px-4 ">
                <div className="flex flex-col flex-1">
                  <div className="flex flex-1 items-center sm:items-baseline justify-between">
                    <div className="text-base font-[500] ">Ways to share</div>
                    {prevSlug !== slug && (
                      <div>
                        <button
                          className="bg-[#019ca2] text-white py-2 p-4 rounded-md hover:bg-[#46aaad] text-xs font-semibold"
                          onClick={handleUpdateSlug}
                        >
                          Update
                        </button>
                      </div>
                    )}
                  </div>
                  <div className="flex flex-col w-full">
                    <div className="flex mt-5 gap-4 w-full">
                      <div className="flex flex-col sm:flex-row gap-y-4 sm:gap-y-0  w-full flex-1 gap-x-4 mb-6">
                        <div className="flex-1 relative">
                          <input
                            autoComplete="off"
                            type="text"
                            id="13"
                            className={`peer p-3 pr-9 pl-4 block w-full text-[12px] rounded-md font-base border-[1px] placeholder:text-transparent focus:ring-4 h-11
                            pt-5 pb-2.5
                            disabled:opacity-50 disabled:pointer-events-none 
                            bg-neutral-200
                            text-neutral-950
                            dark:bg-neutral-800 focus:outline-none 
                            dark:text-neutral-200
                            border-gray-200  dark:focus:ring-4 focus:ring-blue-100 focus:border-blue-700 dark:border-neutral-700 dark:focus:ring-blue-950 dark:focus:border-blue-400"
                            }`}
                            placeholder=""
                            value={DOMAINS}
                            disabled
                          />

                          <label
                            className={`absolute top-2 start-0 left-0.5 font-bold p-1.5 px-3 h-full peer-focus:text-[10px] pt-2 text-[9px] peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:translate-x-0.5 peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500
                              
                              
                              dark:peer-focus:text-neutral-600`}
                          >
                            DOMAIN
                          </label>
                        </div>

                        <div className="flex-1 relative">
                          <input
                            autoComplete="off"
                            type="text"
                            id="12"
                            className={`peer p-3 pr-9 block w-full text-[12px] rounded-md font-light border-[1px] placeholder:text-transparent focus:ring-4 h-11 ${
                              slug.length > 0 ? "pt-5 pb-2.5" : "pt-4 pb-2.5"
                            } disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 focus:outline-none ${
                              slugValid === false
                                ? "border-red-500 dark:border-red-500  dark:focus:ring-red-900 dark:focus:border-red-500  dark:focus:ring-2 focus:border-red-500 focus:ring-red-200 focus:border-[1.5px]"
                                : "border-gray-200 focus:ring-4 dark:focus:ring-4 focus:ring-blue-100 focus:border-blue-700 dark:border-neutral-700 dark:focus:ring-blue-950 dark:focus:border-blue-400"
                            }`}
                            placeholder="you@email.com"
                            value={slug}
                            onChange={handleChangeSlug}
                          />

                          <label
                            className={`absolute top-2 start-0 font-bold p-1.5 px-3 h-full peer-focus:text-[10px] ${
                              slug.length > 0
                                ? "pt-2 text-[9px] peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:translate-x-0.5 peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 peer-focus:text-[9px] dark:peer-focus:text-neutral-400"
                                : "text-[9px] peer-focus:text-[9px]"
                            } truncate pointer-events-none transition ease-in-out duration-100 text-neutral-500 border border-transparent origin-[0_0] dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none peer-focus:scale-90 peer-focus:translate-x-0.5 peer-focus:-translate-y-2 
                                  peer-focus:text-gray-400 dark:peer-focus:text-neutral-400`}
                          >
                            SLUG | BACK HALF
                          </label>
                          {slugValid === false && (
                            <svg
                              width="20px"
                              height="20px"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="absolute right-3 top-[22px] transform -translate-y-1/2 "
                            >
                              <path
                                d="M12 16H12.01M12 8V12M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                                stroke="#ef4444"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          )}

                          {slugValid === false && (
                            <p
                              className="text-[12px] -top-0.5 font-light text-red-600"
                              id="hs-validation-email-error-helper"
                            >
                              {slugMessage}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end mid section */}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LinkDetailsTemp3;
