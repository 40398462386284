import React, { useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import "preline/preline";
import moment from "moment";
import CircularProgressBar from "../subcomponents/CircularProgressBar";
import { useLocation } from "react-router-dom";
import PlanDetailsCard from "../AiCredits/PlanDetailsCard";
import SmallLoader from "../subcomponents/SmallLoader";
const MainBody = () => {
  const [inviteLoading, setInviteLoading] = useState(false);
  const [planDetails, setPlanDetails] = useState({});
  const [loadingSubs, setLoadingSubs] = useState(false);
  const [loadingCreds, setLoadingCreds] = useState(false);
  const [progress, setProgress] = useState(0);
  const [kpiData, setKpiData] = useState({});
  const [validEmail, setValidEmail] = useState(true);
  const [inputEmail, setInputEmail] = useState("");
  const user = JSON.parse(localStorage.getItem("user"));
  const name = user.name.split(" ");
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  useEffect(() => {
    const fetchKpi = async () => {
      const tokenData = localStorage.getItem("token");
      if (!tokenData) {
        console.log("Token not found in localStorage");
        return;
      }

      let access_token;
      try {
        const data = JSON.parse(tokenData);
        access_token = data.access_token;
        if (!access_token) {
          console.log("Access token is missing");
          return;
        }
      } catch (e) {
        console.log("Failed to parse token data");
        return;
      }

      const requestData = {
        data: {},
      };

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_GATEWAY_URL}/atz/core/dashboard-kpi`,
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
        data: requestData,
      };

      try {
        setLoading(true);
        const response = await axios(config);
        const data = response.data.data;
        console.log(data);
        if (response.status === 200) {
          setLoading(false);
          setKpiData(response.data.data);
        }
      } catch (error) {
        setLoading(false);
        console.log(error);
        console.log("Error response:", error.response);
        console.log("Error message:", error.message);
        // setObj({ error: true });
      }
    };
    fetchKpi();
  }, []);

  useEffect(() => {
    // Reinitialize Preline components every time the location changes
    if (
      window.HSStaticMethods &&
      typeof window.HSStaticMethods.autoInit === "function"
    ) {
      window.HSStaticMethods.autoInit();
    }
  }, [location.pathname, planDetails, loading]);

  const handleInvite = async () => {
    const tokenData = localStorage.getItem("token");
    if (!tokenData) {
      return;
    }
    let access_token;
    try {
      const data = JSON.parse(tokenData);
      access_token = data.access_token;
      if (!access_token) {
        return;
      }
    } catch (e) {
      console.log("Failed to parse token data");
    }

    const requestData = {
      data: {
        emailId: inputEmail,
      },
    };

    const config = {
      method: "post",
      url: `${process.env.REACT_APP_GATEWAY_URL}/atz/core/invite`,
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
      data: requestData,
    };

    try {
      setInviteLoading(true);
      const response = await axios(config);
      const data = response.data.data;
      if (response.status === 200) {
        setInputEmail("");
        document.querySelector("#failureButton").click();
        setInviteLoading(false);
      }
    } catch (error) {
      setInviteLoading(false);
      console.log("Error response:", error.response);
      console.log("Error message:", error.message);
    }
  };
  const handleEmail = (e) => {
    const value = e.target.value;
    setInputEmail(value);
    if (validateEmail(value)) {
      setValidEmail(true);
    } else {
      setValidEmail(false);
    }
  };
  return (
    <div>
      <div
        id="hs-scale-animation-modal1"
        class="hs-overlay size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none hidden"
        role="dialog"
        tabindex="-1"
        aria-labelledby="hs-scale-animation-modal1-label"
      >
        <div class="hs-overlay-animation-target hs-overlay-open:scale-100 hs-overlay-open:opacity-100 scale-95 opacity-0 ease-in-out transition-all duration-200 sm:max-w-xl sm:w-full m-3 sm:mx-auto min-h-[calc(100%-3.5rem)] flex items-center">
          <div class="w-full flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
            {/* <!-- Header --> */}
            <div class="py-2.5 px-4 flex justify-between items-center border-b dark:border-neutral-700">
              <h3 class="font-medium text-gray-800 dark:text-neutral-200">
                Invite sent successfully
              </h3>

              {/* <!-- End Header --> */}
              <button
                type="button"
                class="size-8 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700 dark:hover:bg-neutral-600 dark:text-neutral-400 dark:focus:bg-neutral-600"
                aria-label="Close"
                data-hs-overlay="#hs-scale-animation-modal1"
              >
                <span class="sr-only">Close</span>
                <svg
                  class="shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M18 6 6 18"></path>
                  <path d="m6 6 12 12"></path>
                </svg>
              </button>
            </div>
            <div
              class="min-h-[200px] flex overflow-y-auto
  [&::-webkit-scrollbar]:w-2
  [&::-webkit-scrollbar-track]:rounded-full
  [&::-webkit-scrollbar-track]:bg-gray-100
  [&::-webkit-scrollbar-thumb]:rounded-full
  [&::-webkit-scrollbar-thumb]:bg-gray-300
  dark:[&::-webkit-scrollbar-track]:bg-neutral-700
  dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500"
            >
              <div className="flex flex-col flex-1 justify-center items-center gap-y-2 dark:text-neutral-100">
                <div>
                  <svg
                    fill="#16A34A"
                    className="size-10"
                    viewBox="0 0 24 24"
                    id="d9090658-f907-4d85-8bc1-743b70378e93"
                    data-name="Livello 1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <title>prime</title>
                      <path
                        id="70fa6808-131f-4233-9c3a-fc089fd0c1c4"
                        data-name="done circle"
                        d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0ZM11.52,17L6,12.79l1.83-2.37L11.14,13l4.51-5.08,2.24,2Z"
                      ></path>
                    </g>
                  </svg>
                </div>
                <div className="text-sm font-semibold -mb-1">Invite Sent!</div>
                <div className="text-xs flex  text-center px-5">
                  <span>
                    Your friend was successfully notified via Email. Make sure
                    to remind them yourself too.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="hs-pro-dshm"
        class="hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto [--close-when-click-inside:true] pointer-events-none"
        role="dialog"
        tabindex="-1"
        aria-labelledby="hs-pro-dshm-label"
      >
        <div class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-xl sm:w-full m-3 sm:mx-auto h-[calc(100%-3.5rem)] min-h-[calc(100%-3.5rem)] flex items-center">
          <div class="w-full max-h-full flex flex-col bg-white rounded-xl pointer-events-auto shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)] dark:bg-neutral-800">
            {/* <!-- Header --> */}
            <div class="py-2.5 px-4 flex justify-between items-center border-b dark:border-neutral-700">
              <h3
                id="hs-pro-dshm-label"
                class="font-medium text-gray-800 dark:text-neutral-200"
              >
                Invite
              </h3>
              <button
                type="button"
                class="size-6 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700 dark:hover:bg-neutral-600 dark:text-neutral-400 dark:focus:bg-neutral-600"
                aria-label="Close"
                data-hs-overlay="#hs-pro-dshm"
              >
                <span class="sr-only">Close</span>
                <svg
                  class="shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M18 6 6 18" />
                  <path d="m6 6 12 12" />
                </svg>
              </button>
            </div>
            {/* <!-- End Header --> */}

            {/* <!-- Header --> */}
            <div class="p-4  border-gray-200 dark:border-neutral-700">
              <span class="block text-xs font-medium text-gray-800 mb-2 dark:text-neutral-300">
                Invite
              </span>

              {/* <!-- Input Form --> */}
              <div class="flex items-center gap-x-2">
                <div class="relative w-full">
                  <input
                    type="text"
                    class="py-2 px-3 pe-24 block w-full border-gray-200 rounded-lg text-sm placeholder:text-gray-400 focus:border-gray-200 focus:ring-0 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400 
                    dark:focus:border-neutral-700 dark:placeholder:text-neutral-500 dark:focus:ring-0"
                    placeholder="Enter recipient email id e.g. atz@nidavellirs.com"
                    value={inputEmail}
                    onChange={handleEmail}
                  />
                </div>

                <button
                  type="button"
                  class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-[#019ca2] text-white hover:bg-[#019ca2] disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:ring-2 focus:ring-[#019ca2]"
                  disabled={
                    !validEmail || inputEmail.length < 1 || inviteLoading
                  }
                  onClick={handleInvite}
                >
                  {!inviteLoading && "Send"}
                  {inviteLoading && (
                    <>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="white"
                        class="w-4 h-4 animate-spin"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                        <path
                          fill-rule="evenodd"
                          d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"
                        />
                      </svg>
                    </>
                  )}
                </button>
              </div>
              {!validEmail && (
                <div className="text-red-500 text-xs ml-1.5">
                  {" "}
                  Provide valid email address
                </div>
              )}
              {/* <!-- End Input Form --> */}

              <div class="flex mt-2">
                <label
                  for="hs-pro-dnshmch"
                  class="text-xs text-gray-600 ms-1.5 dark:text-neutral-400"
                >
                  Recipient will be notified via email
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <div className="flex flex-1 justify-center w-full items-center min-h-screen">
          <SmallLoader />
        </div>
      )}
      {!loading && (
        <>
          <main id="content " class="lg:ps-[260px] pt-[59px] ">
            <ol class="dark:bg-neutral-800 md:hidden py-3 px-2 sm:px-5 flex items-center whitespace-nowrap">
              <li class="flex items-center text-sm text-gray-600 dark:text-neutral-500">
                Dashboard
                <svg
                  class="flex-shrink-0 mx-1 overflow-visible size-4 text-gray-400 dark:text-neutral-600"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="m9 18 6-6-6-6" />
                </svg>
              </li>

              <li
                class="text-sm font-semibold text-gray-800 truncate dark:text-neutral-200"
                aria-current="page"
              >
                Overview
              </li>
            </ol>

            <div class="p-2 sm:p-5 sm:py-0 md:pt-5 space-y-5 mb-4">
              <div class="flex justify-between items-center gap-x-5 pb-4 border-b-[1.5px] border-neutral-300 dark:border-neutral-700">
                <h2 class="inline-block text-3xl font-bold text-gray-800 dark:text-neutral-200">
                  Welcome,{" "}
                  <span className="gap-x-1 inline-flex items-center font-bold">
                    {name[0]}!
                  </span>
                </h2>

                <div class="flex justify-end items-center gap-x-2">
                  {/* <!-- Button --> */}
                  <div class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-[#019ca2] bg-inherit text-[#019ca2] rounded-r-full rounded-l-full">
                    <svg
                      class="flex-shrink-0 size-3"
                      height="36px"
                      width="36px"
                      version="1.1"
                      id="_x32_"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 512 512"
                      xmlSpace="preserve"
                      fill="#019ca2"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <g>
                          {" "}
                          <path
                            class="st0"
                            d="M247.355,106.9C222.705,82.241,205.833,39.18,197.46,0c-8.386,39.188-25.24,82.258-49.899,106.917 c-24.65,24.642-67.724,41.514-106.896,49.904c39.188,8.373,82.254,25.235,106.904,49.895c24.65,24.65,41.522,67.72,49.908,106.9 c8.373-39.188,25.24-82.258,49.886-106.917c24.65-24.65,67.724-41.514,106.896-49.904 C315.08,148.422,272.014,131.551,247.355,106.9z"
                          ></path>{" "}
                          <path
                            class="st0"
                            d="M407.471,304.339c-14.714-14.721-24.81-40.46-29.812-63.864c-5.011,23.404-15.073,49.142-29.803,63.872 c-14.73,14.714-40.464,24.801-63.864,29.812c23.408,5.01,49.134,15.081,63.864,29.811c14.73,14.722,24.81,40.46,29.82,63.864 c5.001-23.413,15.081-49.142,29.802-63.872c14.722-14.722,40.46-24.802,63.856-29.82 C447.939,329.14,422.201,319.061,407.471,304.339z"
                          ></path>{" "}
                          <path
                            class="st0"
                            d="M146.352,354.702c-4.207,19.648-12.655,41.263-25.019,53.626c-12.362,12.354-33.968,20.82-53.613,25.027 c19.645,4.216,41.251,12.656,53.613,25.027c12.364,12.362,20.829,33.96,25.036,53.618c4.203-19.658,12.655-41.255,25.023-53.626 c12.354-12.362,33.964-20.82,53.605-25.035c-19.64-4.2-41.251-12.656-53.613-25.019 C159.024,395.966,150.555,374.351,146.352,354.702z"
                          ></path>{" "}
                        </g>{" "}
                      </g>
                    </svg>
                    <span className="hidden sm:block">
                      Available AI credits :{" "}
                    </span>
                    {kpiData &&
                      kpiData?.aiCredits?.totalAiCredits -
                        kpiData?.aiCredits?.utilizedAiCredits}
                  </div>
                  {/* <!-- End Button --> */}
                </div>
              </div>
              {/* <!-- Stats Grid --> */}
              <div class="grid grid-cols-2 lg:grid-cols-4 gap-2 md:gap-3 xl:gap-5 ">
                {/* <!-- Card --> */}
                <div class="relative overflow-hidden p-4 sm:p-5 bg-white border border-gray-200 rounded-xl shadow-sm dark:shadow-neutral-950 before:absolute before:top-0 before:end-0 before:size-full before:bg-gradient-to-br before:from-purple-100 before:via-transparent before:blur-xl dark:bg-neutral-800 dark:border-neutral-700 dark:before:from-purple-800/30 dark:before:via-transparent">
                  <div class="relative z-10">
                    {/* <!-- Header --> */}
                    <div class="flex justify-between gap-x-3">
                      {/* <!-- Icon --> */}
                      <span class="mb-3 inline-flex justify-center items-center size-8 md:size-10 rounded-lg bg-white text-gray-700 shadow dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400">
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 24 24"
                          fill="none"
                          class="flex-shrink-0 size-4 md:size-6 text-purple-500"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="1">
                            <path
                              d="M19.7388 4.26118C17.0572 1.57961 12.7095 1.57961 10.0279 4.26118L9.30707 4.98203C9.01418 5.27492 9.01418 5.7498 9.30707 6.04269C9.59997 6.33558 10.0748 6.33558 10.3677 6.04269L11.0886 5.32184C13.1844 3.22605 16.5823 3.22605 18.6781 5.32184C20.7739 7.41763 20.7739 10.8156 18.6781 12.9114L17.9573 13.6322C17.6644 13.9251 17.6644 14.4 17.9573 14.6929C18.2502 14.9858 18.725 14.9858 19.0179 14.6929L19.7388 13.972C22.4203 11.2905 22.4203 6.94276 19.7388 4.26118Z"
                              fill="currentColor"
                            />
                            <path
                              d="M6.04269 9.30707C6.33558 9.59997 6.33558 10.0748 6.04269 10.3677L5.32184 11.0886C3.22605 13.1844 3.22605 16.5823 5.32184 18.6781C7.41763 20.7739 10.8156 20.7739 12.9114 18.6781L13.6322 17.9573C13.9251 17.6644 14.4 17.6644 14.6929 17.9573C14.9858 18.2501 14.9858 18.725 14.6929 19.0179L13.972 19.7388C11.2905 22.4203 6.94276 22.4203 4.26118 19.7388C1.57961 17.0572 1.57961 12.7095 4.26118 10.0279L4.98203 9.30707C5.27492 9.01418 5.7498 9.01418 6.04269 9.30707Z"
                              fill="currentColor"
                            />
                          </g>
                          <path
                            d="M14.6933 9.30707C14.9862 9.59997 14.9862 10.0748 14.6933 10.3677L10.3682 14.6928C10.0753 14.9857 9.60045 14.9857 9.30756 14.6928C9.01467 14.3999 9.01467 13.9251 9.30756 13.6322L13.6327 9.30707C13.9255 9.01418 14.4004 9.01418 14.6933 9.30707Z"
                            fill="currentColor"
                          />
                        </svg>
                      </span>
                      {/* <!-- End Icon --> */}
                    </div>
                    {/* <!-- End Header --> */}

                    <h2 class="text-sm md:text-base text-gray-800 dark:text-neutral-200">
                      Links
                    </h2>

                    <div class="grid sm:flex sm:justify-between sm:items-center gap-1 sm:gap-3">
                      <h3 class="text-lg md:text-2xl font-semibold text-gray-800 dark:text-neutral-200">
                        {/* {kpiData &&
                      kpiData?.kpis?.filter((kpi) => kpi.label === "links")[0]
                        ?.value} */}
                        {kpiData &&
                          kpiData?.kpis?.filter(
                            (kpi) => kpi.label === "links"
                          )[0]?.value}
                        <div className="italic font-extralight text-base">
                          ( This month )
                        </div>
                      </h3>

                      <div class="flex items-center -space-x-2">
                        <button
                          className="flex p-2 px-4 rounded-md text-white text-xs font-semibold bg-purple-400 hover:bg-purple-700 dark:bg-purple-900 dark:hover:bg-purple-950 gap-x-1"
                          onClick={() => (window.location.href = "/links")}
                        >
                          My links
                          <svg
                            height="18"
                            width="18"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            transform="rotate(90)"
                          >
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              <path
                                d="M10 3.778a6.222 6.222 0 1 0 3.726 11.205l-4.869-4.869v2.208a.889.889 0 0 1-1.778 0V7.968c0-.49.398-.889.89-.889h4.353a.889.889 0 0 1 0 1.778h-2.208l4.87 4.87A6.193 6.193 0 0 0 16.221 10 6.222 6.222 0 0 0 10 3.778ZM2 10a8 8 0 1 1 16 0 8 8 0 0 1-16 0Z"
                                fill="currentColor"
                              ></path>
                            </g>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- End Card --> */}

                {/* <!-- Card --> */}
                <div class="relative overflow-hidden p-4 sm:p-5 bg-white border border-gray-200 rounded-xl shadow-sm dark:shadow-neutral-950 before:absolute before:top-0 before:end-0 before:size-full before:bg-gradient-to-br before:from-teal-100 before:via-transparent before:blur-xl dark:bg-neutral-800 dark:border-neutral-700 dark:before:from-teal-800/30 dark:before:via-transparent">
                  <div class="relative z-10">
                    {/* <!-- Header --> */}
                    <div class="flex justify-between gap-x-3">
                      {/* <!-- Icon --> */}
                      <span class="mb-3 inline-flex justify-center items-center size-8 md:size-10 rounded-lg bg-white text-gray-700 shadow dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400">
                        <svg
                          fill="currentColor"
                          width="24"
                          class="flex-shrink-0 size-4 md:size-5 text-teal-500"
                          height="24"
                          viewBox="0 0 24 24"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M21,2H15a1,1,0,0,0-1,1V9a1,1,0,0,0,1,1h1v2h2V10h2v2h2V3A1,1,0,0,0,21,2ZM18,8H16V4h4V8ZM3,10H9a1,1,0,0,0,1-1V3A1,1,0,0,0,9,2H3A1,1,0,0,0,2,3V9A1,1,0,0,0,3,10ZM4,4H8V8H4ZM5,16v2H3V16ZM3,20H5v2H3Zm4-2v2H5V18Zm0-2H5V14H7V12H9v4ZM5,12v2H3V12Zm9,3v1H13V14H11v4h3v3a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V15a1,1,0,0,0-1-1H16V12H14Zm6,1v4H16V16ZM9,18h2v2h1v2H7V20H9ZM13,6H11V4h2ZM11,8h2v4H11ZM5,5H7V7H5ZM17,5h2V7H17Zm2,14H17V17h2Z" />
                        </svg>
                      </span>
                      {/* <!-- End Icon --> */}
                    </div>
                    {/* <!-- End Header --> */}

                    <h2 class="text-sm md:text-base text-gray-800 dark:text-neutral-200">
                      QR codes
                    </h2>

                    <div class="grid sm:flex sm:justify-between sm:items-center gap-1 sm:gap-3">
                      <h3 class="text-lg md:text-2xl font-semibold text-gray-800 dark:text-neutral-200">
                        {kpiData &&
                          kpiData?.kpis?.filter(
                            (kpi) => kpi.label === "qr-codes"
                          )[0]?.value}
                        <div className="italic font-extralight text-base">
                          ( This month )
                        </div>
                      </h3>

                      <div class="flex items-center -space-x-2">
                        <button
                          className="flex p-2 px-4 rounded-md text-white text-xs font-semibold bg-teal-400 hover:bg-teal-700 dark:bg-teal-900 dark:hover:bg-teal-950 gap-x-1"
                          onClick={() => (window.location.href = "/qr-code")}
                        >
                          My QRs
                          <svg
                            height="18"
                            width="18"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            transform="rotate(90)"
                          >
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              <path
                                d="M10 3.778a6.222 6.222 0 1 0 3.726 11.205l-4.869-4.869v2.208a.889.889 0 0 1-1.778 0V7.968c0-.49.398-.889.89-.889h4.353a.889.889 0 0 1 0 1.778h-2.208l4.87 4.87A6.193 6.193 0 0 0 16.221 10 6.222 6.222 0 0 0 10 3.778ZM2 10a8 8 0 1 1 16 0 8 8 0 0 1-16 0Z"
                                fill="currentColor"
                              ></path>
                            </g>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- End Card --> */}

                {/* <!-- Card --> */}
                <div class="relative overflow-hidden p-4 sm:p-5 bg-white border border-gray-200 rounded-xl shadow-sm dark:shadow-neutral-950 before:absolute before:top-0 before:end-0 before:size-full before:bg-gradient-to-br before:from-blue-100 before:via-transparent before:blur-xl dark:bg-neutral-800 dark:border-neutral-700 dark:before:from-blue-800/30 dark:before:via-transparent">
                  <div class="relative z-10">
                    {/* <!-- Header --> */}
                    <div class="flex justify-between gap-x-3">
                      {/* <!-- Icon --> */}
                      <span class="mb-3 inline-flex justify-center items-center size-8 md:size-10 rounded-lg bg-white text-gray-700 shadow dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          class="flex-shrink-0 size-4 md:size-5 text-blue-400"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="16"
                            cy="8"
                            r="2"
                            stroke="currentColor"
                            stroke-width="1.5"
                          />
                          <path
                            d="M2 12.5001L3.75159 10.9675C4.66286 10.1702 6.03628 10.2159 6.89249 11.0721L11.1822 15.3618C11.8694 16.0491 12.9512 16.1428 13.7464 15.5839L14.0446 15.3744C15.1888 14.5702 16.7369 14.6634 17.7765 15.599L21 18.5001"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                          <path
                            d="M22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C21.5093 4.43821 21.8356 5.80655 21.9449 8"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                        </svg>
                      </span>
                      {/* <!-- End Icon --> */}
                    </div>
                    {/* <!-- End Header --> */}

                    <h2 class="text-sm md:text-base text-gray-800 dark:text-neutral-200">
                      Gallery
                    </h2>

                    <div class="grid sm:flex sm:justify-between sm:items-center gap-1 sm:gap-3">
                      <h3 class="text-lg md:text-2xl font-semibold text-gray-800 dark:text-neutral-200">
                        {kpiData &&
                          kpiData?.kpis?.filter(
                            (kpi) => kpi.label === "gallery"
                          )[0].value}
                        <div className="italic font-extralight text-base">
                          ( This month )
                        </div>
                      </h3>

                      <div class="flex items-center -space-x-2">
                        <button
                          className="flex p-2 px-4 rounded-md text-white text-xs font-semibold bg-blue-400 hover:bg-blue-700 dark:bg-blue-900 dark:hover:bg-blue-950 gap-x-1"
                          onClick={() => (window.location.href = "/gallery")}
                        >
                          My gallery
                          <svg
                            height="18"
                            width="18"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            transform="rotate(90)"
                          >
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              <path
                                d="M10 3.778a6.222 6.222 0 1 0 3.726 11.205l-4.869-4.869v2.208a.889.889 0 0 1-1.778 0V7.968c0-.49.398-.889.89-.889h4.353a.889.889 0 0 1 0 1.778h-2.208l4.87 4.87A6.193 6.193 0 0 0 16.221 10 6.222 6.222 0 0 0 10 3.778ZM2 10a8 8 0 1 1 16 0 8 8 0 0 1-16 0Z"
                                fill="currentColor"
                              ></path>
                            </g>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- End Card --> */}

                {/* <!-- Card --> */}
                <div class="relative overflow-hidden p-4 sm:p-5 bg-white border border-gray-200 rounded-xl shadow-sm dark:shadow-neutral-950 before:absolute before:top-0 before:end-0 before:size-full before:bg-gradient-to-br before:from-red-100 before:via-transparent before:blur-xl dark:bg-neutral-800 dark:border-neutral-700 dark:before:from-red-800/30 dark:before:via-transparent">
                  <div class="relative z-10">
                    {/* <!-- Header --> */}
                    <div class="flex justify-between gap-x-3">
                      {/* <!-- Icon --> */}
                      <span class="mb-3 inline-flex justify-center items-center size-8 md:size-10 rounded-lg bg-white text-gray-700 shadow dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-400">
                        <svg
                          width="24"
                          height="24"
                          class="flex-shrink-0 size-4 md:size-5 text-red-500"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill="currentColor"
                            d="M13.903 8c.06.629.097 1.292.097 2a1 1 0 01-2 0c0-.713-.039-1.378-.105-2H8.092c-.139 1.33-.14 2.67-.004 4H10a1 1 0 010 2H8.416c.469 2.08 1.202 3.41 1.585 4 0 0 .789.013.926.002a1.008 1.008 0 011.07.925 1 1 0 01-.924 1.07C10.979 20.004 10 20 10 20 4.486 20 0 15.514 0 10 0 4.485 4.486 0 10 0s10 4.485 10 10c0 .379-.036.749-.079 1.116a1.001 1.001 0 01-1.987-.233c.035-.291.066-.583.066-.883 0-.692-.098-1.36-.263-2h-3.834zM9.988 2.036c-.386.606-1.1 1.931-1.564 3.964h3.137c-.466-2.033-1.185-3.358-1.573-3.964zM2.263 12h3.819a21.604 21.604 0 01-.095-1.996c0-.67.033-1.338.096-2.004h-3.82A7.946 7.946 0 002 10a7.96 7.96 0 00.263 2zm.819 2a8.031 8.031 0 004.437 3.601A16.293 16.293 0 016.371 14H3.082zm3.291-8a16.235 16.235 0 011.15-3.603A8.03 8.03 0 003.082 6h3.291zm7.239 0h3.305a8.029 8.029 0 00-4.465-3.612A16.128 16.128 0 0113.612 6z"
                          />
                          <path
                            fill="currentColor"
                            d="M15.223 18.945l-2.55-5.61a.5.5 0 01.662-.662l5.61 2.55a.5.5 0 01.017.903l-1.742.87a.5.5 0 00-.223.224l-.871 1.742a.5.5 0 01-.903-.017z"
                          />
                        </svg>
                      </span>
                      {/* <!-- End Icon --> */}
                    </div>
                    {/* <!-- End Header --> */}

                    <h2 class="text-sm md:text-base text-gray-800 dark:text-neutral-200">
                      Domains
                    </h2>

                    <div class="grid sm:flex sm:justify-between sm:items-center gap-1 sm:gap-3">
                      <h3 class="text-lg md:text-2xl font-semibold text-gray-800 dark:text-neutral-200">
                        {kpiData &&
                          kpiData?.kpis?.filter(
                            (kpi) => kpi.label === "domain"
                          )[0]?.value}

                        <div className="italic font-extralight text-base">
                          ( One time )
                        </div>
                      </h3>

                      <div class="flex items-center -space-x-2">
                        <button
                          className="flex px-4 p-2 rounded-md text-white text-xs font-semibold bg-red-400 hover:bg-red-700 dark:bg-red-900 dark:hover:bg-red-950 gap-x-1"
                          onClick={() => (window.location.href = "/domains")}
                        >
                          My domains
                          <svg
                            height="18"
                            width="18"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                            transform="rotate(90)"
                          >
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              <path
                                d="M10 3.778a6.222 6.222 0 1 0 3.726 11.205l-4.869-4.869v2.208a.889.889 0 0 1-1.778 0V7.968c0-.49.398-.889.89-.889h4.353a.889.889 0 0 1 0 1.778h-2.208l4.87 4.87A6.193 6.193 0 0 0 16.221 10 6.222 6.222 0 0 0 10 3.778ZM2 10a8 8 0 1 1 16 0 8 8 0 0 1-16 0Z"
                                fill="currentColor"
                              ></path>
                            </g>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* <!-- End Col --> */}
                </div>
                {/* <!-- End Card --> */}
              </div>
              {/* <!-- End Stats Grid --> */}

              <div class="p-5 pb-0 bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700 hidden">
                {/* <!-- SVG Background Element --> */}
                <figure>
                  <svg
                    class="w-full"
                    preserveAspectRatio="none"
                    width="1113"
                    height="161"
                    viewBox="0 0 1113 161"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_697_201879)">
                      <rect x="1" width="1112" height="348" fill="#B2E7FE" />
                      <rect
                        width="185.209"
                        height="704.432"
                        transform="matrix(0.50392 0.86375 -0.860909 0.508759 435.452 -177.87)"
                        fill="#FF8F5D"
                      />
                      <rect
                        width="184.653"
                        height="378.667"
                        transform="matrix(0.849839 -0.527043 0.522157 0.852849 -10.4556 -16.4521)"
                        fill="#3ECEED"
                      />
                      <rect
                        width="184.653"
                        height="189.175"
                        transform="matrix(0.849839 -0.527043 0.522157 0.852849 35.4456 58.5195)"
                        fill="#4C48FF"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_697_201879">
                        <rect
                          x="0.5"
                          width="1112"
                          height="161"
                          rx="12"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </figure>
                {/* <!-- End SVG Background Element --> */}

                {/* <!-- Avatar --> */}
                <div class="-mt-24">
                  <div class="relative flex w-[120px] h-[120px] mx-auto border-4 border-white rounded-full dark:border-neutral-700">
                    <img
                      class="object-cover size-full rounded-full"
                      src={user.profilePic}
                      alt="Profile Picture"
                    />
                  </div>

                  <div class="mt-3 text-center mb-6">
                    <h1 class="text-xl font-semibold text-gray-800 dark:text-neutral-200">
                      {user.name}
                    </h1>
                    <p class="text-gray-500 dark:text-neutral-500">
                      {user.email}
                    </p>
                  </div>
                </div>
                {/* <!-- End Avatar --> */}

                {/* <!-- Header --> */}

                {/* <!-- End Header --> */}
              </div>
              <div class="p-5 space-y-4 flex flex-1 bg-white border border-gray-200 shadow-sm rounded-xl  dark:bg-neutral-800 dark:border-neutral-700">
                <div class="max-w-[85rem] mx-auto  px-4 sm:px-6 lg:px-8 py-12 md:pt-10 md:pb-10">
                  {/* <!-- Title --> */}
                  <div class="max-w-[85rem] mx-auto text-center mb-10 lg:mb-14">
                    <h2 class="text-2xl font-bold  md:text-3xl text-gray-800 dark:text-neutral-200">
                      Craft Effective Links with Our Advanced{" "}
                      <span class="text-[#019ca2] dark:text-[#019ca2]">
                        URL Shortener
                      </span>
                    </h2>

                    <p class="text-gray-500 mt-2 dark:text-neutral-400">
                      Leverage our URL shortener, QR codes, and landing pages to
                      engage your audience and guide them to the right content.
                      Build, modify, and track everything effortlessly within
                      the Atz Connections Platform.
                    </p>
                  </div>
                  {/* <!-- End Title --> */}

                  {/* <!-- Grid --> */}
                  <div class="grid grid-cols-12 gap-4 xl:gap-6">
                    <div class="col-span-12 md:col-span-6 md:order-2 lg:col-span-4 grid gap-4 xl:gap-6">
                      {/* <!-- Card --> */}
                      <a
                        class="md:order-1 relative focus:outline-none before:absolute before:inset-0 before:z-10 before:border before:border-gray-200 before:rounded-xl before:transition before:hover:border-2 before:hover:border-[#019ca2] before:focus:border-2 before:focus:border-[#019ca2] before:hover:shadow-lg dark:hover:bg-neutral-900 dark:before:hover:shadow-neutral-950 dark:before:border-neutral-700 dark:before:hover:border-[#019ca2] dark:before:focus:border-[#019ca2] dark:rounded-xl"
                        href="/atz-ai"
                      >
                        <div class="relative overflow-hidden size-full rounded-xl">
                          <div class="p-6 flex flex-col justify-center items-center md:min-h-[480px] text-center rounded-xl dark:border-neutral-700">
                            <p class="bg-clip-text bg-gradient-to-l from-purple-400 to-[#019ca2] text-transparent text-xs font-semibold uppercase">
                              Introducing
                            </p>
                            <span class="bg-clip-text gap-x-1 inline-flex items-center bg-gradient-to-l from-purple-400 to-[#019ca2] text-transparent text-7xl font-bold z-[1]">
                              <svg
                                className="flex-shrink-0"
                                height="36px"
                                width="36px"
                                version="1.1"
                                id="_x32_"
                                xmlns="http://www.w3.org/2000/svg"
                                xmlnsXlink="http://www.w3.org/1999/xlink"
                                viewBox="0 0 512 512"
                                xmlSpace="preserve"
                                fill="#019ca2"
                              >
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g
                                  id="SVGRepo_tracerCarrier"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></g>
                                <g id="SVGRepo_iconCarrier">
                                  {" "}
                                  <g>
                                    {" "}
                                    <path
                                      class="st0"
                                      d="M247.355,106.9C222.705,82.241,205.833,39.18,197.46,0c-8.386,39.188-25.24,82.258-49.899,106.917 c-24.65,24.642-67.724,41.514-106.896,49.904c39.188,8.373,82.254,25.235,106.904,49.895c24.65,24.65,41.522,67.72,49.908,106.9 c8.373-39.188,25.24-82.258,49.886-106.917c24.65-24.65,67.724-41.514,106.896-49.904 C315.08,148.422,272.014,131.551,247.355,106.9z"
                                    ></path>{" "}
                                    <path
                                      class="st0"
                                      d="M407.471,304.339c-14.714-14.721-24.81-40.46-29.812-63.864c-5.011,23.404-15.073,49.142-29.803,63.872 c-14.73,14.714-40.464,24.801-63.864,29.812c23.408,5.01,49.134,15.081,63.864,29.811c14.73,14.722,24.81,40.46,29.82,63.864 c5.001-23.413,15.081-49.142,29.802-63.872c14.722-14.722,40.46-24.802,63.856-29.82 C447.939,329.14,422.201,319.061,407.471,304.339z"
                                    ></path>{" "}
                                    <path
                                      class="st0"
                                      d="M146.352,354.702c-4.207,19.648-12.655,41.263-25.019,53.626c-12.362,12.354-33.968,20.82-53.613,25.027 c19.645,4.216,41.251,12.656,53.613,25.027c12.364,12.362,20.829,33.96,25.036,53.618c4.203-19.658,12.655-41.255,25.023-53.626 c12.354-12.362,33.964-20.82,53.605-25.035c-19.64-4.2-41.251-12.656-53.613-25.019 C159.024,395.966,150.555,374.351,146.352,354.702z"
                                    ></path>{" "}
                                  </g>{" "}
                                </g>
                              </svg>
                              Atz AI
                              {/* <!-- 202 --> */}
                            </span>
                            <h3 class="mt-6 text-lg md:text-xl font-semibold text-gray-800 dark:text-neutral-200">
                              What's in it?
                            </h3>
                            <p class="mt-2 text-gray-500 dark:text-neutral-400">
                              Enhances every link with smart, AI-driven insights
                              and contextual understanding.
                            </p>
                            <p class="mt-6 inline-flex justify-center items-center gap-x-1 text-center bg-[#019ca2] hover:bg-blue-700 border border-transparent text-white text-sm font-medium rounded-lg py-3 px-4 z-[1]">
                              Explore AI
                              <svg
                                class="shrink-0 size-4"
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              >
                                <path d="m9 18 6-6-6-6" />
                              </svg>
                            </p>
                          </div>

                          <div class="absolute top-0 start-full z-[0] w-60 h-20 bg-blue-100 blur-[100px] -translate-y-full -translate-x-1/2 dark:bg-blue-900/30"></div>
                          <div class="absolute top-0 start-full z-[0] w-60 h-20 bg-purple-100 blur-[100px] -translate-y-full -translate-x-1/2 dark:bg-violet-900/30"></div>
                          <div class="absolute top-1/2 start-1/2 z-[0] w-60 h-32 bg-purple-100 blur-[100px] -translate-y-1/2 -translate-x-1/2 dark:bg-violet-900/30"></div>
                          <div class="absolute -bottom-40 -end-24 z-[0] w-60 h-32 bg-blue-200 blur-[100px] dark:bg-blue-900/70"></div>
                          <div class="absolute -bottom-[635px] -end-[635px] z-[0] size-[635px] border border-dashed border-violet-200 rounded-full transform -translate-y-[255px] -translate-x-[310px] opacity-40 dark:border-violet-900/60"></div>
                          <div class="absolute -bottom-[490px] -end-[490px] z-[0] w-[490px] h-[490px] border border-dashed border-violet-200 rounded-full transform -translate-y-[190px] -translate-x-[240px] opacity-60 dark:border-violet-900/60"></div>
                          <div class="absolute -bottom-[340px] -end-[340px] z-[0] w-[340px] h-[340px] border border-dashed border-violet-200 rounded-full transform -translate-y-[120px] -translate-x-[170px] opacity-80 dark:border-violet-900/60"></div>
                          <div class="absolute -bottom-[200px] -end-[200px] z-[0] w-[200px] h-[200px] border border-dashed border-violet-200 rounded-full transform -translate-y-[60px] -translate-x-[100px] dark:border-violet-900/60"></div>
                        </div>
                      </a>
                      {/* <!-- End Card --> */}

                      {/* <!-- Card --> */}
                      <a
                        class="md:order-2 relative focus:outline-none p-6 flex flex-col justify-center items-center md:min-h-[230px] text-center rounded-xl before:absolute before:inset-0 before:z-10 before:border before:border-gray-200 before:rounded-xl before:transition before:hover:border-2 dark:hover:bg-neutral-900 dark:before:hover:shadow-neutral-950 before:hover:border-[#019ca2] before:focus:border-2 before:focus:border-[#019ca2] before:hover:shadow-lg dark:before:border-neutral-700 dark:before:hover:border-[#019ca2] dark:before:focus:border-[#019ca2] dark:rounded-xl"
                        href="/qr-code"
                      >
                        <svg
                          class="shrink-0 size-10 mb-3"
                          width="46"
                          height="46"
                          viewBox="0 0 24 24"
                          fill="#019ca2"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M21,2H15a1,1,0,0,0-1,1V9a1,1,0,0,0,1,1h1v2h2V10h2v2h2V3A1,1,0,0,0,21,2ZM18,8H16V4h4V8ZM3,10H9a1,1,0,0,0,1-1V3A1,1,0,0,0,9,2H3A1,1,0,0,0,2,3V9A1,1,0,0,0,3,10ZM4,4H8V8H4ZM5,16v2H3V16ZM3,20H5v2H3Zm4-2v2H5V18Zm0-2H5V14H7V12H9v4ZM5,12v2H3V12Zm9,3v1H13V14H11v4h3v3a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V15a1,1,0,0,0-1-1H16V12H14Zm6,1v4H16V16ZM9,18h2v2h1v2H7V20H9ZM13,6H11V4h2ZM11,8h2v4H11ZM5,5H7V7H5ZM17,5h2V7H17Zm2,14H17V17h2Z" />
                        </svg>
                        <h3 class="text-lg md:text-xl font-semibold text-gray-800 dark:text-neutral-200">
                          QR Codes
                        </h3>
                        <p class="mt-2 text-gray-500 dark:text-neutral-400">
                          Develop interactive QR codes for Atz that instantly
                          engage and direct users to desired content.
                        </p>
                        <p class="mt-2 inline-flex items-center gap-x-1 text-[#019ca2] font-medium dark:text-[#019ca2]">
                          See how it works
                          <svg
                            class="shrink-0 size-4"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path d="m9 18 6-6-6-6" />
                          </svg>
                        </p>
                      </a>
                      {/* <!-- End Card --> */}
                    </div>
                    {/* <!-- End Col --> */}

                    <div class="col-span-12 md:col-span-6 lg:col-span-4 md:order-1 grid gap-4 xl:gap-6">
                      {/* <!-- Card --> */}
                      <a
                        class="md:order-2 relative focus:outline-none "
                        href="/analytics"
                      >
                        <div class="overflow-hidden p-6 flex flex-col justify-between items-center md:min-h-[480px] text-center rounded-xl before:absolute before:inset-0 before:z-10 before:border before:border-gray-200 before:rounded-xl before:transition before:hover:border-2 before:hover:border-[#019ca2] before:focus:border-2 before:focus:border-[#019ca2] before:hover:shadow-lg dark:before:border-neutral-700 dark:before:hover:border-[#019ca2] dark:before:focus:border-[#019ca2]  dark:before:hover:shadow-neutral-950 dark:hover:bg-neutral-900">
                          <div class="relative w-full min-h-[258px] before:absolute before:top-0 before:-inset-x-6  before:bg-no-repeat before:bg-center before:bg-cover lg:before:bg-auto before:w-[calc(100%+48px)] before:h-full before:z-[0] before:bg-[url('../assets/images/svg/rounded-lines.svg')] z-[1]">
                            <div className="flex flex-1 min-h-[258px] justify-center">
                              <div className="flex flex-col flex-1 justify-evenly items-center">
                                <div>
                                  <div className="bg-white  border shadow-lg rounded-full text-[#019ca2] p-2 h-12 w-12 flex items-center justify-center">
                                    <svg
                                      height="200px"
                                      className="h-7 w-7"
                                      width="200px"
                                      version="1.1"
                                      id="Layer_1"
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      viewBox="0 0 512 512"
                                      xmlSpace="preserve"
                                      fill="#000000"
                                    >
                                      <g
                                        id="SVGRepo_bgCarrier"
                                        stroke-width="0"
                                      ></g>
                                      <g
                                        id="SVGRepo_tracerCarrier"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></g>
                                      <g id="SVGRepo_iconCarrier">
                                        {" "}
                                        <path
                                          style={{ fill: "#00D9C0" }}
                                          d="M180.505,501.644l-55.6-238.434c-0.891-3.82-4.296-6.523-8.219-6.523s-7.328,2.703-8.219,6.523 l-55.6,238.434c-0.585,2.507,0.008,5.146,1.609,7.162c1.6,2.018,4.035,3.194,6.611,3.194h111.2c2.575,0,5.01-1.176,6.61-3.194 C180.497,506.79,181.089,504.151,180.505,501.644z"
                                        ></path>{" "}
                                        <path
                                          style={{ fill: "#1EA29F" }}
                                          d="M116.685,512h55.6c2.575,0,5.01-1.176,6.61-3.194c1.601-2.016,2.193-4.655,1.609-7.162l-55.6-238.434 c-0.891-3.82-4.296-6.523-8.219-6.523L116.685,512L116.685,512z"
                                        ></path>{" "}
                                        <path
                                          style={{ fill: "#FFDB56" }}
                                          d="M268.817,501.964l-60.693-315.077c-0.765-3.972-4.242-6.843-8.288-6.843s-7.522,2.871-8.288,6.843 l-60.693,315.077c-0.476,2.472,0.177,5.029,1.779,6.97c1.604,1.942,3.989,3.066,6.507,3.066h121.387 c2.518,0,4.904-1.124,6.507-3.066C268.64,506.993,269.294,504.436,268.817,501.964z"
                                        ></path>{" "}
                                        <path
                                          style={{ fill: "#FBA806" }}
                                          d="M199.836,512h60.693c2.518,0,4.904-1.124,6.507-3.066c1.604-1.942,2.256-4.498,1.78-6.97 l-60.693-315.077c-0.765-3.972-4.242-6.843-8.288-6.843l0,0V512z"
                                        ></path>{" "}
                                        <path
                                          style={{ fill: "#3FC8F2" }}
                                          d="M345.798,501.644l-55.6-238.434c-0.891-3.82-4.296-6.523-8.219-6.523 c-3.923,0-7.328,2.703-8.219,6.523l-55.601,238.434c-0.585,2.507,0.008,5.146,1.609,7.162c1.6,2.018,4.035,3.194,6.61,3.194h111.2 c2.576,0,5.01-1.176,6.61-3.194C345.79,506.79,346.382,504.151,345.798,501.644z"
                                        ></path>{" "}
                                        <path
                                          style={{ fill: "#0DAACE" }}
                                          d="M281.979,512h55.6c2.576,0,5.01-1.176,6.61-3.194c1.601-2.016,2.193-4.655,1.609-7.162l-55.6-238.434 c-0.891-3.82-4.296-6.523-8.219-6.523l0,0V512L281.979,512z"
                                        ></path>{" "}
                                        <path
                                          style={{ fill: "#F77935" }}
                                          d="M434.825,502.31L374.131,97.211c-0.619-4.132-4.168-7.189-8.346-7.189 c-4.178,0-7.727,3.057-8.346,7.189L296.746,502.31c-0.365,2.429,0.349,4.897,1.951,6.758c1.602,1.861,3.938,2.931,6.395,2.931 h121.387c2.456,0,4.791-1.07,6.395-2.931C434.476,507.206,435.19,504.74,434.825,502.31z"
                                        ></path>{" "}
                                        <path
                                          style={{ fill: "#D9510D" }}
                                          d="M365.786,512h60.693c2.456,0,4.791-1.07,6.395-2.931c1.603-1.861,2.316-4.329,1.951-6.758 L374.131,97.211c-0.619-4.132-4.168-7.189-8.346-7.189V512H365.786z"
                                        ></path>{" "}
                                        <path
                                          style={{ fill: "#808080" }}
                                          d="M502.998,512H9.002c-4.661,0-8.44-3.778-8.44-8.44V8.44C0.563,3.778,4.341,0,9.002,0 s8.44,3.778,8.44,8.44v486.681h485.556c4.661,0,8.44,3.778,8.44,8.44S507.659,512,502.998,512z"
                                        ></path>{" "}
                                      </g>
                                    </svg>
                                  </div>
                                </div>
                                <div>
                                  <div className="bg-white  border shadow-lg rounded-full text-[#019ca2] p-2 h-12 w-12 flex items-center justify-center">
                                    <svg
                                      version="1.1"
                                      className="h-7 w-7"
                                      id="Layer_1"
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      viewBox="0 0 392.727 392.727"
                                      xmlSpace="preserve"
                                      fill="#000000"
                                    >
                                      <g
                                        id="SVGRepo_bgCarrier"
                                        stroke-width="0"
                                      ></g>
                                      <g
                                        id="SVGRepo_tracerCarrier"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></g>
                                      <g id="SVGRepo_iconCarrier">
                                        {" "}
                                        <rect
                                          x="21.851"
                                          y="21.786"
                                          style={{ fill: "#FFC10D" }}
                                          width="348.962"
                                          height="15.192"
                                        ></rect>{" "}
                                        <rect
                                          x="43.96"
                                          y="58.893"
                                          style={{ fill: "#FFFFFF" }}
                                          width="304.679"
                                          height="238.222"
                                        ></rect>{" "}
                                        <g>
                                          {" "}
                                          <path
                                            style={{ fill: "#194F82" }}
                                            d="M381.737,0H10.925C4.913,0,0,4.848,0,10.925v37.107c0,6.012,4.848,10.925,10.925,10.925h11.184 v249.147c0,6.012,4.848,10.925,10.925,10.925h152.372v51.846h-8.469c-6.012,0-10.925,4.848-10.925,10.925 c0,6.012,4.848,10.925,10.925,10.925h38.853c6.012,0,10.925-4.848,10.925-10.925c0-6.012-4.848-10.925-10.925-10.925h-8.469V319.03 h152.372c6.012,0,10.925-4.848,10.925-10.925V58.893h11.184c6.012,0,10.925-4.848,10.925-10.925V10.861 C392.598,4.848,387.749,0,381.737,0z M370.812,37.107H21.851V21.786h348.962L370.812,37.107L370.812,37.107z M348.703,297.051 H43.96V58.893h304.679v238.158H348.703z"
                                          ></path>{" "}
                                          <path
                                            style={{ fill: "#194F82" }}
                                            d="M109.059,225.228c-10.02,0-18.23-8.21-18.23-18.23c0-6.012-4.848-10.925-10.925-10.925 c-6.077,0-10.925,4.848-10.925,10.925c0,18.295,12.347,33.681,29.156,38.465v8.275c0,6.012,4.848,10.925,10.925,10.925 c6.077,0,10.925-4.848,10.925-10.925v-8.275c16.743-4.784,29.156-20.17,29.156-38.465c0-22.109-17.907-40.016-40.016-40.016 c-0.517,0-1.034,0-1.552,0.065c-9.374-0.776-16.679-8.663-16.679-18.166c0-10.02,8.21-18.23,18.23-18.23s18.23,8.21,18.23,18.23 c0,6.012,4.848,10.925,10.925,10.925s10.925-4.848,10.925-10.925c0-18.295-12.347-33.681-29.156-38.465v-8.339 c0-6.012-4.848-10.925-10.925-10.925c-6.012,0-10.925,4.848-10.925,10.925v8.339c-16.743,4.784-29.156,20.17-29.156,38.465 c0,22.109,17.907,40.016,40.016,40.016c0.517,0,1.034,0,1.552-0.065c9.374,0.776,16.679,8.663,16.679,18.166 C127.354,217.147,119.143,225.228,109.059,225.228z"
                                          ></path>{" "}
                                        </g>{" "}
                                        <path
                                          style={{ fill: "#FFC10D" }}
                                          d="M254.513,122.182v44.865h44.865C294.982,144.42,277.139,126.578,254.513,122.182z"
                                        ></path>{" "}
                                        <path
                                          style={{ fill: "#FFFFFF" }}
                                          d="M248.113,188.832l-36.267,36.267c9.051,6.077,20.04,9.762,31.741,9.762 c27.604,0,50.683-19.782,55.79-45.964h-51.265V188.832z"
                                        ></path>{" "}
                                        <path
                                          style={{ fill: "#56ACE0" }}
                                          d="M196.461,209.713l36.267-36.267v-51.329c-26.117,5.107-45.964,28.186-45.964,55.79 C186.764,189.673,190.319,200.598,196.461,209.713z"
                                        ></path>{" "}
                                        <path
                                          style={{ fill: "#194F82" }}
                                          d="M243.653,99.297c-43.378,0-78.675,35.297-78.675,78.675s35.297,78.675,78.675,78.675 s78.675-35.297,78.675-78.675S286.966,99.297,243.653,99.297z M243.653,234.796c-11.766,0-22.626-3.556-31.741-9.762l36.267-36.267 h51.329C294.335,215.014,271.192,234.796,243.653,234.796z M232.727,122.182v51.329l-36.267,36.267 c-6.077-9.051-9.762-20.04-9.762-31.741C186.764,150.368,206.545,127.289,232.727,122.182z M254.513,167.046v-44.865 c22.626,4.396,40.469,22.238,44.865,44.865H254.513L254.513,167.046z"
                                        ></path>{" "}
                                      </g>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                              <div className="flex flex-col flex-1 justify-between items-center">
                                <div>
                                  <div className="bg-white  border shadow-lg rounded-full text-[#019ca2] p-2 h-12 w-12 flex items-center justify-center">
                                    <svg
                                      version="1.1"
                                      id="Layer_1"
                                      xmlns="http://www.w3.org/2000/svg"
                                      className="h-[24px] w-[24px]"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      viewBox="0 0 512 512"
                                      xmlSpace="preserve"
                                      fill="#000000"
                                    >
                                      <g
                                        id="SVGRepo_bgCarrier"
                                        stroke-width="0"
                                      ></g>
                                      <g
                                        id="SVGRepo_tracerCarrier"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></g>
                                      <g id="SVGRepo_iconCarrier">
                                        {" "}
                                        <path
                                          style={{ fill: "#FF754F" }}
                                          d="M430.418,0h-33.758v512h33.758c18.567,0,33.758-15.191,33.758-33.758V33.758 C464.176,15.191,448.985,0,430.418,0z"
                                        ></path>{" "}
                                        <path
                                          style={{ fill: "#FF9269" }}
                                          d="M364.851,205.363l65.566-171.604C430.418,15.191,415.226,0,396.659,0h-53.451L256,23.74L168.791,0 H81.582C63.015,0,47.824,15.191,47.824,33.758v444.484c0,18.567,15.191,33.758,33.758,33.758h315.077 c18.567,0,33.758-15.191,33.758-33.758V376.967L364.851,205.363z"
                                        ></path>{" "}
                                        <polygon
                                          style={{ fill: "#FFEAB5" }}
                                          points="430.418,33.758 430.418,478.242 396.659,478.242 329.143,222.242 396.659,33.758 "
                                        ></polygon>{" "}
                                        <polygon
                                          style={{ fill: "#A7CBCF" }}
                                          points="343.209,0 334.769,56.264 244.747,56.264 301.011,0 "
                                        ></polygon>{" "}
                                        <polygon
                                          style={{ fill: "#C7E7EB" }}
                                          points="301.011,0 301.011,56.264 177.231,56.264 168.791,0 "
                                        ></polygon>{" "}
                                        <rect
                                          x="81.582"
                                          y="33.758"
                                          style={{ fill: "#FFFFFF" }}
                                          width="315.077"
                                          height="444.484"
                                        ></rect>{" "}
                                        <g>
                                          {" "}
                                          <path
                                            style={{ fill: "#88ACB5" }}
                                            d="M326.33,0v61.89c0,4.654-3.785,8.44-8.44,8.44H194.11c-4.654,0-8.44-3.785-8.44-8.44V0h-16.879 v61.89c0,13.96,11.357,25.319,25.319,25.319h123.78c13.961,0,25.319-11.359,25.319-25.319V0H326.33z"
                                          ></path>{" "}
                                          <path
                                            style={{ fill: "#88ACB5" }}
                                            d="M354.462,362.901H157.538c-4.661,0-8.44,3.778-8.44,8.44s3.779,8.44,8.44,8.44h196.923 c4.661,0,8.44-3.778,8.44-8.44S359.122,362.901,354.462,362.901z"
                                          ></path>{" "}
                                          <path
                                            style={{ fill: "#88ACB5" }}
                                            d="M354.462,413.538H157.538c-4.661,0-8.44-3.778-8.44-8.44s3.779-8.44,8.44-8.44h196.923 c4.661,0,8.44,3.778,8.44,8.44S359.122,413.538,354.462,413.538z"
                                          ></path>{" "}
                                          <path
                                            style={{ fill: "#88ACB5" }}
                                            d="M264.44,447.297H157.538c-4.661,0-8.44-3.778-8.44-8.44c0-4.662,3.779-8.44,8.44-8.44H264.44 c4.661,0,8.44,3.778,8.44,8.44C272.879,443.519,269.1,447.297,264.44,447.297z"
                                          ></path>{" "}
                                        </g>{" "}
                                        <path
                                          style={{ fill: "#FFBE35" }}
                                          d="M331.596,251.668L256,331.956c29.527,0,56.241-11.962,75.596-31.305V251.668z"
                                        ></path>{" "}
                                        <path
                                          style={{ fill: "#FF4719" }}
                                          d="M316.371,191.297v94.129l15.225,15.225c19.343-19.355,31.305-46.069,31.305-75.596L316.371,191.297z"
                                        ></path>{" "}
                                        <path
                                          style={{ fill: "#FFCD60" }}
                                          d="M316.371,180.044v105.382c-13.177,28.098-35.3,46.53-60.371,46.53 c-59.043,0-106.901-47.858-106.901-106.901L316.371,180.044z"
                                        ></path>{" "}
                                        <path
                                          style={{ fill: "#FF754F" }}
                                          d="M256,225.055l36.571-11.253l36.571,11.253c0,22.404-4.715,43.188-12.772,60.371L256,225.055z"
                                        ></path>{" "}
                                        <path
                                          style={{ fill: "#FF9269" }}
                                          d="M331.596,149.459h-37.73l35.277,75.596h33.758C362.901,195.528,350.939,168.814,331.596,149.459z"
                                        ></path>{" "}
                                        <path
                                          style={{ fill: "#FFEAB5" }}
                                          d="M256,118.154l18.848,56.545L256,225.055H149.099C149.099,166.012,196.957,118.154,256,118.154z"
                                        ></path>{" "}
                                        <path
                                          style={{ fill: "#FFB082" }}
                                          d="M329.143,225.055H256l18.848-50.356l41.523-10.015C324.428,181.867,329.143,202.651,329.143,225.055z "
                                        ></path>{" "}
                                        <path
                                          style={{ fill: "#25BBCC" }}
                                          d="M256,118.154l60.371,46.53l15.225-15.225C312.241,130.116,285.527,118.154,256,118.154z"
                                        ></path>{" "}
                                        <path
                                          style={{ fill: "#50D1DD" }}
                                          d="M316.371,164.684L256,225.055V118.154C281.071,118.154,303.194,136.586,316.371,164.684z"
                                        ></path>{" "}
                                      </g>
                                    </svg>
                                  </div>
                                </div>
                                <div>
                                  <div className="bg-white  border shadow-lg rounded-full text-[#019ca2] p-2 h-12 w-12 flex items-center justify-center ">
                                    <svg
                                      viewBox="-1 0 24 24"
                                      height="22"
                                      width="22"
                                      id="meteor-icon-kit__regular-analytics"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <g
                                        id="SVGRepo_bgCarrier"
                                        stroke-width="0"
                                      ></g>
                                      <g
                                        id="SVGRepo_tracerCarrier"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></g>
                                      <g id="SVGRepo_iconCarrier">
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M3 15C2.44772 15 2 15.4477 2 16V21C2 21.5523 2.44772 22 3 22C3.55228 22 4 21.5523 4 21V16C4 15.4477 3.55228 15 3 15zM3 13C4.65685 13 6 14.3431 6 16V21C6 22.6569 4.65685 24 3 24C1.34315 24 0 22.6569 0 21V16C0 14.3431 1.34315 13 3 13zM11 0C12.6569 0 14 1.34315 14 3V21C14 22.6569 12.6569 24 11 24C9.3431 24 8 22.6569 8 21V3C8 1.34315 9.3431 0 11 0zM11 2C10.4477 2 10 2.44772 10 3V21C10 21.5523 10.4477 22 11 22C11.5523 22 12 21.5523 12 21V3C12 2.44772 11.5523 2 11 2zM19 7C20.6569 7 22 8.34315 22 10V21C22 22.6569 20.6569 24 19 24C17.3431 24 16 22.6569 16 21V10C16 8.34315 17.3431 7 19 7zM19 9C18.4477 9 18 9.44771 18 10V21C18 21.5523 18.4477 22 19 22C19.5523 22 20 21.5523 20 21V10C20 9.44771 19.5523 9 19 9z"
                                          fill="currentColor"
                                        ></path>
                                      </g>
                                    </svg>
                                  </div>
                                </div>
                                <div>
                                  <div className="bg-white  border shadow-lg rounded-full text-[#019ca2] p-2 h-12 w-12 flex items-center justify-center">
                                    <svg
                                      version="1.1"
                                      className="h-9 w-9"
                                      id="Layer_1"
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      viewBox="0 0 512 512"
                                      xmlSpace="preserve"
                                      fill="#000000"
                                    >
                                      <g
                                        id="SVGRepo_bgCarrier"
                                        stroke-width="0"
                                      ></g>
                                      <g
                                        id="SVGRepo_tracerCarrier"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></g>
                                      <g id="SVGRepo_iconCarrier">
                                        {" "}
                                        <circle
                                          style={{ fill: "#E6E5E5" }}
                                          cx="256"
                                          cy="256"
                                          r="256"
                                        ></circle>{" "}
                                        <rect
                                          x="127.504"
                                          y="83.888"
                                          style={{ fill: "#FFFFFF" }}
                                          width="258"
                                          height="344"
                                        ></rect>{" "}
                                        <g>
                                          {" "}
                                          <rect
                                            x="127.528"
                                            y="83.896"
                                            style={{ fill: "#232323" }}
                                            width="258"
                                            height="41.4"
                                          ></rect>{" "}
                                          <rect
                                            x="167.064"
                                            y="331.656"
                                            style={{ fill: "#232323" }}
                                            width="178.904"
                                            height="7.168"
                                          ></rect>{" "}
                                        </g>{" "}
                                        <g>
                                          {" "}
                                          <rect
                                            x="167.064"
                                            y="353.12"
                                            style={{ fill: "#D8D8D8" }}
                                            width="178.904"
                                            height="7.168"
                                          ></rect>{" "}
                                          <rect
                                            x="167.064"
                                            y="367.488"
                                            style={{ fill: "#D8D8D8" }}
                                            width="178.904"
                                            height="7.168"
                                          ></rect>{" "}
                                          <rect
                                            x="167.064"
                                            y="381.824"
                                            style={{ fill: "#D8D8D8" }}
                                            width="178.904"
                                            height="7.168"
                                          ></rect>{" "}
                                        </g>{" "}
                                        <polygon
                                          style={{ fill: "#FFFFFF" }}
                                          points="358.56,115.848 214.64,115.848 203.856,94.344 358.56,94.344 "
                                        ></polygon>{" "}
                                        <g>
                                          {" "}
                                          <rect
                                            x="334.52"
                                            y="94.344"
                                            style={{ fill: "#DB2B42" }}
                                            width="24"
                                            height="21.504"
                                          ></rect>{" "}
                                          <circle
                                            style={{ fill: "#DB2B42" }}
                                            cx="149.416"
                                            cy="105.16"
                                            r="5.824"
                                          ></circle>{" "}
                                        </g>{" "}
                                        <circle
                                          style={{ fill: "#8AD5DD" }}
                                          cx="164.856"
                                          cy="105.16"
                                          r="5.824"
                                        ></circle>{" "}
                                        <circle
                                          style={{ fill: "#CCCBCA" }}
                                          cx="180.304"
                                          cy="105.16"
                                          r="5.824"
                                        ></circle>{" "}
                                        <g>
                                          {" "}
                                          <path
                                            style={{ fill: "#232323" }}
                                            d="M223.368,100.584l0.92,4.016c0.2,0.944,0.4,1.904,0.568,2.864h0.04 c0.184-0.96,0.472-1.944,0.728-2.848l1.144-4.032h1.816l1.112,3.928c0.272,1.032,0.504,2,0.704,2.96h0.04 c0.144-0.96,0.344-1.928,0.576-2.96l0.984-3.928h2.192l-2.776,8.848h-2.072l-1.048-3.6c-0.272-0.92-0.472-1.76-0.672-2.832h-0.04 c-0.184,1.088-0.416,1.96-0.672,2.832l-1.128,3.6h-2.088l-2.616-8.848H223.368z"
                                          ></path>{" "}
                                          <path
                                            style={{ fill: "#232323" }}
                                            d="M237.264,100.584l0.92,4.016c0.2,0.944,0.4,1.904,0.568,2.864h0.04 c0.184-0.96,0.472-1.944,0.728-2.848l1.144-4.032h1.816l1.112,3.928c0.272,1.032,0.504,2,0.704,2.96h0.04 c0.144-0.96,0.344-1.928,0.576-2.96l0.984-3.928h2.192l-2.776,8.848h-2.072l-1.048-3.6c-0.272-0.92-0.472-1.76-0.672-2.832h-0.04 c-0.184,1.088-0.416,1.96-0.672,2.832l-1.128,3.6h-2.088l-2.616-8.848H237.264z"
                                          ></path>{" "}
                                          <path
                                            style={{ fill: "#232323" }}
                                            d="M251.16,100.584l0.92,4.016c0.2,0.944,0.4,1.904,0.568,2.864h0.04 c0.184-0.96,0.472-1.944,0.728-2.848l1.144-4.032h1.816l1.112,3.928c0.272,1.032,0.504,2,0.704,2.96h0.04 c0.144-0.96,0.344-1.928,0.576-2.96l0.984-3.928h2.192l-2.776,8.848h-2.072l-1.048-3.6c-0.272-0.92-0.472-1.76-0.672-2.832h-0.04 c-0.184,1.088-0.416,1.96-0.672,2.832l-1.128,3.6h-2.088l-2.616-8.848H251.16z"
                                          ></path>{" "}
                                          <path
                                            style={{ fill: "#232323" }}
                                            d="M262.344,108.192c0-0.84,0.576-1.432,1.376-1.432c0.816,0,1.376,0.576,1.376,1.432 c0,0.816-0.544,1.432-1.376,1.432C262.912,109.632,262.344,109.008,262.344,108.192z"
                                          ></path>{" "}
                                        </g>{" "}
                                        <g>
                                          {" "}
                                          <polygon
                                            style={{ fill: "#FFFFFF" }}
                                            points="347.976,111.688 346.528,110.272 351.536,105.128 346.232,99.944 347.64,98.504 354.4,105.08 "
                                          ></polygon>{" "}
                                          <rect
                                            x="338.48"
                                            y="104.08"
                                            style={{ fill: "#FFFFFF" }}
                                            width="13.376"
                                            height="2.024"
                                          ></rect>{" "}
                                        </g>{" "}
                                        <polygon
                                          style={{ fill: "#CCCBCA" }}
                                          points="320.864,108.984 326.256,102.48 315.48,102.48 "
                                        ></polygon>{" "}
                                        <rect
                                          x="170.312"
                                          y="232.72"
                                          style={{ fill: "#232323" }}
                                          width="15.68"
                                          height="54.056"
                                        ></rect>{" "}
                                        <rect
                                          x="195.792"
                                          y="210.632"
                                          style={{ fill: "#DB2B42" }}
                                          width="15.68"
                                          height="76.16"
                                        ></rect>{" "}
                                        <rect
                                          x="221.272"
                                          y="188.52"
                                          style={{ fill: "#232323" }}
                                          width="15.68"
                                          height="98.28"
                                        ></rect>{" "}
                                        <rect
                                          x="246.76"
                                          y="173.776"
                                          style={{ fill: "#DB2B42" }}
                                          width="15.68"
                                          height="113.04"
                                        ></rect>{" "}
                                        <polygon
                                          style={{ fill: "#232323" }}
                                          points="317.424,186.896 341.688,221.024 293.168,221.024 "
                                        ></polygon>{" "}
                                        <rect
                                          x="309.432"
                                          y="215.056"
                                          width="16"
                                          height="58.352"
                                        ></rect>{" "}
                                      </g>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                              <div className="flex flex-col flex-1 justify-evenly items-center">
                                <div>
                                  <div className="bg-white  border shadow-lg rounded-full text-[#019ca2] p-2 h-12 w-12 flex items-center justify-center">
                                    <svg
                                      height="200px"
                                      className="h-7 w-7"
                                      width="200px"
                                      version="1.1"
                                      id="Layer_1"
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      viewBox="0 0 512 512"
                                      xmlSpace="preserve"
                                      fill="#000000"
                                    >
                                      <g
                                        id="SVGRepo_bgCarrier"
                                        stroke-width="0"
                                      ></g>
                                      <g
                                        id="SVGRepo_tracerCarrier"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></g>
                                      <g id="SVGRepo_iconCarrier">
                                        {" "}
                                        <path
                                          style={{ fill: "#EDEDED" }}
                                          d="M467.478,489.739H66.783c-24.588,0-44.522-19.934-44.522-44.522V44.522 c0-12.295,9.966-22.261,22.261-22.261h422.957c12.295,0,22.261,9.966,22.261,22.261v422.957 C489.739,479.773,479.773,489.739,467.478,489.739z"
                                        ></path>{" "}
                                        <path
                                          style={{ fill: "#777777" }}
                                          d="M509.555,487.294l-22.261,22.261c-1.631,1.629-3.768,2.445-5.904,2.445s-4.273-0.815-5.904-2.445 c-3.26-3.26-3.26-8.546,0-11.805l8.011-8.011H52.87c-16.877,0-30.609-13.731-30.609-30.609V28.501l-8.009,8.011 c-3.261,3.26-8.546,3.26-11.806,0s-3.26-8.546,0-11.805L24.706,2.445c3.261-3.26,8.546-3.26,11.806,0l22.261,22.261 c3.26,3.26,3.26,8.546,0,11.805c-1.631,1.629-3.768,2.445-5.904,2.445s-4.273-0.815-5.904-2.445l-8.009-8.011v430.63 c0,7.672,6.241,13.913,13.913,13.913h430.629l-8.011-8.011c-3.26-3.26-3.26-8.546,0-11.805c3.261-3.26,8.546-3.26,11.806,0 l22.261,22.261C512.815,478.748,512.815,484.035,509.555,487.294z"
                                        ></path>{" "}
                                        <path
                                          style={{ fill: "#FFBC00" }}
                                          d="M77.913,411.826v-256c0-12.295,9.966-22.261,22.261-22.261h3.84c4.737,0,9.35,1.51,13.168,4.313 l21.635,15.874c3.819,2.803,8.432,4.313,13.168,4.313h15.185c6.885,0,13.383-3.186,17.598-8.629L293.837,8.628 C298.054,3.186,304.552,0,311.436,0h17.291c7.077,0,13.732,3.365,17.927,9.064l101.024,137.228 c5.631,7.649,8.668,16.897,8.668,26.395v239.14c0,12.295-9.966,22.261-22.261,22.261H100.174 C87.879,434.087,77.913,424.121,77.913,411.826z"
                                        ></path>{" "}
                                        <path
                                          style={{ fill: "#FF6029" }}
                                          d="M434.087,434.087H100.174c-12.295,0-22.261-9.966-22.261-22.261V353.65 c0-5.537,2.064-10.877,5.789-14.974l98.892-108.78c4.218-4.64,10.2-7.287,16.472-7.287h20.656c5.748,0,11.273,2.224,15.419,6.204 l114.573,110.025c4.146,3.981,9.671,6.204,15.419,6.204h13.302c7.007,0,13.605-3.299,17.809-8.904l20.035-26.713 c4.204-5.605,10.802-8.904,17.809-8.904l0,0c12.295,0,22.261,9.966,22.261,22.261v89.043 C456.348,424.121,446.382,434.087,434.087,434.087z"
                                        ></path>{" "}
                                      </g>
                                    </svg>
                                  </div>
                                </div>
                                <div>
                                  <div className="bg-white  border shadow-lg rounded-full text-[#019ca2] p-2 h-12 w-12 flex items-center justify-center">
                                    <svg
                                      height="200px"
                                      className="h-7 w-7"
                                      width="200px"
                                      version="1.1"
                                      id="Layer_1"
                                      xmlns="http://www.w3.org/2000/svg"
                                      xmlnsXlink="http://www.w3.org/1999/xlink"
                                      viewBox="0 0 512 512"
                                      xmlSpace="preserve"
                                      fill="#000000"
                                    >
                                      <g
                                        id="SVGRepo_bgCarrier"
                                        stroke-width="0"
                                      ></g>
                                      <g
                                        id="SVGRepo_tracerCarrier"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      ></g>
                                      <g id="SVGRepo_iconCarrier">
                                        {" "}
                                        <g>
                                          {" "}
                                          <polygon
                                            style={{ fill: "#DB2B42" }}
                                            points="363.32,471.112 404.208,512 460.952,457.056 419.16,415.264 419.16,415.264 419.16,415.264 "
                                          ></polygon>{" "}
                                          <path
                                            style={{ fill: "#DB2B42" }}
                                            d="M347.384,422.248l22.912-22.912l-22.672-22.672c-7.72,7.632-16.136,14.56-25.168,20.656 L347.384,422.248z"
                                          ></path>{" "}
                                        </g>{" "}
                                        <g>
                                          {" "}
                                          <polygon
                                            style={{ fill: "#2D2D2D" }}
                                            points="330.92,438.712 363.32,471.112 363.32,471.112 419.16,415.264 419.16,415.264 386.76,382.872 370.296,399.336 347.384,422.248 "
                                          ></polygon>{" "}
                                          <path
                                            style={{ fill: "#2D2D2D" }}
                                            d="M139.488,122.696c8.92-5.888,18.496-10.816,28.568-14.784v-19.56 c-19.064,6.624-36.624,16.448-52.024,28.864h23.456L139.488,122.696L139.488,122.696z"
                                          ></path>{" "}
                                          <path
                                            style={{ fill: "#2D2D2D" }}
                                            d="M347.752,156.712h6.008v8.032c17.216,25.072,27.328,55.392,27.328,88.104 c0,86.096-69.792,155.896-155.896,155.896c-86.096,0-155.896-69.792-155.896-155.896c0-32.712,10.112-63.032,27.328-88.104v-29.336 c-28.304,30.968-45.576,72.184-45.576,117.448C51.048,349.032,129.016,427,225.192,427c36.024,0,69.488-10.936,97.256-29.672 c9.032-6.088,17.448-13.024,25.168-20.656c31.92-31.568,51.712-75.376,51.712-123.816c0-76.152-48.904-140.848-117-164.496v19.56 C308.288,118.152,330.792,135.112,347.752,156.712z"
                                          ></path>{" "}
                                          <path
                                            style={{ fill: "#2D2D2D" }}
                                            d="M225.2,96.96c4.824,0,9.576,0.296,14.288,0.72V79.336c-4.712-0.384-9.472-0.632-14.288-0.632 s-9.568,0.248-14.288,0.632v18.336C215.624,97.248,220.376,96.96,225.2,96.96z"
                                          ></path>{" "}
                                        </g>{" "}
                                        <path
                                          style={{ fill: "#FFFFFF" }}
                                          d="M139.488,299.56H96.632V164.744c-17.216,25.072-27.328,55.392-27.328,88.104 c0,86.096,69.792,155.896,155.896,155.896c86.096,0,155.896-69.792,155.896-155.896c0-32.712-10.112-63.032-27.328-88.104v135.232 h-42.856V156.712h36.848c-16.96-21.592-39.464-38.56-65.416-48.8V299.56h-42.856V97.68c-4.712-0.432-9.464-0.72-14.288-0.72 s-9.576,0.296-14.288,0.72v201.88h-42.856V107.912c-10.072,3.976-19.648,8.904-28.568,14.784V299.56z"
                                        ></path>{" "}
                                        <polygon
                                          style={{ fill: "#8AD5DD" }}
                                          points="96.64,299.56 139.488,299.56 139.488,122.696 139.488,117.216 116.032,117.216 96.64,117.216 96.64,135.408 96.64,164.744 "
                                        ></polygon>{" "}
                                        <polygon
                                          style={{ fill: "#DB2B42" }}
                                          points="168.064,0 168.064,88.352 168.064,107.912 168.064,299.56 210.912,299.56 210.912,97.68 210.912,79.336 210.912,0 "
                                        ></polygon>{" "}
                                        <polygon
                                          style={{ fill: "#8AD5DD" }}
                                          points="239.488,299.56 282.336,299.56 282.336,107.912 282.336,88.352 282.336,60.44 239.488,60.44 239.488,79.336 239.488,97.68 "
                                        ></polygon>{" "}
                                        <polygon
                                          style={{ fill: "#DB2B42" }}
                                          points="310.904,299.976 353.76,299.976 353.76,164.744 353.76,156.712 347.752,156.712 310.904,156.712 "
                                        ></polygon>{" "}
                                      </g>
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="mt-8">
                            <h3 class="text-lg md:text-xl font-semibold text-gray-800 dark:text-neutral-200">
                              Analytics
                            </h3>
                            <p class="mt-2 text-gray-500 dark:text-neutral-400">
                              Maximize power efficiency with Atz through
                              cutting-edge real-time analytics.
                            </p>
                            <p class="mt-2 inline-flex items-center gap-x-1 text-[#019ca2] font-medium dark:text-[#019ca2]">
                              View analytics
                              <svg
                                class="size-2.5"
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                              >
                                <path
                                  d="M5.27921 2L10.9257 7.64645C11.1209 7.84171 11.1209 8.15829 10.9257 8.35355L5.27921 14"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                />
                              </svg>
                            </p>
                          </div>
                        </div>

                        <div class="absolute top-0 end-0 z-[0] w-70 h-auto">
                          <svg
                            width="384"
                            height="268"
                            viewBox="0 0 384 268"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g filter="url(#filter0_f_6966_190390)">
                              <rect
                                x="200.667"
                                y="-57"
                                width="240.294"
                                height="124.936"
                                fill="#E2CCFF"
                                fill-opacity="0.35"
                              />
                            </g>
                            <defs>
                              <filter
                                id="filter0_f_6966_190390"
                                x="0.666687"
                                y="-257"
                                width="640.294"
                                height="524.936"
                                filterUnits="userSpaceOnUse"
                                color-interpolation-filters="sRGB"
                              >
                                <feFlood
                                  flood-opacity="0"
                                  result="BackgroundImageFix"
                                />
                                <feBlend
                                  mode="normal"
                                  in="SourceGraphic"
                                  in2="BackgroundImageFix"
                                  result="shape"
                                />
                                <feGaussianBlur
                                  stdDeviation="100"
                                  result="effect1_foregroundBlur_6966_190390"
                                />
                              </filter>
                            </defs>
                          </svg>
                        </div>
                      </a>
                      {/* <!-- End Card --> */}

                      {/* <!-- Card --> */}
                      <a
                        class="md:order-1 relative focus:outline-none p-6 flex flex-col justify-center items-center md:min-h-[230px] dark:hover:bg-neutral-900 dark:before:hover:shadow-neutral-950 text-center rounded-xl before:absolute before:inset-0 before:z-10 before:border before:border-gray-200 before:rounded-xl before:transition before:hover:border-2 before:hover:border-[#019ca2] before:focus:border-2 before:focus:border-[#019ca2] before:hover:shadow-lg dark:before:border-neutral-700 dark:before:hover:border-[#019ca2] dark:before:focus:border-[#019ca2] dark:rounded-xl"
                        href="/links"
                      >
                        <svg
                          class="shrink-0 size-10 mb-3"
                          width="46"
                          height="46"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19.7388 4.26118C17.0572 1.57961 12.7095 1.57961 10.0279 4.26118L9.30707 4.98203C9.01418 5.27492 9.01418 5.7498 9.30707 6.04269C9.59997 6.33558 10.0748 6.33558 10.3677 6.04269L11.0886 5.32184C13.1844 3.22605 16.5823 3.22605 18.6781 5.32184C20.7739 7.41763 20.7739 10.8156 18.6781 12.9114L17.9573 13.6322C17.6644 13.9251 17.6644 14.4 17.9573 14.6929C18.2502 14.9858 18.725 14.9858 19.0179 14.6929L19.7388 13.972C22.4203 11.2905 22.4203 6.94276 19.7388 4.26118Z"
                            fill="#019ca2"
                          />
                          <path
                            d="M6.04269 9.30707C6.33558 9.59997 6.33558 10.0748 6.04269 10.3677L5.32184 11.0886C3.22605 13.1844 3.22605 16.5823 5.32184 18.6781C7.41763 20.7739 10.8156 20.7739 12.9114 18.6781L13.6322 17.9573C13.9251 17.6644 14.4 17.6644 14.6929 17.9573C14.9858 18.2501 14.9858 18.725 14.6929 19.0179L13.972 19.7388C11.2905 22.4203 6.94276 22.4203 4.26118 19.7388C1.57961 17.0572 1.57961 12.7095 4.26118 10.0279L4.98203 9.30707C5.27492 9.01418 5.7498 9.01418 6.04269 9.30707Z"
                            fill="#019ca2"
                          />
                          <path
                            d="M14.6928 9.30707C14.9857 9.59997 14.9857 10.0748 14.6928 10.3677L10.3677 14.6928C10.0748 14.9857 9.59997 14.9857 9.30707 14.6928C9.01418 14.3999 9.01418 13.9251 9.30707 13.6322L13.6322 9.30707C13.9251 9.01418 14.3999 9.01418 14.6928 9.30707Z"
                            fill="#019ca2"
                          />
                        </svg>
                        {/* <svg
                      class="shrink-0 size-10 mb-3"
                      width="46"
                      height="46"
                      viewBox="0 0 46 46"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="14.3592"
                        y="33.9731"
                        width="12.2615"
                        height="12.2615"
                        rx="3"
                        transform="rotate(-45 14.3592 33.9731)"
                        stroke="#2563EB"
                        stroke-width="2"
                      />
                      <rect
                        x="26.2454"
                        y="23.0282"
                        width="12.2615"
                        height="12.2615"
                        rx="3"
                        transform="rotate(-45 26.2454 23.0282)"
                        stroke="#2563EB"
                        stroke-width="2"
                      />
                      <rect
                        x="2.47311"
                        y="23.0282"
                        width="12.2615"
                        height="12.2615"
                        rx="3"
                        transform="rotate(-45 2.47311 23.0282)"
                        stroke="#2563EB"
                        stroke-width="2"
                      />
                      <rect
                        x="14.3592"
                        y="12.0564"
                        width="12.2615"
                        height="12.2615"
                        rx="3"
                        transform="rotate(-45 14.3592 12.0564)"
                        stroke="#C084FC"
                        stroke-width="2"
                      />
                    </svg> */}
                        <h3 class="text-lg md:text-xl font-semibold text-gray-800 dark:text-neutral-200">
                          Links
                        </h3>
                        <p class="mt-2 text-gray-500 dark:text-neutral-400">
                          Create, manage, and monitor shortened links for
                          streamlined sharing and tracking.
                        </p>
                        <p class="mt-2 inline-flex items-center gap-x-1 text-[#019ca2] font-medium dark:text-[#019ca2]">
                          Get started
                          <svg
                            class="shrink-0 size-4"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path d="m9 18 6-6-6-6" />
                          </svg>
                        </p>
                      </a>
                      {/* <!-- End Card --> */}
                    </div>
                    {/* <!-- End Col --> */}

                    <div class="col-span-12 lg:col-span-4 md:order-3 grid md:grid-cols-2 lg:grid-cols-1 gap-4 xl:gap-6">
                      {/* <!-- Card --> */}
                      <a
                        class="md:order-2 relative focus:outline-none before:absolute before:inset-0 before:z-10 before:border dark:hover:bg-neutral-900 dark:before:hover:shadow-neutral-950 before:border-gray-200 before:rounded-xl before:transition before:hover:border-2 before:hover:border-[#019ca2] before:focus:border-2 before:focus:border-[#019ca2] before:hover:shadow-lg after:absolute after:inset-x-0.5 after:bottom-0.5 after:z-10 after:w-[calc(100%-4px)] after:h-24 after:rounded-b-xl after:bg-gradient-to-t after:from-white after:via-white/90 after:to-white/40 dark:after:from-neutral-900 dark:after:via-neutral-900/90 dark:after:to-neutral-900/40 dark:before:border-neutral-700 dark:before:hover:border-[#019ca2] dark:before:focus:border-[#019ca2] dark:rounded-xl"
                        href="/gallery"
                      >
                        <div class="relative overflow-hidden size-full rounded-xl">
                          <div class="flex flex-col justify-between md:min-h-[480px] text-center rounded-xl dark:border-neutral-700">
                            <div class="p-6">
                              <svg
                                class="shrink-0 size-10 mb-3 mx-auto"
                                width="46"
                                height="46"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <circle
                                  cx="16"
                                  cy="8"
                                  r="2"
                                  stroke="#019ca2"
                                  stroke-width="1.5"
                                />
                                <path
                                  d="M2 12.5001L3.75159 10.9675C4.66286 10.1702 6.03628 10.2159 6.89249 11.0721L11.1822 15.3618C11.8694 16.0491 12.9512 16.1428 13.7464 15.5839L14.0446 15.3744C15.1888 14.5702 16.7369 14.6634 17.7765 15.599L21 18.5001"
                                  stroke="#019ca2"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                />
                                <path
                                  d="M22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C21.5093 4.43821 21.8356 5.80655 21.9449 8"
                                  stroke="#019ca2"
                                  stroke-width="1.5"
                                  stroke-linecap="round"
                                />
                              </svg>
                              <h3 class="text-lg md:text-xl font-semibold text-gray-800 dark:text-neutral-200">
                                Gallery
                              </h3>
                              <p class="mt-2 text-gray-500 dark:text-neutral-400">
                                A link-in-bio that showcases multiple links on
                                one page for easy redirection.
                              </p>
                              <p class="mt-2 inline-flex items-center gap-x-1 text-[#019ca2] font-medium dark:text-[#019ca2]">
                                View gallery
                                <svg
                                  class="shrink-0 size-4"
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                >
                                  <path d="m9 18 6-6-6-6" />
                                </svg>
                              </p>
                            </div>

                            <img
                              class="w-10/12 ms-auto dark:hidden"
                              src="https://preline.co/assets/others/templates.webp"
                              alt="Templates"
                            />
                            <img
                              class="w-10/12 ms-auto hidden dark:block"
                              src="https://preline.co/assets/others-dark/templates.webp"
                              alt="Templates"
                            />
                          </div>

                          <div class="absolute top-1/2 -start-1/2 z-[0] w-60 h-32 bg-purple-200 blur-[100px] -translate-y-1/2 dark:bg-violet-900/30"></div>
                        </div>
                      </a>
                      {/* <!-- End Card --> */}

                      {/* <!-- Card --> */}
                      <a
                        class="md:order-1 relative focus:outline-none p-6 flex flex-col justify-center items-center md:min-h-[230px] dark:hover:bg-neutral-900 dark:before:hover:shadow-neutral-950 text-center rounded-xl before:absolute before:inset-0 before:z-10 before:border before:border-gray-200 before:rounded-xl before:transition before:hover:border-2 before:hover:border-[#019ca2] before:focus:border-2 before:focus:border-[#019ca2] before:hover:shadow-lg dark:before:border-neutral-700 dark:before:hover:border-[#019ca2] dark:before:focus:border-[#019ca2] dark:rounded-xl"
                        href="/domains"
                      >
                        <svg
                          class="size-8 mb-4"
                          width="33"
                          height="32"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill="#019ca2"
                            d="M13.903 8c.06.629.097 1.292.097 2a1 1 0 01-2 0c0-.713-.039-1.378-.105-2H8.092c-.139 1.33-.14 2.67-.004 4H10a1 1 0 010 2H8.416c.469 2.08 1.202 3.41 1.585 4 0 0 .789.013.926.002a1.008 1.008 0 011.07.925 1 1 0 01-.924 1.07C10.979 20.004 10 20 10 20 4.486 20 0 15.514 0 10 0 4.485 4.486 0 10 0s10 4.485 10 10c0 .379-.036.749-.079 1.116a1.001 1.001 0 01-1.987-.233c.035-.291.066-.583.066-.883 0-.692-.098-1.36-.263-2h-3.834zM9.988 2.036c-.386.606-1.1 1.931-1.564 3.964h3.137c-.466-2.033-1.185-3.358-1.573-3.964zM2.263 12h3.819a21.604 21.604 0 01-.095-1.996c0-.67.033-1.338.096-2.004h-3.82A7.946 7.946 0 002 10a7.96 7.96 0 00.263 2zm.819 2a8.031 8.031 0 004.437 3.601A16.293 16.293 0 016.371 14H3.082zm3.291-8a16.235 16.235 0 011.15-3.603A8.03 8.03 0 003.082 6h3.291zm7.239 0h3.305a8.029 8.029 0 00-4.465-3.612A16.128 16.128 0 0113.612 6z"
                          />
                          <path
                            fill="#019ca2"
                            d="M15.223 18.945l-2.55-5.61a.5.5 0 01.662-.662l5.61 2.55a.5.5 0 01.017.903l-1.742.87a.5.5 0 00-.223.224l-.871 1.742a.5.5 0 01-.903-.017z"
                          />
                        </svg>
                        <h3 class="text-lg md:text-xl font-semibold text-gray-800 dark:text-neutral-200">
                          Branded Domains
                        </h3>
                        <p class="mt-2 text-gray-500 dark:text-neutral-400">
                          Customize links with your brand’s URL for a seamless
                          and professional online presence.
                        </p>
                        <p class="mt-2 inline-flex items-center gap-x-1 text-[#019ca2] font-medium dark:text-[#019ca2]">
                          Learn more
                          <svg
                            class="shrink-0 size-4"
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          >
                            <path d="m9 18 6-6-6-6" />
                          </svg>
                        </p>
                      </a>
                      {/* <!-- End Card --> */}
                    </div>
                    {/* <!-- End Col --> */}
                  </div>
                  {/* <!-- End Grid --> */}
                </div>
                {/* <!-- End Features --> */}
              </div>

              <div class="flex flex-1 gap-x-5">
                {/* <!-- Card --> */}
                <div class="flex flex-1 py-10 justify-center items-center px-5 relative overflow-hidden text-center bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700">
                  <div class="relative z-10">
                    <h3 class=" gap-x-1 inline-flex items-center dark:text-neutral-50  text-xl font-bold">
                      Invite friends
                    </h3>
                    <p class="mt-1 text-gray-500 dark:text-neutral-500">
                      Invite friends to use link shortener & insights on the{" "}
                      <strong>Atz </strong>
                      platform.
                    </p>
                    <div class="mt-3 sm:mt-5">
                      <button
                        type="button"
                        class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                        data-hs-overlay="#hs-pro-dshm"
                      >
                        Send an invite
                      </button>
                    </div>
                  </div>

                  <div class="absolute top-5 start-10">
                    <img
                      class="shrink-0 size-10 rounded-full"
                      src={require("../../assets/avatars/1.avif")}
                      alt="Avatar"
                    />
                  </div>

                  <div class="hidden sm:block absolute top-20 start-20">
                    <img
                      class="shrink-0 size-14 rounded-full"
                      src={require("../../assets/avatars/2.avif")}
                      alt="Avatar"
                    />
                  </div>

                  <div class="absolute bottom-5 start-40">
                    <img
                      class="shrink-0 size-8 rounded-full"
                      src={require("../../assets/avatars/3.avif")}
                      alt="Avatar"
                    />
                  </div>

                  <div class="absolute bottom-3 start-8">
                    <img
                      class="shrink-0 size-8 rounded-full"
                      src={require("../../assets/avatars/4.avif")}
                      alt="Avatar"
                    />
                  </div>

                  <div class="absolute -top-5 end-40">
                    <img
                      class="shrink-0 size-14 rounded-full"
                      src={require("../../assets/avatars/5.avif")}
                      alt="Avatar"
                    />
                  </div>

                  <div class="absolute top-7 end-14">
                    <img
                      class="shrink-0 size-8 rounded-full"
                      src={require("../../assets/avatars/6.avif")}
                      alt="Avatar"
                    />
                  </div>

                  <div class="hidden sm:block absolute top-20 end-20">
                    <img
                      class="shrink-0 size-14 rounded-full"
                      src={require("../../assets/avatars/7.avif")}
                      alt="Avatar"
                    />
                  </div>

                  <div class="hidden sm:block absolute bottom-5 end-40">
                    <img
                      class="shrink-0 size-8 rounded-full"
                      src={require("../../assets/avatars/8.avif")}
                      alt="Avatar"
                    />
                  </div>

                  <div class="absolute bottom-3 end-8">
                    <img
                      class="shrink-0 size-8 rounded-full"
                      src={require("../../assets/avatars/9.avif")}
                      alt="Avatar"
                    />
                  </div>
                </div>
                {/* <!-- End Card --> */}
              </div>
            </div>
          </main>
        </>
      )}
    </div>
  );
};

export default MainBody;
