import moment from "moment";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import axios from "axios";
import "preline/preline";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_ATZ_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_ATZ_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_ATZ_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_ATZ_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_ATZ_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ATZ_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_ATZ_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const QrCard = ({ qrData, index }) => {
  const location = useLocation();
  const [click, setClick] = useState(0);
  const {
    title,
    destinationUrl,
    shortenUrl,
    clickCount,
    active,
    starred,
    createdOn,
    id,
    qrCodeUrl,
    linkId,
  } = qrData;
  // const date = new Date(createdOn);
  const [showMore, setShowMore] = useState(false);

  var name = `${shortenUrl.slice(8, shortenUrl.length).replace("/", "_")}.png`;

  const [copy, setCopy] = useState("Copy");

  useEffect(() => {
    // Reinitialize Preline components every time the location changes
    if (
      window.HSStaticMethods &&
      typeof window.HSStaticMethods.autoInit === "function"
    ) {
      window.HSStaticMethods.autoInit();
    }
  }, [location.pathname, index, click, active, qrData]);

  const copyContent = async (e) => {
    try {
      setClick(click + 1);
      await navigator.clipboard.writeText(shortenUrl);
      setCopy("Copied!");
      setTimeout(() => {
        setCopy("Copy");
      }, 2000);
      console.log("Content copied to clipboard");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const handleDelete = async () => {
    const tokenData = localStorage.getItem("token");
    if (!tokenData) {
      console.log("Token not found in localStorage");
      return;
    }

    let access_token;
    try {
      const data = JSON.parse(tokenData);
      access_token = data.access_token;
      if (!access_token) {
        console.log("Access token is missing");
        return;
      }
    } catch (e) {
      console.log("Failed to parse token data");
      return;
    }

    const requestData = {
      data: {
        qrCodeId: qrData?.id,
      },
    };

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_GATEWAY_URL}/atz/qr-codes/delete-qr-code`,
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
      data: requestData,
    };

    try {
      const response = await axios(config);

      if (response.status === 200) {
        window.location.reload();
      }
    } catch (error) {
      console.log("Error response:", error.response);
      console.log("Error message:", error.message);
    }
  };

  // const date = createdOn.split("T").join(" ");
  // const utcDate = moment(date[0]);
  const local = moment(createdOn.split("T").join(" "))
    .local()
    .format("YYYY-MMM-DD");
  const daate = local.split("-");
  // const handleFavorite = () => {
  //   setFavorite(!favorite);
  // };
  const handleShowMore = () => {
    setShowMore(!showMore);
  };

  const handleLinkClick = (cardId) => {
    logEvent(analytics, `CTA_QR_CODES_ANALYTICS_CLICK`);
    window.location.href = `/links-details?id=${cardId}`;
  };
  const handleLinkClickDetails = (cardId) => {
    localStorage.setItem("activeMode", "details");
    window.location.href = `/links-details?id=${cardId}`;
  };
  const handleLinkClickEdit = (cardId) => {
    window.location.href = `/qr-code/edit/code?id=${cardId}`;
  };
  const handleDownload = async () => {
    try {
      const response = await fetch(`${qrData?.qrCodeUrl}`);

      // Convert the response to a Blob
      const blob = await response.blob();

      // Create an object URL from the Blob

      console.log(name);
      const url = window.URL.createObjectURL(blob);

      // Create a link and trigger download
      var link = document.createElement("a");
      link.href = url;
      link.download = name; // Add the correct file extension
      link.click();
      logEvent(analytics, `CTA_QR_CODES_DOWNLOAD_SUCCESS`);

      // Clean up the object URL after download
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading the image:", error);
    }
  };
  return (
    <>
      <div
        id={`hs-vertically-centered-modal-${id}`}
        class="hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none"
        role="dialog"
        tabindex="-1"
        aria-labelledby={`hs-vertically-centered-modal-${id}-label`}
      >
        <div class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto min-h-[calc(100%-3.5rem)] flex items-center">
          <div class="w-full flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
            <div class="flex justify-between items-center py-3 px-4 border-b dark:border-neutral-700">
              <h3
                id="hs-vertically-centered-modal-label"
                class="font-bold text-gray-800 dark:text-white text-base"
              >
                Delete QR Code
              </h3>
              <button
                type="button"
                class="size-8 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700 dark:hover:bg-neutral-600 dark:text-neutral-400 dark:focus:bg-neutral-600"
                aria-label="Close"
                data-hs-overlay={`#hs-vertically-centered-modal-${id}`}
              >
                <span class="sr-only">Close</span>
                <svg
                  class="shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M18 6 6 18"></path>
                  <path d="m6 6 12 12"></path>
                </svg>
              </button>
            </div>
            <div class="p-4 overflow-y-auto">
              <div className="flex flex-col flex-1 justify-center items-center gap-y-2 dark:text-neutral-100">
                <div>
                  <svg
                    viewBox="0 0 16 16"
                    fill="none"
                    className="size-10"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        d="M7.493 0.015 C 7.442 0.021,7.268 0.039,7.107 0.055 C 5.234 0.242,3.347 1.208,2.071 2.634 C 0.660 4.211,-0.057 6.168,0.009 8.253 C 0.124 11.854,2.599 14.903,6.110 15.771 C 8.169 16.280,10.433 15.917,12.227 14.791 C 14.017 13.666,15.270 11.933,15.771 9.887 C 15.943 9.186,15.983 8.829,15.983 8.000 C 15.983 7.171,15.943 6.814,15.771 6.113 C 14.979 2.878,12.315 0.498,9.000 0.064 C 8.716 0.027,7.683 -0.006,7.493 0.015 M8.853 1.563 C 9.967 1.707,11.010 2.136,11.944 2.834 C 12.273 3.080,12.920 3.727,13.166 4.056 C 13.727 4.807,14.142 5.690,14.330 6.535 C 14.544 7.500,14.544 8.500,14.330 9.465 C 13.916 11.326,12.605 12.978,10.867 13.828 C 10.239 14.135,9.591 14.336,8.880 14.444 C 8.456 14.509,7.544 14.509,7.120 14.444 C 5.172 14.148,3.528 13.085,2.493 11.451 C 2.279 11.114,1.999 10.526,1.859 10.119 C 1.618 9.422,1.514 8.781,1.514 8.000 C 1.514 6.961,1.715 6.075,2.160 5.160 C 2.500 4.462,2.846 3.980,3.413 3.413 C 3.980 2.846,4.462 2.500,5.160 2.160 C 6.313 1.599,7.567 1.397,8.853 1.563 M7.706 4.290 C 7.482 4.363,7.355 4.491,7.293 4.705 C 7.257 4.827,7.253 5.106,7.259 6.816 C 7.267 8.786,7.267 8.787,7.325 8.896 C 7.398 9.033,7.538 9.157,7.671 9.204 C 7.803 9.250,8.197 9.250,8.329 9.204 C 8.462 9.157,8.602 9.033,8.675 8.896 C 8.733 8.787,8.733 8.786,8.741 6.816 C 8.749 4.664,8.749 4.662,8.596 4.481 C 8.472 4.333,8.339 4.284,8.040 4.276 C 7.893 4.272,7.743 4.278,7.706 4.290 M7.786 10.530 C 7.597 10.592,7.410 10.753,7.319 10.932 C 7.249 11.072,7.237 11.325,7.294 11.495 C 7.388 11.780,7.697 12.000,8.000 12.000 C 8.303 12.000,8.612 11.780,8.706 11.495 C 8.763 11.325,8.751 11.072,8.681 10.932 C 8.616 10.804,8.460 10.646,8.333 10.580 C 8.217 10.520,7.904 10.491,7.786 10.530 "
                        stroke="none"
                        fill-rule="evenodd"
                        fill="#ff0000"
                      ></path>
                    </g>
                  </svg>
                </div>
                <div className="text-sm font-semibold -mb-1 text-center">
                  Are you sure you want to delete this QR code?
                </div>
                <div className="text-xs flex  text-center px-5">
                  <span>
                    Deleting this QR code will also delete the link associated
                    and will redirect you to Atz error page!
                  </span>
                </div>
              </div>
            </div>
            <div class="flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-neutral-700">
              <button
                type="button"
                class="py-1.5 px-3 inline-flex items-center gap-x-2 text-xs font-medium rounded-lg border border-red-400 hover:bg-red-600 text-red-600 hover:text-white dark:hover:bg-red-700 focus:outline-none focus:bg-red-700 disabled:opacity-50 disabled:pointer-events-none"
                onClick={handleDelete}
                data-hs-overlay={`#hs-vertically-centered-modal-${id}`}
              >
                Delete
              </button>
              <button
                type="button"
                class="py-1.5 px-3 inline-flex items-center gap-x-2 text-xs font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                data-hs-overlay={`#hs-vertically-centered-modal-${id}`}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <div
        id={`hs-vertically-centered-modal-active-${id}`}
        class="hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none"
        role="dialog"
        tabindex="-1"
        aria-labelledby={`hs-vertically-centered-modal-active-${id}-label`}
      >
        <div class="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto min-h-[calc(100%-3.5rem)] flex items-center">
          <div class="w-full flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
            <div class="flex justify-between items-center py-3 px-4 border-b dark:border-neutral-700">
              <h3
                id="hs-vertically-centered-modal-label"
                class="font-bold text-gray-800 dark:text-white text-base"
              >
                Activate link
              </h3>
              <button
                type="button"
                class="size-8 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700 dark:hover:bg-neutral-600 dark:text-neutral-400 dark:focus:bg-neutral-600"
                aria-label="Close"
                data-hs-overlay={`#hs-vertically-centered-modal-active-${id}`}
              >
                <span class="sr-only">Close</span>
                <svg
                  class="shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M18 6 6 18"></path>
                  <path d="m6 6 12 12"></path>
                </svg>
              </button>
            </div>
            <div class="p-4 overflow-y-auto">
              <div className="flex flex-col flex-1 justify-center items-center gap-y-2 dark:text-neutral-100">
                <div>
                  <svg
                    viewBox="0 0 16 16"
                    fill="none"
                    className="size-10"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <path
                        d="M7.493 0.015 C 7.442 0.021,7.268 0.039,7.107 0.055 C 5.234 0.242,3.347 1.208,2.071 2.634 C 0.660 4.211,-0.057 6.168,0.009 8.253 C 0.124 11.854,2.599 14.903,6.110 15.771 C 8.169 16.280,10.433 15.917,12.227 14.791 C 14.017 13.666,15.270 11.933,15.771 9.887 C 15.943 9.186,15.983 8.829,15.983 8.000 C 15.983 7.171,15.943 6.814,15.771 6.113 C 14.979 2.878,12.315 0.498,9.000 0.064 C 8.716 0.027,7.683 -0.006,7.493 0.015 M8.853 1.563 C 9.967 1.707,11.010 2.136,11.944 2.834 C 12.273 3.080,12.920 3.727,13.166 4.056 C 13.727 4.807,14.142 5.690,14.330 6.535 C 14.544 7.500,14.544 8.500,14.330 9.465 C 13.916 11.326,12.605 12.978,10.867 13.828 C 10.239 14.135,9.591 14.336,8.880 14.444 C 8.456 14.509,7.544 14.509,7.120 14.444 C 5.172 14.148,3.528 13.085,2.493 11.451 C 2.279 11.114,1.999 10.526,1.859 10.119 C 1.618 9.422,1.514 8.781,1.514 8.000 C 1.514 6.961,1.715 6.075,2.160 5.160 C 2.500 4.462,2.846 3.980,3.413 3.413 C 3.980 2.846,4.462 2.500,5.160 2.160 C 6.313 1.599,7.567 1.397,8.853 1.563 M7.706 4.290 C 7.482 4.363,7.355 4.491,7.293 4.705 C 7.257 4.827,7.253 5.106,7.259 6.816 C 7.267 8.786,7.267 8.787,7.325 8.896 C 7.398 9.033,7.538 9.157,7.671 9.204 C 7.803 9.250,8.197 9.250,8.329 9.204 C 8.462 9.157,8.602 9.033,8.675 8.896 C 8.733 8.787,8.733 8.786,8.741 6.816 C 8.749 4.664,8.749 4.662,8.596 4.481 C 8.472 4.333,8.339 4.284,8.040 4.276 C 7.893 4.272,7.743 4.278,7.706 4.290 M7.786 10.530 C 7.597 10.592,7.410 10.753,7.319 10.932 C 7.249 11.072,7.237 11.325,7.294 11.495 C 7.388 11.780,7.697 12.000,8.000 12.000 C 8.303 12.000,8.612 11.780,8.706 11.495 C 8.763 11.325,8.751 11.072,8.681 10.932 C 8.616 10.804,8.460 10.646,8.333 10.580 C 8.217 10.520,7.904 10.491,7.786 10.530 "
                        stroke="none"
                        fill-rule="evenodd"
                        fill="#16a34a"
                      ></path>
                    </g>
                  </svg>
                </div>
                <div className="text-sm font-semibold -mb-1 text-center">
                  Are you sure you want to activate this link?
                </div>
              </div>
            </div>
            <div class="flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-neutral-700">
              <button
                type="button"
                class="py-1.5 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-green-600 text-white hover:bg-green-700 focus:outline-none focus:bg-green-700 disabled:opacity-50 disabled:pointer-events-none"
                // onClick={() => {
                //   handleActive();
                // }}
                data-hs-overlay={`#hs-vertically-centered-modal-active-${id}`}
              >
                Activate
              </button>
              <button
                type="button"
                class="py-1.5 px-3 inline-flex items-center gap-x-2 text-xs font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 dark:border-neutral-700 dark:text-white dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                data-hs-overlay={`#hs-vertically-centered-modal-active-${id}`}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div> */}

      <div className="flex-col sm:flex-row flex bg-white dark:bg-neutral-800 border border-gray-200 dark:border-neutral-700 p-3 rounded-lg gap-x-4 py-5">
        <div className="flex flex-none justify-center items-center">
          <img
            class="hidden sm:block  flex-shrink-0 size-28 mx-auto p-2 rounded-md border border-gray-300 dark:border-neutral-700 "
            src={qrCodeUrl}
            alt="qr-code"
            loading=""
          />
        </div>
        <div className="flex sm:flex sm:flex-1 sm:truncate">
          <div className="flex flex-1 pr-5 sm:pr-0 truncate">
            <div className="flex flex-col truncate ">
              <div className="inline-flex mb-1">
                <div
                  className="truncate dark:text-white  text-lg font-semibold hover:underline cursor-pointer"
                  onClick={() => handleLinkClick(linkId)}
                >
                  {title}
                </div>
              </div>
              <div className="inline-flex gap-x-3">
                <div className="flex flex-none">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 3H7C4.79086 3 3 4.79086 3 7V17C3 19.2091 4.79086 21 7 21H17C19.2091 21 21 19.2091 21 17V13"
                      stroke="#019ca2"
                      stroke-width="2.5"
                      stroke-linecap="round"
                    />
                    <path
                      d="M12 12L21 3"
                      stroke="#019ca2"
                      stroke-width="2.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M21 9V3H15"
                      stroke="#019ca2"
                      stroke-width="2.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <a
                  className="truncate text-sm text-[#019ca2] hover:underline"
                  href={shortenUrl}
                  target="_blank"
                >
                  {shortenUrl}
                </a>
              </div>
              <div className="inline-flex gap-x-2">
                <div className="flex flex-none -ml-0.5">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14 7H16C18.7614 7 21 9.23858 21 12C21 14.7614 18.7614 17 16 17H14M10 7H8C5.23858 7 3 9.23858 3 12C3 14.7614 5.23858 17 8 17H10M8 12H16"
                      stroke="#808080"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <a
                  className="truncate dark:text-white text-sm hover:underline"
                  href={destinationUrl}
                  target="_blank"
                >
                  {destinationUrl}{" "}
                </a>
              </div>

              <div className="mt-1">
                {!active && (
                  <span class="inline-flex items-center gap-x-1.5 py-1 px-2.5 text-xs font-medium bg-red-200 text-red-600 rounded-full dark:bg-red-950 dark:text-red-400">
                    <span class="size-1.5 inline-block bg-red-600 rounded-full dark:bg-red-400"></span>
                    Inactive
                  </span>
                )}
                {active && (
                  <span class="inline-flex items-center gap-x-1.5 py-1 px-2.5 text-xs font-medium bg-green-200 text-green-700 rounded-full dark:bg-green-950 dark:text-green-400">
                    <span class="size-1.5 inline-block bg-green-600 rounded-full dark:bg-green-400"></span>
                    Active
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-none justify-center items-center sm:hidden">
            <img
              class="flex-shrink-0 size-28 mx-auto p-2 border border-gray-200 dark:border-neutral-700 rounded-lg"
              src={qrCodeUrl}
              alt="Image Description"
            />
          </div>
        </div>
        <div className="flex flex-none">
          <div class="mt-2 sm:mt-0 flex flex-col flex-1 gap-y-4 ">
            <div class="flex sm:justify-end justify-between items-center ">
              <div class="inline-flex items-center sm:block gap-x-2 whitespace-nowrap">
                <p class="text-xs sm:text-sm text-gray-500 dark:text-neutral-500">
                  {clickCount} clicks
                </p>
                <button
                  id="hs-pro-dptied"
                  type="button"
                  class="py-2 px-2.5 inline-flex items-center gap-x-1.5 text-[9px] sm:text-xs rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 sm:hidden"
                >
                  <svg
                    width="17"
                    height="17"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3 9H21M7 3V5M17 3V5M6 12H8M11 12H13M16 12H18M6 15H8M11 15H13M16 15H18M6 18H8M11 18H13M16 18H18M6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V8.2C21 7.07989 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21Z"
                      stroke="#808080"
                      stroke-width="2"
                      stroke-linecap="round"
                    />
                  </svg>
                  <span className="">
                    {daate[2] + " " + daate[1] + " " + daate[0]}
                  </span>
                </button>
              </div>
              <div className="flex justify-center items-center">
                <label class="relative py-1.5 px-3 w-auto block text-center sm:text-start rounded-lg cursor-pointer text-sm font-medium focus:outline-none">
                  <button
                    type="button"
                    class=" flex justify-center items-center relative bg-gray-300 py-1.5 px-4 rounded-md text-white hover:bg-neutral-600 dark:bg-gray-600 dark:hover:bg-neutral-400"
                    onClick={copyContent}
                  >
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M21 8C21 6.34315 19.6569 5 18 5H10C8.34315 5 7 6.34315 7 8V20C7 21.6569 8.34315 23 10 23H18C19.6569 23 21 21.6569 21 20V8ZM19 8C19 7.44772 18.5523 7 18 7H10C9.44772 7 9 7.44772 9 8V20C9 20.5523 9.44772 21 10 21H18C18.5523 21 19 20.5523 19 20V8Z"
                        fill="currentColor"
                      />
                      <path
                        d="M6 3H16C16.5523 3 17 2.55228 17 2C17 1.44772 16.5523 1 16 1H6C4.34315 1 3 2.34315 3 4V18C3 18.5523 3.44772 19 4 19C4.55228 19 5 18.5523 5 18V4C5 3.44772 5.44772 3 6 3Z"
                        fill="currentColor"
                      />
                    </svg>
                    {"\u00A0"}
                    <span className="hidden sm:block">{copy}</span>
                  </button>
                </label>

                {/* <!-- More Dropdown --> */}
              </div>
              <div class="absolute top-0 end-0 sm:static" id={`${qrData.id}`}>
                <div class="hs-dropdown [--placement:bottom-right] relative inline-flex">
                  <button
                    id="hs-pro-dupc1"
                    type="button"
                    class="size-7 inline-flex justify-center items-center gap-x-2 rounded-lg border border-transparent text-gray-500 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100 dark:text-neutral-400 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                  >
                    <svg
                      class="flex-shrink-0 size-4"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <circle cx="12" cy="12" r="1" />
                      <circle cx="12" cy="5" r="1" />
                      <circle cx="12" cy="19" r="1" />
                    </svg>
                  </button>

                  {/* <!-- Dropdown --> */}
                  <div
                    class="hs-dropdown-menu hs-dropdown-open:opacity-100 w-40 transition-[opacity,margin] duration opacity-0 hidden z-20 bg-white rounded-xl shadow-[0_10px_40px_10px_rgba(0,0,0,0.08)] dark:shadow-[0_10px_40px_10px_rgba(0,0,0,0.2)] dark:bg-neutral-900"
                    aria-labelledby="hs-pro-dupc1"
                  >
                    <div class="p-1">
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2 rounded-lg text-[13px] text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                        onClick={() => handleLinkClickEdit(id)}
                      >
                        <svg
                          width="21"
                          height="21"
                          className="size-5"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M8.56078 20.2501L20.5608 8.25011L15.7501 3.43945L3.75012 15.4395V20.2501H8.56078ZM15.7501 5.56077L18.4395 8.25011L16.5001 10.1895L13.8108 7.50013L15.7501 5.56077ZM12.7501 8.56079L15.4395 11.2501L7.93946 18.7501H5.25012L5.25012 16.0608L12.7501 8.56079Z"
                            fill="currentColor"
                          />
                        </svg>
                        Edit QR code
                      </button>
                      <button
                        type="button"
                        class="w-full flex items-center gap-x-3 py-1.5 px-2.5 rounded-lg text-[13px] text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-100 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                        onClick={() => handleLinkClickDetails(linkId)}
                      >
                        <svg
                          width="17"
                          height="17"
                          className="size-5"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            stroke-width="1.5"
                          />
                          <path
                            d="M12 17V11"
                            stroke="currentColor"
                            stroke-width="1.5"
                            stroke-linecap="round"
                          />
                          <circle
                            cx="1"
                            cy="1"
                            r="1"
                            transform="matrix(1 0 0 -1 11 9)"
                            fill="currentColor"
                          />
                        </svg>
                        QR code details
                      </button>
                      {active && (
                        <button
                          // onClick={() => setOpenModal(true)}
                          type="button"
                          class="w-full flex items-center gap-x-3 py-1.5 px-1.5 text-[13px] border-t text-red-500 hover:bg-gray-100 focus:outline-none dark:border-gray-800 focus:bg-gray-100 dark:text-red-500 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800 disabled:opacity-50 disabled:pointer-events-none"
                          aria-haspopup="dialog"
                          aria-expanded="false"
                          aria-controls={`hs-vertically-centered-modal-${id}`}
                          data-hs-overlay={`#hs-vertically-centered-modal-${id}`}
                        >
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10 12V17"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M14 12V17"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M4 7H20"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M6 10V18C6 19.6569 7.34315 21 9 21H15C16.6569 21 18 19.6569 18 18V10"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M9 5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V7H9V5Z"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                          Delete QR code
                        </button>
                      )}
                      {!active && (
                        <button
                          type="button"
                          // onClick={() => setOpenModalActive(true)}
                          class="w-full flex items-center pl-2.5 gap-x-3 py-1.5 px-1.5 text-[13px] border-t text-green-500 hover:bg-gray-100 focus:outline-none dark:border-gray-800 focus:bg-gray-100 dark:text-green-500 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800 disabled:opacity-50 disabled:pointer-events-none"
                          aria-haspopup="dialog"
                          aria-expanded="false"
                          aria-controls={`hs-vertically-centered-modal-active-${id}`}
                          data-hs-overlay={`#hs-vertically-centered-modal-active-${id}`}
                          // aria-haspopup="dialog"
                          // aria-expanded="false"
                          // aria-controls="hs-basic-modal"
                          // data-hs-overlay="#hs-basic-modal"
                          // data-hs-overlay="#hs-slide-down-animation-modal"
                        >
                          <svg
                            fill="#22C55E"
                            height="17"
                            width="17"
                            version="1.1"
                            xmlnsSketch="http://www.bohemiancoding.com/sketch/ns"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 24 24"
                            xmlSpace="preserve"
                          >
                            <g id="active">
                              <path d="M8.6,20.1l-7.8-8l1.4-1.4l6.4,6.5L21.8,3.9l1.4,1.4L8.6,20.1z" />
                            </g>
                          </svg>
                          Activate link
                        </button>
                      )}
                    </div>
                  </div>
                  {/* <!-- End Dropdown --> */}
                </div>
                {/* <!-- End More Dropdown --> */}
              </div>
            </div>

            <div className="flex flex-1 sm:justify-end">
              <div className="relative inline-flex gap-x-2 mr-1 flex-1">
                <div className="flex flex-1 justify-between space-x-2 h-9">
                  <div
                    className="flex flex-col justify-center items-center p-2 border border-gray-200 rounded-lg dark:border-neutral-700 cursor-pointer hs-tooltip hs-tooltip-toggle"
                    onClick={() =>
                      (window.location.href = `/qr-code/customize/code?id=${id}`)
                    }
                  >
                    {
                      <svg
                        viewBox="0 0 512 512"
                        xmlns="http://www.w3.org/2000/svg"
                        className="size-5"
                        fill="#808080"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          <circle
                            cx="256"
                            cy="184"
                            r="120"
                            style={{
                              fill: "none",
                              stroke: "#808080",
                              strokeLinejoin: "round",
                              strokeWidth: "32px",
                            }}
                          ></circle>
                          <circle
                            cx="344"
                            cy="328"
                            r="120"
                            style={{
                              fill: "none",
                              stroke: "#808080",
                              strokeLinejoin: "round",
                              strokeWidth: "32px",
                            }}
                          ></circle>
                          <circle
                            cx="168"
                            cy="328"
                            r="120"
                            style={{
                              fill: "none",
                              stroke: "#808080",
                              strokeLinejoin: "round",
                              strokeWidth: "32px",
                            }}
                          ></circle>
                        </g>
                      </svg>
                    }
                    <span
                      class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm dark:bg-neutral-700"
                      role="tooltip"
                    >
                      Customize QR
                    </span>
                  </div>
                  <div
                    className="flex flex-col justify-center items-center p-2 border border-gray-200 rounded-lg dark:border-neutral-700 cursor-pointer  hs-tooltip hs-tooltip-toggle"
                    onClick={() =>
                      (window.location.href = `/qr-code/edit/code?id=${id}`)
                    }
                  >
                    {
                      <svg
                        viewBox="0 0 24 24"
                        className="size-5"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M20.8477 1.87868C19.6761 0.707109 17.7766 0.707105 16.605 1.87868L2.44744 16.0363C2.02864 16.4551 1.74317 16.9885 1.62702 17.5692L1.03995 20.5046C0.760062 21.904 1.9939 23.1379 3.39334 22.858L6.32868 22.2709C6.90945 22.1548 7.44285 21.8693 7.86165 21.4505L22.0192 7.29289C23.1908 6.12132 23.1908 4.22183 22.0192 3.05025L20.8477 1.87868ZM18.0192 3.29289C18.4098 2.90237 19.0429 2.90237 19.4335 3.29289L20.605 4.46447C20.9956 4.85499 20.9956 5.48815 20.605 5.87868L17.9334 8.55027L15.3477 5.96448L18.0192 3.29289ZM13.9334 7.3787L3.86165 17.4505C3.72205 17.5901 3.6269 17.7679 3.58818 17.9615L3.00111 20.8968L5.93645 20.3097C6.13004 20.271 6.30784 20.1759 6.44744 20.0363L16.5192 9.96448L13.9334 7.3787Z"
                            fill="#808080"
                          ></path>{" "}
                        </g>
                      </svg>
                    }
                    <span
                      class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm dark:bg-neutral-700"
                      role="tooltip"
                    >
                      Edit QR
                    </span>
                  </div>
                  <div>
                    <div
                      class="flex flex-col justify-center items-center p-2 border border-gray-200 rounded-lg dark:border-neutral-700 hs-tooltip"
                      onClick={handleDownload}
                    >
                      <></>
                      {/* {console.log()} */}

                      <button className="hs-tooltip-toggle ">
                        {
                          <svg
                            viewBox="0 0 24 24"
                            className="size-5"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              {" "}
                              <path
                                opacity="1"
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M3 14.25C3.41421 14.25 3.75 14.5858 3.75 15C3.75 16.4354 3.75159 17.4365 3.85315 18.1919C3.9518 18.9257 4.13225 19.3142 4.40901 19.591C4.68577 19.8678 5.07435 20.0482 5.80812 20.1469C6.56347 20.2484 7.56459 20.25 9 20.25H15C16.4354 20.25 17.4365 20.2484 18.1919 20.1469C18.9257 20.0482 19.3142 19.8678 19.591 19.591C19.8678 19.3142 20.0482 18.9257 20.1469 18.1919C20.2484 17.4365 20.25 16.4354 20.25 15C20.25 14.5858 20.5858 14.25 21 14.25C21.4142 14.25 21.75 14.5858 21.75 15V15.0549C21.75 16.4225 21.75 17.5248 21.6335 18.3918C21.5125 19.2919 21.2536 20.0497 20.6517 20.6516C20.0497 21.2536 19.2919 21.5125 18.3918 21.6335C17.5248 21.75 16.4225 21.75 15.0549 21.75H8.94513C7.57754 21.75 6.47522 21.75 5.60825 21.6335C4.70814 21.5125 3.95027 21.2536 3.34835 20.6517C2.74643 20.0497 2.48754 19.2919 2.36652 18.3918C2.24996 17.5248 2.24998 16.4225 2.25 15.0549C2.25 15.0366 2.25 15.0183 2.25 15C2.25 14.5858 2.58579 14.25 3 14.25Z"
                                fill="#808080"
                              ></path>{" "}
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M12 16.75C12.2106 16.75 12.4114 16.6615 12.5535 16.5061L16.5535 12.1311C16.833 11.8254 16.8118 11.351 16.5061 11.0715C16.2004 10.792 15.726 10.8132 15.4465 11.1189L12.75 14.0682V3C12.75 2.58579 12.4142 2.25 12 2.25C11.5858 2.25 11.25 2.58579 11.25 3V14.0682L8.55353 11.1189C8.27403 10.8132 7.79963 10.792 7.49393 11.0715C7.18823 11.351 7.16698 11.8254 7.44648 12.1311L11.4465 16.5061C11.5886 16.6615 11.7894 16.75 12 16.75Z"
                                fill="#808080"
                              ></path>{" "}
                            </g>
                          </svg>
                        }
                        <span
                          class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm dark:bg-neutral-700"
                          role="tooltip"
                        >
                          Download
                        </span>
                      </button>
                    </div>
                  </div>
                  <div
                    className="relative inline-flex sm:block space-x-2 overflow-auto
                  "
                  >
                    <button
                      id="hs-pro-dptied"
                      type="button"
                      class="py-2 px-6 inline-flex items-center gap-x-2 text-xs rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700"
                      onClick={() => handleLinkClick(linkId)}
                    >
                      <svg
                        width="17"
                        height="17"
                        viewBox="0 0 24 24"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <title>Analytics</title>
                        <g
                          id="Analytics"
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                          stroke-dasharray="0,0"
                          stroke-linecap="round"
                        >
                          <path
                            d="M4,18.9999905 L7.75407946,11.491832 C7.8680565,11.2638231 8.06482016,11.0879651 8.30413884,11.0001847 C9.11356935,10.7032911 9.60000359,10.8000012 9.76344156,11.2903152 L11.1190224,15.3570574 C11.1996482,15.5988744 11.3695623,15.8007859 11.5940587,15.9215227 C12.3533352,16.3298705 12.8485386,16.3029137 13.079669,15.8406525 L18,5.99999082 M13,6.46409237 L17.2774408,5.31795559 C17.4347269,5.27579637 17.5999292,5.27269863 17.7586883,5.30891799 C18.3183612,5.43660193 18.6397719,5.65560146 18.7229204,5.96591657 L19.9282036,10.4640923"
                            id="Vector"
                            stroke="#808080"
                            stroke-width="2"
                          ></path>
                        </g>
                      </svg>
                      <p className="hidden sm:block">Analytics</p>
                    </button>
                    <button
                      id="hs-pro-dptied"
                      type="button"
                      class="py-2 px-2.5 sm:inline-flex items-center gap-x-1.5 text-[9px] sm:text-xs rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-neutral-800 dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-700 dark:focus:bg-neutral-700 hidden"
                    >
                      <svg
                        width="17"
                        height="17"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3 9H21M7 3V5M17 3V5M6 12H8M11 12H13M16 12H18M6 15H8M11 15H13M16 15H18M6 18H8M11 18H13M16 18H18M6.2 21H17.8C18.9201 21 19.4802 21 19.908 20.782C20.2843 20.5903 20.5903 20.2843 20.782 19.908C21 19.4802 21 18.9201 21 17.8V8.2C21 7.07989 21 6.51984 20.782 6.09202C20.5903 5.71569 20.2843 5.40973 19.908 5.21799C19.4802 5 18.9201 5 17.8 5H6.2C5.0799 5 4.51984 5 4.09202 5.21799C3.71569 5.40973 3.40973 5.71569 3.21799 6.09202C3 6.51984 3 7.07989 3 8.2V17.8C3 18.9201 3 19.4802 3.21799 19.908C3.40973 20.2843 3.71569 20.5903 4.09202 20.782C4.51984 21 5.07989 21 6.2 21Z"
                          stroke="#808080"
                          stroke-width="2"
                          stroke-linecap="round"
                        />
                      </svg>
                      <span className="">
                        {daate[2] + " " + daate[1] + " " + daate[0]}
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QrCard;
