import React, { useState, useEffect, useRef } from "react";
import axios from "axios"; // Import axios
import * as echarts from "echarts";

const DoughnutChart = ({
  id,
  refreshOS,
  startTime,
  endTime,
  setFilterEmpty,
  setLoadingOS,
  setNoOsData,
}) => {
  const chartRef = useRef(null);
  const [chartInstance, setChartInstance] = useState(null);
  const theme = localStorage.getItem("theme");

  useEffect(() => {
    if (chartInstance) {
      const fetchData = async () => {
        const tokenData = localStorage.getItem("token");
        if (!tokenData) {
          console.log("Token not found in localStorage");
          return;
        }

        let access_token;
        try {
          const data = JSON.parse(tokenData);
          access_token = data.access_token;
          if (!access_token) {
            console.log("Access token is missing");
            return;
          }
        } catch (e) {
          console.log("Failed to parse token data");
          return;
        }

        try {
          const requestData = {
            data: {
              linkId: id,
              fromDate: startTime,
              toDate: endTime,
            },
          };

          const config = {
            method: "post",
            maxBodyLength: Infinity,
            url: `${process.env.REACT_APP_GATEWAY_URL}/atz/links/click-scan-by-os`,
            headers: {
              Authorization: `Bearer ${access_token}`,
              "Content-Type": "application/json",
            },
            data: requestData,
          };
          setLoadingOS(true);
          setNoOsData(false);
          const response = await axios(config);
          if (response.status === 200) {
            setLoadingOS(false);
          }
          const data = response.data.data;
          const countNo = response.data.data.reduce(
            (acc, data) => (acc += data.count),
            0
          );
          if (countNo == 0) {
            setNoOsData(true);
          }
          console.log(response);
          setFilterEmpty(false);
          if (data.length === 0) {
            setFilterEmpty(true);
          }

          // Transform the data for the pie chart
          const chartData = data.map((item) => ({
            value: item.count,
            name: item.osName,
          }));

          if (chartInstance) {
            chartInstance.setOption({
              series: [
                {
                  data: chartData,
                },
              ],
            });
          }
        } catch (error) {
          setLoadingOS(false);
          console.error("Error fetching the data:", error);
        }
      };

      fetchData();
    }
  }, [id, chartInstance, startTime, endTime]);

  useEffect(() => {
    if (chartRef.current) {
      const myChart = echarts.init(chartRef.current);

      const option = {
        toolbox: {
          bottom: "2%",
          right: "2%",
          show: true,
          feature: {
            saveAsImage: { show: true },
          },
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          top: "5%",
          left: "center",
          textStyle: {
            color: "#8C8C8C",
          },
        },
        series: [
          {
            name: "Operating",
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: "#fff",
              borderWidth: 2,
            },
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 40,
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data: [], // Initial empty data
          },
        ],
      };

      myChart.setOption(option);
      setChartInstance(myChart);

      return () => {
        myChart.dispose();
      };
    }
  }, [refreshOS]);

  return (
    <div
      ref={chartRef}
      style={{ height: "400px", width: "100%" }}
      className="flex items-center justify-center dark:text-white"
    ></div>
  );
};

export default DoughnutChart;
