import React from "react";
import Header from "../Landing/Header";
import Footer1 from "../Landing/Footer1";

const Terms = () => {
  const url =
  process.env.REACT_APP_IDP_URL +
  "/oauth2/authorize?response_type=code&client_id=atz&scope=openid&redirect_uri=" +
  process.env.REACT_APP_ATZ_CALLBACK_URL;
  return (
    <>
      <Header url={url} />
      <main id="content" className="bg-white dark:bg-neutral-950">
        {/* <!-- Heading --> */}
        <div class="md:max-w-screen-sm text-center px-4 sm:px-6 lg:px-8 pt-24 pb-6 mx-auto">
          <h1 class="mb-2 text-2xl font-bold md:text-4xl dark:text-white">
            Terms &amp; Conditions
          </h1>
          <p class="text-gray-700 dark:text-neutral-400">
            Last updated: October 18, 2024
          </p>
        </div>
        {/* <!-- End Heading --> */}

        {/* <!-- Content --> */}

        <div class="md:max-w-screen-sm lg:max-w-[992px] px-4 sm:px-6 lg:px-8 pb-12 md:pt-6 sm:pb-20 mx-auto">
          <div class="grid gap-4 md:gap-8">
            <div>
              <p class="mb-8 dark:text-neutral-400 text-xl">
                Welcome to Atz Link Shortening Services. By accessing or using
                our website and services, you agree to comply with the following
                terms and conditions. Please read them carefully before using
                our platform.
              </p>

              <h2 class="text-lg font-semibold mb-2 dark:text-white">
                1. Acceptance of Terms
              </h2>
              <p class="mb-5 dark:text-neutral-400">
                By using Atz, you agree to these Terms and Conditions and our
                Privacy Policy. If you do not agree, please discontinue the use
                of our services.
              </p>

              <h2 class="text-lg font-semibold mb-2 dark:text-white">
                2. Modifications to the Terms
              </h2>
              <p class="mb-5 dark:text-neutral-400">
                We reserve the right to modify these terms at any time without
                prior notice. We will notify users of significant changes via
                email or a prominent notice on our website.
              </p>

              <h2 class="text-lg font-semibold mb-2 dark:text-white">
                3. Service Usage
              </h2>
              <p class="mb-5 dark:text-neutral-400">
                Atz offers link shortening, QR code creation, and analytics
                services. By using our services, you agree not to use them for
                any unlawful purposes, including but not limited to:
                <br />
                <br />
                Distributing malicious or harmful content
                <br />
                Engaging in phishing, spamming, or fraudulent activities
                <br />
                Violating third-party intellectual property rights
              </p>

              <h2 class="text-lg font-semibold mb-2 dark:text-white">
                4. Recurring Payments
              </h2>
              <p class="mb-5 dark:text-neutral-400">
                For paid plans, you will be billed on a recurring basis (monthly
                or annually, depending on your chosen subscription plan). The
                payment method you provided will be charged automatically at the
                start of each billing cycle until you cancel your plan.
              </p>

              <h2 class="text-lg font-semibold mb-2 dark:text-white">
                5. Cancellation and Termination
              </h2>
              <p class="mb-5 dark:text-neutral-400">
                You may cancel your subscription plan at any time. To cancel, go
                to your account settings and follow the cancellation
                instructions. Once canceled, your plan will remain active until
                the end of your current billing period, and you will not be
                charged for the next cycle. Please note that no refunds will be
                issued for partial months of service. <br />
                We reserve the right to terminate or suspend your access to Atz
                if you are found in violation of these terms. In such cases, no
                refunds will be issued.
              </p>

              <h2 class="text-lg font-semibold mb-2 dark:text-white">
                6. No Refund Policy
              </h2>
              <p class="mb-5 dark:text-neutral-400">
                Atz operates under a No Refund Policy. All payments made for any
                subscription or service are final and non-refundable. This
                applies to both monthly and annual billing cycles. If you cancel
                your plan, you will continue to have access to the service until
                the end of the current billing cycle, but you will not be
                refunded for any unused portion of your subscription.
              </p>

              <h2 class="text-lg font-semibold mb-2 dark:text-white">
                7. Account Responsibilities
              </h2>
              <p class="mb-5 dark:text-neutral-400">
                You are responsible for maintaining the security of your account
                and all activities that occur under your account. Notify us
                immediately of any unauthorized use or security breach.
              </p>

              <h2 class="text-lg font-semibold mb-2 dark:text-white">
                8. Termination of Service
              </h2>
              <p class="mb-5 dark:text-neutral-400">
                We reserve the right to terminate or suspend your access to Atz
                at our discretion, without notice, if we find you in violation
                of these terms.
              </p>

              <h2 class="text-lg font-semibold mb-2 dark:text-white">
                9. Limitation of Liability
              </h2>
              <p class="mb-5 dark:text-neutral-400">
                Atz is not liable for any indirect, incidental, or consequential
                damages resulting from your use of our services. Your use of Atz
                is at your own risk.
              </p>

              <h2 class="text-lg font-semibold mb-2 dark:text-white">
                10. Governing Law
              </h2>
              <p class="mb-5 dark:text-neutral-400">
                These terms are governed by the laws of the jurisdiction in
                which Atz operates. Any legal disputes shall be handled by the
                appropriate courts.
              </p>
              <h2 class="text-lg font-semibold mb-2 dark:text-white"></h2>
              <p class="mb-5 dark:text-neutral-400">
                For any queries related to our Terms and Conditions, please
                contact us at [atz@nidavellirs.com].
              </p>
            </div>
          </div>
        </div>
        {/* <!-- End Content --> */}
      </main>
      {/* <!-- ========== END MAIN CONTENT ========== --> */}
      <Footer1 />
    </>
  );
};

export default Terms;
