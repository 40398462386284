import React from "react";

const Features3 = ({ url }) => {
  return (
    <div className="flex flex-col bg-white  dark:bg-neutral-950 p-4 md:px-28 md:py-10 items-center gap-y-20">
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-10 gap-y-5">
        <div className=" w-auto border border-[#019ca2] rounded-2xl flex flex-col bg-gradient-to-b from-[#F3F9FF] to-[#C4E0FD] bg-opacity-70">
          <div className="flex flex-col pl-4 pr-10 py-4">
            <div className="text-[#041527] font-bold text-2xl">
              Click scan by browsers
            </div>
            <div className="text-[#041527] text-[13px]">
              Displays browser usage distribution, highlighting clicks from
              browsers like Chrome, Safari, and others.
            </div>
          </div>
          <div className="flex bg-white flex-1 mr-10 rounded-tr-xl rounded-bl-2xl justify-center items-center">
            <img
              src={require("../../assets/landing/chartDevice.png")}
              alt="chart-device"
            />
          </div>
        </div>
        <div className="w-auto border border-[#019ca2] rounded-2xl flex flex-col bg-gradient-to-b from-[#F3F9FF] to-[#C4E0FD] bg-opacity-70">
          <div className="flex flex-col pl-4 pr-10 py-4">
            <div className="text-[#041527] font-bold text-2xl">
              Click scan by OS
            </div>
            <div className="text-[#041527] text-[13px]">
              Shows the breakdown of clicks by operating system, including
              Windows, Mac, Linux, and Ubuntu.
            </div>
          </div>
          <div className="flex bg-white flex-1 mr-10 rounded-tr-xl rounded-bl-2xl  justify-center items-center">
            <img
              src={require("../../assets/landing/chartOS.png")}
              alt="chart-os"
            />
          </div>
        </div>
        <div className="w-auto border border-[#019ca2] rounded-2xl flex flex-col bg-gradient-to-b from-[#F3F9FF] to-[#C4E0FD] bg-opacity-70">
          <div className="flex flex-col px-10 py-4 py-4">
            <div className="text-[#041527] font-bold text-2xl">
              Click scan by country
            </div>
            <div className="text-[#041527] text-[13px]">
              Presents total and unique clicks by country, providing insights
              into geographic click activity
            </div>
          </div>
          <div className="flex flex-1 mx-10 rounded-t-xl justify-center items-center">
            <img
              src={require("../../assets/landing/scanCountry.png")}
              alt="country"
            />
          </div>
        </div>
        <div className="w-auto border border-[#019ca2] rounded-2xl flex flex-col bg-gradient-to-b from-[#F3F9FF] to-[#C4E0FD] bg-opacity-70 overflow-hidden">
          <div className="flex flex-col px-10 py-4">
            <div className="text-[#041527] font-bold text-2xl">
              Click scan by location
            </div>
            <div className="text-[#041527] text-[13px]">
              Visualizes clicks on a map, offering a geographical overview of
              user interactions by location.
            </div>
          </div>
          <div className="flex flex-1 mx-10 rounded-t-xl justify-center items-center">
            <img
              src={require("../../assets/landing/scanLocation.png")}
              alt="location"
            />
          </div>
        </div>
        <div className="w-auto border border-[#019ca2] rounded-2xl flex flex-col bg-gradient-to-b from-[#F3F9FF] to-[#C4E0FD] bg-opacity-70">
          <div className="flex flex-1 px-10 pt-4 pb-0 justify-center">
            <div className="text-[#041527] font-bold text-2xl">
              <img
                src={require("../../assets/landing/chart5Head.png")}
                alt="time-period"
              />
            </div>
          </div>
          <div className="flex flex-1 mx-10 rounded-t-xl justify-center items-center">
            <img
              src={require("../../assets/landing/chart5Body.png")}
              alt="time-period"
            />
          </div>
        </div>
        <div className="w-auto border border-[#019ca2] rounded-2xl flex flex-col bg-gradient-to-b from-[#F3F9FF] to-[#C4E0FD] bg-opacity-70">
          <div className="flex flex-1 px-10 pt-4 pb-0  justify-center">
            <div className="text-[#041527] font-bold text-2xl">
              <img
                src={require("../../assets/landing/chart6Head.png")}
                alt="devices"
              />
            </div>
          </div>
          <div className="flex flex-1 mx-10 rounded-t-xl justify-center items-center">
            <img
              src={require("../../assets/landing/chart6Body.png")}
              alt="devices"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col flex-1 justify-center items-center">
        <div className="flex flex-col text-center gap-y-10">
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-x-10 gap-y-10 mx-auto">
            <div className="bg-[#CEE7E8] w-auto rounded-[30px] flex flex-col justify-around items-center p-4">
              <div>
                <img
                  src={require("../../assets/landing/customers.png")}
                  alt="customers"
                />
              </div>
              <div className="text-[40px] sm:text-[64px] font-bold">1.5k+</div>
              <div className="text-[#353535] text-2xl tracking-tight">
                Global paying customers
              </div>
            </div>
            <div className="bg-[#CEE7E8] w-auto rounded-[30px] flex flex-col justify-around items-center p-4">
              <div>
                <img
                  src={require("../../assets/landing/linksQr.png")}
                  alt="linksQr"
                />
              </div>
              <div className="text-[40px] sm:text-[64px] font-bold">3.5k+</div>
              <div className="text-[#353535] text-2xl tracking-tight">
                Links Shortened
              </div>
            </div>
            <div className="bg-[#CEE7E8] w-auto rounded-[30px] flex flex-col justify-around items-center p-4">
              <div>
                <img src={require("../../assets/landing/QR.png")} alt="qr" />
              </div>
              <div className="text-[40px] sm:text-[64px] font-bold">1000+</div>
              <div className="text-[#353535] text-2xl tracking-tight">
                QR Code Generated
              </div>
            </div>
            <div className="bg-[#CEE7E8] w-auto rounded-[30px] flex flex-col justify-around items-center p-4">
              <div>
                <img
                  src={require("../../assets/landing/analyticsScan.png")}
                  alt="scan"
                />
              </div>
              <div className="text-[40px] sm:text-[64px] font-bold">10B</div>
              <div className="text-[#353535] text-2xl tracking-tight">
                Analytics Scans and clicks monthly
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col py-10 items-center text-center">
        <div className="text-center sm:text-[48px] text-[32px] font-semibold inline-flex items-center gap-x-2 dark:text-neutral-50">
          We’ve taught{" "}
          <span className="font-black inline-flex items-center gap-x-2">
            over 3,000 creators
          </span>{" "}
        </div>
        <div className="text-center sm:text-[48px] text-[32px font-semibold inline-flex items-center gap-x-2 dark:text-neutral-50">
          <span className="font-black inline-flex items-center gap-x-2">
            how to{" "}
          </span>{" "}
          <span className="relative">
            grow on any Platform
            <img
              src={require("../../assets/landing/Vector2.png")}
              className="absolute top-2 left-0"
            />
          </span>
        </div>
      </div>
      <div className="flex flex-1 md:flex-row flex-col gap-x-6 gap-y-6 items-center">
        <div className="border border-neutral-800 bg-gradient-to-br from-white via-white from-60% via-orange-600/10 to-90% to-purple-600/50 rounded-xl w-auto flex flex-col flex-1 ">
          <div className="flex flex-col gap-y-6 pb-10 p-8">
            <div>
              <img src={require("../../assets/landing/ratin4.png")} />
            </div>
            <div className="text-[20px] text-[#1A2D47]">
              “We rely on Atz's short links for all our email campaigns. It’s
              simple to use and provides valuable insights that help optimize
              our strategies”
            </div>
          </div>
          <div className="flex flex-1 overflow-hidden p-8 pb-4">
            <div className="flex flex-col flex-1 justify-around">
              <div className="flex flex-col">
                <span className="text-[18px] font-medium">
                  Parul Shrivastava
                </span>
                <span className="text-[16px] text-[#757575]">
                  CEO, Credmudra
                </span>
              </div>
              {/* <div>
                <img src={require("../../assets/landing/logo.png")} />
              </div> */}
            </div>
            <div className="relative">
              <img
                src={`https://media.licdn.com/dms/image/v2/D4D03AQEJ5qkYdnU5xQ/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1707330687769?e=1734566400&v=beta&t=sWrvXMwJDfUn2r8x_RCnR9La8fQUnKXTH97xzl6JbG0`}
                className=" size-28 rounded-full"
              />
              {/* <img
                src={require("../../assets/landing/person4.png")}
                className="-mt-10"
              /> */}
            </div>
          </div>
        </div>
        <div className="border border-neutral-800 bg-gradient-to-br from-white via-white from-60% via-orange-600/10 to-90% to-purple-600/50 rounded-xl w-auto flex flex-col flex-1 ">
          <div className="flex flex-col gap-y-6 pb-10 p-8">
            <div>
              <img src={require("../../assets/landing/ratin.png")} />
            </div>
            <div className="text-[20px] text-[#1A2D47]">
              “Atz makes sharing content with our clients a breeze. The custom
              branded links improve trust and give us detailed analytics to
              measure engagement efficiently.”
            </div>
          </div>
          <div className="flex flex-1 overflow-hidden p-8 pb-4">
            <div className="flex flex-col flex-1 justify-around">
              <div className="flex flex-col">
                <span className="text-[18px] font-medium">Varun Gaur</span>
                <span className="text-[16px] text-[#757575]">
                  Founder, Planbow
                </span>
              </div>
            </div>
            <div className="relative">
              <img
                src="https://media.licdn.com/dms/image/v2/D5603AQHPokP0k8vaTQ/profile-displayphoto-shrink_400_400/profile-displayphoto-shrink_400_400/0/1695364970593?e=1734566400&v=beta&t=t5XE5iP76zFB1L95mYMYONrf7VxrQqbRUSuACgsluLg"
                className="size-28 rounded-full"
              />
            </div>
          </div>
        </div>
        <div className="border border-neutral-800 bg-gradient-to-br from-white via-white from-60% via-orange-600/10 to-90% to-purple-600/50 rounded-xl w-auto flex flex-col flex-1 hidden lg:flex">
          <div className="flex flex-col gap-y-6 pb-10 p-8">
            <div>
              <img src={require("../../assets/landing/ratin4.png")} />
            </div>
            <div className="text-[15px] text-[#1A2D47]">
              “Unique AI-powered features that can significantly streamline link
              sharing and content creation, making it a great choice for
              businesses looking to boost productivity and engagement.”
            </div>
          </div>
          <div className="flex flex-1 overflow-hidden p-8 pb-4">
            <div className="flex flex-col flex-1 justify-around">
              <div className="flex flex-col">
                <span className="text-[13.5px] font-medium">Ayush Sharma</span>
                <span className="text-[12px] text-[#757575]">
                  Web Developer
                </span>
              </div>
            </div>
            <div className="relative">
              <img
                src="https://media.licdn.com/dms/image/v2/D4D03AQHicsF3hTxqcA/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1718237285745?e=1734566400&v=beta&t=0BxlIVPp18P4qt6pkp-72JfWjpi-Z447dFmUT6ecB_U"
                className="size-28 rounded-full"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col w-auto rounded-[30px] bg-[#007579] min-h-96 justify-center items-center text-center md:px-56 gap-y-4 relative overflow-hidden">
        <div className="md:text-[48px] text-[28px] font-black text-white leading-none z-10">
          Elevate Your Marketing with Smarter Link Sharing
        </div>
        <div className="text-[16px] text-white font-light z-10">
          Use{" "}
          <span
            className="font-medium
          "
          >
            Atz to optimize and track their links for better performance
          </span>
          . Enhance your marketing strategy with{" "}
          <span
            className="font-medium
          "
          >
            easy-to-use link management tools{" "}
          </span>
          that provide valuable insights and boost engagement.
        </div>
        <div className="relative z-10">
          <button
            className="rounded-r-[42px] rounded-l-[42px] border-white border-2 bg-[#353535] text-white p-3 px-6 text-[18px] font-bold "
            onClick={() => (window.location.href = url)}
          >
            Get Started- its free!
          </button>
        </div>

        <img
          src={require("../../assets/landing/ctaBg.png")}
          className="absolute mix-blend-luminosity bottom-0 rounded-b-[32px] w-full"
        />
      </div>
    </div>
  );
};

export default Features3;
