import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
const DashboardFooter = () => {
  const location = useLocation();
  const [visible, setVisible] = useState(true);
  const handleClose = () => {
    setVisible(false);
  };
  useEffect(() => {
    // Reinitialize Preline components every time the location changes
    if (
      window.HSStaticMethods &&
      typeof window.HSStaticMethods.autoInit === "function"
    ) {
      window.HSStaticMethods.autoInit();
    }
  }, [location.pathname]);
  return (
    <div className="fixed bottom-6 right-6 z-20">
      <div
        className={`${
          visible ? `` : `hidden`
        } absolute -top-2.5 -right-2 cursor-pointer z-20`}
        onClick={handleClose}
      >
        <svg
          viewBox="0 0 24 24"
          version="1.1"
          className="size-4"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          fill="#808080"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <g
              id="Page-1"
              stroke="none"
              stroke-width="1"
              fill="none"
              fill-rule="evenodd"
            >
              {" "}
              <g id="Close-Circle">
                {" "}
                <rect
                  id="Rectangle"
                  fill-rule="nonzero"
                  x="0"
                  y="0"
                  width="24"
                  height="24"
                >
                  {" "}
                </rect>{" "}
                <circle
                  id="Oval"
                  stroke="#808080"
                  stroke-width="2"
                  stroke-linecap="round"
                  cx="12"
                  cy="12"
                  r="9"
                >
                  {" "}
                </circle>{" "}
                <line
                  x1="14.1213"
                  y1="9.87866"
                  x2="9.8787"
                  y2="14.1213"
                  id="Path"
                  stroke="#808080"
                  stroke-width="2"
                  stroke-linecap="round"
                >
                  {" "}
                </line>{" "}
                <line
                  x1="9.87866"
                  y1="9.87866"
                  x2="14.1213"
                  y2="14.1213"
                  id="Path"
                  stroke="#808080"
                  stroke-width="2"
                  stroke-linecap="round"
                >
                  {" "}
                </line>{" "}
              </g>{" "}
            </g>{" "}
          </g>
        </svg>
      </div>
      <div
        className={`${
          visible ? `` : `hidden`
        } flex justify-center  h-14 w-14 bg-[#019ca2] hover:bg-[#074c4e] rounded-full text-white cursor-pointer items-center`}
        data-hs-overlay="#hs-pro-dnsm"
      >
        <svg
          height="200px"
          width="200px"
          className="shrink-0 h-7 w-7"
          version="1.1"
          id="_x32_"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 512 512"
          xmlSpace="preserve"
          fill="#fff"
        >
          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <g>
              {" "}
              <path
                class="st0"
                d="M247.355,106.9C222.705,82.241,205.833,39.18,197.46,0c-8.386,39.188-25.24,82.258-49.899,106.917 c-24.65,24.642-67.724,41.514-106.896,49.904c39.188,8.373,82.254,25.235,106.904,49.895c24.65,24.65,41.522,67.72,49.908,106.9 c8.373-39.188,25.24-82.258,49.886-106.917c24.65-24.65,67.724-41.514,106.896-49.904 C315.08,148.422,272.014,131.551,247.355,106.9z"
              ></path>{" "}
              <path
                class="st0"
                d="M407.471,304.339c-14.714-14.721-24.81-40.46-29.812-63.864c-5.011,23.404-15.073,49.142-29.803,63.872 c-14.73,14.714-40.464,24.801-63.864,29.812c23.408,5.01,49.134,15.081,63.864,29.811c14.73,14.722,24.81,40.46,29.82,63.864 c5.001-23.413,15.081-49.142,29.802-63.872c14.722-14.722,40.46-24.802,63.856-29.82 C447.939,329.14,422.201,319.061,407.471,304.339z"
              ></path>{" "}
              <path
                class="st0"
                d="M146.352,354.702c-4.207,19.648-12.655,41.263-25.019,53.626c-12.362,12.354-33.968,20.82-53.613,25.027 c19.645,4.216,41.251,12.656,53.613,25.027c12.364,12.362,20.829,33.96,25.036,53.618c4.203-19.658,12.655-41.255,25.023-53.626 c12.354-12.362,33.964-20.82,53.605-25.035c-19.64-4.2-41.251-12.656-53.613-25.019 C159.024,395.966,150.555,374.351,146.352,354.702z"
              ></path>{" "}
            </g>{" "}
          </g>
        </svg>
      </div>
    </div>
  );
};

export default DashboardFooter;
