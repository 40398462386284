import React, { useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import axiosInstance from "../src/instances/axiosInstance";
import Loader from "./components/subcomponents/Loader";
import axios from "axios";
import DashboardHeader from "./components/Dashboard/DashboardHeader";
import DashboardSidebar from "./components/Dashboard/DashboardSidebar";
import SubExpired from "./components/SubExpired";

const PrivateRoute = ({ element: Component }) => {
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(null); // Use null for loading state
  const [isLoading, setIsLoading] = useState(true); // Separate state for loading
  const email = JSON.parse(localStorage?.getItem("user"));
  const subbed = JSON.parse(localStorage?.getItem("activeSub"));
  const [isSubscribed, setIsSubscribed] = useState(true);
  const [plan, setPlan] = useState(null);
  useEffect(() => {
    // Initial token check and introspect on mount
    introspectAPI();
  }, []); // Empty dependency array to ensure it runs only once on mount
  useEffect(() => {
    // Initial token check and introspect on mount
    if (
      isAuthenticated &&
      window.location.pathname === "/billing/subscriptions"
    ) {
      setIsLoading(false);
    }
    if (
      isAuthenticated &&
      window.location.pathname !== "/billing/subscriptions"
    ) {
      activeSubscription();
    }
  }, [isAuthenticated]); // Empty dependency array to ensure it runs only once on mount

  const activeSubscription = async () => {
    const token = JSON.parse(localStorage.getItem("token"));

    // If no token is present, redirect to login
    if (!token) {
      localStorage.clear();
      setIsAuthenticated(false);
      setIsLoading(false);
      return;
    }

    const accessToken = token.access_token;
    const refreshToken = token.refresh_token;

    const config = {
      method: "post",
      url: "/atz/subscription/active-subscriptions",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        data: {},
      },
    };

    try {
      // Try introspecting the access token
      setIsLoading(true);
      const activeResponse = await axiosInstance(config);
      if (activeResponse.status === 200) {
        setPlan(activeResponse.data.data);
        if (
          activeResponse.data.data.subscriptionStatus == "activated" ||
          activeResponse.data.data.subscriptionStatus == null
        ) {
          localStorage.setItem("activeSub", true);
          setIsSubscribed(true);
        } else {
          setIsSubscribed(false);
        }
      } else {
        setIsSubscribed(false);
      }
    } catch (error) {
      // Handle introspect error (e.g., 401)

      if (error.response.status == 404) {
        setIsSubscribed(false);
        // await handleTokenRefresh(refreshConfig);
      } else {
        // localStorage.clear();
        // sessionStorage.setItem("email", JSON.stringify(email));
        // setIsAuthenticated(false);
        // window.location.href = "/session-expired";
      }
    } finally {
      setIsLoading(false); // Stop loading when done
    }
  };

  const introspectAPI = async () => {
    const token = JSON.parse(localStorage.getItem("token"));

    // If no token is present, redirect to login
    if (!token) {
      localStorage.clear();
      setIsAuthenticated(false);
      setIsLoading(false);
      return;
    }

    const accessToken = token.access_token;
    const refreshToken = token.refresh_token;

    const introspectConfig = {
      method: "post",
      url: "/secured/introspect",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        data: {
          clientName: "atz",
        },
      },
    };

    const refreshConfig = {
      method: "post",
      url: "/core/refresh-token",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        data: {
          clientName: "atz",
          refreshToken: `${refreshToken}`,
        },
      },
    };

    try {
      // Try introspecting the access token
      const introspectResponse = await axiosInstance(introspectConfig);
      if (introspectResponse.data.data.active) {
        // If token is active, authentication is valid
        localStorage.setItem("role", introspectResponse.data.data.roles[0]);
        localStorage.setItem("auth", true);
        setIsAuthenticated(true);
      } else {
        // If token is not active, try to refresh it
        await handleTokenRefresh(refreshConfig);
      }
    } catch (error) {
      // Handle introspect error (e.g., 401)
      if (error.response && error.response.status === 401) {
        await handleTokenRefresh(refreshConfig);
      } else {
        localStorage.clear();
        sessionStorage.setItem("email", JSON.stringify(email));
        setIsAuthenticated(false);
        window.location.href = "/session-expired";
      }
    } finally {
      // setIsLoading(false); // Stop loading when done
    }
  };

  // Function to handle token refresh
  const handleTokenRefresh = async (refreshConfig) => {
    try {
      const responseRefresh = await axiosInstance(refreshConfig);
      const newToken = responseRefresh.data.data;
      localStorage.setItem("token", JSON.stringify(newToken));
      localStorage.setItem("auth", true);
      setIsAuthenticated(true);
    } catch (e) {
      sessionStorage.setItem("email", JSON.stringify(email));
      localStorage.clear();
      setIsAuthenticated(false);
      window.location.href = "/session-expired";
    }
  };

  // If loading, display the loader
  if (isLoading) {
    return <Loader />;
  }

  // If the user is not authenticated, redirect to login or home page
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  if (isAuthenticated && !isSubscribed && plan) {
    return <SubExpired plan={plan} />;
  }

  // If authenticated && subscribed, render the protected component
  return <Component />;
};

export default PrivateRoute;

// import React, { useEffect, useState } from "react";
// import { Navigate } from "react-router-dom";
// import axiosInstance from "../src/instances/axiosInstance";
// import Loader from "./components/subcomponents/Loader";
// import axios from "axios";

// const PrivateRoute = ({ element: Component }) => {
//   const [isAuthenticated, setIsAuthenticated] = useState(null); // Use null to indicate loading state

//   useEffect(() => {
//     // Initial token check and introspect on mount
//     introspectAPI();

//     // Add event listener for browser focus
//     const handleFocus = () => {
//       introspectAPI();
//     };

//     window.addEventListener("focus", handleFocus);

//     // Cleanup the event listener on component unmount
//     return () => {
//       window.removeEventListener("focus", handleFocus);
//     };
//   }, []); // Empty dependency array to ensure it runs only once on mount
//   if (!localStorage.getItem("token")) {
//     window.location.href = "/";
//   }

//   const introspectAPI = async () => {
//     const data = JSON.stringify({
//       data: {
//         clientName: "atz",
//       },
//     });
//     const token = JSON.parse(localStorage.getItem("token"));
//     if (!token) {
//       setIsAuthenticated(false);
//       return;
//     }
//     const dataRefresh = JSON.stringify({
//       data: {
//         clientName: "atz",
//         refreshToken: `${token.refresh_token}`,
//       },
//     });
//     const { access_token: accessToken } = token;
//     const config = {
//       method: "post",
//       url: "/secured/introspect",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${accessToken}`,
//       },
//       data: data,
//     };
//     const configRefresh = {
//       method: "post",
//       url: "/core/refresh-token",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       data: dataRefresh,
//     };

//     try {
//       const introspectResponse = await axiosInstance(config);
//       if (introspectResponse.data.data.active) {
//         localStorage.setItem("auth", true);
//         setIsAuthenticated(true);
//       } else {
//         try {
//           const responseRefresh = await axiosInstance(configRefresh);
//           const newToken = responseRefresh.data.data;
//           localStorage.setItem("token", JSON.stringify(newToken));
//           localStorage.setItem("auth", true);
//           setIsAuthenticated(true);
//         } catch (e) {
//           localStorage.clear();
//           setIsAuthenticated(false);
//           window.location.href = "/session-expired";
//         }
//       }
//     } catch (error) {
//       //handling 401 for introspect API
//       if (error.response.status === 401) {
//         //get the refresh
//         try {
//           const refreshResponse = await axiosInstance(configRefresh);
//           const newToken = refreshResponse.data.data;
//           localStorage.setItem("token", JSON.stringify(newToken));
//           localStorage.setItem("auth", true);
//           setIsAuthenticated(true);
//         } catch (e) {
//           setIsAuthenticated(false);
//           localStorage.setItem("auth", false);
//           window.location.href = "/session-expired";
//         }
//       } else {
//         setIsAuthenticated(false);
//         localStorage.setItem("auth", false);
//         window.location.href = "/session-expired";
//       }
//     }
//   };

//   return (
//     <>
//       <div className={!isAuthenticated ? "hidden" : ""}>
//         <Component />
//       </div>
//       <div className={isAuthenticated ? "hidden" : ""}>
//         <Loader />
//       </div>
//     </>
//   );
// };

// export default PrivateRoute;

// axios.interceptors.request.use(
//   async (req) => {
//     try {
//       let token = await introspectAPI();
//       if (token) {
//         token = JSON.parse(localStorage.getItem("token"));
//         console.log(token);
//         req.headers.Authorization = `Bearer ${token.access_token}`;
//       }
//       return req;
//     } catch (error) {
//       // Handle the error or redirect to login
//       localStorage.setItem("auth", false);
//       window.location.href = "/session-expired";
//       // return Promise.reject(error);
//     }
//   },
//   (error) => {
//     localStorage.setItem("auth", false);
//     window.location.href = "/session-expired";
//     // return Promise.reject(error);
//   }
// );
// axios.interceptors.response.use((res) => {
//   if (res.status === 200) {
//     setIsAuthenticated(true);
//   }
//   return res;
// });
