import React from "react";
import Header from "../Landing/Header";
import Footer1 from "../Landing/Footer1";

const PrivacyPolicy = () => {
  const url =
    process.env.REACT_APP_IDP_URL +
    "/oauth2/authorize?response_type=code&client_id=atz&scope=openid&redirect_uri=" +
    process.env.REACT_APP_ATZ_CALLBACK_URL;
  return (
    <>
      <Header url={url} />
      <main id="content" className="bg-white dark:bg-neutral-950">
        {/* <!-- Heading --> */}
        <div class="md:max-w-screen-sm text-center px-4 sm:px-6 lg:px-8 pt-24 pb-6 mx-auto">
          <h1 class="mb-2 text-2xl font-bold md:text-4xl dark:text-white">
            Privacy Policy
          </h1>
          <p class="text-gray-700 dark:text-neutral-400">
            Last updated: October 18, 2024
          </p>
        </div>
        {/* <!-- End Heading --> */}
        {/*  */}
        {/* <!-- Content --> */}
        <div class="md:max-w-screen-sm lg:max-w-[992px] px-4 sm:px-6 lg:px-8 pb-12 md:pt-6 sm:pb-20 mx-auto">
          <div class="grid gap-4 md:gap-8">
            <div>
              <p class="mb-8 dark:text-neutral-400 text-xl">
                At Atz Link Shortening Services, we are committed to protecting
                your privacy. This policy outlines how we collect, use, and
                safeguard your personal information when you use our website and
                services.
              </p>

              <h2 class="text-lg font-semibold mb-2 dark:text-white">
                1. Information We Collect
              </h2>

              <p class="mb-5 dark:text-neutral-400">
                We may collect the following types of information:
                <br />
                Personal Information: Such as your name, email address, and
                payment details when you sign up for our services.
                <br />
                Usage Data: Information about how you use our services,
                including shortened links, QR codes, and interaction data.
              </p>

              <h2 class="text-lg font-semibold mb-2 dark:text-white">
                2. How We Use Your Information
              </h2>

              <p class="mb-5 dark:text-neutral-400">
                We use the information collected for the following purposes:
                <br />
                To provide and improve our services
                <br />
                To communicate with you regarding updates, new features, and
                offers
                <br />
                To analyze user behavior and optimize user experience
              </p>

              <h2 class="text-lg font-semibold mb-2 dark:text-white">
                3. Information Sharing
              </h2>

              <p class="mb-5 dark:text-neutral-400">
                We do not sell or share your personal information with third
                parties, except when required by law or necessary to provide our
                services (e.g., for payment processing).
              </p>

              <h2 class="text-lg font-semibold mb-2 dark:text-white">
                4. Data Retention
              </h2>

              <p class="mb-5 dark:text-neutral-400">
                We retain your personal information for as long as your account
                is active or as necessary to fulfill the purposes outlined in
                this policy. You may request to delete your information at any
                time by contacting atz@nidavellirs.com.
              </p>

              <h2 class="text-lg font-semibold mb-2 dark:text-white">
                5. Data Security
              </h2>

              <p class="mb-5 dark:text-neutral-400">
                We use industry-standard security measures to protect your
                personal information from unauthorized access, disclosure, or
                destruction.
              </p>

              <h2 class="text-lg font-semibold mb-2 dark:text-white">
                6. Your Rights
              </h2>

              <p class="mb-5 dark:text-neutral-400">
                You have the right to:
                <br />
                Access the personal information we hold about you
                <br />
                Request corrections to your personal information
                <br />
                Request the deletion of your personal information
                <br />
                For any questions or concerns regarding your privacy, please
                reach out to us at atz@nidavellirs.com
              </p>
            </div>
          </div>
        </div>
        {/* <!-- End Content --> */}
      </main>
      {/* <!-- ========== END MAIN CONTENT ========== --> */}
      <Footer1 />
    </>
  );
};

export default PrivacyPolicy;
