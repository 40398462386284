import React from "react";
import ReactCountryFlag from "react-country-flag";

const Pills = ({ isoCode, text, onClick }) => {
  return (
    <div class="hs-tooltip inline-block">
      <span
        className="flex items-center p-3 py-4 gap-2 border-[1px] border-gray-200 rounded-3xl h-5"
        onClick={onClick}
      >
        <ReactCountryFlag countryCode={isoCode} svg />
        <span className="dark:text-neutral-50">&times;</span>
        {/* {text} */}
        <span
          class="hs-tooltip-content hs-tooltip-shown:opacity-100 hs-tooltip-shown:visible opacity-0 transition-opacity inline-block absolute invisible z-10 py-1 px-2 bg-gray-900 text-xs font-medium text-white rounded shadow-sm dark:bg-neutral-700"
          role="tooltip"
        >
          {text}
        </span>
      </span>
    </div>
  );
};

export default Pills;
