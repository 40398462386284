import "preline/preline";
import React, { useEffect, useState } from "react";
import ReactCountryFlag from "react-country-flag";
import { useLocation } from "react-router-dom";
import countryCodes from "../../../features/countryCodes";
import * as XLSX from "xlsx";
const CountryTable = ({
  setDownloadCounter,
  id,
  countryData,
  setModalTitle,
  setTableViewCountry,
  setCountryIso,
}) => {
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState(true);
  const location = useLocation();
  const [currentData, setCurrentData] = useState([]);
  const [sortedData, setSortedData] = useState([]); // Store sorted data here
  const [sortDirection, setSortDirection] = useState("asc"); // Track the sort direction
  const [sortColumn, setSortColumn] = useState("totalCount");
  // Track the column being sorted
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const totalPages = Math.ceil(countryData.length / itemsPerPage);

  useEffect(() => {
    // Initial sorting by total clicks
    const sorted = [...countryData].sort((a, b) => b.totalCount - a.totalCount);
    setSortedData(sorted); // Store sorted dat
    // Set paginated data
    setLoading(false);
  }, [countryData]);

  // Function to handle sorting based on the column
  const handleClick = (column) => {
    let newDirection = sortDirection === "asc" ? "desc" : "asc"; // Toggle direction
    setSortDirection(newDirection);
    setSortColumn(column); // Track the column being sorted

    const sorted = [...sortedData].sort((a, b) => {
      if (column === "totalCount") {
        return newDirection === "asc"
          ? a.totalCount - b.totalCount
          : b.totalCount - a.totalCount;
      } else if (column === "country") {
        return newDirection === "asc"
          ? a.country.localeCompare(b.country)
          : b.country.localeCompare(a.country);
      }
      return 0;
    });

    setSortedData(sorted); // Update sorted data
    setCurrentData(sorted.slice(startIndex, endIndex)); // Update paginated data
  };

  // Handle page change (when pagination is clicked)
  useEffect(() => {
    setCurrentData(sortedData.slice(startIndex, endIndex)); // Use the sorted data for pagination
  }, [sortedData, startIndex, endIndex]);
  // Pagination states

  // const currentData = countryData
  //   .sort((a, b) => b.totalCount - a.totalCount)
  //   .slice(startIndex, endIndex);

  // Calculate the data to display on the current page

  // Calculate total pages

  // Function to handle exporting data to Excel

  // useEffect(() => {
  //   // Set the initial sorted data
  //   const sortedData = [...countryData]
  //     .sort((a, b) => b.totalCount - a.totalCount)
  //     .slice(startIndex, endIndex);
  //   setCurrentData(sortedData);
  //   setLoading(false);
  // }, [countryData, startIndex, endIndex]);
  useEffect(() => {
    // Reinitialize Preline components every time the location changes
    if (
      window.HSStaticMethods &&
      typeof window.HSStaticMethods.autoInit === "function"
    ) {
      window.HSStaticMethods.autoInit();
    }
  }, [location.pathname, currentData]);
  // Function to handle sorting
  // const handleClick = () => {
  //   const newDirection = sortDirection === "asc" ? "desc" : "asc"; // Toggle sort direction
  //   setSortDirection(newDirection); // Update the state

  //   const sortedData = [...countryData].sort((a, b) => {
  //     return newDirection === "asc"
  //       ? a.totalCount - b.totalCount
  //       : b.totalCount - a.totalCount;
  //   });
  //   setCurrentData(sortedData.slice(startIndex, endIndex)); // Update sorted data in state
  // };

  const exportToExcel = () => {
    const formattedData = countryData.map((item) => ({
      Country: item.country,
      "Total Clicks": item.totalCount,
      "Unique Clicks": item.uniqueCount,
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Country Data");

    // Generate Excel file and trigger download
    XLSX.writeFile(workbook, "country_data.xlsx");
  };

  return (
    <>
      {loading && (
        <div className="flex flex-1 justify-center items-center p-10 min-h-[400px]">
          <div
            className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] text-[#019ca2] dark:text-[#019ca2]"
            role="status"
          >
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Loading..
            </span>
          </div>
        </div>
      )}
      {!loading && (
        <div className="flex flex-col">
          <div className="-m-1.5 overflow-x-auto">
            <div className="p-1.5 min-w-full inline-block align-middle">
              <div className="overflow-hidden">
                <table className="min-w-full divide-y divide-gray-200 dark:divide-neutral-700">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-medium text-gray-500 uppercase dark:text-neutral-500 text-start flex items-center  gap-x-1"
                      >
                        Country Name
                        <span
                          className="cursor-pointer hover:underline inline-flex items-center"
                          onClick={() => handleClick("country")}
                        >
                          <svg
                            viewBox="0 0 1024 1024"
                            className="size-4"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                          >
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              <path
                                fill="currentColor"
                                d="M384 96a32 32 0 0 1 64 0v786.752a32 32 0 0 1-54.592 22.656L95.936 608a32 32 0 0 1 0-45.312h.128a32 32 0 0 1 45.184 0L384 805.632V96zm192 45.248a32 32 0 0 1 54.592-22.592L928.064 416a32 32 0 0 1 0 45.312h-.128a32 32 0 0 1-45.184 0L640 218.496V928a32 32 0 1 1-64 0V141.248z"
                              ></path>
                            </g>
                          </svg>
                        </span>
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-medium text-gray-500 uppercase dark:text-neutral-500 text-end"
                      >
                        Total Clicks{" "}
                        <span
                          className="cursor-pointer hover:underline absolute px-1"
                          style={{ alignItems: "center" }}
                          onClick={() => handleClick("totalCount")}
                        >
                          <svg
                            viewBox="0 0 1024 1024"
                            className="size-4"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="currentColor"
                          >
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              <path
                                fill="currentColor"
                                d="M384 96a32 32 0 0 1 64 0v786.752a32 32 0 0 1-54.592 22.656L95.936 608a32 32 0 0 1 0-45.312h.128a32 32 0 0 1 45.184 0L384 805.632V96zm192 45.248a32 32 0 0 1 54.592-22.592L928.064 416a32 32 0 0 1 0 45.312h-.128a32 32 0 0 1-45.184 0L640 218.496V928a32 32 0 1 1-64 0V141.248z"
                              ></path>
                            </g>
                          </svg>
                        </span>
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-medium text-gray-500 uppercase dark:text-neutral-500 text-end"
                      >
                        Unique Clicks
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-medium text-gray-500 uppercase dark:text-neutral-500 text-end"
                      >
                        Actions{" "}
                        <span
                          className="hover:underline cursor-pointer hidden"
                          id="download-country"
                          onClick={exportToExcel}
                          // onClick={() => {
                          //   sort
                          //     ? setCurrentData((prev) =>
                          //         prev
                          //           .sort((a, b) => a.totalCount - b.totalCount)
                          //           .slice(startIndex, endIndex)
                          //       )
                          //     : setCurrentData((prev) =>
                          //         prev
                          //           .sort((a, b) => b.totalCount - a.totalCount)
                          //           .slice(startIndex, endIndex)
                          //       );
                          //   setSort(!sort);
                          // }}
                        >
                          | download as .xlxs
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 dark:divide-neutral-700">
                    {currentData.map((item) => (
                      <tr
                        key={item.country}
                        className="hover:bg-gray-100 dark:hover:bg-neutral-700"
                      >
                        <td className="px-6 py-3 whitespace-nowrap text-sm font-bold text-gray-800 dark:text-neutral-200">
                          <ReactCountryFlag
                            title={item.country}
                            countryCode={item.isoCode}
                            style={{ fontSize: 1.5 + "rem" }}
                            svg
                          />
                          {"\u00a0\u00a0\u00a0"}
                          {item.country}
                        </td>
                        <td className="font-bold px-10 py-3 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200 text-end">
                          {item.totalCount}
                        </td>
                        <td className="font-bold px-10 py-3 whitespace-nowrap text-sm text-gray-800 dark:text-neutral-200 text-end">
                          {item.uniqueCount}
                        </td>
                        <td className="px-6 py-3 whitespace-nowrap text-sm text-[#019ca2] justify-end flex gap-x-2">
                          <button
                            className="hover:underline"
                            onClick={() => {
                              setModalTitle(item.country);
                              setTableViewCountry(item.country);
                              setCountryIso(item.isoCode);
                            }}
                            aria-haspopup="dialog"
                            aria-expanded="false"
                            aria-controls="hs-stacked-overlays"
                            data-hs-overlay="#hs-stacked-overlays"
                          >
                            View state wise
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                {/* Pagination buttons */}
                <div className="flex flex-1 justify-end m-2">
                  <nav
                    class="flex items-center gap-x-1"
                    aria-label="Pagination"
                  >
                    <button
                      type="button"
                      class="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-2 text-sm rounded-lg text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10"
                      aria-label="Previous"
                      onClick={() =>
                        setCurrentPage((prev) => Math.max(prev - 1, 1))
                      }
                      disabled={currentPage === 1}
                    >
                      <svg
                        class="shrink-0 size-3.5"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="m15 18-6-6 6-6"></path>
                      </svg>
                      <span class="sr-only">Previous</span>
                    </button>
                    <div class="flex items-center gap-x-1">
                      <span class="min-h-[38px] min-w-[38px] flex justify-center items-center border border-gray-200 text-gray-800 py-2 px-3 text-sm rounded-lg focus:outline-none focus:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:border-neutral-700 dark:text-white dark:focus:bg-white/10">
                        {currentPage}
                      </span>
                      <span class="min-h-[38px] flex justify-center items-center text-gray-500 py-2 px-1.5 text-sm dark:text-neutral-500">
                        of
                      </span>
                      <span class="min-h-[38px] flex justify-center items-center text-gray-500 py-2 px-1.5 text-sm dark:text-neutral-500">
                        {totalPages}
                      </span>
                    </div>
                    <button
                      type="button"
                      class="min-h-[38px] min-w-[38px] py-2 px-2.5 inline-flex justify-center items-center gap-x-2 text-sm rounded-lg text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-white/10 dark:focus:bg-white/10"
                      aria-label="Next"
                      onClick={() =>
                        setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                      }
                      disabled={currentPage === totalPages}
                    >
                      <span class="sr-only">Next</span>
                      <svg
                        class="shrink-0 size-3.5"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="m9 18 6-6-6-6"></path>
                      </svg>
                    </button>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CountryTable;
