import axios from "axios";

// Create an axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_GATEWAY_URL,
  headers: {
    "Content-Type": "application/json",
  },
  maxBodyLength: Infinity,
});

// Add a request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;

/**
 * const [isAuthenticated, setIsAuthenticated] = useState(null); // Use null to indicate loading state
  const navigate = useNavigate();

  const introspectAPI = async () => {
    const data = JSON.stringify({
      data: {
        clientName: "atz",
      },
    });
    const token = JSON.parse(localStorage.getItem("token"));
    const dataRefresh = JSON.stringify({
      data: {
        clientName: "atz",
        refreshToken: `${token.refresh_token}`,
      },
    });
    const { access_token: accessToken } = token;
    const config = {
      method: "post",
      url: "/secured/introspect",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: data,
    };
    const configRefresh = {
      method: "post",
      url: "/core/refresh-token",
      headers: {
        "Content-Type": "application/json",
      },
      data: dataRefresh,
    };

    try {
      const introspectResponse = await axiosInstance(config);
      if (introspectResponse.data.data.active) {
        setIsAuthenticated(true);
      } else {
        try {
          const responseRefresh = await axiosInstance(configRefresh);
          const token = responseRefresh.data.data;
          localStorage.setItem("token", JSON.stringify(token));
          setIsAuthenticated(true);
        } catch (e) {
          localStorage.clear();
          setIsAuthenticated(false);
        }
      }
    } catch (error) {
      //handling 401 for introspect API
      if (error.response.status === 401) {
        //get the refresh
        try {
          const refreshResponse = await axiosInstance(configRefresh);
          const token = refreshResponse.data.data;
          localStorage.setItem("token", JSON.stringify(token));
          setIsAuthenticated(true);
        } catch (e) {
          localStorage.clear();
          setIsAuthenticated(false);
        }
      } else {
        localStorage.clear();
        setIsAuthenticated(false);
      }
    }
  };
 */
