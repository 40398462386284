import React from "react";
import ReactECharts from "echarts-for-react";

const MetricsChart = ({ data }) => {
  // Extract the labels for the x-axis (e.g., "1 Oct", "2 Oct", ...)
  const labels = data.map((item) => item.label);

  // Extract the series data for each metric (linkCount, qrCodeCount, etc.)
  const linkCounts = data.map((item) => item.linkCount);
  const qrCodeCounts = data.map((item) => item.qrCodeCount);
  const domainCounts = data.map((item) => item.domainCount);
  const linkContentCounts = data.map((item) => item.linkContentCount);

  // Combine all series data into rawData for stack calculation
  const rawData = [linkCounts, qrCodeCounts, domainCounts, linkContentCounts];

  // Calculate totalData for percentage calculations
  const totalData = [];
  for (let i = 0; i < rawData[0].length; i++) {
    let sum = 0;
    for (let j = 0; j < rawData.length; j++) {
      sum += rawData[j][i];
    }
    totalData.push(sum);
  }

  // Define the series for stacked bar charts
  const series = [
    "Link Count",
    "QR Code Count",
    "Domain Count",
    "Link Content Count",
  ].map((name, idx) => {
    return {
      name,
      type: "bar",
      stack: "total",
      barWidth: "60%",
      label: {
        show: true,
        formatter: (params) =>
          totalData[params.dataIndex] > 0
            ? `${Math.round(
                (params.value / totalData[params.dataIndex]) * 100
              )}%`
            : "0%",
      },
      data: rawData[idx].map((value, i) => (totalData[i] === 0 ? 0 : value)), // Normalize by totalData
    };
  });

  // ECharts option object
  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    legend: {
      data: [
        "Link Count",
        "QR Code Count",
        "Domain Count",
        "Link Content Count",
      ],
    },
    grid: {
      left: "3%",
      right: "4%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: labels, // The x-axis labels (e.g., "1 Oct", "2 Oct", etc.)
    },
    yAxis: {
      type: "value",
    },
    series,
  };

  // Render the chart using the ReactECharts component
  return (
    <ReactECharts option={option} style={{ height: "400px", width: "100%" }} />
  );
};

export default MetricsChart;
