import React, { useEffect, useRef, useState } from "react";
import Datamap from "datamaps";

const UserDataMap = ({
  id,
  mapData,
  refreshLocation,
  startDateLocation,
  endDateLocation,
}) => {
  const mapContainerRef = useRef(null);
  const mapInstanceRef = useRef(null); // Reference to hold the Datamap instance

  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const getShade = (value) => {
    const shade = Math.floor(180 - (value % 100));
    return `rgb(${shade},${shade},${shade})`;
  };

  const dataWithShades = Object.keys(mapData).reduce((acc, key) => {
    const { value } = mapData[key].active; // Destructuring for clarity
    acc[key] = {
      ...mapData[key],
      fillColor: getShade(value),
    };
    return acc;
  }, {});

  useEffect(() => {
    if (!mapContainerRef.current) return;
    if (!mapInstanceRef.current) {
      mapInstanceRef.current = new Datamap({
        element: mapContainerRef.current,
        projection: "mercator",
        responsive: true,
        fills: {
          defaultFill: "#d1d5db",
          MAJOR: "#9ca3af",
        },
        data: dataWithShades,
        geographyConfig: {
          borderColor: "rgba(0, 0, 0, .09)",
          highlightFillColor: "#019ca2",
          highlightBorderColor: "#019ca2",
          popupTemplate: function (geo, data) {
            if (!data)
              return `<div class="text-sm p-2">No data available</div>`;

            return `<div class="bg-white rounded-xl shadow-lg w-[150px] p-3">
              <div class="flex mb-1">
                <div class="me-2">
                  <div class="size-4 rounded-full bg-no-repeat bg-center bg-cover" style="background-image: url('../node_modules/svg-country-flags/svg/${data.short}.svg')"></div>
                </div>
                <span class="text-sm font-medium">${geo.properties.name}</span>
              </div>
              <div class="flex items-center">
                <span class="text-sm text-gray-500 dark:text-neutral-500">Total clicks:</span>
                <span class="text-sm font-medium">${data.active.value}</span>
              </div>
              <div class="flex items-center">
                <span class="text-sm text-gray-500 dark:text-neutral-500">Unique clicks:</span>
                <span class="text-sm font-medium">${data.active.unique}</span>
              </div>
              
            </div>`;
          },
        },
      });

      window.addEventListener("resize", handleResize);

      return () => {
        if (mapInstanceRef.current) {
          mapInstanceRef.current.svg.remove();
          mapInstanceRef.current = null;
        }
        window.removeEventListener("resize", handleResize);
      };
    }
  }, []);

  useEffect(() => {
    if (mapInstanceRef.current) {
      mapInstanceRef.current.updateChoropleth(dataWithShades, { reset: true });
    }
  }, [dataWithShades]);

  const handleResize = () => {
    if (mapInstanceRef.current) {
      mapInstanceRef.current.resize();
    }
  };

  return (
    <>
      {loading && (
        <div className="flex flex-1 justify-center items-center p-10">
          <div
            className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] text-[#019ca2] dark:text-[#019ca2]"
            role="status"
          >
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Loading...
            </span>
          </div>
        </div>
      )}
      {message.length === 0 && !loading && (
        <div
          id="basic"
          ref={mapContainerRef}
          style={{ position: "relative", width: "100%", height: "400px" }}
        />
      )}
      {message.length > 0 && !loading && (
        <div className="flex flex-1 justify-center items-center p-10 text-sm text-[#019ca2]">
          {message}
        </div>
      )}
    </>
  );
};

export default UserDataMap;
