import React from "react";

const Grids = () => {
  return (
    <div className="max-w-6xl pb-16 px-4 sm:px-6 lg:px-8 lg:pt-10 lg:pb-24 mx-auto">
      <div className="grid gap-4 md:gap-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-y-4 md:gap-y-0 md:gap-x-8">
          <div className="flex flex-col bg-gray-50 border border-gray-200 overflow-hidden rounded-xl text-center h-full dark:bg-neutral-800 dark:border-neutral-700 z-[1]">
            <div className="p-4 md:p-8">
              <h3 className="text-lg md:text-2xl font-semibold text-gray-800 dark:text-neutral-200">
                Links
              </h3>
              <p className="mt-1 text-gray-600 dark:text-neutral-400">
                Create, manage, and monitor shortened links for streamlined
                sharing and tracking.
              </p>
            </div>
            <div className="mt-auto px-6">
              <img
                src={require("../../assets/images/home_images/links-round.png")}
                alt="Figma Tokens"
                className="shadow-lg"
              />
            </div>
          </div>
          {/* abc */}
          <div className="flex flex-col bg-gray-50 border border-gray-200 overflow-hidden rounded-xl text-center h-full dark:bg-neutral-800 dark:border-neutral-700">
            <div className="p-4 md:p-8">
              <h3 className="text-lg md:text-2xl font-semibold text-gray-800 dark:text-neutral-200">
                Branded Domains
              </h3>
              <p className="mt-1 text-gray-600 dark:text-neutral-400">
                Customize links with your brand’s URL for a seamless and
                professional online presence.
              </p>
            </div>
            <div className="mt-auto px-6">
              <img
                src={require("../../assets/images/home_images/branded-domains.png")}
                alt="Figma Tokens"
                className=" shadow-lg"
              />
            </div>
          </div>
        </div>

        <div class="flex flex-col bg-gray-50 border border-gray-200 overflow-hidden rounded-xl text-center h-full dark:bg-neutral-800 dark:border-neutral-700">
          <div class="max-w-xl mx-auto p-4 md:p-8">
            <h3 class="text-lg md:text-2xl font-semibold text-gray-800 dark:text-neutral-200">
              Atz AI
            </h3>
            <p class="mt-1 text-gray-600 dark:text-neutral-400">
              Enhances every link with smart, AI-driven insights and contextual
              understanding.
            </p>
          </div>
          {/* <img class="dark:hidden" src="" alt="Variants and Properties" /> */}
          <img
            src={require("../../assets/images/home_images/airound.png")}
            alt="Figma Tokens"
            className="px-6 shadow-lg"
          />
        </div>

        {/* <!-- Grid --> */}
        <div class="grid grid-cols-1 max-w-md gap-y-4 sm:gap-y-0 sm:gap-x-4 md:gap-x-8 mx-auto">
          {/* <!-- Card --> */}

          {/* <!-- End Card --> */}

          {/* <!-- Card --> */}
          <div class="flex flex-col bg-gray-50 border border-gray-200 overflow-hidden rounded-xl text-center h-full dark:bg-neutral-800 dark:border-neutral-700">
            <div class="p-4 md:p-8">
              <h3 class="text-lg md:text-2xl font-semibold text-gray-800 dark:text-neutral-200">
                UTM Campaigns
              </h3>
              <p class="mt-1 text-gray-600 dark:text-neutral-400">
                Track links with UTM parameters.
              </p>
            </div>
            <div class="mt-auto">
              <img
                src={require("../../assets/images/home_images/utm.png")}
                alt="Figma Tokens"
                className="px-6 shadow-lg"
              />
            </div>
          </div>
        </div>
        {/* <!-- End Card --> */}
        <div class="flex flex-col bg-gray-50 border border-gray-200 overflow-hidden rounded-xl text-center h-full dark:bg-neutral-800 dark:border-neutral-700">
          <div class="max-w-xl mx-auto p-4 md:p-8">
            <h3 class="text-lg md:text-2xl font-semibold text-gray-800 dark:text-neutral-200">
              Analytics
            </h3>
            <p class="mt-1 text-gray-600 dark:text-neutral-400">
              Maximize power efficienct with Atz through cutting-edge real-time
              analytics.
            </p>
          </div>
          {/* <img class="dark:hidden" src="" alt="Variants and Properties" /> */}
          <img
            src={require("../../assets/images/home_images/analytics1.png")}
            alt="Figma Tokens"
            className="px-6 shadow-lg"
          />
        </div>
        {/* <!-- End Grid --> */}
      </div>
    </div>
  );
};

export default Grids;
