import React from "react";

const Footer = () => {
  const d = new Date();
  return (
    <footer class="dark:bg-gradient-to-t dark:from-blue-900/10 dark:to-transparent">
      <div class="max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto">
        <div class="pb-10">
          {/* <!-- Grid --> */}
          <div class="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-6 mb-10 hidden">
            <div class="col-span-full lg:col-span-1">
              <a
                class="flex-none inline-block focus:outline-none focus:opacity-80 dark:hidden"
                href="/"
              >
                <img
                  src={require("../../assets/images/logo/logo.png")}
                  alt="logo"
                  className="w-16"
                />
              </a>
              <a
                class="flex-none inline-block focus:outline-none focus:opacity-80 hidden dark:block"
                href="/"
              >
                <img
                  src={require("../../assets/images/logo/logo_white.png")}
                  alt="logo"
                  className="w-16"
                />
              </a>
            </div>
            {/* <!-- End Col --> */}

            <div>
              <h4 class="font-semibold text-gray-800 dark:text-neutral-200">
                Preline
              </h4>

              <div class="mt-3 grid space-y-3 text-sm">
                <p>
                  <button
                    type="button"
                    class="text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 dark:text-neutral-500 dark:hover:text-neutral-300 dark:focus:text-neutral-300"
                    onclick="$crisp.push(['do', 'chat:open'])"
                  >
                    Contact Us
                  </button>
                </p>
                <p>
                  <button
                    type="button"
                    class="text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 dark:text-neutral-500 dark:hover:text-neutral-300 dark:focus:text-neutral-300"
                    onclick="$crisp.push(['do', 'chat:open'])"
                  >
                    Hire Us
                  </button>
                </p>
              </div>
            </div>
            {/* <!-- End Col --> */}

            <div>
              <h4 class="font-semibold text-gray-800 dark:text-neutral-200">
                Legal
              </h4>

              <div class="mt-3 grid space-y-3 text-sm">
                <p>
                  <a
                    class="group inline-flex items-center gap-x-1 text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 dark:text-neutral-500 dark:hover:text-neutral-300 dark:focus:text-neutral-300"
                    href="../terms.html"
                    target="_blank"
                  >
                    Terms &amp; Conditions
                    <svg
                      class="shrink-0 size-4 opacity-0 group-hover:opacity-100 group-hover:-translate-y-0.5 group-hover:translate-x-0.5 transition"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path d="M7 7h10v10" />
                      <path d="M7 17 17 7" />
                    </svg>
                  </a>
                </p>

                <p>
                  <a
                    class="group inline-flex items-center gap-x-1 text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 dark:text-neutral-500 dark:hover:text-neutral-300 dark:focus:text-neutral-300"
                    href="../privacy.html"
                    target="_blank"
                  >
                    Privacy Policy
                    <svg
                      class="shrink-0 size-4 opacity-0 group-hover:opacity-100 group-hover:-translate-y-0.5 group-hover:translate-x-0.5 transition"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path d="M7 7h10v10" />
                      <path d="M7 17 17 7" />
                    </svg>
                  </a>
                </p>

                <p>
                  <a
                    class="group inline-flex items-center gap-x-1 text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 dark:text-neutral-500 dark:hover:text-neutral-300 dark:focus:text-neutral-300"
                    href="../license.html"
                    target="_blank"
                  >
                    License
                    <svg
                      class="shrink-0 size-4 opacity-0 group-hover:opacity-100 group-hover:-translate-y-0.5 group-hover:translate-x-0.5 transition"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path d="M7 7h10v10" />
                      <path d="M7 17 17 7" />
                    </svg>
                  </a>
                </p>
              </div>
            </div>
            {/* <!-- End Col --> */}

            <div>
              <h4 class="font-semibold text-gray-800 dark:text-neutral-200">
                Resources
              </h4>

              <div class="mt-3 grid space-y-3 text-sm">
                <p>
                  <a
                    class="group inline-flex items-center gap-x-1 text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 dark:text-neutral-500 dark:hover:text-neutral-300 dark:focus:text-neutral-300"
                    href="../docs/index.html"
                    target="_blank"
                  >
                    Preline UI
                    <svg
                      class="shrink-0 size-4 opacity-0 group-hover:opacity-100 group-hover:-translate-y-0.5 group-hover:translate-x-0.5 transition"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path d="M7 7h10v10" />
                      <path d="M7 17 17 7" />
                    </svg>
                  </a>
                </p>

                <p>
                  <a
                    class="group inline-flex items-center gap-x-1 text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 dark:text-neutral-500 dark:hover:text-neutral-300 dark:focus:text-neutral-300"
                    href="../examples.html"
                    target="_blank"
                  >
                    Preline Examples
                    <svg
                      class="shrink-0 size-4 opacity-0 group-hover:opacity-100 group-hover:-translate-y-0.5 group-hover:translate-x-0.5 transition"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path d="M7 7h10v10" />
                      <path d="M7 17 17 7" />
                    </svg>
                  </a>
                </p>

                <p>
                  <a
                    class="group inline-flex items-center gap-x-1 text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 dark:text-neutral-500 dark:hover:text-neutral-300 dark:focus:text-neutral-300"
                    href="../plugins.html"
                    target="_blank"
                  >
                    Preline Plugins
                    <svg
                      class="shrink-0 size-4 opacity-0 group-hover:opacity-100 group-hover:-translate-y-0.5 group-hover:translate-x-0.5 transition"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path d="M7 7h10v10" />
                      <path d="M7 17 17 7" />
                    </svg>
                  </a>
                </p>

                <p>
                  <a
                    class="group inline-flex items-center gap-x-1 text-gray-500 hover:text-gray-900 focus:outline-none focus:text-gray-900 dark:text-neutral-500 dark:hover:text-neutral-300 dark:focus:text-neutral-300"
                    href="../figma.html"
                    target="_blank"
                  >
                    Preline Figma
                    <svg
                      class="shrink-0 size-4 opacity-0 group-hover:opacity-100 group-hover:-translate-y-0.5 group-hover:translate-x-0.5 transition"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path d="M7 7h10v10" />
                      <path d="M7 17 17 7" />
                    </svg>
                  </a>
                </p>
              </div>
            </div>
            {/* <!-- End Col --> */}
          </div>
          {/* <!-- End Grid --> */}

          <p class="text-xs sm:text-sm text-gray-600 dark:text-neutral-500">
            © {d.getFullYear()} Atz | Powered by{"\u00A0"}
            <a href="https://nidavellirs.com">
              Nidavellir Software Private Limited
            </a>
          </p>
        </div>

        <div class="bg-[radial-gradient(closest-side,#019ca2,#019ca2,transparent)] h-1"></div>
      </div>
    </footer>
    // <footer class="dark:bg-gradient-to-t dark:from-teal-900/10 dark:to-transparent">
    //   <div class="max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto">
    //     <div class="pb-10">
    //       <p class="flex text-xs sm:text-sm text-gray-600 dark:text-neutral-500 justify-center">
    //         © {d.getFullYear()} Atz | Powered by{"\u00A0"}
    //         <a href="https://nidavellirs.com"> Nidavellir</a>
    //       </p>
    //     </div>

    //     <div class="bg-[radial-gradient(closest-side,#019ca2,#019ca2,transparent)] h-1"></div>
    //   </div>
    // </footer>
  );
};

export default Footer;
