import React, { useEffect } from "react";
import Header from "./Header";
import Hero from "./Hero";
import Grids from "./Grids";
import Footer from "./Footer";
import { Router, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../instances/axiosInstance";
import { setAuthData } from "../../features/auth/authSlice";
import "preline/preline";
const Home = () => {
  const location = useLocation();

  useEffect(() => {
    // Reinitialize Preline components every time the location changes
    if (
      window.HSStaticMethods &&
      typeof window.HSStaticMethods.autoInit === "function"
    ) {
      window.HSStaticMethods.autoInit();
    }
  }, [location.pathname]);
  const { tokens } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const url =
    process.env.REACT_APP_IDP_URL +
    "/oauth2/authorize?response_type=code&client_id=atz&scope=openid&redirect_uri=" +
    process.env.REACT_APP_ATZ_CALLBACK_URL;
  const dispatch = useDispatch();

  const introspectAPI = async () => {
    const data = JSON.stringify({
      data: {
        clientName: "atz",
      },
    });
    const token = JSON.parse(localStorage.getItem("token"));
    const dataRefresh = JSON.stringify({
      data: {
        clientName: "atz",
        refreshToken: `${token.refresh_token}`,
      },
    });
    const { access_token: accessToken } = token;
    const config = {
      method: "post",
      url: "/secured/introspect",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: data,
    };
    const configRefresh = {
      method: "post",
      url: "/core/refresh-token",
      headers: {
        "Content-Type": "application/json",
      },
      data: dataRefresh,
    };

    try {
      const introspectResponse = await axiosInstance(config);
      if (introspectResponse.data.data.active) {
        localStorage.setItem("activeLink", "/dashboard");
        navigate("/dashboard");
      } else {
        try {
          const responseRefresh = await axiosInstance(configRefresh);
          const token = responseRefresh.data.data;
          localStorage.setItem("token", JSON.stringify(token));
          localStorage.setItem("activeLink", "/dashboard");
          navigate("/dashboard");
        } catch (e) {
          localStorage.clear();
          navigate("/");
        }
      }
    } catch (error) {
      //handling 401 for introspect API
      if (error.response.status === 401) {
        //get the refresh
        try {
          const refreshResponse = await axiosInstance(configRefresh);
          const token = refreshResponse.data.data;
          localStorage.setItem("token", JSON.stringify(token));
          navigate("/dashboard");
        } catch (e) {
          localStorage.clear();
          navigate("/");
        }
      } else {
        localStorage.clear();
        navigate("/");
      }
    }
  };

  if (localStorage.getItem("token")) {
    introspectAPI();
  }

  return (
    <div
      className="dark:bg-neutral-950 relative before:absolute before:top-0 before:inset-x-0 before:bg-[url('../assets/images/svg/bg-shapes-ligh.svg')] before:bg-no-repeat before:bg-top before:size-full before:-z-[1] dark:before:z-[1] dark:before:bg-[url('../assets/images/svg/bg-shapes.svg')] "
      style={{ zoom: 1 }}
    >
      <Header url={url} />
      <Hero url={url} />
      <Grids />
      <Footer />
    </div>
  );
};

export default Home;

// // dark:before:absolute dark:before:top-0 dark:before:inset-x-0 dark:before:bg-no-repeat dark:before:bg-top dark:before:size-full dark:before:-z-[1]

//relative overflow-hidden before:absolute before:top-0 before:start-1/2 before:bg-[url('../assets/images/svg/bg-shapes-ligh.svg')] dark:before:bg-[url('../assets/images/svg/bg-shapes-ligh.svg')] before:bg-no-repeat before:bg-top before:bg-cover before:size-full before:-z-[1] before:transform before:-translate-x-1/2

//dark:bg-neutral-900 relative before:absolute before:top-0 before:inset-x-0 before:bg-[url('../assets/images/svg/bg-shapes-ligh.svg')] before:bg-no-repeat before:bg-top before:size-full before:-z-[1] dark:before:bg-[url('../assets/images/svg/bg-shapes-ligh.svg')]
