import React, { useEffect, useState } from "react";
import Header from "../Landing/Header";
import { useLocation } from "react-router-dom";
import Footer1 from "../Landing/Footer1";

const BrandedDomain1 = () => {
  const location = useLocation();
  const url =
    process.env.REACT_APP_IDP_URL +
    "/oauth2/authorize?response_type=code&client_id=atz&scope=openid&redirect_uri=" +
    process.env.REACT_APP_ATZ_CALLBACK_URL;
  useEffect(() => {
    // Reinitialize Preline components every time the location changes
    if (
      window.HSStaticMethods &&
      typeof window.HSStaticMethods.autoInit === "function"
    ) {
      window.HSStaticMethods.autoInit();
    }
  }, [location.pathname]);
  return (
    <>
      <Header url={url} />
      <div className="bg-white dark:bg-neutral-950 flex flex-col flex-1  items-center justify-center lg:px-28 p-4 pt-20 lg:pt-4">
        {/* hero open*/}
        <div className="grid md:grid-cols-2 grid-cols-1 place-items-center min-h-screen">
          <div className="flex flex-col gap-y-5">
            <div>
              <span className="relative bg-[#E4F8F2]  uppercase p-2 rounded-r-full rounded-l-full text-sm font-bold text-[#00805B]">
                Branded Domains
              </span>
            </div>
            <div>
              <span className="font-black text-[40px] text-[#353535] dark:text-neutral-50">
                Atz Branded Domains: Elevate Your Brand with Custom URLs
              </span>
            </div>
            <div>
              <span className="text-xl font-medium text-[#353535] dark:text-neutral-50">
                Tired of those generic, forgettable links? Give your brand the
                spotlight with personalized, professional URLs. Branded domains
                make a statement and build trust with your audience.
              </span>
            </div>
            <div className="flex flex-col gap-y-2.5">
              <span className="text-xl font-black dark:text-neutral-50">
                Why choose branded domains?
              </span>
              <div className="flex gap-x-2.5 items-center">
                <div className="flex flex-none">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="23"
                      height="23"
                      rx="5.5"
                      fill="#D5FCC5"
                      stroke="#353535"
                    />
                    <path
                      d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                      fill="#353535"
                    />
                  </svg>
                </div>
                <div className="flex flex-1  text-base dark:text-neutral-50 text-[#353535]">
                  Boost brand recognition: Make your links instantly
                  recognizable.
                </div>
              </div>
              <div className="flex gap-x-2.5 items-center">
                <div className="flex flex-none">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="23"
                      height="23"
                      rx="5.5"
                      fill="#D5FCC5"
                      stroke="#353535"
                    />
                    <path
                      d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                      fill="#353535"
                    />
                  </svg>
                </div>
                <div className="flex flex-1  text-base dark:text-neutral-50 text-[#353535]">
                  Build trust: Show your audience you’re a serious player.
                </div>
              </div>
              <div className="flex gap-x-2.5 items-center">
                <div className="flex flex-none">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="23"
                      height="23"
                      rx="5.5"
                      fill="#D5FCC5"
                      stroke="#353535"
                    />
                    <path
                      d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                      fill="#353535"
                    />
                  </svg>
                </div>
                <div className="flex flex-1  text-base dark:text-neutral-50 text-[#353535]">
                  Enhance engagement: Make your links more memorable and
                  clickable.
                </div>
              </div>
            </div>
            <div className="flex items-center">
              <button
                className="border-2 border-[#97c4b8] text-[#00805B] rounded-r-full font-semibold text-lg rounded-l-full px-6 py-2.5 hover:drop-shadow-lg"
                onClick={() => (window.location.href = url)}
              >
                Create Your{" "}
                <span className="italic">Custom Branded Domain </span> Now!
              </button>
            </div>
          </div>
          <div>
            <img
              src={require("../../assets/landing/domainPage.webp")}
              alt="linkshorten"
              className="w-auto"
            />
          </div>
        </div>
        {/* hero close */}
      </div>

      <div className="lg:px-28 flex flex-col lg:py-10 p-4 bg-gradient-to-b dark:bg-gradient-to-b from-white to-[#F0FBF8] dark:from-neutral-950 dark:to-[#F0FBF8]/10">
        {/* features */}
        <div className="flex flex-1 lg:flex-row flex-col items-center">
          <div className="flex flex-col flex-1 gap-y-10 dark:text-white ">
            <div className="flex font-black text-5xl">
              <span className="relative text-5xl dark:text-neutral-50 font-black  ">
                Key Features of Atz Branded Domains
              </span>
            </div>
            <div class="hs-accordion-group space-y-8 min-w-full">
              <div
                class="hs-accordion active hs-accordion-active:border-l-4 hs-accordion-active:border-l-[#00805B] hs-accordion-active:pl-2"
                id="hs-basic-no-arrow-heading-one"
              >
                <button
                  class="hs-accordion-toggle hs-accordion-active:text-[#00805B] py-3 inline-flex justify-between items-center gap-x-3 w-full font-bold text-2xl  text-start text-[#0A0B1E]  hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
                  aria-expanded="true"
                  aria-controls="hs-basic-no-arrow-collapse-one"
                >
                  Compatible with UTM Campaigns
                </button>
                <div
                  id="hs-basic-no-arrow-collapse-one"
                  class="hs-accordion-content w-full overflow-hidden transition-[height] duration-300"
                  role="region"
                  aria-labelledby="hs-basic-no-arrow-heading-one"
                >
                  <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] font-medium flex flex-col">
                    <div className="flex gap-x-2.5 items-center">
                      <div className="flex flex-none">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="23"
                            height="23"
                            rx="5.5"
                            fill="#D5FCC5"
                            stroke="#353535"
                          />
                          <path
                            d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                            fill="#353535"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                        Optimize your marketing campaigns with branded URLs that
                        integrate seamlessly with UTM parameters, making it easy
                        to track performance across channels.
                      </div>
                    </div>
                  </p>
                </div>
              </div>

              <div
                class="hs-accordion hs-accordion-active:border-l-4 hs-accordion-active:border-l-[#00805B] hs-accordion-active:pl-2"
                id="hs-basic-no-arrow-heading-two"
              >
                <button
                  class="hs-accordion-toggle hs-accordion-active:text-[#00805B] py-3 inline-flex justify-between items-center gap-x-3 w-full font-bold text-2xl  text-start text-[#0A0B1E]  hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
                  aria-expanded="false"
                  aria-controls="hs-basic-no-arrow-collapse-two"
                  id="bulk-shorten"
                >
                  Bulk URL Shortening
                </button>
                <div
                  id="hs-basic-no-arrow-collapse-two"
                  class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
                  role="region"
                  aria-labelledby="hs-basic-no-arrow-heading-two"
                >
                  <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] font-medium flex flex-col">
                    <div className="flex gap-x-2.5 items-center">
                      <div className="flex flex-none">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="23"
                            height="23"
                            rx="5.5"
                            fill="#D5FCC5"
                            stroke="#353535"
                          />
                          <path
                            d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                            fill="#353535"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                        Save time and increase efficiency by shortening multiple
                        URLs in one go.
                      </div>
                    </div>
                    <div className="flex gap-x-2.5 items-center">
                      <div className="flex flex-none">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="23"
                            height="23"
                            rx="5.5"
                            fill="#D5FCC5"
                            stroke="#353535"
                          />
                          <path
                            d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                            fill="#353535"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                        Ideal for businesses managing large-scale campaigns.
                      </div>
                    </div>
                  </p>
                </div>
              </div>

              <div
                class="hs-accordion hs-accordion-active:border-l-4 hs-accordion-active:border-l-[#00805B] hs-accordion-active:pl-2"
                id="hs-basic-no-arrow-heading-three"
              >
                <button
                  class="hs-accordion-toggle hs-accordion-active:text-[#00805B] py-3 inline-flex justify-between items-center gap-x-3 w-full font-bold text-2xl  text-start text-[#0A0B1E]  hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
                  aria-expanded="false"
                  aria-controls="hs-basic-no-arrow-collapse-three"
                >
                  Advanced Analytics
                </button>
                <div
                  id="hs-basic-no-arrow-collapse-three"
                  class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
                  role="region"
                  aria-labelledby="hs-basic-no-arrow-heading-three"
                >
                  <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] font-medium flex flex-col">
                    <div className="flex gap-x-2.5 items-center">
                      <div className="flex flex-none">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="23"
                            height="23"
                            rx="5.5"
                            fill="#D5FCC5"
                            stroke="#353535"
                          />
                          <path
                            d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                            fill="#353535"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                        Track key performance metrics such as clicks,
                        geolocation, and device data to gain valuable insights
                        into your audience's behavior and optimize your
                        marketing efforts.
                      </div>
                    </div>
                  </p>
                </div>
              </div>
              <div
                class="hs-accordion hs-accordion-active:border-l-4 hs-accordion-active:border-l-[#00805B] hs-accordion-active:pl-2"
                id="hs-basic-no-arrow-heading-four"
              >
                <button
                  class="hs-accordion-toggle hs-accordion-active:text-[#00805B] py-3 inline-flex justify-between items-center gap-x-3 w-full font-bold text-2xl  text-start text-[#0A0B1E]  hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
                  aria-expanded="false"
                  aria-controls="hs-basic-no-arrow-collapse-four"
                >
                  Custom Domain Names
                </button>
                <div
                  id="hs-basic-no-arrow-collapse-four"
                  class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
                  role="region"
                  aria-labelledby="hs-basic-no-arrow-heading-four"
                >
                  <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] font-medium flex flex-col">
                    <div className="flex gap-x-2.5 items-center">
                      <div className="flex flex-none">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="23"
                            height="23"
                            rx="5.5"
                            fill="#D5FCC5"
                            stroke="#353535"
                          />
                          <path
                            d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                            fill="#353535"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                        Enhance your brand identity with custom domain names
                        that reflect your business and resonate with your target
                        audience.
                      </div>
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <img
              src={require("../../assets/landing/customdomains.png")}
              alt="linkshorten"
              className="w-auto"
            />
          </div>
        </div>
      </div>
      {/* features close */}
      {/* benefits */}
      <div className="grid grid-cols-1 gap-y-10 lg:px-28 py-10 p-4 bg-white dark:bg-neutral-950">
        <div className="flex flex-col w-auto rounded-[50px] bg-gradient-to-tr from-[#F0FBF8]  to-[#6482AD] p-1">
          <div className="flex flex-1 lg:flex-row flex-col bg-white dark:bg-neutral-950 rounded-[50px]  p-10 items-center text-center">
            <div className="flex flex-col gap-y-5">
              <div>
                <div className="font-black text-[40px] text-[#353535] dark:text-neutral-50 ">
                  What are{" "}
                  <span className="text-[#6482AD]">Branded Domains</span> ?
                </div>
              </div>
              <div className="flex flex-col gap-y-2.5">
                <div className="flex gap-x-2.5 items-center">
                  <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                    Branded domains allow you to customize your shortened URLs
                    to reflect your business name, creating a cohesive and
                    professional online presence. Instead of using generic
                    links, branded domains help you build credibility and ensure
                    your brand is consistently presented across all digital
                    platforms.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-auto rounded-[50px]">
          <div className="flex flex-1 lg:flex-row flex-col bg-[#C4E0FD] rounded-[50px] bg-opacity-30 p-10 items-center">
            <div className="flex flex-col flex-1 gap-y-5">
              <div className="flex flex-1 justify-center">
                <div className="font-black text-[40px] text-[#353535] dark:text-neutral-50 ">
                  Why Choose Branded Domains?
                </div>
              </div>
              <div className="flex flex-col gap-y-2.5">
                <div className="flex gap-x-2.5 items-center">
                  <div className="flex flex-none">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="5.5"
                        fill="#3F9DFF"
                        stroke="#353535"
                      />
                      <path
                        d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                    Strengthen Brand Identity : Custom URLs reinforce your brand
                    presence across all digital platforms, ensuring your
                    audience instantly recognizes your business.
                  </div>
                </div>
                <div className="flex gap-x-2.5 items-center">
                  <div className="flex flex-none">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="5.5"
                        fill="#3F9DFF"
                        stroke="#353535"
                      />
                      <path
                        d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                    Build Trust: Branded links appear more credible to users,
                    leading to higher click-through rates and improved customer
                    relationships.
                  </div>
                </div>
                <div className="flex gap-x-2.5 items-center">
                  <div className="flex flex-none">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="5.5"
                        fill="#3F9DFF"
                        stroke="#353535"
                      />
                      <path
                        d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                    Improve Marketing Efficiency: Easily manage and track
                    branded links, giving you deeper insights into campaign
                    performance and helping you make data-driven decisions.
                  </div>
                </div>
                <div className="flex gap-x-2.5 items-center">
                  <div className="flex flex-none">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="5.5"
                        fill="#3F9DFF"
                        stroke="#353535"
                      />
                      <path
                        d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                    Effortless Updates: With dynamic QR codes, you can easily
                    change the destination URL without reprinting or reissuing
                    the QR code.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-auto rounded-[50px]">
          <div className="flex flex-1 lg:flex-row flex-col bg-[#cffdc4] rounded-[50px] bg-opacity-30 p-10 items-center">
            <div className="flex flex-col flex-1 gap-y-5">
              <div className="flex flex-1 justify-center">
                <div className="font-black text-[40px] text-[#353535] dark:text-neutral-50 ">
                  How Atz Branded Domains Work?
                </div>
              </div>
              <div className="flex flex-col gap-y-2.5">
                <div className="flex gap-x-2.5 items-center">
                  <div className="flex flex-none">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="5.5"
                        fill="#D5FCC5"
                        stroke="#353535"
                      />
                      <path
                        d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                        fill="#353535"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                    Choose Your Domain: Select a custom domain that reflects
                    your brand identity and aligns with your marketing strategy.
                  </div>
                </div>
                <div className="flex gap-x-2.5 items-center">
                  <div className="flex flex-none">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="5.5"
                        fill="#D5FCC5"
                        stroke="#353535"
                      />
                      <path
                        d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                        fill="#353535"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                    Seamless Integration: With minimal setup, connect your
                    branded domain to your shortened URLs and start using custom
                    links immediately.
                  </div>
                </div>
                <div className="flex gap-x-2.5 items-center">
                  <div className="flex flex-none">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="5.5"
                        fill="#D5FCC5"
                        stroke="#353535"
                      />
                      <path
                        d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                        fill="#353535"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                    Start Sharing: Use your branded URLs across all marketing
                    channels to boost consistency, trust, and recognition in
                    your brand.
                  </div>
                </div>
                <div className="flex gap-x-2.5 items-center">
                  <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                    <a href={url} className="hover:underline">
                      {" "}
                      Get Started with Atz Branded Domains Today!
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-auto rounded-[50px]">
          <div className="flex flex-1 lg:flex-row flex-col bg-[#fdd6c4] rounded-[50px] bg-opacity-30 p-10 items-center">
            <div className="flex flex-col flex-1 gap-y-5">
              <div className="flex flex-1 justify-center">
                <div className="font-black text-[40px] text-[#353535] dark:text-neutral-50 ">
                  How to Start Using Atz Branded Domain?
                </div>
              </div>
              <div className="flex flex-col gap-y-2.5">
                <div className="flex gap-x-2.5 items-center">
                  <div className="flex flex-none">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="5.5"
                        fill="#FF915E"
                        stroke="white"
                      />
                      <path
                        d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                    Sign Up: Create an account and choose a plan that fits your
                    business needs, whether you're a small business or a large
                    enterprise.
                  </div>
                </div>
                <div className="flex gap-x-2.5 items-center">
                  <div className="flex flex-none">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="5.5"
                        fill="#FF915E"
                        stroke="white"
                      />
                      <path
                        d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                    Set Up Your Domain: Follow our step-by-step guide to
                    configure your branded domain, ensuring a smooth
                    integration.
                  </div>
                </div>
                <div className="flex gap-x-2.5 items-center">
                  <div className="flex flex-none">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="5.5"
                        fill="#FF915E"
                        stroke="white"
                      />
                      <path
                        d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                    Start Branding: Begin using branded URLs across your digital
                    marketing efforts to elevate your brand’s visibility and
                    credibility.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col w-auto rounded-[50px]">
          <div className="flex flex-1 lg:flex-row flex-col bg-[#d8d8d8] rounded-[50px] bg-opacity-30 p-10 items-center text-center">
            <div className="flex flex-col gap-y-5">
              <div>
                <div className="font-black text-[40px] text-[#353535] dark:text-neutral-50 ">
                  Enhance Your Digital Strategy with Atz Branded Domains
                </div>
              </div>
              <div className="flex flex-col gap-y-2.5">
                <div className="flex gap-x-2.5 items-center">
                  <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                    Join thousands of businesses using Atz to create custom,
                    branded URLs that elevate their digital marketing efforts.
                    Start today and watch your engagement grow.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* benefits close*/}

      <div className="min-h-96 md:px-28 md:py-10 p-4 pb-12 flex flex-col items-center bg-white dark:bg-neutral-950 gap-y-8">
        <span className="relative text-5xl dark:text-neutral-50 font-black text-center ">
          FAQs
          <img
            src={require("../../assets/landing/Vector3.png")}
            className="absolute inset-0"
          />
        </span>
        <div class="hs-accordion-group space-y-6">
          <div
            class="hs-accordion  bg-white border border-transparent rounded-xl dark:bg-neutral-800 dark:border-transparent drop-shadow-lg rounded-r-[24.06px] rounded-l-[24.06px]"
            id="hs-active-bordered-heading-one"
          >
            <button
              class="hs-accordion-toggle inline-flex justify-between items-center gap-x-3 w-full font-semibold text-2xl  text-start text-[#0A0B1E] py-7 px-6 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
              aria-expanded="false"
              aria-controls="hs-basic-active-bordered-collapse-one"
            >
              How do branded domains help my business?
              <svg
                class="hs-accordion-active:hidden block size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5v14"></path>
              </svg>
              <svg
                class="hs-accordion-active:block hidden size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
              </svg>
            </button>
            <div
              id="hs-basic-active-bordered-collapse-one"
              class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
              role="region"
              aria-labelledby="hs-active-bordered-heading-one"
            >
              <div class="pb-4 px-5">
                <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] ">
                  Branded domains help build trust with your audience by
                  displaying your business name in every URL, improving
                  click-through rates and engagement.
                </p>
              </div>
            </div>
          </div>

          <div
            class="hs-accordion  bg-white border border-transparent rounded-xl dark:bg-neutral-800 dark:border-transparent drop-shadow-lg rounded-r-[24.06px] rounded-l-[24.06px]"
            id="hs-active-bordered-heading-two"
          >
            <button
              class="hs-accordion-toggle inline-flex justify-between items-center gap-x-3 w-full font-semibold text-2xl  text-start text-[#0A0B1E] py-7 px-6 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
              aria-expanded="true"
              aria-controls="hs-basic-active-bordered-collapse-two"
            >
              Can I use branded domains with UTM tracking?
              <svg
                class="hs-accordion-active:hidden block size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5v14"></path>
              </svg>
              <svg
                class="hs-accordion-active:block hidden size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
              </svg>
            </button>
            <div
              id="hs-basic-active-bordered-collapse-two"
              class="hs-accordion-content w-full overflow-hidden transition-[height] duration-300"
              role="region"
              aria-labelledby="hs-active-bordered-heading-two"
            >
              <div class="pb-4 px-5">
                <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] ">
                  Yes! Atz Branded Domains seamlessly integrates with UTM
                  parameters, making it easy to track and measure the success of
                  your campaigns.
                </p>
              </div>
            </div>
          </div>

          <div
            class="hs-accordion  bg-white border border-transparent rounded-xl dark:bg-neutral-800 dark:border-transparent drop-shadow-xl rounded-r-[24.06px] rounded-l-[24.06px]"
            id="hs-active-bordered-heading-three"
          >
            <button
              class="hs-accordion-toggle inline-flex justify-between items-center gap-x-3 w-full font-semibold text-2xl  text-start text-[#0A0B1E] py-7 px-6 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
              aria-expanded="false"
              aria-controls="hs-basic-active-bordered-collapse-three"
            >
              Is it difficult to set up a custom domain?
              <svg
                class="hs-accordion-active:hidden block size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5v14"></path>
              </svg>
              <svg
                class="hs-accordion-active:block hidden size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
              </svg>
            </button>
            <div
              id="hs-basic-active-bordered-collapse-three"
              class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
              role="region"
              aria-labelledby="hs-active-bordered-heading-three"
            >
              <div class="pb-4 px-5">
                <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] ">
                  Not at all! Atz provides a simple, step-by-step guide to help
                  you configure your custom domain in minutes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer1 />
    </>
  );
};

export default BrandedDomain1;
