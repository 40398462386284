import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sidebarVisible: null,
  modalVisible: false,
  theme: localStorage.getItem("theme"),
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setUiData: (state, action) => {
      const { sidebarVisible, theme, modalVisible } = action.payload;
      if (sidebarVisible !== undefined) {
        state.sidebarVisible = sidebarVisible;
      }
      if (theme) state.theme = theme;
      if (modalVisible) state.modalVisible = modalVisible;
    },
  },
});

export const { setUiData } = uiSlice.actions;

export default uiSlice.reducer;
