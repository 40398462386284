import React from "react";

const SmallLoader = ({ ai }) => {
  return (
    <>
      {/* <div className="flex h-[500px] pt-72 justify-center items-center">
        <iframe src="tmp.html"></iframe>
      </div> */}
      <div className="relative flex justify-center items-center">
        <div className="dark:hidden">
          <div className="relative inline-block">
            <img
              src={require("../../assets/images/logo/logo.png")}
              alt="logo"
              className="block w-10 h-4 animate-pulse"
            />
            <div className="absolute top-1/2 left-1/2 w-20 h-20 -mt-10 -ml-10 border-4 border-t-4 border-gray-200 border-t-[#019ca2] rounded-full animate-spin"></div>
          </div>
        </div>
        <div className="hidden dark:block">
          <div className="relative inline-block">
            <img
              src={require("../../assets/images/logo/logo_white.png")}
              alt="logo"
              className="block w-10 h-4 animate-pulse"
            />
            <div className="absolute top-1/2 left-1/2 w-20 h-20 -mt-10 -ml-10 border-4 border-t-4 border-gray-200 border-t-[#019ca2] rounded-full animate-spin"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SmallLoader;
