import React, { useEffect, useState } from "react";
import Header from "../Landing/Header";
import { useLocation } from "react-router-dom";
import Footer1 from "../Landing/Footer1";
import HubSpotComponent from "../HubSpotComponent";

const AtzAi1 = () => {
  const url =
    process.env.REACT_APP_IDP_URL +
    "/oauth2/authorize?response_type=code&client_id=atz&scope=openid&redirect_uri=" +
    process.env.REACT_APP_ATZ_CALLBACK_URL;
  const location = useLocation();
  useEffect(() => {
    // Reinitialize Preline components every time the location changes
    if (
      window.HSStaticMethods &&
      typeof window.HSStaticMethods.autoInit === "function"
    ) {
      window.HSStaticMethods.autoInit();
    }
  }, [location.pathname]);
  return (
    <>
      <Header url={url} />
      <div className="bg-white dark:bg-neutral-950 flex flex-col flex-1  items-center justify-center lg:px-28 p-4 pt-20 lg:pt-4">
        {/* hero open*/}
        <div className="grid grid-cols-1 md:grid-cols-2 place-items-center min-h-screen">
          <div className="flex flex-col gap-y-3">
            <div className="">
              <span className="p-[2px]  border w-auto bg-gradient-to-r from-[#144EE3] via-[#EB568E] via-[#A353AA] to-[#144EE3] rounded-r-full rounded-l-full">
                <span className="bg-white dark:bg-neutral-950  px-4 rounded-r-full rounded-l-full text-sm font-bold text-[#9966FF]">
                  <span className="inline-flex text-sm font-bold items-center  bg-clip-text bg-gradient-to-l from-[#144EE3] via-[#EB568E] via-[#A353AA] to-[#144EE3] text-transparent p-0">
                    Atz AI
                  </span>
                </span>
              </span>
            </div>
            <div>
              <span className="inline-flex text-[40px] font-black items-center gap-x-1.5 bg-clip-text bg-gradient-to-l from-[#144EE3] via-[#EB568E] via-[#A353AA] to-[#144EE3] text-transparent">
                Atz: AI for Marketing
              </span>
            </div>
            <div>
              <span className="text-xl font-medium text-[#353535] dark:text-neutral-50">
                Tired of guessing what's working in your marketing? Let AI be
                your sidekick. Atz harnesses the power of AI to give you
                insights and tools that make your marketing smarter, faster, and
                more effective.
              </span>
            </div>
            <div className="flex flex-col gap-y-2.5">
              <span className="text-xl font-black dark:text-neutral-50">
                Key Features Of Atz AI Analytics:
              </span>
              <div className="flex gap-x-2.5 items-center">
                <div className="flex flex-none">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="23"
                      height="23"
                      rx="5.5"
                      fill="url(#paint0_linear_1867_1372)"
                      stroke="#353535"
                    />
                    <path
                      d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                      fill="white"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_1867_1372"
                        x1="24"
                        y1="12"
                        x2="0"
                        y2="12"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#9966FF" />
                        <stop offset="1" stop-color="#00CCFF" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div className="flex flex-1  text-base dark:text-neutral-50 text-[#353535]">
                  Branded links: Make a lasting impression with custom-branded
                  URLs.
                </div>
              </div>
              <div className="flex gap-x-2.5 items-center">
                <div className="flex flex-none">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="23"
                      height="23"
                      rx="5.5"
                      fill="url(#paint0_linear_1867_1372)"
                      stroke="#353535"
                    />
                    <path
                      d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                      fill="white"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_1867_1372"
                        x1="24"
                        y1="12"
                        x2="0"
                        y2="12"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#9966FF" />
                        <stop offset="1" stop-color="#00CCFF" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div className="flex flex-1  text-base dark:text-neutral-50 text-[#353535]">
                  Track it all: Monitor clicks, user behavior, and geographic
                  data.
                </div>
              </div>
              <div className="flex gap-x-2.5 items-center">
                <div className="flex flex-none">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="23"
                      height="23"
                      rx="5.5"
                      fill="url(#paint0_linear_1867_1372)"
                      stroke="#353535"
                    />
                    <path
                      d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                      fill="white"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_1867_1372"
                        x1="24"
                        y1="12"
                        x2="0"
                        y2="12"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#9966FF" />
                        <stop offset="1" stop-color="#00CCFF" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div className="flex flex-1  text-base dark:text-neutral-50 text-[#353535]">
                  AI-crafted messages: Personalized content that resonates.
                </div>
              </div>
              <div className="flex gap-x-2.5 items-center">
                <div className="flex flex-none">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="0.5"
                      width="23"
                      height="23"
                      rx="5.5"
                      fill="url(#paint0_linear_1867_1372)"
                      stroke="#353535"
                    />
                    <path
                      d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                      fill="white"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_1867_1372"
                        x1="24"
                        y1="12"
                        x2="0"
                        y2="12"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stop-color="#9966FF" />
                        <stop offset="1" stop-color="#00CCFF" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
                <div className="flex flex-1  text-base dark:text-neutral-50 text-[#353535]">
                  Optimized timing: Send messages when your audience is most
                  active.
                </div>
              </div>
              <div className="flex gap-x-2.5 items-center">
                <div className="flex flex-1  text-base dark:text-neutral-50 text-[#353535]">
                  Ready to level up your marketing? Atz is your AI-powered
                  solution.
                </div>
              </div>
            </div>
            <div className="flex items-center">
              <button
                className="border-2 border-[#EEEEEE] text-[#9966FF] rounded-r-full font-semibold text-lg rounded-l-full px-6 py-2.5 hover:drop-shadow-lg hover:bg-gradient-to-r hover:from-[#144EE3] hover:via-[#EB568E] hover:via-[#A353AA] hover:to-[#144EE3] hover:text-white transition-all"
                onClick={() => (window.location.href = url)}
              >
                <span className="italic">Optimize Your Campaigns Now!</span>
              </button>
            </div>
          </div>
          <div>
            <img
              src={require("../../assets/landing/aiPage.webp")}
              alt="linkshorten"
              className="w-auto"
            />
          </div>
        </div>
        {/* hero close */}
      </div>
      <div className="bg-gradient-to-r from-[#9966FF] to-[#00CCFF]  lg:px-28 flex flex-col items-center lg:py-10 p-4">
        {/* benefits */}
        <div className="flex flex-col flex-1 justify-center gap-y-10">
          <div className="flex justify-center items-center dark:text-white font-bold text-2xl">
            <span className="text-center text-white">
              Harness the power of AI to drive engagement, improve conversions,
              and make your campaigns smarter.{" "}
              <a href={url} className="underline">
                Sign up
              </a>{" "}
              for Atz today and unlock the full potential of AI-powered
              analytics for your business.
            </span>
          </div>
        </div>
        {/* benefits close */}
      </div>
      <div className="lg:px-28 flex flex-col lg:py-10 p-4 bg-gradient-to-b dark:bg-gradient-to-b to-white from-[#EEEEEE] dark:to-neutral-950 dark:from-[#9966FF]/20">
        {/* features */}
        <div className="flex flex-1 lg:flex-row flex-col items-center">
          <div className="flex flex-col flex-1 gap-y-10 dark:text-white ">
            <div className="flex font-black text-5xl">
              <span className="relative text-5xl dark:text-neutral-50 font-black  ">
                Optimize Your Campaigns with Data-Driven Insights
              </span>
            </div>
            <div class="hs-accordion-group space-y-8 min-w-full">
              <div
                class="hs-accordion active hs-accordion-active:border-l-4 hs-accordion-active:border-l-[#9966FF] hs-accordion-active:pl-2"
                id="hs-basic-no-arrow-heading-one"
              >
                <button
                  class="hs-accordion-toggle hs-accordion-active:text-[#9966FF] py-3 inline-flex justify-between items-center gap-x-3 w-full font-bold text-2xl  text-start text-[#0A0B1E]  hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
                  aria-expanded="true"
                  aria-controls="hs-basic-no-arrow-collapse-one"
                >
                  Total Clicks & Unique Clicks
                </button>
                <div
                  id="hs-basic-no-arrow-collapse-one"
                  class="hs-accordion-content w-full overflow-hidden transition-[height] duration-300"
                  role="region"
                  aria-labelledby="hs-basic-no-arrow-heading-one"
                >
                  <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] font-medium">
                    <div className="flex flex-col gap-y-1">
                      <div className="flex gap-x-2.5 items-center">
                        <div className="flex flex-none">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="0.5"
                              y="0.5"
                              width="23"
                              height="23"
                              rx="5.5"
                              fill="url(#paint0_linear_1867_1372)"
                              stroke="#353535"
                            />
                            <path
                              d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                              fill="white"
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_1867_1372"
                                x1="24"
                                y1="12"
                                x2="0"
                                y2="12"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stop-color="#9966FF" />
                                <stop offset="1" stop-color="#00CCFF" />
                              </linearGradient>
                            </defs>
                          </svg>
                        </div>
                        <div className="flex flex-1  text-base dark:text-neutral-50 text-[#353535]">
                          Measure the overall success of your campaigns by
                          tracking the total and unique clicks on your links.
                        </div>
                      </div>
                      <div className="flex gap-x-2.5 items-center">
                        <div className="flex flex-none">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="0.5"
                              y="0.5"
                              width="23"
                              height="23"
                              rx="5.5"
                              fill="url(#paint0_linear_1867_1372)"
                              stroke="#353535"
                            />
                            <path
                              d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                              fill="white"
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_1867_1372"
                                x1="24"
                                y1="12"
                                x2="0"
                                y2="12"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stop-color="#9966FF" />
                                <stop offset="1" stop-color="#00CCFF" />
                              </linearGradient>
                            </defs>
                          </svg>
                        </div>
                        <div className="flex flex-1  text-base dark:text-neutral-50 text-[#353535]">
                          Track clicks and performance in real-time to
                          understand your campaign’s success and make
                          data-driven decisions.
                        </div>
                      </div>
                    </div>
                  </p>
                </div>
              </div>

              <div
                class="hs-accordion hs-accordion-active:border-l-4 hs-accordion-active:border-l-[#9966FF] hs-accordion-active:pl-2"
                id="hs-basic-no-arrow-heading-two"
              >
                <button
                  class="hs-accordion-toggle hs-accordion-active:text-[#9966FF] py-3 inline-flex justify-between items-center gap-x-3 w-full font-bold text-2xl  text-start text-[#0A0B1E]  hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
                  aria-expanded="false"
                  aria-controls="hs-basic-no-arrow-collapse-two"
                >
                  Geographic Data & Click Location
                </button>
                <div
                  id="hs-basic-no-arrow-collapse-two"
                  class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
                  role="region"
                  aria-labelledby="hs-basic-no-arrow-heading-two"
                >
                  <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] font-medium">
                    <div className="flex flex-col gap-y-1">
                      <div className="flex gap-x-2.5 items-center">
                        <div className="flex flex-none">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="0.5"
                              y="0.5"
                              width="23"
                              height="23"
                              rx="5.5"
                              fill="url(#paint0_linear_1867_1372)"
                              stroke="#353535"
                            />
                            <path
                              d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                              fill="white"
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_1867_1372"
                                x1="24"
                                y1="12"
                                x2="0"
                                y2="12"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stop-color="#9966FF" />
                                <stop offset="1" stop-color="#00CCFF" />
                              </linearGradient>
                            </defs>
                          </svg>
                        </div>
                        <div className="flex flex-1  text-base dark:text-neutral-50 text-[#353535]">
                          Identify the regions and cities where your campaigns
                          perform best, helping you to tailor your marketing
                          efforts to key markets.
                        </div>
                      </div>
                      <div className="flex gap-x-2.5 items-center">
                        <div className="flex flex-none">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="0.5"
                              y="0.5"
                              width="23"
                              height="23"
                              rx="5.5"
                              fill="url(#paint0_linear_1867_1372)"
                              stroke="#353535"
                            />
                            <path
                              d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                              fill="white"
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_1867_1372"
                                x1="24"
                                y1="12"
                                x2="0"
                                y2="12"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stop-color="#9966FF" />
                                <stop offset="1" stop-color="#00CCFF" />
                              </linearGradient>
                            </defs>
                          </svg>
                        </div>
                        <div className="flex flex-1  text-base dark:text-neutral-50 text-[#353535]">
                          View detailed data on clicks from different states,
                          regions, and cities to fine-tune your localized
                          marketing strategies.
                        </div>
                      </div>
                    </div>
                  </p>
                </div>
              </div>

              <div
                class="hs-accordion hs-accordion-active:border-l-4 hs-accordion-active:border-l-[#9966FF] hs-accordion-active:pl-2"
                id="hs-basic-no-arrow-heading-three"
              >
                <button
                  class="hs-accordion-toggle hs-accordion-active:text-[#9966FF] py-3 inline-flex justify-between items-center gap-x-3 w-full font-bold text-2xl  text-start text-[#0A0B1E]  hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
                  aria-expanded="false"
                  aria-controls="hs-basic-no-arrow-collapse-three"
                >
                  Device & Browser Usage
                </button>
                <div
                  id="hs-basic-no-arrow-collapse-three"
                  class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
                  role="region"
                  aria-labelledby="hs-basic-no-arrow-heading-three"
                >
                  <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] font-medium">
                    <div className="flex flex-col gap-y-1">
                      <div className="flex gap-x-2.5 items-center">
                        <div className="flex flex-none">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="0.5"
                              y="0.5"
                              width="23"
                              height="23"
                              rx="5.5"
                              fill="url(#paint0_linear_1867_1372)"
                              stroke="#353535"
                            />
                            <path
                              d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                              fill="white"
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_1867_1372"
                                x1="24"
                                y1="12"
                                x2="0"
                                y2="12"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stop-color="#9966FF" />
                                <stop offset="1" stop-color="#00CCFF" />
                              </linearGradient>
                            </defs>
                          </svg>
                        </div>
                        <div className="flex flex-1  text-base dark:text-neutral-50 text-[#353535]">
                          Gain insight into which devices and browsers your
                          audience is using to interact with your content,
                          allowing you to optimize user experiences across
                          platforms.
                        </div>
                      </div>
                      <div className="flex gap-x-2.5 items-center">
                        <div className="flex flex-none">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="0.5"
                              y="0.5"
                              width="23"
                              height="23"
                              rx="5.5"
                              fill="url(#paint0_linear_1867_1372)"
                              stroke="#353535"
                            />
                            <path
                              d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                              fill="white"
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_1867_1372"
                                x1="24"
                                y1="12"
                                x2="0"
                                y2="12"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stop-color="#9966FF" />
                                <stop offset="1" stop-color="#00CCFF" />
                              </linearGradient>
                            </defs>
                          </svg>
                        </div>
                        <div className="flex flex-1  text-base dark:text-neutral-50 text-[#353535]">
                          Atz helps you understand which devices and browsers
                          your users prefer, so you can optimize their
                          experience accordingly.
                        </div>
                      </div>
                    </div>
                  </p>
                </div>
              </div>
              <div
                class="hs-accordion hs-accordion-active:border-l-4 hs-accordion-active:border-l-[#9966FF] hs-accordion-active:pl-2"
                id="hs-basic-no-arrow-heading-four"
              >
                <button
                  class="hs-accordion-toggle hs-accordion-active:text-[#9966FF] py-3 inline-flex justify-between items-center gap-x-3 w-full font-bold text-2xl  text-start text-[#0A0B1E]  hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
                  aria-expanded="false"
                  aria-controls="hs-basic-no-arrow-collapse-four"
                >
                  Time-Based Engagement Metrics
                </button>
                <div
                  id="hs-basic-no-arrow-collapse-four"
                  class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
                  role="region"
                  aria-labelledby="hs-basic-no-arrow-heading-four"
                >
                  <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] font-medium">
                    <div className="flex flex-col gap-y-1">
                      <div className="flex gap-x-2.5 items-center">
                        <div className="flex flex-none">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="0.5"
                              y="0.5"
                              width="23"
                              height="23"
                              rx="5.5"
                              fill="url(#paint0_linear_1867_1372)"
                              stroke="#353535"
                            />
                            <path
                              d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                              fill="white"
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_1867_1372"
                                x1="24"
                                y1="12"
                                x2="0"
                                y2="12"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stop-color="#9966FF" />
                                <stop offset="1" stop-color="#00CCFF" />
                              </linearGradient>
                            </defs>
                          </svg>
                        </div>
                        <div className="flex flex-1  text-base dark:text-neutral-50 text-[#353535]">
                          Review click data by time of day, helping you identify
                          peak engagement periods and adjust your strategy
                          accordingly.
                        </div>
                      </div>
                      <div className="flex gap-x-2.5 items-center">
                        <div className="flex flex-none">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <rect
                              x="0.5"
                              y="0.5"
                              width="23"
                              height="23"
                              rx="5.5"
                              fill="url(#paint0_linear_1867_1372)"
                              stroke="#353535"
                            />
                            <path
                              d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                              fill="white"
                            />
                            <defs>
                              <linearGradient
                                id="paint0_linear_1867_1372"
                                x1="24"
                                y1="12"
                                x2="0"
                                y2="12"
                                gradientUnits="userSpaceOnUse"
                              >
                                <stop stop-color="#9966FF" />
                                <stop offset="1" stop-color="#00CCFF" />
                              </linearGradient>
                            </defs>
                          </svg>
                        </div>
                        <div className="flex flex-1  text-base dark:text-neutral-50 text-[#353535]">
                          Know exactly when your users are most active and
                          ensure your content reaches them at the optimal time
                          for maximum impact.
                        </div>
                      </div>
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <img
              src={require("../../assets/landing/atzAi.png")}
              alt="linkshorten"
              className="w-auto"
            />
          </div>
        </div>
      </div>
      {/* features close */}
      {/* benefits */}
      <div className="grid grid-cols-1 gap-y-6 lg:px-28 py-10 p-4 bg-white dark:bg-neutral-950">
        <div className="flex flex-col w-auto rounded-[50px]">
          <div className="flex flex-1 lg:flex-row flex-col bg-[#C4E0FD] rounded-[50px] bg-opacity-30 p-10 items-center">
            <div className="flex flex-col flex-1 gap-y-5">
              <div className="flex flex-1 justify-center">
                <div className="font-black text-[40px] text-[#353535] dark:text-neutral-50 ">
                  How Atz AI Helps You Succeed ?
                </div>
              </div>
              <div className="flex flex-col gap-y-2.5">
                <div className="flex gap-x-2.5 items-center">
                  <div className="flex flex-none">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="5.5"
                        fill="url(#paint0_linear_1867_1372)"
                        stroke="#353535"
                      />
                      <path
                        d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                        fill="white"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_1867_1372"
                          x1="24"
                          y1="12"
                          x2="0"
                          y2="12"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#9966FF" />
                          <stop offset="1" stop-color="#00CCFF" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                    Analyze Performance in Real-Time: Get instant insights into
                    how your campaigns are performing, from click-through rates
                    to user demographics, so you can make data-driven
                    adjustments on the fly.
                  </div>
                </div>
                <div className="flex gap-x-2.5 items-center">
                  <div className="flex flex-none">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="5.5"
                        fill="url(#paint0_linear_1867_1372)"
                        stroke="#353535"
                      />
                      <path
                        d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                        fill="white"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_1867_1372"
                          x1="24"
                          y1="12"
                          x2="0"
                          y2="12"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#9966FF" />
                          <stop offset="1" stop-color="#00CCFF" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                    Automate Personalized Messaging: Let AtZ’s AI take the
                    guesswork out of your communication. Our platform
                    automatically generates and schedules personalized messages
                    to keep your audience engaged and your campaigns running
                    smoothly.
                  </div>
                </div>
                <div className="flex gap-x-2.5 items-center">
                  <div className="flex flex-none">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="5.5"
                        fill="url(#paint0_linear_1867_1372)"
                        stroke="#353535"
                      />
                      <path
                        d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                        fill="white"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_1867_1372"
                          x1="24"
                          y1="12"
                          x2="0"
                          y2="12"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#9966FF" />
                          <stop offset="1" stop-color="#00CCFF" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                  <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                    Refine Your Strategy with Advanced Analytics: With Atz, you
                    don’t just track your success—you understand it. Our
                    comprehensive analytics platform gives you the insights you
                    need to fine-tune your strategy and achieve better results.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* benefits close*/}

      <div className="min-h-96 md:px-28 md:py-10 p-4 pb-12 flex flex-col items-center bg-white dark:bg-neutral-950 gap-y-8">
        <span className="relative text-5xl dark:text-neutral-50 font-black text-center ">
          FAQs
          <img
            src={require("../../assets/landing/Vector3.png")}
            className="absolute inset-0"
          />
        </span>
        <div class="hs-accordion-group space-y-6">
          <div
            class="hs-accordion  bg-white border border-transparent rounded-xl dark:bg-neutral-800 dark:border-transparent drop-shadow-lg rounded-r-[24.06px] rounded-l-[24.06px]"
            id="hs-active-bordered-heading-one"
          >
            <button
              class="hs-accordion-toggle inline-flex justify-between items-center gap-x-3 w-full font-semibold text-2xl  text-start text-[#0A0B1E] py-7 px-6 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
              aria-expanded="false"
              aria-controls="hs-basic-active-bordered-collapse-one"
            >
              How does AI optimize my campaigns?
              <svg
                class="hs-accordion-active:hidden block size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5v14"></path>
              </svg>
              <svg
                class="hs-accordion-active:block hidden size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
              </svg>
            </button>
            <div
              id="hs-basic-active-bordered-collapse-one"
              class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
              role="region"
              aria-labelledby="hs-active-bordered-heading-one"
            >
              <div class="pb-4 px-5">
                <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] ">
                  AtZ’s AI analyzes user behavior and engagement patterns to
                  automatically schedule messages, create personalized content,
                  and provide actionable insights into your campaign
                  performance.
                </p>
              </div>
            </div>
          </div>

          <div
            class="hs-accordion  bg-white border border-transparent rounded-xl dark:bg-neutral-800 dark:border-transparent drop-shadow-lg rounded-r-[24.06px] rounded-l-[24.06px]"
            id="hs-active-bordered-heading-two"
          >
            <button
              class="hs-accordion-toggle inline-flex justify-between items-center gap-x-3 w-full font-semibold text-2xl  text-start text-[#0A0B1E] py-7 px-6 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
              aria-expanded="true"
              aria-controls="hs-basic-active-bordered-collapse-two"
            >
              Can I track the performance of my links?
              <svg
                class="hs-accordion-active:hidden block size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5v14"></path>
              </svg>
              <svg
                class="hs-accordion-active:block hidden size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
              </svg>
            </button>
            <div
              id="hs-basic-active-bordered-collapse-two"
              class="hs-accordion-content w-full overflow-hidden transition-[height] duration-300"
              role="region"
              aria-labelledby="hs-active-bordered-heading-two"
            >
              <div class="pb-4 px-5">
                <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] ">
                  Yes! Atz provides detailed reports on click data, including
                  geographic locations, time-based metrics, and device usage, so
                  you can fully understand how your audience interacts with your
                  content.
                </p>
              </div>
            </div>
          </div>

          <div
            class="hs-accordion  bg-white border border-transparent rounded-xl dark:bg-neutral-800 dark:border-transparent drop-shadow-lg rounded-r-[24.06px] rounded-l-[24.06px]"
            id="hs-active-bordered-heading-three"
          >
            <button
              class="hs-accordion-toggle inline-flex justify-between items-center gap-x-3 w-full font-semibold text-2xl  text-start text-[#0A0B1E] py-7 px-6 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
              aria-expanded="false"
              aria-controls="hs-basic-active-bordered-collapse-three"
            >
              Is it easy to integrate AI into my current marketing strategy?
              <svg
                class="hs-accordion-active:hidden block size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5v14"></path>
              </svg>
              <svg
                class="hs-accordion-active:block hidden size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
              </svg>
            </button>
            <div
              id="hs-basic-active-bordered-collapse-three"
              class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
              role="region"
              aria-labelledby="hs-active-bordered-heading-three"
            >
              <div class="pb-4 px-5">
                <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] ">
                  Absolutely. AtZ’s platform is designed for seamless
                  integration, allowing you to use AI-driven insights and tools
                  without disrupting your existing workflow.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" bg-gradient-to-b dark:bg-gradient-to-b dark:from-neutral-950 dark:to-[#9966FF]/30 from-white to-[#9966FF]/20 flex flex-1 flex-col items-center justify-center p-4 lg:px-28 lg:py-10">
        <div className="flex flex-1 flex-col items-center gap-y-5 text-center">
          <span className="font-black text-5xl dark:text-neutral-100 ">
            Unlock Smarter Marketing with
            <br /> Atz AI-Powered Analytics
          </span>
          <span className="font-medium text-xl text-center dark:text-neutral-100">
            Atz AI offers the tools you need to enhance your campaigns, automate
            engagement, and track performance—all in one easy-to-use platform.
            <a href={url} className="hover:underline">
              {" "}
              Start leveraging AI today
            </a>{" "}
            for better results tomorrow.
          </span>
        </div>
      </div>

      <Footer1 />
    </>
  );
};

export default AtzAi1;
