import React, { useState } from "react";

const CircularProgressBar = ({ progress, utilized, color, total }) => {
  const radius = 70; // Radius of the circle
  const stroke = 10; // Thickness of the progress bar
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const strokeDashoffset = circumference - (progress / 100) * circumference;
  const [isHovered, setIsHovered] = useState(false);
  return (
    <>
      <div
        style={{ position: "relative", display: "inline-block" }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <svg
          height={radius * 2}
          width={radius * 2}
          style={{ transform: "rotate(-90deg)" }}
          className="cursor-pointer"
        >
          <circle
            stroke="#d1d5db" // Background circle color
            fill="transparent"
            strokeWidth={stroke}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
          />
          <circle
            stroke="#019ca2" // Progress color
            fill="transparent"
            strokeWidth={stroke}
            strokeDasharray={circumference + " " + circumference}
            style={{ strokeDashoffset }}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
          />
          <text
            x="50%"
            y="50%"
            dominantBaseline="middle"
            textAnchor="middle"
            fontSize="20px"
            fill={color}
            style={{
              transform: "rotate(90deg)",
              transformOrigin: "center",
              fontWeight: 500,
            }}
          >
            {utilized}
          </text>
        </svg>

        {isHovered && (
          <div
            style={{
              position: "absolute",
              top: "100%", // Position the tooltip below the SVG
              left: "50%",
              transform: "translateX(-50%)",
              backgroundColor: "white",
              padding: "8px",
              borderRadius: "4px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
              zIndex: 1,
              whiteSpace: "nowrap",
            }}
          >
            <div className="flex flex-col flex-1 justify-between space-y-1 dark:text-neutral-800">
              <div>
                <div className="text-base text-neutral-600">Ai Credits</div>
              </div>
              <div className="flex justify-between items-center space-x-4">
                <div className="flex items-center gap-x-1">
                  <div className="bg-[#019ca2] h-3 w-3 rounded-full"></div>
                  <div className="text-sm text-neutral-500 ">Utilized </div>
                </div>
                <div className="text-sm font-semibold">{utilized}</div>
              </div>
              <div className="flex justify-between items-center space-x-4 hidden">
                <div className="flex items-center gap-x-1">
                  <div className="bg-[#b675c0] h-3 w-3 rounded-full space-x-4"></div>
                  <div className="text-sm text-neutral-500">Available </div>
                </div>
                <div className="text-sm font-semibold">{total - utilized}</div>
              </div>
              <div className="flex justify-between items-center space-x-4">
                <div className="flex items-center gap-x-1">
                  <div className="bg-[#d1d5db] h-3 w-3 rounded-full"></div>
                  <div className="text-sm text-neutral-500">Total </div>
                </div>
                <div className="text-sm font-semibold">{total}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CircularProgressBar;
