import React, { useEffect, useState } from "react";
import Header from "../Landing/Header";
import { useLocation } from "react-router-dom";
import Footer1 from "../Landing/Footer1";

const Links1 = () => {
  const url =
    process.env.REACT_APP_IDP_URL +
    "/oauth2/authorize?response_type=code&client_id=atz&scope=openid&redirect_uri=" +
    process.env.REACT_APP_ATZ_CALLBACK_URL;
  const location = useLocation();
  useEffect(() => {
    // Reinitialize Preline components every time the location changes
    if (
      window.HSStaticMethods &&
      typeof window.HSStaticMethods.autoInit === "function"
    ) {
      window.HSStaticMethods.autoInit();
    }
  }, [location.pathname]);
  return (
    <>
      <Header url={url} />
      <div className="bg-white dark:bg-neutral-950 flex flex-col flex-1  items-center justify-center lg:px-28 p-4 pt-20 lg:pt-4">
        {/* hero open*/}
        <div className="grid grid-cols-1 md:grid-cols-2 place-items-center min-h-screen">
          <div className="flex flex-col gap-y-8">
            <div>
              <span className="relative bg-[#C4E0FD] bg-opacity-40 uppercase p-2 rounded-r-full rounded-l-full text-sm font-bold text-[#3F9DFF]">
                Atz URL Shortener
              </span>
            </div>
            <div>
              <span className="font-black text-[40px] text-[#353535] dark:text-neutral-50">
                Simplify Your Links and Amplify Your Reach
              </span>
            </div>
            <div>
              <span className="text-xl font-medium text-[#353535] dark:text-neutral-50">
                Tired of those long, unwieldy URLs dragging down your digital
                presence? <br />
                Atz is here to rescue your links. <br />
                <br />
                Our powerful shortener transforms those clunky links into sleek,
                shareable gems that boost engagement and simplify your
                marketing.
              </span>
            </div>
            <div className="flex items-center">
              <button
                className="border-2 border-[#C4E0FD] text-[#3F9DFF] rounded-r-full font-semibold text-lg rounded-l-full px-6 py-2.5 hover:drop-shadow-lg"
                onClick={() => (window.location.href = url)}
              >
                Start <span className="italic">Shortening Links</span> Now!
              </button>
            </div>
          </div>
          <div>
            <img
              src={require("../../assets/landing/linksPage1.png")}
              alt="linkshorten"
              className="w-auto"
            />
          </div>
        </div>
        {/* hero close */}
      </div>
      <div className="bg-[#C4E0FD] dark:bg-opacity-25 bg-opacity-15 lg:px-28 flex flex-col items-center lg:py-10 p-4">
        {/* benefits */}
        <div className="flex flex-col flex-1 justify-center gap-y-10">
          <div className="flex justify-center items-center dark:text-white font-black text-5xl">
            <span className="text-center">
              Benefits Of Atz{" "}
              <span className="bg-[#C4E0FD] dark:text-black z-[-1]">
                URL Shortener
              </span>{" "}
            </span>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-6 gap-y-6">
            <div className="flex min-h-[162px] max-w-[600px] rounded-3xl drop-shadow-lg bg-white dark:bg-neutral-800 p-[30px] gap-x-[29px]">
              <div>
                <img src={require("../../assets/landing/linksQr.png")} />
              </div>
              <div className="flex flex-col ">
                <div className="text-[#353535] dark:text-neutral-50 text-2xl font-bold">
                  Less is more
                </div>
                <div className="text-[#353535] dark:text-neutral-50 text-2xl">
                  Shorter links are easier to click, share, and remember.
                </div>
              </div>
            </div>
            <div className="flex min-h-[162px] max-w-[600px] rounded-3xl drop-shadow-lg bg-white dark:bg-neutral-800 p-[30px] gap-x-[29px]">
              <div>
                {" "}
                <img src={require("../../assets/landing/customers.png")} />
              </div>
              <div className="flex flex-col ">
                <div className="text-[#353535] dark:text-neutral-50 text-2xl font-bold">
                  Streamline your marketing
                </div>
                <div className="text-[#353535] dark:text-neutral-50 text-2xl">
                  Simplify your digital efforts with one powerful tool.
                </div>
              </div>
            </div>
            <div className="sm:col-span-2 flex min-h-[162px] rounded-3xl items-center drop-shadow-lg bg-white dark:bg-neutral-800 p-[30px] gap-x-[29px]">
              <div>
                <img src={require("../../assets/landing/analyticsScan.png")} />
              </div>
              <div className="flex flex-col ">
                <div className="text-[#353535] dark:text-neutral-50 text-2xl font-bold">
                  Boost engagement
                </div>
                <div className="text-[#353535] dark:text-neutral-50 text-2xl">
                  Drive more clicks and conversions with memorable links.
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* benefits close */}
      </div>
      <div className="lg:px-28 flex flex-col lg:py-10 p-4 bg-gradient-to-b dark:bg-gradient-to-b to-white from-[#F1F6FC] dark:from-neutral-950 dark:to-blue-950/50">
        {/* features */}
        <div className="flex flex-1 lg:flex-row flex-col">
          <div className="flex flex-col flex-1 gap-y-10 dark:text-white">
            <div className="flex font-black text-5xl">
              <span className="relative text-5xl dark:text-neutral-50 font-black text-center ">
                Key Features
              </span>
            </div>
            <div class="hs-accordion-group space-y-8 min-w-full">
              <div
                class="hs-accordion active hs-accordion-active:border-l-4 hs-accordion-active:border-l-[#3F9DFF] hs-accordion-active:pl-2"
                id="hs-basic-no-arrow-heading-one"
              >
                <button
                  class="hs-accordion-toggle hs-accordion-active:text-[#3F9DFF] py-3 inline-flex justify-between items-center gap-x-3 w-full font-bold text-2xl  text-start text-[#0A0B1E]  hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
                  aria-expanded="true"
                  aria-controls="hs-basic-no-arrow-collapse-one"
                >
                  Shorten Links in Seconds
                </button>
                <div
                  id="hs-basic-no-arrow-collapse-one"
                  class="hs-accordion-content w-full overflow-hidden transition-[height] duration-300"
                  role="region"
                  aria-labelledby="hs-basic-no-arrow-heading-one"
                >
                  <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] font-medium">
                    <div className="flex gap-x-2.5 items-center">
                      <div className="flex flex-none">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="23"
                            height="23"
                            rx="5.5"
                            fill="#3F9DFF"
                            stroke="#353535"
                          />
                          <path
                            d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                        With Atz, you can quickly turn any long URL into a
                        compact and trackable link that’s easy to share across
                        platforms.
                      </div>
                    </div>
                  </p>
                </div>
              </div>

              <div
                class="hs-accordion hs-accordion-active:border-l-4 hs-accordion-active:border-l-[#3F9DFF] hs-accordion-active:pl-2"
                id="hs-basic-no-arrow-heading-two"
              >
                <button
                  class="hs-accordion-toggle hs-accordion-active:text-[#3F9DFF] py-3 inline-flex justify-between items-center gap-x-3 w-full font-bold text-2xl  text-start text-[#0A0B1E]  hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
                  aria-expanded="false"
                  aria-controls="hs-basic-no-arrow-collapse-two"
                >
                  Advanced Analytic
                </button>
                <div
                  id="hs-basic-no-arrow-collapse-two"
                  class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
                  role="region"
                  aria-labelledby="hs-basic-no-arrow-heading-two"
                >
                  <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] font-medium flex flex-col">
                    <div className="flex gap-x-2.5 items-center">
                      <div className="flex flex-none">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="23"
                            height="23"
                            rx="5.5"
                            fill="#3F9DFF"
                            stroke="#353535"
                          />
                          <path
                            d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                        Gain insights into link performance with real-time data
                        on clicks, user behavior, and geolocation.
                      </div>
                    </div>
                    <div className="flex gap-x-2.5 items-center">
                      <div className="flex flex-none">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="23"
                            height="23"
                            rx="5.5"
                            fill="#3F9DFF"
                            stroke="#353535"
                          />
                          <path
                            d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                        Make data-driven decisions to optimize your campaigns.
                      </div>
                    </div>
                  </p>
                </div>
              </div>

              <div
                class="hs-accordion hs-accordion-active:border-l-4 hs-accordion-active:border-l-[#3F9DFF] hs-accordion-active:pl-2"
                id="hs-basic-no-arrow-heading-three"
              >
                <button
                  class="hs-accordion-toggle hs-accordion-active:text-[#3F9DFF] py-3 inline-flex justify-between items-center gap-x-3 w-full font-bold text-2xl  text-start text-[#0A0B1E]  hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
                  aria-expanded="false"
                  aria-controls="hs-basic-no-arrow-collapse-three"
                >
                  Custom Branded Link
                </button>
                <div
                  id="hs-basic-no-arrow-collapse-three"
                  class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
                  role="region"
                  aria-labelledby="hs-basic-no-arrow-heading-three"
                >
                  <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] font-medium flex flex-col">
                    <div className="flex gap-x-2.5 items-center">
                      <div className="flex flex-none">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="23"
                            height="23"
                            rx="5.5"
                            fill="#3F9DFF"
                            stroke="#353535"
                          />
                          <path
                            d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                        Build trust and brand recognition by using custom domain
                        names for your shortened links.
                      </div>
                    </div>
                    <div className="flex gap-x-2.5 items-center">
                      <div className="flex flex-none">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="23"
                            height="23"
                            rx="5.5"
                            fill="#3F9DFF"
                            stroke="#353535"
                          />
                          <path
                            d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                        Strengthen your brand identity while making your links
                        look professional.
                      </div>
                    </div>
                  </p>
                </div>
              </div>
              <div
                class="hs-accordion hs-accordion-active:border-l-4 hs-accordion-active:border-l-[#3F9DFF] hs-accordion-active:pl-2"
                id="hs-basic-no-arrow-heading-four"
              >
                <button
                  class="hs-accordion-toggle hs-accordion-active:text-[#3F9DFF] py-3 inline-flex justify-between items-center gap-x-3 w-full font-bold text-2xl  text-start text-[#0A0B1E]  hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
                  aria-expanded="false"
                  aria-controls="hs-basic-no-arrow-collapse-four"
                >
                  UTM Tracking Integration
                </button>
                <div
                  id="hs-basic-no-arrow-collapse-four"
                  class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
                  role="region"
                  aria-labelledby="hs-basic-no-arrow-heading-four"
                >
                  <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] font-medium flex flex-col">
                    <div className="flex gap-x-2.5 items-center">
                      <div className="flex flex-none">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="23"
                            height="23"
                            rx="5.5"
                            fill="#3F9DFF"
                            stroke="#353535"
                          />
                          <path
                            d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                        Seamlessly add UTM parameters to your links to track
                        traffic sources and measure the effectiveness of your
                        marketing campaigns.
                      </div>
                    </div>
                  </p>
                </div>
              </div>
              <div
                class="hs-accordion hs-accordion-active:border-l-4 hs-accordion-active:border-l-[#3F9DFF] hs-accordion-active:pl-2"
                id="hs-basic-no-arrow-heading-five"
              >
                <button
                  class="hs-accordion-toggle hs-accordion-active:text-[#3F9DFF] py-3 inline-flex justify-between items-center gap-x-3 w-full font-bold text-2xl  text-start text-[#0A0B1E]  hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
                  aria-expanded="false"
                  aria-controls="hs-basic-no-arrow-collapse-five"
                >
                  Password-Protected Links
                </button>
                <div
                  id="hs-basic-no-arrow-collapse-five"
                  class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
                  role="region"
                  aria-labelledby="hs-basic-no-arrow-heading-five"
                >
                  <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] font-medium flex flex-col">
                    <div className="flex gap-x-2.5 items-center">
                      <div className="flex flex-none">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="23"
                            height="23"
                            rx="5.5"
                            fill="#3F9DFF"
                            stroke="#353535"
                          />
                          <path
                            d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                        Keep sensitive information secure by enabling password
                        protection for any link, ensuring only authorized users
                        have access.
                      </div>
                    </div>
                  </p>
                </div>
              </div>
              <div
                class="hs-accordion hs-accordion-active:border-l-4 hs-accordion-active:border-l-[#3F9DFF] hs-accordion-active:pl-2"
                id="hs-basic-no-arrow-heading-six"
              >
                <button
                  class="hs-accordion-toggle hs-accordion-active:text-[#3F9DFF] py-3 inline-flex justify-between items-center gap-x-3 w-full font-bold text-2xl  text-start text-[#0A0B1E]  hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
                  aria-expanded="false"
                  aria-controls="hs-basic-no-arrow-collapse-six"
                >
                  AI-Powered Content Suggestions
                </button>
                <div
                  id="hs-basic-no-arrow-collapse-six"
                  class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
                  role="region"
                  aria-labelledby="hs-basic-no-arrow-heading-six"
                >
                  <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] font-medium flex flex-col">
                    <div className="flex gap-x-2.5 items-center">
                      <div className="flex flex-none">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="23"
                            height="23"
                            rx="5.5"
                            fill="#3F9DFF"
                            stroke="#353535"
                          />
                          <path
                            d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                        Use our integrated AI to generate tailored SMS and email
                        content that boosts engagement rates for your shared
                        links.
                      </div>
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden md:block">
            <img
              src={require("../../assets/landing/linkshorten.png")}
              alt="linkshorten"
              className="w-auto"
            />
          </div>
        </div>
      </div>
      {/* features close */}
      {/* benefits */}
      <div className="grid grid-cols-1 gap-y-10 lg:px-28 py-10 p-4 bg-white dark:bg-neutral-950">
        <div className="flex flex-col w-auto rounded-[50px] bg-gradient-to-l from-[#144EE3] via-[#EB568E] via-[#A353AA] to-[#144EE3] p-1">
          <div className="flex flex-1 lg:flex-row flex-col bg-white dark:bg-neutral-950 rounded-[50px]  p-10 items-center text-center">
            <div className="flex flex-col gap-y-5">
              <div>
                <div className="font-black text-[40px] text-[#353535] dark:text-neutral-50 ">
                  Boost Engagement with{" "}
                  <span className="bg-clip-text bg-gradient-to-l from-[#144EE3] via-[#EB568E] via-[#A353AA] to-[#144EE3] text-transparent">
                    AI-Generated
                  </span>{" "}
                  Content
                </div>
              </div>
              <div className="flex flex-col gap-y-3">
                <div className="flex gap-x-2.5 items-center">
                  <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                    Maximize the power of your shortened links with our
                    AI-driven content generation feature. AtZ’s AI suggests
                    engaging SMS and email copy that fits your brand voice and
                    enhances your digital campaigns.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-auto rounded-[50px]">
          <div className="flex flex-1 lg:flex-row flex-col bg-[#C4E0FD] rounded-[50px] bg-opacity-30 p-10 items-center ">
            <div className="flex flex-col flex-1 gap-y-5">
              <div className="flex flex-1 justify-center">
                <div className="font-black text-[40px] text-[#353535] dark:text-neutral-50 ">
                  Who can benefit from Atz URL Shortener?
                </div>
              </div>
              <div className="flex flex-col gap-y-3">
                <div className="flex gap-x-2.5 items-center">
                  <div className="flex flex-none">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="5.5"
                        fill="#3F9DFF"
                        stroke="#353535"
                      />
                      <path
                        d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                    Digital Marketers: Simplify campaign tracking with shorter,
                    branded links and detailed performance analytics.
                  </div>
                </div>
                <div className="flex gap-x-2.5 items-center">
                  <div className="flex flex-none">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="5.5"
                        fill="#3F9DFF"
                        stroke="#353535"
                      />
                      <path
                        d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                    Small Businesses: Build a stronger online presence by
                    sharing professional, branded URLs that customers trust.
                  </div>
                </div>
                <div className="flex gap-x-2.5 items-center">
                  <div className="flex flex-none">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="5.5"
                        fill="#3F9DFF"
                        stroke="#353535"
                      />
                      <path
                        d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                    Social Media Managers: Make your links more clickable on
                    social platforms while optimizing engagement with custom
                    short links.
                  </div>
                </div>
                <div className="flex gap-x-2.5 items-center">
                  <div className="flex flex-none">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="5.5"
                        fill="#3F9DFF"
                        stroke="#353535"
                      />
                      <path
                        d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                    E-commerce Platforms: Track clicks and conversions for
                    product URLs across multiple channels, and retarget
                    customers with ease.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-auto rounded-[50px]">
          <div className="flex flex-1 lg:flex-row flex-col bg-[#cffdc4] rounded-[50px] bg-opacity-30 p-10 items-center ">
            <div className="flex flex-col flex-1 gap-y-5">
              <div className="flex flex-1 items-center justify-center">
                <div className="font-black text-[40px] text-[#353535] dark:text-neutral-50 ">
                  How link shortening works?
                </div>
              </div>
              <div className="flex flex-col gap-y-3">
                <div className="flex gap-x-2.5 items-center">
                  <div className="flex flex-none">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="5.5"
                        fill="#D5FCC5"
                        stroke="#353535"
                      />
                      <path
                        d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                        fill="#353535"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                    Paste Your URL: Insert the long link into the field
                    provided.
                  </div>
                </div>
                <div className="flex gap-x-2.5 items-center">
                  <div className="flex flex-none">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="5.5"
                        fill="#D5FCC5"
                        stroke="#353535"
                      />
                      <path
                        d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                        fill="#353535"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                    Customize Your Link: Add UTM tracking, custom domains, or
                    password protection.
                  </div>
                </div>
                <div className="flex gap-x-2.5 items-center">
                  <div className="flex flex-none">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="5.5"
                        fill="#D5FCC5"
                        stroke="#353535"
                      />
                      <path
                        d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                        fill="#353535"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                    Shorten & Share: Hit the shorten button and your new URL is
                    ready to share!
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col w-auto rounded-[50px]">
          <div className="flex flex-1 lg:flex-row flex-col bg-[#d8d8d8] rounded-[50px] bg-opacity-30 p-10 items-center text-center">
            <div className="flex flex-col gap-y-5">
              <div>
                <div className="font-black text-[40px] text-[#353535] dark:text-neutral-50 ">
                  Built for Modern Marketers
                </div>
              </div>
              <div className="flex flex-col gap-y-3">
                <div className="flex gap-x-2.5 items-center">
                  <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                    Atz URL Shortener is designed to meet the needs of today’s
                    marketers. Whether you're looking to track traffic, protect
                    your content, or boost your brand recognition, Atz provides
                    the tools you need in one intuitive platform.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* benefits close*/}

      <div className="min-h-96 md:px-16 md:py-10 p-4 pb-12 flex flex-col items-center bg-white dark:bg-neutral-950 gap-y-8">
        <span className="relative text-5xl dark:text-neutral-50 font-black text-center ">
          FAQs
          <img
            src={require("../../assets/landing/Vector3.png")}
            className="absolute inset-0"
          />
        </span>
        <div class="hs-accordion-group space-y-6">
          <div
            class="hs-accordion  bg-white border border-transparent rounded-xl dark:bg-neutral-800 dark:border-transparent drop-shadow-lg rounded-r-[24.06px] rounded-l-[24.06px]"
            id="hs-active-bordered-heading-one"
          >
            <button
              class="hs-accordion-toggle inline-flex justify-between items-center gap-x-3 w-full font-semibold text-2xl  text-start text-[#0A0B1E] py-7 px-6 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
              aria-expanded="false"
              aria-controls="hs-basic-active-bordered-collapse-one"
            >
              How do I create a custom-branded link?
              <svg
                class="hs-accordion-active:hidden block size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5v14"></path>
              </svg>
              <svg
                class="hs-accordion-active:block hidden size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
              </svg>
            </button>
            <div
              id="hs-basic-active-bordered-collapse-one"
              class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
              role="region"
              aria-labelledby="hs-active-bordered-heading-one"
            >
              <div class="pb-4 px-5">
                <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] ">
                  Simply choose the custom domain option when shortening your
                  URL, and personalize it to match your brand name.
                </p>
              </div>
            </div>
          </div>

          <div
            class="hs-accordion  bg-white border border-transparent rounded-xl dark:bg-neutral-800 dark:border-transparent drop-shadow-lg rounded-r-[24.06px] rounded-l-[24.06px]"
            id="hs-active-bordered-heading-two"
          >
            <button
              class="hs-accordion-toggle inline-flex justify-between items-center gap-x-3 w-full font-semibold text-2xl  text-start text-[#0A0B1E] py-7 px-6 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
              aria-expanded="true"
              aria-controls="hs-basic-active-bordered-collapse-two"
            >
              Can I track clicks and conversions?
              <svg
                class="hs-accordion-active:hidden block size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5v14"></path>
              </svg>
              <svg
                class="hs-accordion-active:block hidden size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
              </svg>
            </button>
            <div
              id="hs-basic-active-bordered-collapse-two"
              class="hs-accordion-content w-full overflow-hidden transition-[height] duration-300"
              role="region"
              aria-labelledby="hs-active-bordered-heading-two"
            >
              <div class="pb-4 px-5">
                <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] ">
                  Yes! Atz offers advanced analytics so you can monitor link
                  performance, clicks, geographic data, and more in real time.
                </p>
              </div>
            </div>
          </div>

          <div
            class="hs-accordion  bg-white border border-transparent rounded-xl dark:bg-neutral-800 dark:border-transparent drop-shadow-lg rounded-r-[24.06px] rounded-l-[24.06px]"
            id="hs-active-bordered-heading-three"
          >
            <button
              class="hs-accordion-toggle inline-flex justify-between items-center gap-x-3 w-full font-semibold text-2xl  text-start text-[#0A0B1E] py-7 px-6 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
              aria-expanded="false"
              aria-controls="hs-basic-active-bordered-collapse-three"
            >
              Is there a limit on the number of links I can shorten?
              <svg
                class="hs-accordion-active:hidden block size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5v14"></path>
              </svg>
              <svg
                class="hs-accordion-active:block hidden size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
              </svg>
            </button>
            <div
              id="hs-basic-active-bordered-collapse-three"
              class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
              role="region"
              aria-labelledby="hs-active-bordered-heading-three"
            >
              <div class="pb-4 px-5">
                <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] ">
                  Our free plan allows up to X shortened links. For higher
                  volumes, explore our premium plans with no limits.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" bg-gradient-to-b dark:bg-gradient-to-b dark:from-neutral-950 dark:to-[#019ca2]/50 from-white to-[#019CA2]/20 flex flex-1 flex-col items-center justify-center p-4 lg:px-28 lg:py-10">
        <div className="flex flex-1 flex-col items-center gap-y-5 text-center">
          <span className="font-black text-5xl dark:text-neutral-100 ">
            Join Thousands of Users
            <br /> Simplifying Their Links with Atz
          </span>
          <span className="font-medium text-xl text-center dark:text-neutral-100">
            Optimize your digital marketing efforts with Atz’s powerful,
            easy-to-use link-shortening platform.{" "}
            <a href={url} className="hover:underline">
              Sign up
            </a>{" "}
            today!
          </span>
        </div>
      </div>
      <Footer1 />
    </>
  );
};

export default Links1;
