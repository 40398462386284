import React, { useState, useEffect } from "react";
import DashboardHeader from "../Dashboard/DashboardHeader";
import DashboardSidebar from "../Dashboard/DashboardSidebar";
import DashboardFooter from "../Dashboard/DashboardFooter";
import axios from "axios";
import { useLocation } from "react-router-dom";
import LinkDetailCard from "../subcomponents/LinkDetailCard";

const QrEdit = () => {
  const location = useLocation();
  const [title, setTitle] = useState("");
  const [shortenUrl, setShortenUrl] = useState("");
  const [destinationUrl, setDestinationUrl] = useState("");
  const [isValidTitle, setIsValidTitle] = useState(null);
  const [messageTitle, setMessageTitle] = useState("");
  const [isValid, setIsValid] = useState(null);
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const [message, setMessage] = useState("");

  const [qr, setQr] = useState(null);
  const [prevTitle, setPrevTitle] = useState("");
  const [prevUrl, setPrevUrl] = useState("");
  useEffect(() => {
    const getQr = async () => {
      const tokenData = localStorage.getItem("token");
      if (!tokenData) {
        console.log("Token not found in localStorage");
        return;
      }

      let access_token;
      try {
        const data = JSON.parse(tokenData);
        access_token = data.access_token;
        if (!access_token) {
          console.log("Access token is missing");
          return;
        }
      } catch (e) {
        console.log("Failed to parse token data");
        return;
      }

      const requestData = {
        data: {
          qrCodeId: id,
        },
      };

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_GATEWAY_URL}/atz/qr-codes/get-qr-code`,
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
        data: requestData,
      };

      try {
        const response = await axios(config);
        const data = response.data.data;

        if (response.status === 200) {
          setIsValid(true);

          setIsValidTitle(true);
          setQr(data);
          setTitle(data.title);
          setPrevTitle(data.title);
          setShortenUrl(data.shortenUrl);
          setDestinationUrl(data.destinationUrl);
          setPrevUrl(data.destinationUrl);
        }
      } catch (error) {
        window.location.href = "/qr-code";
        if (error.response.status === 404) {
        }
        console.log("Error response:", error.response);
        console.log("Error message:", error.message);
        // setObj({ error: true });
      }
    };
    getQr();
  }, []);
  const handleModify = async () => {
    const tokenData = localStorage.getItem("token");
    if (!tokenData) {
      console.log("Token not found in localStorage");
      return;
    }

    let access_token;
    try {
      const data = JSON.parse(tokenData);
      access_token = data.access_token;
      if (!access_token) {
        console.log("Access token is missing");
        return;
      }
    } catch (e) {
      console.log("Failed to parse token data");
      return;
    }

    const requestData = {
      data: {
        qrCodeId: id,
        title: title == prevTitle ? "" : title,
        destinationUrl: destinationUrl == prevUrl ? "" : destinationUrl,
      },
    };

    const config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_GATEWAY_URL}/atz/qr-codes/update-qr-code-detail`,
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
      data: requestData,
    };

    try {
      const response = await axios(config);

      if (response.status === 200) {
        console.log("updated successfully");
        // window.location.reload();
      }
    } catch (error) {
      console.log("Error response:", error.response);
      console.log("Error message:", error.message);
    }
  };
  const validateURL = (url) => {
    // var urlR = /^(http|https):\/\/[\w.-]+(?:[:\d])(\/.)?$/;
    var urlR =
      /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/gi;
    const regex = new RegExp(urlR);
    return regex.test(url);
  };

  const validateTitle = (title) => {
    return title.length > 0;
  };
  const handleTitle = (e) => {
    setTitle(e.target.value);
    setIsValidTitle(validateTitle(e.target.value));
  };

  const handleDestinationUrl = (e) => {
    setDestinationUrl(e.target.value);
    setIsValid(validateURL(e.target.value));
    if (isValid) {
      setMessage("");
    } else {
      if (e.target.value.length < 1)
        setMessage("Please provide destination url");
      else {
        setMessage("Please provide valid destination url");
      }
    }
  };
  const handleApplyChanges = () => {
    if (isValidTitle && isValid) {
      //api call
      handleModify();
    } else {
      if (isValid && !isValidTitle) {
        setIsValidTitle(false);
        setMessageTitle("Please provide a title");
      } else if (isValidTitle && !isValid) {
        setIsValid(false);
        setMessage("Please provide a valid url");
      } else {
        setIsValid(false);
        setIsValidTitle(false);
        setMessage("Please provide a valid url");
        setMessageTitle("Please provide a title");
      }
    }
  };
  const copyContent = async (e) => {
    try {
      await navigator.clipboard.writeText(shortenUrl);
      //   setCopy("Copied!");
      console.log("Content copied to clipboard");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };
  return (
    <div>
      <DashboardHeader />
      <DashboardSidebar />
      <DashboardFooter />
      <main
        id="content"
        className="lg:ps-[260px] pt-[59px] dark:text-neutral-100"
      >
        <div class="p-2 sm:p-5 sm:py-0 md:pt-5 space-y-5">
          <div className="flex justify-between">
            <div className="text-3xl font-bold">
              Edit your QR code
              {/* {linksCount && <span> ({linksCount})</span>} */}
            </div>
          </div>
          <div class="p-5 bg-white border border-gray-200 shadow-sm rounded-xl dark:bg-neutral-800 dark:border-neutral-700 space-y-10">
            {qr && (
              <div className="sm:p-4 p-0 flex flex-col -mb-6">
                <LinkDetailCard linkDetails={qr} clickCounts={qr?.clickCount} />
              </div>
            )}
            <div className="sm:p-4 p-0 flex flex-1 flex-col sm:flex-row gap-x-4 gap-y-6 sm:gap-y-0">
              <div className="flex flex-1 flex-col gap-y-4">
                <div className="flex flex-1 flex-col dark:border-neutral-700 border rounded-lg">
                  <div className="flex dark:border-b-neutral-700 border-b p-4 text-lg font-semibold">
                    Link details
                  </div>
                  <div className="flex flex-col p-4 ">
                    <div className="relative flex-1">
                      <input
                        autoComplete="off"
                        type="text"
                        id="hs-floating-input-email3"
                        className={`peer p-3 pr-9 block w-full text-[12px] rounded-md font-light border-[1px] placeholder:text-transparent focus:ring-4 h-11 ${
                          title.length > 0 ? "pt-5 pb-2.5" : "pt-4 pb-2.5"
                        } disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-800 focus:outline-none  ${
                          isValidTitle === false
                            ? "border-red-500 dark:border-red-500  dark:focus:ring-red-900 dark:focus:border-red-500  dark:focus:ring-2 focus:border-red-500 focus:ring-red-200 focus:border-[1.5px]"
                            : isValidTitle === true
                            ? "border-green-500 dark:border-green-400 dark:focus:ring-green-900  dark:focus:ring-[3px] focus:border-green-500 focus:ring-green-100 focus:border-[1.5px]"
                            : "border-gray-200 focus:ring-4 dark:focus:ring-4 focus:ring-blue-100 focus:border-blue-700 dark:border-neutral-700  dark:focus:ring-blue-950 dark:focus:border-blue-400"
                        }`}
                        placeholder="you@email.com"
                        value={title}
                        onChange={handleTitle}
                      />

                      <label
                        htmlFor="hs-floating-input-email4"
                        className={`absolute top-0 start-0 font-bold p-3.5 px-3  h-full peer-focus:text-[10px] ${
                          title.length > 0
                            ? "pt-3.5 text-[9px] peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:translate-x-0.5 peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 peer-focus:text-[9px] dark:peer-focus:text-neutral-400 "
                            : "text-[9px] peer-focus:text-[9px] "
                        } truncate pointer-events-none transition ease-in-out duration-100 text-neutral-500 border border-transparent origin-[0_0] dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none peer-focus:scale-90 peer-focus:translate-x-0.5 peer-focus:-translate-y-2 
         peer-focus:text-gray-400 dark:peer-focus:text-neutral-400`}
                      >
                        TITLE
                      </label>
                      {isValidTitle === true && (
                        <svg
                          fill="#22c55e"
                          version="1.1"
                          id="Capa_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="20px"
                          height="20px"
                          viewBox="0 0 335.765 335.765"
                          xmlSpace="preserve"
                          className="absolute top-1/2 right-3 transform -translate-y-1/2"
                        >
                          <g>
                            <g>
                              <polygon points="311.757,41.803 107.573,245.96 23.986,162.364 0,186.393 107.573,293.962 335.765,65.795" />
                            </g>
                          </g>
                        </svg>
                      )}

                      {isValidTitle === false && (
                        <svg
                          width="20px"
                          height="20px"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="absolute top-1/2 right-3 transform -translate-y-1/2"
                        >
                          <path
                            d="M12 16H12.01M12 8V12M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                            stroke="#ef4444"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                    </div>
                    {isValidTitle === false && (
                      <p
                        className="text-[12px] -top-1 font-light text-red-600"
                        id="hs-validation-email-error-helper"
                      >
                        {messageTitle}
                      </p>
                    )}
                    {isValidTitle === true && (
                      <p
                        className="text-[12px] -top-1 font-light text-green-600"
                        id="hs-validation-email-success-helper"
                      ></p>
                    )}
                  </div>

                  <div className="flex p-4 flex-col">
                    <div className="relative flex-1">
                      <input
                        autoComplete="off"
                        type="text"
                        id="hs-floating-input-email3"
                        className={`peer p-3 pr-9 block w-full text-[12px] rounded-md font-light border-[1px] placeholder:text-transparent focus:ring-4 h-11 ${
                          destinationUrl.length > 0
                            ? "pt-5 pb-2.5"
                            : "pt-4 pb-2.5"
                        } disabled:opacity-50 
                        disabled:bg-neutral-300
                        dark:disabled:bg-neutral-600
                        disabled:cursor-not-allowed dark:bg-neutral-800 focus:outline-none  ${
                          isValid === false
                            ? "border-red-500 dark:border-red-500  dark:focus:ring-red-900 dark:focus:border-red-500  dark:focus:ring-2 focus:border-red-500 focus:ring-red-200 focus:border-[1.5px]"
                            : isValid === true
                            ? "border-green-500 dark:border-green-400 dark:focus:ring-green-900  dark:focus:ring-[3px] focus:border-green-500 focus:ring-green-100 focus:border-[1.5px]"
                            : "border-gray-200 focus:ring-4 dark:focus:ring-4 focus:ring-blue-100 focus:border-blue-700 dark:border-neutral-700  dark:focus:ring-blue-950 dark:focus:border-blue-400"
                        }`}
                        placeholder="you@email.com"
                        value={destinationUrl}
                        onChange={handleDestinationUrl}
                        // disabled
                      />

                      <label
                        htmlFor="hs-floating-input-email4"
                        className={`absolute top-0 start-0 font-bold p-3.5 px-3  h-full peer-focus:text-[10px] ${
                          destinationUrl.length > 0
                            ? "pt-3.5 text-[9px] peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:translate-x-0.5 peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 peer-focus:text-[9px] dark:peer-focus:text-neutral-400 "
                            : "text-[9px] peer-focus:text-[9px] "
                        } truncate pointer-events-none transition ease-in-out duration-100 text-neutral-500 border border-transparent origin-[0_0] dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none peer-focus:scale-90 peer-focus:translate-x-0.5 peer-focus:-translate-y-2 
         peer-focus:text-gray-400 dark:peer-focus:text-neutral-400`}
                      >
                        DESTINATION URL
                      </label>
                      {isValid === true && (
                        <svg
                          fill="#22c55e"
                          version="1.1"
                          id="Capa_1"
                          xmlns="http://www.w3.org/2000/svg"
                          xmlnsXlink="http://www.w3.org/1999/xlink"
                          width="20px"
                          height="20px"
                          viewBox="0 0 335.765 335.765"
                          xmlSpace="preserve"
                          className="absolute top-1/2 right-3 transform -translate-y-1/2"
                        >
                          <g>
                            <g>
                              <polygon points="311.757,41.803 107.573,245.96 23.986,162.364 0,186.393 107.573,293.962 335.765,65.795" />
                            </g>
                          </g>
                        </svg>
                      )}

                      {isValid === false && (
                        <svg
                          width="20px"
                          height="20px"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="absolute top-1/2 right-3 transform -translate-y-1/2"
                        >
                          <path
                            d="M12 16H12.01M12 8V12M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                            stroke="#ef4444"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                    </div>
                    {isValid === false && (
                      <p
                        className="text-[12px] -top-1 font-light text-red-600"
                        id="hs-validation-email-error-helper"
                      >
                        {message}
                      </p>
                    )}
                    {isValid === true && (
                      <p
                        className="text-[12px] -top-1 font-light text-green-600"
                        id="hs-validation-email-success-helper"
                      ></p>
                    )}
                  </div>
                  <div className="flex p-4 gap-x-2">
                    <div className="relative flex-1">
                      <input
                        autoComplete="off"
                        type="text"
                        id="hs-floating-input-email3"
                        className={`peer p-3 pr-9 block w-full text-[12px] rounded-md font-light border-[1px] placeholder:text-transparent focus:ring-4 h-11 ${
                          shortenUrl.length > 0 ? "pt-5 pb-2.5" : "pt-4 pb-2.5"
                        } disabled:opacity-50 
                        disabled:bg-neutral-300
                        dark:disabled:bg-neutral-600
                        disabled:cursor-not-allowed dark:bg-neutral-800 focus:outline-none
                            border-gray-200 focus:ring-4 dark:focus:ring-4 focus:ring-blue-100 focus:border-blue-700 dark:border-neutral-700  dark:focus:ring-blue-950 dark:focus:border-blue-400"
                        }`}
                        placeholder="you@email.com"
                        value={shortenUrl}
                        // onChange={handleTitle}
                        disabled
                      />

                      <label
                        htmlFor="hs-floating-input-email4"
                        className={`absolute top-0 start-0 font-bold p-3.5 px-3  h-full peer-focus:text-[10px] ${
                          shortenUrl.length > 0
                            ? "pt-3.5 text-[9px] peer-[:not(:placeholder-shown)]:scale-90 peer-[:not(:placeholder-shown)]:translate-x-0.5 peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:text-gray-500 dark:peer-[:not(:placeholder-shown)]:text-neutral-500 peer-focus:text-[9px] dark:peer-focus:text-neutral-400 "
                            : "text-[9px] peer-focus:text-[9px] "
                        } truncate pointer-events-none transition ease-in-out duration-100 text-neutral-500 border border-transparent origin-[0_0] dark:text-white peer-disabled:opacity-50 peer-disabled:pointer-events-none peer-focus:scale-90 peer-focus:translate-x-0.5 peer-focus:-translate-y-2 
         peer-focus:text-gray-400 dark:peer-focus:text-neutral-400`}
                      >
                        SHORTEN URL
                      </label>
                    </div>
                    <div
                      className="relative flex items-center dark:border-neutral-700 border size-11 p-2 rounded-lg hover:cursor-pointer hover:bg-neutral-300 justify-center"
                      onClick={copyContent}
                    >
                      <svg
                        viewBox="0 0 24 24"
                        className="size-5"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M21 8C21 6.34315 19.6569 5 18 5H10C8.34315 5 7 6.34315 7 8V20C7 21.6569 8.34315 23 10 23H18C19.6569 23 21 21.6569 21 20V8ZM19 8C19 7.44772 18.5523 7 18 7H10C9.44772 7 9 7.44772 9 8V20C9 20.5523 9.44772 21 10 21H18C18.5523 21 19 20.5523 19 20V8Z"
                            fill="#808080"
                          ></path>{" "}
                          <path
                            d="M6 3H16C16.5523 3 17 2.55228 17 2C17 1.44772 16.5523 1 16 1H6C4.34315 1 3 2.34315 3 4V18C3 18.5523 3.44772 19 4 19C4.55228 19 5 18.5523 5 18V4C5 3.44772 5.44772 3 6 3Z"
                            fill="#808080"
                          ></path>{" "}
                        </g>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-none">
                <div className="flex flex-1 flex-col dark:border-neutral-700 border rounded-lg">
                  <div className="flex justify-center text-lg items-center dark:border-b-neutral-700 border-b p-4 font-semibold">
                    PREVIEW
                  </div>
                  <div className="flex justify-center items-center p-4">
                    <img
                      src={qr?.qrCodeUrl}
                      alt="qrcode"
                      className="size-56"
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-1 flex-col pb-4">
              <div className="flex justify-center">
                <button
                  className={`text-sm px-6 border font-semibold rounded-r-full rounded-l-full py-1.5 border-primary-800 text-primary-800 hover:bg-primary-800 hover:text-white disabled:bg-neutral-300 disabled:cursor-not-allowed disabled:hover:text-inherit ${
                    prevTitle == title && prevUrl == destinationUrl
                      ? ` hidden`
                      : ``
                  }`}
                  onClick={handleApplyChanges}
                >
                  Apply Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default QrEdit;
