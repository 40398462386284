import React, { useEffect } from "react";

const HubSpotComponent = () => {
  useEffect(() => {
    // Create a script element
    const script = document.createElement("script");
    script.src = "//js-na1.hs-scripts.com/47787840.jss";
    script.async = true;
    script.defer = true;
    script.id = "hs-script-loader";

    // Append the script to the document head
    document.head.appendChild(script);

    // Cleanup the script when the component unmounts (optional)
    return () => {
      document.head.removeChild(script);
    };
  }, []); // Empty dependency array to run only on mount and unmount

  return (
    <div id="aihelp">
      <h2 className="hidden">This is a component with HubSpot script loaded</h2>
      {/* Your component content */}
    </div>
  );
};

export default HubSpotComponent;
