import React, { useState, useEffect, useRef } from "react";
import DashboardHeader from "../Dashboard/DashboardHeader";
import DashboardSidebar from "../Dashboard/DashboardSidebar";
import DashboardFooter from "../Dashboard/DashboardFooter";
import moment from "moment";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import axios from "axios";
import SmallLoader from "../subcomponents/SmallLoader";
import NoLink from "../Links/NoLink";

const InvoiceSub = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const [loading, setLoading] = useState(false);
  const [invoice, setInvoice] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const id = queryParams.get("id");
  const divRef = useRef();
  const divToPrintRef = useRef();
  useEffect(() => {
    const fetchInvoice = async () => {
      const tokenData = localStorage.getItem("token");
      if (!tokenData) {
        console.log("Token not found in localStorage");
        return;
      }

      let access_token;
      try {
        const data = JSON.parse(tokenData);
        access_token = data.access_token;
        if (!access_token) {
          console.log("Access token is missing");
          return;
        }
      } catch (e) {
        console.log("Failed to parse token data");
        return;
      }

      const config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_GATEWAY_URL}/atz/payments/subscription-transactions-details`,
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
        data: {
          data: {
            transactionId: id,
          },
        },
      };
      try {
        setLoading(true);
        const response = await axios(config);
        console.log(response.data);
        if (response.status === 200) {
          setNotFound(false);
          setInvoice(response.data.data);
          setLoading(false);
        }
      } catch (e) {
        console.log(e);
        if (e.response.status === 500) {
          window.location.href = "/billing/subscriptions";
        }
        if (e.response.status === 404) {
          setLoading(false);
          setNotFound(true);
        }
      } finally {
        // setLoading(false);
      }
    };
    fetchInvoice();
  }, []);

  const handleDownload = () => {
    const input = divToPrintRef.current;

    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");

      // Calculate the PDF width and height
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (canvas.height * pdfWidth) / canvas.width;

      pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save(`invoice_${id}.pdf`);
    });
  };

  const handlePrint = () => {
    // Save the current body content
    const originalContent = document.body.innerHTML;

    // Get the content of the div to print
    const contentToPrint = divToPrintRef.current.innerHTML;

    // Replace the body content with the div content for printing
    document.body.innerHTML = contentToPrint;

    // Trigger the print function
    window.print();

    // Restore the original body content after printing
    document.body.innerHTML = originalContent;

    // Ensure the React app is re-rendered after restoring the content
    window.location.reload();
  };
  return (
    <>
      <DashboardHeader />
      <DashboardSidebar />
      <DashboardFooter />
      <main
        id="content"
        className="lg:ps-[260px] pt-[59px] dark:text-neutral-100"
      >
        <div class="p-2 sm:p-5 sm:py-0 md:pt-5 space-y-5">
          {loading && (
            <div class="max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto my-4 sm:my-10">
              <div class="sm:w-11/12 lg:w-3/4 mx-auto">
                {/* <!-- Card --> */}
                <div class="flex flex-1 flex-col p-4 min-h-[500px] sm:p-10 bg-white shadow-md rounded-xl items-center justify-center dark:bg-neutral-800">
                  <SmallLoader />
                </div>
              </div>
            </div>
          )}
          {!loading && notFound && (
            <div class="max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto my-4 sm:my-10">
              <div class="sm:w-11/12 lg:w-3/4 mx-auto">
                {/* <!-- Card --> */}
                <div class="flex flex-1 flex-col p-4 min-h-[500px] sm:p-10 bg-white shadow-md rounded-xl items-center justify-center dark:bg-neutral-800">
                  <NoLink noInvoice={true} />
                </div>
              </div>
            </div>
          )}
          {!loading && !notFound && (
            <div
              class="max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto my-4 sm:my-10"
              id="printMe"
            >
              <div class="sm:w-11/12 lg:w-3/4 mx-auto">
                {/* <!-- Card --> */}
                <div
                  class="flex flex-col p-4 sm:p-10 sm:pb-14 bg-white shadow-md rounded-xl dark:bg-neutral-800"
                  ref={divToPrintRef}
                >
                  {/* <!-- Grid --> */}
                  <div class="flex justify-between">
                    <div>
                      <img
                        src={require("../../assets/images/logo/nidalogo_white.png")}
                        className="sm:h-10 h-5  dark:hidden"
                      />
                      <img
                        src={require("../../assets/images/logo/nidalogo_black.png")}
                        className="sm:h-10 h-5  hidden dark:block"
                      />
                    </div>
                    {/* <!-- Col --> */}

                    <div class="text-end">
                      <h2 class="text-2xl md:text-3xl font-semibold text-gray-800 dark:text-neutral-200">
                        Invoice
                      </h2>
                      <span class="mt-1 block text-gray-500 dark:text-neutral-500">
                        {id}
                      </span>

                      <address class="mt-4 not-italic text-gray-800 dark:text-neutral-200">
                        <span className="font-bold">
                          {invoice?.billingFrom?.name}
                        </span>
                        <br />
                        {invoice?.billingFrom?.addressLine1}
                        <br />
                        {invoice?.billingFrom?.addressLine2}
                        <br />
                        {invoice?.billingFrom?.city +
                          ",  " +
                          invoice?.billingFrom?.state +
                          ", " +
                          invoice?.billingFrom?.postalCode}
                        <br />
                        {invoice?.billingFrom?.country}
                        <br />
                        GSTIN : {" " + invoice?.billingFrom?.gsTin}
                        <br />
                      </address>
                    </div>
                    {/* <!-- Col --> */}
                  </div>
                  {/* <!-- End Grid --> */}

                  {/* <!-- Grid --> */}
                  <div class="mt-8 grid sm:grid-cols-3 gap-3">
                    <div className="grid col-span-1">
                      <h3 class="text-lg font-semibold text-gray-800 dark:text-neutral-200">
                        Bill to:
                      </h3>
                      <h3 class="text-lg font-semibold text-gray-800 dark:text-neutral-200">
                        {invoice?.billingTo?.name}
                      </h3>
                      <address class="mt-2 not-italic text-gray-500 dark:text-neutral-500">
                        {invoice?.billingTo?.addressLine1},
                        <br />
                        {invoice?.billingTo?.addressLine2},
                        <br />
                        {invoice?.billingTo?.city +
                          " " +
                          invoice?.billingTo?.state}
                        ,
                        <br />
                        {invoice?.billingTo?.country},
                        <br />
                        {invoice?.billingTo?.postalCode}
                        <br />
                      </address>
                    </div>
                    <div class="sm:text-end sm:content-end space-y-2 grid col-span-2">
                      {/* <!-- Grid --> */}
                      <div class="grid grid-cols-1 sm:grid-cols-1 gap-3 sm:gap-2">
                        <dl class="grid sm:grid-cols-8 gap-x-3 items-center">
                          <dt class="col-span-3 text-sm font-semibold text-gray-500 dark:text-neutral-200 uppercase ">
                            Transaction Id
                          </dt>
                          <dd class="col-span-5 text-gray-500 dark:text-neutral-500">
                            {invoice?.transactionId}
                          </dd>
                        </dl>
                        <dl class="grid sm:grid-cols-8 gap-x-3 items-center">
                          <dt class="col-span-3 text-sm font-semibold text-gray-500 dark:text-neutral-200 uppercase ">
                            Subscription Id
                          </dt>
                          <dd class="col-span-5 text-gray-500  dark:text-neutral-500 ">
                            {invoice?.subscriptionId}
                          </dd>
                        </dl>
                        <dl class="grid sm:grid-cols-8 gap-x-3 items-center">
                          <dt class="col-span-3 text-sm font-semibold text-gray-500 dark:text-neutral-200 uppercase ">
                            Invoice date
                          </dt>
                          <dd class="col-span-5 text-gray-500 dark:text-neutral-500">
                            {moment(invoice?.date).format("DD MMM, YYYY")}
                          </dd>
                        </dl>

                        <dl class="grid sm:grid-cols-8 gap-x-3 items-center">
                          <dt class="col-span-3 text-sm font-semibold text-gray-500 dark:text-neutral-200 uppercase ">
                            Transaction status
                          </dt>
                          <dd class="col-span-5 text-gray-500 dark:text-neutral-500">
                            {invoice?.status &&
                              invoice?.status !== "completed" && (
                                <span class="inline-flex text-xs  bg-red-100 text-red-800 rounded-full dark:bg-red-500/10 dark:text-red-500 gap-1.5 py-1.5 px-4">
                                  {/* <span class=" items-center gap-1.5 py-1.5 px-2 text-xs font-medium ">
          </span> */}
                                  {invoice?.status?.[0].toUpperCase() +
                                    invoice?.status?.slice(
                                      1,
                                      invoice?.status.length
                                    )}
                                </span>
                              )}

                            {invoice?.status == "completed" && (
                              <span class="inline-flex text-xs  bg-green-100 text-green-800 rounded-full dark:bg-green-500/10 dark:text-green-500 gap-1.5 py-1.5 px-4">
                                {/* <span class=" items-center gap-1.5 py-1.5 px-2 text-xs font-medium ">
          </span> */}
                                {invoice?.status?.[0].toUpperCase() +
                                  invoice?.status?.slice(
                                    1,
                                    invoice?.status.length
                                  )}
                              </span>
                            )}
                          </dd>
                        </dl>
                      </div>
                      {/* <!-- End Grid --> */}
                    </div>
                    {/* <!-- Col --> */}

                    {/* <!-- Col --> */}
                  </div>
                  {/* <!-- End Grid --> */}

                  {/* <!-- Table --> */}
                  <div class="mt-6">
                    <div class="border border-gray-200 p-4 rounded-lg space-y-4 dark:border-neutral-700">
                      <div class="hidden sm:grid sm:grid-cols-5">
                        <div class="sm:col-span-2 text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">
                          Item
                        </div>
                        <div class="text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">
                          Qty
                        </div>
                        <div class="text-start text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">
                          Price
                        </div>
                        <div class="text-end text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">
                          Subtotal
                        </div>
                      </div>

                      <div class="hidden sm:block border-b border-gray-200 dark:border-neutral-700"></div>

                      {invoice?.items?.map((item) => (
                        <>
                          <div class="grid grid-cols-3 sm:grid-cols-5 gap-2">
                            <div class="col-span-full sm:col-span-2">
                              <h5 class="sm:hidden text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">
                                Item
                              </h5>
                              <p class="font-medium text-gray-800 dark:text-neutral-200">
                                {item.itemName}
                              </p>
                              <p class="text-xs text-gray-800 dark:text-neutral-200">
                                ( {item.description} )
                              </p>
                            </div>
                            <div>
                              <h5 class="sm:hidden text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">
                                Qty
                              </h5>
                              <p class="text-gray-800 dark:text-neutral-200">
                                {item.quantity}
                              </p>
                            </div>
                            <div>
                              <h5 class="sm:hidden text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">
                                Price
                              </h5>
                              <p class="text-gray-800 dark:text-neutral-200">
                                {item.price}
                              </p>
                            </div>
                            <div>
                              <h5 class="sm:hidden text-xs font-medium text-gray-500 uppercase dark:text-neutral-500">
                                Subtotal
                              </h5>
                              <p class="sm:text-end text-gray-800 dark:text-neutral-200">
                                {item.subTotal}
                              </p>
                            </div>
                          </div>
                          <div class="sm:hidden border-b border-gray-200 dark:border-neutral-700"></div>
                        </>
                      ))}
                      <div class="hidden sm:block border-b border-gray-200 dark:border-neutral-700"></div>

                      <div class="hidden sm:grid sm:grid-cols-2">
                        <div class="sm:col-span-1 text-sm font-bold uppercase text-gray-500 dark:text-neutral-500">
                          Total
                        </div>
                        <div class="sm:col-span-1 text-end text-sm font-bold text-gray-500 uppercase dark:text-neutral-500">
                          {invoice?.total?.mascot +
                            invoice?.total?.amount +
                            " " +
                            invoice?.total?.currency}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <!-- End Table --> */}

                  {/* <!-- Flex --> */}
                  <div class="mt-8 flex sm:justify-end">
                    <div class="w-full max-w-2xl sm:text-end space-y-2">
                      {/* <!-- Grid --> */}
                      <div class="grid grid-cols-2 sm:grid-cols-1 gap-3 sm:gap-2">
                        <dl class="grid sm:grid-cols-5 gap-x-3">
                          <dt class="col-span-3 font-semibold text-gray-500 dark:text-neutral-200 uppercase">
                            Subtotal
                          </dt>
                          <dd class="col-span-2 text-gray-500 dark:text-neutral-500">
                            $
                            {invoice?.items.reduce(
                              (acc, val) =>
                                (acc += Number(val.subTotal.slice(1, 5))),
                              0
                            )}
                            .00 USD
                          </dd>
                        </dl>

                        <dl class="grid sm:grid-cols-5 gap-x-3">
                          <dt class="col-span-3 font-semibold text-gray-500 dark:text-neutral-200 uppercase">
                            Total
                          </dt>
                          <dd class="col-span-2 text-gray-500 dark:text-neutral-500">
                            {invoice?.total?.mascot +
                              invoice?.total?.amount +
                              " " +
                              invoice?.total?.currency}
                          </dd>
                        </dl>

                        <dl class="grid sm:grid-cols-5 gap-x-3">
                          <dt class="col-span-3 font-semibold text-gray-500 dark:text-neutral-200 uppercase">
                            Tax
                          </dt>
                          <dd class="col-span-2 text-gray-500 dark:text-neutral-500">
                            $0.00 USD
                          </dd>
                        </dl>

                        <dl class="grid sm:grid-cols-5 gap-x-3">
                          <dt class="col-span-3 font-semibold text-gray-500 dark:text-neutral-200 uppercase">
                            Amount paid
                          </dt>
                          <dd class="col-span-2 text-gray-500 dark:text-neutral-500">
                            {invoice?.total?.mascot +
                              invoice?.total?.amount +
                              " " +
                              invoice?.total?.currency}
                          </dd>
                        </dl>
                      </div>
                      {/* <!-- End Grid --> */}
                    </div>
                  </div>
                  {/* <!-- End Flex --> */}

                  <div class="mt-8 sm:mt-12">
                    <h4 class="text-lg font-semibold text-gray-800 dark:text-neutral-200">
                      Thank you!
                    </h4>
                    <p class="text-gray-500 dark:text-neutral-500">
                      If you have any questions concerning this invoice, use the
                      following contact information:
                    </p>
                    <div class="mt-2">
                      <p class="block text-sm font-medium text-gray-800 dark:text-neutral-200">
                        atz@nidavellirs.com
                      </p>
                    </div>
                  </div>

                  <p class="mt-5 text-sm text-gray-500 dark:text-neutral-500">
                    © {moment().format("YYYY")} Atz - Nidavellir Software
                    Private Limited
                  </p>
                </div>
                {/* <!-- End Card --> */}

                {/* <!-- Buttons --> */}
                {invoice?.status === "completed" && (
                  <div class="mt-6 flex justify-end gap-x-3">
                    <button
                      class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none focus:outline-none focus:bg-gray-50 dark:bg-transparent dark:border-neutral-700 dark:text-neutral-300 dark:hover:bg-neutral-800 dark:focus:bg-neutral-800"
                      onClick={handleDownload}
                    >
                      <svg
                        class="shrink-0 size-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                        <polyline points="7 10 12 15 17 10" />
                        <line x1="12" x2="12" y1="15" y2="3" />
                      </svg>
                      Invoice PDF
                    </button>
                    <button
                      class="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-transparent bg-primary-800 text-white hover:bg-primary-700 focus:outline-none focus:bg-primary-700 disabled:opacity-50 disabled:pointer-events-none"
                      onClick={handlePrint}
                    >
                      <svg
                        class="shrink-0 size-4"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <polyline points="6 9 6 2 18 2 18 9" />
                        <path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2" />
                        <rect width="12" height="8" x="6" y="14" />
                      </svg>
                      Print
                    </button>
                  </div>
                )}
                {/* <!-- End Buttons --> */}
              </div>
            </div>
          )}
        </div>
      </main>
    </>
  );
};

export default InvoiceSub;
