import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import axiosInstance from "../../instances/axiosInstance";
import axios from "axios";
import HubSpotComponent from "../HubSpotComponent";

const Footer1 = () => {
  const [email, setEmail] = useState("");
  const [loadingNewsletter, setLoadingNewsletter] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const [message, setMessage] = useState(null);
  const location = useLocation();
  useEffect(() => {
    if (email.length > 1) {
      setInvalid(!validateEmail(email));
    }
  }, [email]);
  useEffect(() => {
    // Reinitialize Preline components every time the location changes
    if (
      window.HSStaticMethods &&
      typeof window.HSStaticMethods.autoInit === "function"
    ) {
      window.HSStaticMethods.autoInit();
    }
  }, [location.pathname]);
  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const handleNewsletter = async () => {
    try {
      setLoadingNewsletter(true);
      const config = {
        url: `${process.env.REACT_APP_GATEWAY_URL}/atz/public/subscribe-newsletter`,
        data: {
          data: { emailId: email },
        },
        method: "post",
      };
      const response = await axios(config);
      if (response.status === 200) {
        setInvalid(false);
        setMessage(null);
        setEmail("");
        document.getElementById("successButton").click();
      } else if (response.status === 208) {
        setInvalid(true);
        setMessage("This email is already subscribed to the newsletter.");
      }
    } catch (e) {
      setInvalid(true);
      setMessage("Some error occured please try again later.");
      console.log(e);
    } finally {
      setLoadingNewsletter(false);
    }
  };
  return (
    <>
      <div
        id="hs-scale-animation-modal2"
        class="hs-overlay size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none hidden"
        role="dialog"
        tabindex="-1"
        aria-labelledby="hs-scale-animation-modal2-label"
      >
        <div class="hs-overlay-animation-target hs-overlay-open:scale-100 hs-overlay-open:opacity-100 scale-95 opacity-0 ease-in-out transition-all duration-200 sm:max-w-xl sm:w-full m-3 sm:mx-auto min-h-[calc(100%-3.5rem)] flex items-center">
          <div class="w-full flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-neutral-800 dark:border-neutral-700 dark:shadow-neutral-700/70">
            {/* <!-- Header --> */}
            <div class="py-2.5 px-4 flex justify-between items-center border-b dark:border-neutral-700">
              <h3 class="font-medium text-gray-800 dark:text-neutral-200">
                Subscription Success
              </h3>

              {/* <!-- End Header --> */}
              <button
                type="button"
                class="size-8 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent bg-gray-100 text-gray-800 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 disabled:opacity-50 disabled:pointer-events-none dark:bg-neutral-700 dark:hover:bg-neutral-600 dark:text-neutral-400 dark:focus:bg-neutral-600"
                aria-label="Close"
                id="hs-scale-animation-modal2-btn"
                data-hs-overlay="#hs-scale-animation-modal2"
              >
                <span class="sr-only">Close</span>
                <svg
                  class="shrink-0 size-4"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path d="M18 6 6 18"></path>
                  <path d="m6 6 12 12"></path>
                </svg>
              </button>
            </div>
            <div
              class="min-h-[200px] flex overflow-y-auto
  [&::-webkit-scrollbar]:w-2
  [&::-webkit-scrollbar-track]:rounded-full
  [&::-webkit-scrollbar-track]:bg-gray-100
  [&::-webkit-scrollbar-thumb]:rounded-full
  [&::-webkit-scrollbar-thumb]:bg-gray-300
  dark:[&::-webkit-scrollbar-track]:bg-neutral-700
  dark:[&::-webkit-scrollbar-thumb]:bg-neutral-500"
            >
              <div className="flex flex-col flex-1 justify-center items-center gap-y-2 dark:text-neutral-100">
                <div>
                  <svg
                    fill="#16A34A"
                    className="size-10"
                    viewBox="0 0 24 24"
                    id="d9090658-f907-4d85-8bc1-743b70378e93"
                    data-name="Livello 1"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                    <g
                      id="SVGRepo_tracerCarrier"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    ></g>
                    <g id="SVGRepo_iconCarrier">
                      <title>prime</title>
                      <path
                        id="70fa6808-131f-4233-9c3a-fc089fd0c1c4"
                        data-name="done circle"
                        d="M12,0A12,12,0,1,0,24,12,12,12,0,0,0,12,0ZM11.52,17L6,12.79l1.83-2.37L11.14,13l4.51-5.08,2.24,2Z"
                      ></path>
                    </g>
                  </svg>
                </div>
                <div className="text-sm font-semibold -mb-1">
                  Newsletter Subscribed Successfully
                </div>
                <div className="text-xs flex text-center px-5">
                  Congratulations, you have successfully subscribed to our
                  newsletter. Keep checking your mail for the latest updates.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col bg-[#f0f0f0]  flex-1">
        <div
          className="flex flex-col overflow-auto lg:overflow-hidden lg:px-28 md:py-6 p-4  justify-around min-h-[350px]"
          // style={{
          //   background: `linear-gradient(to right,
          //   #44FF9A 0%,
          //   #44B0FF 23%,
          //   #8B44FF 49%,
          //   #FF6644 74%,
          //   #EBFF70 100%)`,
          //   filter: "blur(400px)",
          //   zIndex: "-1",
          // }}
        >
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:hidden place-content-center gap-y-4">
            <div className="flex flex-col flex-1 justify-around space-y-2">
              <div>
                <img
                  src={require("../../assets/images/logo/logo.png")}
                  className="h-[20px] w-[50px]"
                />
              </div>
              <div className="text-[14px] text-[#353535]">
                Your Link's Personal AI Assistant.
              </div>
              <div className="flex items-center gap-x-2">
                <svg
                  fill="#019ca2"
                  version="1.1"
                  id="Layer_1"
                  className="size-7 hover:drop-shadow-lg cursor-pointer"
                  onClick={() =>
                    (window.location.href =
                      "https://www.facebook.com/people/Atz/61567043289892/")
                  }
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 512 512"
                  enable-background="new 0 0 512 512"
                  xmlSpace="preserve"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path d="M256,0C114.609,0,0,114.609,0,256s114.609,256,256,256s256-114.609,256-256S397.391,0,256,0z M256,472 c-119.297,0-216-96.703-216-216S136.703,40,256,40s216,96.703,216,216S375.297,472,256,472z"></path>{" "}
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M316.75,216.812h-44.531v-32.5c0-9.969,10.312-12.281,15.125-12.281 c4.781,0,28.766,0,28.766,0v-43.859L283.141,128c-44.984,0-55.25,32.703-55.25,53.672v35.141H195.25V262h32.641 c0,58.016,0,122,0,122h44.328c0,0,0-64.641,0-122h37.656L316.75,216.812z"
                    ></path>{" "}
                  </g>
                </svg>
                <svg
                  fill="#019ca2"
                  version="1.1"
                  id="Layer_1"
                  className="size-7 hover:drop-shadow-lg cursor-pointer"
                  onClick={() =>
                    (window.location.href = "https://x.com/atz2024")
                  }
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 512 512"
                  enable-background="new 0 0 512 512"
                  xmlSpace="preserve"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path d="M256,0C114.609,0,0,114.609,0,256c0,141.392,114.609,256,256,256c141.392,0,256-114.608,256-256 C512,114.609,397.392,0,256,0z M256,472c-119.297,0-216-96.702-216-216c0-119.297,96.703-216,216-216c119.298,0,216,96.703,216,216 C472,375.298,375.298,472,256,472z"></path>{" "}
                    <path d="M384,170.922c-4.313,2.562-17.248,7.671-29.313,8.953c7.736-4.491,19.188-19.203,22.016-30.89 c-7.435,5.109-24.516,12.562-32.95,12.562c0,0,0,0.023,0.016,0.039C334.141,150.75,320.608,144,305.577,144 c-29.154,0-52.81,25.461-52.81,56.875c0,4.36,0.481,8.595,1.357,12.672h-0.017c-39.562-1.094-85.811-22.446-111.874-59 c-16,29.852-2.156,63.046,16.015,75.141c-6.203,0.516-17.671-0.766-23.061-6.407c-0.375,19.797,8.484,46.048,40.735,55.563 c-6.221,3.61-17.19,2.579-21.984,1.781c1.687,16.75,23.437,38.623,47.202,38.623c-8.47,10.534-37.373,29.706-73.141,23.596 C152.298,358.782,180.625,368,210.608,368c85.205,0,151.376-74.359,147.814-166.093c0-0.11-0.031-0.219-0.031-0.313 c0-0.25,0.031-0.5,0.031-0.719c0-0.281-0.031-0.562-0.031-0.859C366.141,194.328,376.546,184.234,384,170.922z"></path>{" "}
                  </g>
                </svg>
                <svg
                  fill="#019ca2"
                  version="1.1"
                  id="Layer_1"
                  className="size-7 hover:drop-shadow-lg cursor-pointer"
                  onClick={() =>
                    (window.location.href =
                      "https://www.linkedin.com/company/atz-nidavellir")
                  }
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 512 512"
                  enable-background="new 0 0 512 512"
                  xmlSpace="preserve"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path d="M256,0C114.609,0,0,114.609,0,256s114.609,256,256,256s256-114.609,256-256S397.391,0,256,0z M256,472 c-119.297,0-216-96.703-216-216S136.703,40,256,40s216,96.703,216,216S375.297,472,256,472z"></path>{" "}
                    <g>
                      {" "}
                      <g>
                        {" "}
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M128.094,383.891h48v-192h-48V383.891z M320.094,191.891 c-41.094,0.688-61.312,30.641-64,32v-32h-48v192h48v-112c0-4.109,10.125-37,48-32c20.344,1.328,31.312,28.234,32,32v112 l47.812,0.219V251.188C382.219,232,372.625,192.578,320.094,191.891z M152.094,127.891c-13.25,0-24,10.734-24,24s10.75,24,24,24 s24-10.734,24-24S165.344,127.891,152.094,127.891z"
                        ></path>{" "}
                      </g>{" "}
                    </g>{" "}
                  </g>
                </svg>
              </div>
              <div className="flex flex-col gap-y-1">
                <div className="inline-flex flex-1 gap-x-1 items-center ">
                  <span>
                    <svg
                      fill="#019ca2"
                      className="size-5"
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 512 512"
                      enable-background="new 0 0 512 512"
                      xmlSpace="preserve"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <g>
                          {" "}
                          <path d="M256,0C114.609,0,0,114.609,0,256s114.609,256,256,256s256-114.609,256-256S397.391,0,256,0z M256,472 c-119.297,0-216-96.703-216-216S136.703,40,256,40s216,96.703,216,216S375.297,472,256,472z"></path>{" "}
                          <path d="M315.844,198.359c0.172-1.109,0.25-2.141,0.25-3.094c0-8.797-5.5-13.172-16.5-13.172c-5.281,0-8.844,0.906-10.688,2.688 c-1.875,1.812-3.156,5.391-3.906,10.75c-4.219-5.938-8.469-9.938-12.75-12.016c-3.938-1.891-9.578-2.828-16.875-2.828 c-18.797,0-35.109,9.562-48.875,28.672c-12.688,17.578-19.016,36.359-19.016,56.328c0,15.594,4.109,29.281,12.344,41.094 c9.125,13.016,21.062,19.516,35.766,19.516c12.594,0,24.688-4.516,36.266-13.547c5.219,9.031,14.141,13.547,26.734,13.547 c23.844,0,44.266-10.062,61.219-30.203C375.938,276.938,384,254.906,384,230.031c0-29.75-12.516-54.562-37.547-74.422 C323.266,137.219,296.75,128,266.844,128c-38.906,0-71.562,12.297-98.016,36.906C141.609,190.281,128,222.656,128,262.062 c0,40.266,13.953,71.188,41.828,92.781C194.859,374.297,227.688,384,268.344,384c17.312,0,34.188-2.703,50.656-8.141 c18.062-5.922,32.922-14.359,44.594-25.297c3.766-3.609,7.391-8,10.828-13.156c4.297-6.297,6.422-11.375,6.422-15.234 c0-2.922-1.016-5.531-3.078-7.828c-2.047-2.281-4.516-3.422-7.359-3.422c-5.391,0-9.922,1.906-13.625,5.672 c-6.109,6.562-10.656,11.562-13.594,15c-20,17.469-44.844,26.203-74.594,26.203c-31.906,0-57.438-7.828-76.578-23.484 c-20.922-17.109-31.375-41.625-31.375-73.531c0-30.969,9.938-56.844,29.859-77.625C210.406,162.391,235.484,152,265.703,152 c23.781,0,43.547,6.75,59.328,20.281c16.906,14.344,25.328,33.438,25.328,57.25c0,12.469-2.125,24.094-6.422,34.828 c-4.781,12.047-11.719,21.328-20.781,27.859c-6.812,4.891-12.141,7.344-16,7.344c-4.203,0-6.297-1.969-6.297-5.922 c0-1.641,1.078-9.203,3.281-22.719L315.844,198.359z M267.344,269.578c-7.734,21.453-15.969,32.188-24.688,32.188 c-13.031,0-19.547-8.312-19.547-24.953c0-12.922,3.734-27.469,11.234-43.688c8.734-19.141,18.672-28.703,29.844-28.703 c4.625,0,8.062,2.5,10.328,7.484c1.844,3.812,2.766,8.344,2.766,13.594C277.281,236.719,273.984,251.406,267.344,269.578z"></path>{" "}
                        </g>{" "}
                      </g>
                    </svg>
                  </span>
                  <span className="text-sm ">atz@nidavellirs.com</span>
                </div>
              </div>
            </div>
            <div className="flex flex-col flex-1 justify-between space-y-2">
              <div className="text-[16px] font-bold text-[#071A34]">
                Services
              </div>
              <div className="text-[16px] font-medium  text-[#090914] ">
                <a href="/services/links" className="hover:underline">
                  Links
                </a>
              </div>
              <div className="text-[16px] font-medium text-[#090914] ">
                <a href="/services/qr-code" className="hover:underline">
                  QR Codes
                </a>
              </div>
              <div className="text-[16px] font-medium text-[#090914] ">
                <a href="/services/atz-ai" className="hover:underline">
                  Atz AI
                </a>
              </div>
              <div className="text-[16px] font-medium text-[#090914] ">
                <a href="/services/branded-domains" className="hover:underline">
                  Branded Domains
                </a>
              </div>
              <div className="text-[16px] font-medium text-[#090914] ">
                <a href="/services/analytics" className="hover:underline">
                  Analytics
                </a>
              </div>
              <div className="text-[16px] font-medium text-[#090914] ">
                <a href="/services/gallery" className="hover:underline">
                  Gallery
                </a>
              </div>
            </div>
            <div className="flex flex-col flex-1 justify-between space-y-2">
              <div className="text-[16px] font-bold text-[#071A34]">Legal</div>
              <a
                className="text-[16px] text-[#090914] font-medium hover:underline"
                href="/privacy-policy"
              >
                Privacy Policy
              </a>
              <a
                className="text-[16px] font-medium text-[#090914] hover:underline"
                href="/cookies-policy"
              >
                Cookie Policy
              </a>
              <a
                className="text-[16px] font-medium text-[#090914] hover:underline"
                href="/terms-and-conditions"
              >
                Terms & Conditions
              </a>
            </div>
            <div className="flex flex-col  flex-1 space-y-2">
              <div className=" space-y-2">
                <div className="text-[16px] font-bold  text-[#071A34]">
                  Newsletter
                </div>
                <div>
                  <input
                    placeholder="Enter your email address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className={`rounded-r-[24.06px] rounded-l-[24.06px] h-[55px] w-[306px] placeholder:text-base placeholder:font-medium font-medium border-neutral-200 placeholder:text-gray-400 outline-none focus:outline-none focus:ring-0 focus:border-neutral-200 ${
                      invalid
                        ? `border-red-500 ring-2 ring-red-200 focus:ring-red-200 focus:border-red-500`
                        : ``
                    }`}
                  />
                </div>
                {message &&
                  invalid &&
                  message !==
                    "This email is already subscribed to the newsletter." && (
                    <div>
                      <span className="text-sm text-red-600">
                        Please provide a valid email
                      </span>
                    </div>
                  )}
                {message == null && invalid && (
                  <div>
                    <span className="text-sm text-red-600">
                      Please provide a valid email
                    </span>
                  </div>
                )}
                {message &&
                  invalid &&
                  message ===
                    "This email is already subscribed to the newsletter." && (
                    <div>
                      <span className="text-sm text-red-600">{message}</span>
                    </div>
                  )}
                <div>
                  <button
                    className="rounded-r-[24.06px] rounded-l-[24.06px] h-[55px] w-[306px] font-semibold bg-[#019ca2] text-white"
                    onClick={handleNewsletter}
                    disabled={loadingNewsletter}
                  >
                    {loadingNewsletter && "Subscribing..."}
                    {!loadingNewsletter && "Subscribe Now !"}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden flex-1 lg:flex justify-between max-h-[219px] w-auto gap-x-16 ">
            <div className="flex flex-col flex-1 justify-around">
              <div>
                <img
                  src={require("../../assets/images/logo/logo.png")}
                  className="h-[34.5px] w-[87.58px]"
                />
              </div>
              <div className="text-[14px] text-[#353535]">
                Your Link's Personal AI Assistant.
              </div>
              <div className="flex items-center gap-x-2">
                <svg
                  fill="#019ca2"
                  version="1.1"
                  id="Layer_1"
                  className="size-7 hover:drop-shadow-lg cursor-pointer"
                  onClick={() =>
                    (window.location.href =
                      " https://www.facebook.com/people/Atz/61567043289892/")
                  }
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 512 512"
                  enable-background="new 0 0 512 512"
                  xmlSpace="preserve"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path d="M256,0C114.609,0,0,114.609,0,256s114.609,256,256,256s256-114.609,256-256S397.391,0,256,0z M256,472 c-119.297,0-216-96.703-216-216S136.703,40,256,40s216,96.703,216,216S375.297,472,256,472z"></path>{" "}
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M316.75,216.812h-44.531v-32.5c0-9.969,10.312-12.281,15.125-12.281 c4.781,0,28.766,0,28.766,0v-43.859L283.141,128c-44.984,0-55.25,32.703-55.25,53.672v35.141H195.25V262h32.641 c0,58.016,0,122,0,122h44.328c0,0,0-64.641,0-122h37.656L316.75,216.812z"
                    ></path>{" "}
                  </g>
                </svg>
                <svg
                  fill="#019ca2"
                  version="1.1"
                  id="Layer_1"
                  className="size-7 hover:drop-shadow-lg cursor-pointer"
                  onClick={() =>
                    (window.location.href = "https://x.com/atz2024")
                  }
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 512 512"
                  enable-background="new 0 0 512 512"
                  xmlSpace="preserve"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path d="M256,0C114.609,0,0,114.609,0,256c0,141.392,114.609,256,256,256c141.392,0,256-114.608,256-256 C512,114.609,397.392,0,256,0z M256,472c-119.297,0-216-96.702-216-216c0-119.297,96.703-216,216-216c119.298,0,216,96.703,216,216 C472,375.298,375.298,472,256,472z"></path>{" "}
                    <path d="M384,170.922c-4.313,2.562-17.248,7.671-29.313,8.953c7.736-4.491,19.188-19.203,22.016-30.89 c-7.435,5.109-24.516,12.562-32.95,12.562c0,0,0,0.023,0.016,0.039C334.141,150.75,320.608,144,305.577,144 c-29.154,0-52.81,25.461-52.81,56.875c0,4.36,0.481,8.595,1.357,12.672h-0.017c-39.562-1.094-85.811-22.446-111.874-59 c-16,29.852-2.156,63.046,16.015,75.141c-6.203,0.516-17.671-0.766-23.061-6.407c-0.375,19.797,8.484,46.048,40.735,55.563 c-6.221,3.61-17.19,2.579-21.984,1.781c1.687,16.75,23.437,38.623,47.202,38.623c-8.47,10.534-37.373,29.706-73.141,23.596 C152.298,358.782,180.625,368,210.608,368c85.205,0,151.376-74.359,147.814-166.093c0-0.11-0.031-0.219-0.031-0.313 c0-0.25,0.031-0.5,0.031-0.719c0-0.281-0.031-0.562-0.031-0.859C366.141,194.328,376.546,184.234,384,170.922z"></path>{" "}
                  </g>
                </svg>
                <svg
                  fill="#019ca2"
                  version="1.1"
                  id="Layer_1"
                  className="size-7 hover:drop-shadow-lg cursor-pointer"
                  onClick={() =>
                    (window.location.href =
                      "https://www.linkedin.com/company/atz-nidavellir")
                  }
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  viewBox="0 0 512 512"
                  enable-background="new 0 0 512 512"
                  xmlSpace="preserve"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <path d="M256,0C114.609,0,0,114.609,0,256s114.609,256,256,256s256-114.609,256-256S397.391,0,256,0z M256,472 c-119.297,0-216-96.703-216-216S136.703,40,256,40s216,96.703,216,216S375.297,472,256,472z"></path>{" "}
                    <g>
                      {" "}
                      <g>
                        {" "}
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M128.094,383.891h48v-192h-48V383.891z M320.094,191.891 c-41.094,0.688-61.312,30.641-64,32v-32h-48v192h48v-112c0-4.109,10.125-37,48-32c20.344,1.328,31.312,28.234,32,32v112 l47.812,0.219V251.188C382.219,232,372.625,192.578,320.094,191.891z M152.094,127.891c-13.25,0-24,10.734-24,24s10.75,24,24,24 s24-10.734,24-24S165.344,127.891,152.094,127.891z"
                        ></path>{" "}
                      </g>{" "}
                    </g>{" "}
                  </g>
                </svg>
              </div>
              <div className="flex flex-col gap-y-1">
                <div className="inline-flex flex-1 gap-x-1 items-center ">
                  <span>
                    <svg
                      fill="#019ca2"
                      className="size-5"
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 512 512"
                      enable-background="new 0 0 512 512"
                      xmlSpace="preserve"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <g>
                          {" "}
                          <path d="M256,0C114.609,0,0,114.609,0,256s114.609,256,256,256s256-114.609,256-256S397.391,0,256,0z M256,472 c-119.297,0-216-96.703-216-216S136.703,40,256,40s216,96.703,216,216S375.297,472,256,472z"></path>{" "}
                          <path d="M315.844,198.359c0.172-1.109,0.25-2.141,0.25-3.094c0-8.797-5.5-13.172-16.5-13.172c-5.281,0-8.844,0.906-10.688,2.688 c-1.875,1.812-3.156,5.391-3.906,10.75c-4.219-5.938-8.469-9.938-12.75-12.016c-3.938-1.891-9.578-2.828-16.875-2.828 c-18.797,0-35.109,9.562-48.875,28.672c-12.688,17.578-19.016,36.359-19.016,56.328c0,15.594,4.109,29.281,12.344,41.094 c9.125,13.016,21.062,19.516,35.766,19.516c12.594,0,24.688-4.516,36.266-13.547c5.219,9.031,14.141,13.547,26.734,13.547 c23.844,0,44.266-10.062,61.219-30.203C375.938,276.938,384,254.906,384,230.031c0-29.75-12.516-54.562-37.547-74.422 C323.266,137.219,296.75,128,266.844,128c-38.906,0-71.562,12.297-98.016,36.906C141.609,190.281,128,222.656,128,262.062 c0,40.266,13.953,71.188,41.828,92.781C194.859,374.297,227.688,384,268.344,384c17.312,0,34.188-2.703,50.656-8.141 c18.062-5.922,32.922-14.359,44.594-25.297c3.766-3.609,7.391-8,10.828-13.156c4.297-6.297,6.422-11.375,6.422-15.234 c0-2.922-1.016-5.531-3.078-7.828c-2.047-2.281-4.516-3.422-7.359-3.422c-5.391,0-9.922,1.906-13.625,5.672 c-6.109,6.562-10.656,11.562-13.594,15c-20,17.469-44.844,26.203-74.594,26.203c-31.906,0-57.438-7.828-76.578-23.484 c-20.922-17.109-31.375-41.625-31.375-73.531c0-30.969,9.938-56.844,29.859-77.625C210.406,162.391,235.484,152,265.703,152 c23.781,0,43.547,6.75,59.328,20.281c16.906,14.344,25.328,33.438,25.328,57.25c0,12.469-2.125,24.094-6.422,34.828 c-4.781,12.047-11.719,21.328-20.781,27.859c-6.812,4.891-12.141,7.344-16,7.344c-4.203,0-6.297-1.969-6.297-5.922 c0-1.641,1.078-9.203,3.281-22.719L315.844,198.359z M267.344,269.578c-7.734,21.453-15.969,32.188-24.688,32.188 c-13.031,0-19.547-8.312-19.547-24.953c0-12.922,3.734-27.469,11.234-43.688c8.734-19.141,18.672-28.703,29.844-28.703 c4.625,0,8.062,2.5,10.328,7.484c1.844,3.812,2.766,8.344,2.766,13.594C277.281,236.719,273.984,251.406,267.344,269.578z"></path>{" "}
                        </g>{" "}
                      </g>
                    </svg>
                  </span>
                  <span className="text-sm ">atz@nidavellirs.com</span>
                </div>
                <div className="flex flex-1 gap-x-1 items-center">
                  <span>
                    <svg
                      fill="#019ca2"
                      className="size-5"
                      version="1.1"
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 512 512"
                      enable-background="new 0 0 512 512"
                      xmlSpace="preserve"
                    >
                      <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                      <g
                        id="SVGRepo_tracerCarrier"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></g>
                      <g id="SVGRepo_iconCarrier">
                        {" "}
                        <path d="M256,0C114.609,0,0,114.609,0,256s114.609,256,256,256s256-114.609,256-256S397.391,0,256,0z M256,472 c-119.297,0-216-96.703-216-216S136.703,40,256,40s216,96.703,216,216S375.297,472,256,472z"></path>{" "}
                        <path d="M128,288l256-160L224,384v-96H128z"></path>{" "}
                      </g>
                    </svg>
                  </span>
                  <span className="text-sm">India</span>
                </div>
              </div>
            </div>
            <div className="flex flex-col flex-1 gap-y-2">
              <div className="text-[16px] font-bold text-[#071A34]">
                Services
              </div>
              <div className="text-[16px] font-medium  text-[#090914] ">
                <a href="/services/links" className="hover:underline">
                  Links
                </a>
              </div>
              <div className="text-[16px] font-medium text-[#090914] ">
                <a href="/services/qr-code" className="hover:underline">
                  QR Codes
                </a>
              </div>
              <div className="text-[16px] font-medium text-[#090914] ">
                <a href="/services/atz-ai" className="hover:underline">
                  Atz AI
                </a>
              </div>
              <div className="text-[16px] font-medium text-[#090914] ">
                <a href="/services/branded-domains" className="hover:underline">
                  Branded Domains
                </a>
              </div>
              <div className="text-[16px] font-medium text-[#090914] ">
                <a href="/services/analytics" className="hover:underline">
                  Analytics
                </a>
              </div>
              <div className="text-[16px] font-medium text-[#090914] ">
                <a href="/services/gallery" className="hover:underline">
                  Gallery
                </a>
              </div>
            </div>
            <div className="flex flex-col flex-1 gap-y-2">
              <div className="text-[16px] font-bold text-[#071A34]">Legal</div>
              <a
                className="text-[16px] text-[#090914] font-medium hover:underline"
                href="/privacy-policy"
              >
                Privacy Policy
              </a>
              <a
                className="text-[16px] font-medium text-[#090914] hover:underline"
                href="/cookies-policy"
              >
                Cookie Policy
              </a>
              <a
                className="text-[16px] font-medium text-[#090914] hover:underline"
                href="/terms-and-conditions"
              >
                Terms & Conditions
              </a>
            </div>
            <div className="flex flex-col  flex-1 gap-y-2">
              <div className="text-[16px] font-bold  text-[#071A34]">
                Newsletter
              </div>
              <div>
                <input
                  placeholder="Enter your email address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className={`rounded-r-[24.06px] rounded-l-[24.06px] h-[55px] w-[306px] placeholder:text-base placeholder:font-medium font-medium border-neutral-200 placeholder:text-gray-400 outline-none focus:outline-none focus:ring-0 focus:border-neutral-200 ${
                    invalid
                      ? `border-red-500 ring-2 ring-red-200 focus:ring-red-200 focus:border-red-500`
                      : ``
                  }`}
                />
              </div>
              {message &&
                invalid &&
                message !==
                  "This email is already subscribed to the newsletter." && (
                  <div>
                    <span className="text-sm text-red-600">
                      Please provide a valid email
                    </span>
                  </div>
                )}
              {message == null && invalid && (
                <div>
                  <span className="text-sm text-red-600">
                    Please provide a valid email
                  </span>
                </div>
              )}
              {message &&
                invalid &&
                message ===
                  "This email is already subscribed to the newsletter." && (
                  <div>
                    <span className="text-sm text-red-600">{message}</span>
                  </div>
                )}
              <div>
                <button
                  className="rounded-r-[24.06px] rounded-l-[24.06px] h-[55px] w-[306px] font-semibold bg-[#019ca2] text-white"
                  onClick={handleNewsletter}
                  disabled={loadingNewsletter}
                >
                  {loadingNewsletter && "Subscribing..."}
                  {!loadingNewsletter && "Subscribe Now !"}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-none items-center justify-center px-4 lg:px-28 py-6 border-2 border-t text-[10px] sm:text-[14px] border-white">
          © {moment().format("YYYY")} Atz | Powered by{"\u00a0"}
          <a href="https://nidavellirs.com/" className="hover:underline">
            Nidavellir Software Private Limited{" "}
          </a>
        </div>
      </div>
      <HubSpotComponent />
    </>
  );
};

export default Footer1;
