import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import uiReducer from "../features/uiSlice";
const store = configureStore({
  reducer: {
    auth: authReducer,
    ui: uiReducer,
  },
});

export default store;

/*

// store.js
import { createStore, combineReducers } from 'redux';

const tokenReducer = (state = null, action) => {
  switch (action.type) {
    case 'SET_TOKEN':
      return action.payload;
    case 'CLEAR_TOKEN':
      return null;
    default:
      return state;
  }
};

const refreshTokenReducer = (state = null, action) => {
  switch (action.type) {
    case 'SET_REFRESH_TOKEN':
      return action.payload;
    case 'CLEAR_REFRESH_TOKEN':
      return null;
    default:
      return state;
  }
};

const userReducer = (state = null, action) => {
  switch (action.type) {
    case 'SET_USER':
      return action.payload;
    case 'CLEAR_USER':
      return null;
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  token: tokenReducer,
  refreshToken: refreshTokenReducer,
  user: userReducer,
});

const store = createStore(rootReducer);

export default store;
*/
