import React from "react";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();

  return <div>{navigate("/")}</div>;
};

export default Login;
