import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import authSlice from "../../features/auth/authSlice";

const Hero = ({ url }) => {
  // const dispatch = useDispatch();

  return (
    <div className="pt-24">
      <div className="max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto pb-12">
        <div className="max-w-xl lg:max-w-3xl text-center mx-auto">
          <p className="inline-flex items-center font-medium text-gray-800 mb-3 dark:text-neutral-200">
            Craft effective links with our advanced URL shortener
          </p>
          <h1 className="text-3xl leading-snug font-bold sm:text-5xl sm:leading-tight lg:text-7xl lg:leading-tight dark:text-white">
            World's largest{" "}
            <span className="relative text-[#019ca2] dark:text-[#019ca2] bg-[url('../assets/images/svg/pen-drawing.svg')] bg-no-repeat bg-[0_100%] bg-[length:100%] px-[2%] pb-[6%] -ms-[2%] -mb-[6%] before:bg-[url('../assets/images/svg/surprised-emoji.svg')] before:absolute before:top-0 before:end-0 before:z-10 before:bg-[length:24px_24px] before:size-6 before:block before:-me-3 before:-mt-2 before:-rotate-12 sm:before:bg-[length:32px_32px] sm:before:size-8">
              Link
            </span>{" "}
            <span className="block">Shortener Platform</span>
          </h1>
          <p className="mt-3 md:mt-5 md:text-lg text-gray-600 dark:text-neutral-400">
            Leverage our URL shortener, QR codes, and landing pages to engage
            your audience and guide them to the right content. Build, modify,
            and track everything effortlessly within the Atz Connections
            Platform
          </p>
          <div className="mt-8 grid gap-3 w-full sm:justify-center sm:inline-flex">
            <a
              className="py-3 px-4 inline-flex justify-center items-center gap-x-1 text-md font-semibold rounded-lg border border-transparent bg-[#019ca2] text-white hover:bg-[#019ca2] disabled:opacity-50 disabled:pointer-events-none"
              href={url}
            >
              Get Started
              <svg
                className="p-0.5"
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3.7242 8.45192L7.28876 7.19383C6.55281 8.18469 5.92055 9.24884 5.40213 10.3696L3.7242 8.45192ZM1.24742 8.6585L4.54987 12.4327L4.07152 13.6286C3.92296 14 4.01003 14.4242 4.29289 14.7071L4.60651 15.0207C2.90342 16.9411 1.9627 18.9496 1.05132 21.6838C0.931539 22.0431 1.02506 22.4393 1.29289 22.7071C1.56073 22.9749 1.95689 23.0685 2.31623 22.9487C5.05037 22.0373 7.05889 21.0966 8.97928 19.3935L9.29289 19.7071C9.57576 19.99 9.99997 20.077 10.3714 19.9285L11.5673 19.4501L15.3415 22.7526C15.5911 22.971 15.9327 23.0514 16.2535 22.9673C16.5744 22.8832 16.8326 22.6456 16.943 22.3328L19.9291 13.8722C21.8977 11.5428 23 8.57479 23 5.48078V2C23 1.44772 22.5523 1 22 1H18.5192C15.4252 1 12.4572 2.10225 10.1278 4.0709L1.66718 7.05701C1.35444 7.16739 1.11676 7.42565 1.03268 7.74646C0.948589 8.06728 1.02903 8.40891 1.24742 8.6585ZM3.68527 20.3147C4.31277 18.7992 5.017 17.5929 6.02356 16.4378L7.56223 17.9764C6.40713 18.983 5.20083 19.6872 3.68527 20.3147ZM10.2408 17.8266L9.70711 17.2929L6.70711 14.2929L6.17337 13.7592L6.88327 11.9844C7.53465 10.356 8.44936 8.84567 9.59079 7.51401L10.1674 6.84129C12.2572 4.40319 15.308 3 18.5192 3H21V5.48078C21 8.69196 19.5968 11.7428 17.1587 13.8326L16.486 14.4092C15.1543 15.5506 13.644 16.4653 12.0156 17.1167L10.2408 17.8266ZM15.5481 20.2758L13.6304 18.5979C14.7512 18.0795 15.8153 17.4472 16.8062 16.7112L15.5481 20.2758ZM15 8C15 7.44772 15.4477 7 16 7C16.5523 7 17 7.44772 17 8C17 8.55228 16.5523 9 16 9C15.4477 9 15 8.55228 15 8ZM16 5C14.3431 5 13 6.34315 13 8C13 9.65685 14.3431 11 16 11C17.6569 11 19 9.65685 19 8C19 6.34315 17.6569 5 16 5Z"
                  fill="#FFFFFF"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
