import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const Faq = () => {
  const location = useLocation();
  useEffect(() => {
    // Reinitialize Preline components every time the location changes
    if (
      window.HSStaticMethods &&
      typeof window.HSStaticMethods.autoInit === "function"
    ) {
      window.HSStaticMethods.autoInit();
    }
  }, [location.pathname]);
  return (
    <div className="min-h-96 md:px-28 md:py-10 p-4 pb-12 flex flex-col items-center bg-white dark:bg-neutral-950 gap-y-8">
      <span className="relative text-5xl dark:text-neutral-50 font-black text-center ">
        FAQs
        <img
          src={require("../../assets/landing/Vector3.png")}
          className="absolute inset-0"
        />
      </span>
      <div class="hs-accordion-group space-y-6">
        <div
          class="hs-accordion  bg-white border border-transparent rounded-xl dark:bg-neutral-800 dark:border-transparent drop-shadow-lg rounded-r-[24.06px] rounded-l-[24.06px]"
          id="hs-active-bordered-heading-one"
        >
          <button
            class="hs-accordion-toggle inline-flex justify-between items-center gap-x-3 w-full font-semibold text-2xl  text-start text-[#0A0B1E] py-7 px-6 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
            aria-expanded="false"
            aria-controls="hs-basic-active-bordered-collapse-one"
          >
            What are the benefits of using shortened links?
            <svg
              class="hs-accordion-active:hidden block size-3.5"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M5 12h14"></path>
              <path d="M12 5v14"></path>
            </svg>
            <svg
              class="hs-accordion-active:block hidden size-3.5"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M5 12h14"></path>
            </svg>
          </button>
          <div
            id="hs-basic-active-bordered-collapse-one"
            class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
            role="region"
            aria-labelledby="hs-active-bordered-heading-one"
          >
            <div class="pb-4 px-5">
              <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] ">
                Shortened links encourage more sharing because they are easier
                to remember and reproduce. They can be quickly typed during
                presentations and reduce the likelihood of errors, making the
                overall user experience more efficient.
              </p>
            </div>
          </div>
        </div>

        <div
          class="hs-accordion  bg-white border border-transparent rounded-xl dark:bg-neutral-800 dark:border-transparent drop-shadow-lg rounded-r-[24.06px] rounded-l-[24.06px]"
          id="hs-active-bordered-heading-two"
        >
          <button
            class="hs-accordion-toggle inline-flex justify-between items-center gap-x-3 w-full font-semibold text-2xl  text-start text-[#0A0B1E] py-7 px-6 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
            aria-expanded="true"
            aria-controls="hs-basic-active-bordered-collapse-two"
          >
            Why should you shorten links for social media?
            <svg
              class="hs-accordion-active:hidden block size-3.5"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M5 12h14"></path>
              <path d="M12 5v14"></path>
            </svg>
            <svg
              class="hs-accordion-active:block hidden size-3.5"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M5 12h14"></path>
            </svg>
          </button>
          <div
            id="hs-basic-active-bordered-collapse-two"
            class="hs-accordion-content w-full overflow-hidden transition-[height] duration-300"
            role="region"
            aria-labelledby="hs-active-bordered-heading-two"
          >
            <div class="pb-4 px-5">
              <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] ">
                Shortened links make your posts look cleaner and more
                professional. On platforms with character limits like Twitter,
                they allow you to maximize your messaging while sharing the
                necessary links. They are also easier for your audience to
                remember and engage with, reducing the likelihood of broken
                links from copy-pasting. Additionally, many link shorteners
                provide analytics, so you can track the performance of your
                links, helping you understand user behavior and optimize future
                posts.
              </p>
            </div>
          </div>
        </div>

        <div
          class="hs-accordion  bg-white border border-transparent rounded-xl dark:bg-neutral-800 dark:border-transparent drop-shadow-lg rounded-r-[24.06px] rounded-l-[24.06px]"
          id="hs-active-bordered-heading-three"
        >
          <button
            class="hs-accordion-toggle inline-flex justify-between items-center gap-x-3 w-full font-semibold text-2xl  text-start text-[#0A0B1E] py-7 px-6 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
            aria-expanded="false"
            aria-controls="hs-basic-active-bordered-collapse-three"
          >
            Are short links effective marketing tools?
            <svg
              class="hs-accordion-active:hidden block size-3.5"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M5 12h14"></path>
              <path d="M12 5v14"></path>
            </svg>
            <svg
              class="hs-accordion-active:block hidden size-3.5"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M5 12h14"></path>
            </svg>
          </button>
          <div
            id="hs-basic-active-bordered-collapse-three"
            class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
            role="region"
            aria-labelledby="hs-active-bordered-heading-three"
          >
            <div class="pb-4 px-5">
              <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] ">
                Shortened links make your posts look cleaner and more
                professional. On platforms with character limits like Twitter,
                they allow you to maximize your messaging while sharing the
                necessary links. They are also easier for your audience to
                remember and engage with, reducing the likelihood of broken
                links from copy-pasting. Additionally, many link shorteners
                provide analytics, so you can track the performance of your
                links, helping you understand user behavior and optimize future
                posts.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
