import React, { useState, useEffect } from "react";
import Header from "./Header";
import Hero1 from "./Hero1";
import Hero2 from "./Hero2";
import Hero3 from "./Hero3";
import Features1 from "./Features1";
import Features2 from "./Features2";
import Features3 from "./Features3";
import Footer1 from "./Footer1";
import Footer from "./Footer";
import Faq from "./Faq";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import axiosInstance from "../../instances/axiosInstance";
import Loader from "../subcomponents/Loader";
import HubSpotComponent from "../HubSpotComponent";

const Home1 = () => {
  const location = useLocation();

  const navigate = useNavigate();
  const url =
    process.env.REACT_APP_IDP_URL +
    "/oauth2/authorize?response_type=code&client_id=atz&scope=openid&redirect_uri=" +
    process.env.REACT_APP_ATZ_CALLBACK_URL;

  const introspectAPI = async () => {
    const data = JSON.stringify({
      data: {
        clientName: "atz",
      },
    });
    const token = JSON.parse(localStorage.getItem("token"));
    const dataRefresh = JSON.stringify({
      data: {
        clientName: "atz",
        refreshToken: `${token.refresh_token}`,
      },
    });
    const { access_token: accessToken } = token;
    const config = {
      method: "post",
      url: "/secured/introspect",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: data,
    };
    const configRefresh = {
      method: "post",
      url: "/core/refresh-token",
      headers: {
        "Content-Type": "application/json",
      },
      data: dataRefresh,
    };

    try {
      const introspectResponse = await axiosInstance(config);
      if (introspectResponse.data.data.active) {
        localStorage.setItem("activeLink", "/dashboard");
        navigate("/dashboard");
      } else {
        try {
          const responseRefresh = await axiosInstance(configRefresh);
          const token = responseRefresh.data.data;
          localStorage.setItem("token", JSON.stringify(token));
          localStorage.setItem("activeLink", "/dashboard");
          navigate("/dashboard");
        } catch (e) {
          localStorage.clear();
          navigate("/");
        }
      }
    } catch (error) {
      //handling 401 for introspect API
      if (error.response.status === 401) {
        //get the refresh
        try {
          const refreshResponse = await axiosInstance(configRefresh);
          const token = refreshResponse.data.data;
          localStorage.setItem("token", JSON.stringify(token));
          navigate("/dashboard");
        } catch (e) {
          localStorage.clear();
          navigate("/");
        }
      } else {
        localStorage.clear();

        navigate("/");
      }
    }
  };

  if (localStorage.getItem("token")) {
    introspectAPI();
  }

  useEffect(() => {
    // Reinitialize Preline components every time the location changes
    if (
      window.HSStaticMethods &&
      typeof window.HSStaticMethods.autoInit === "function"
    ) {
      window.HSStaticMethods.autoInit();
    }
  }, [location.pathname]);

  return (
    <>
      <Header url={url} />
      <Hero1 url={url} />
      <Hero2 url={url} />
      <Hero3 url={url} />
      <Features1 url={url} />
      <Features2 />
      <Features3 url={url} />
      <Faq />

      <Footer1 />
    </>
  );
};

export default Home1;
