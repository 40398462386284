import React, { useEffect, useState } from "react";
import Header from "../Landing/Header";
import { useLocation } from "react-router-dom";
import Footer1 from "../Landing/Footer1";
import HubSpotComponent from "../HubSpotComponent";

const Analytics1 = () => {
  const [visibleImg, setVisibleImg] = useState(0);
  const url =
    process.env.REACT_APP_IDP_URL +
    "/oauth2/authorize?response_type=code&client_id=atz&scope=openid&redirect_uri=" +
    process.env.REACT_APP_ATZ_CALLBACK_URL;
  const location = useLocation();
  useEffect(() => {
    console.log("visible img : ", visibleImg);
  }, [visibleImg]);
  useEffect(() => {
    // Reinitialize Preline components every time the location changes
    if (
      window.HSStaticMethods &&
      typeof window.HSStaticMethods.autoInit === "function"
    ) {
      window.HSStaticMethods.autoInit();
    }
  }, [location.pathname]);
  return (
    <>
      <Header url={url} />
      <div className="bg-white dark:bg-neutral-950 flex flex-col flex-1  items-center justify-center lg:px-28 p-4 pt-20 lg:pt-4">
        {/* hero open*/}
        <div className="grid md:grid-cols-2 grid-cols-1 place-items-center min-h-screen">
          <div className="flex flex-col gap-y-8">
            <div>
              <span className="relative bg-[#A275EA] bg-opacity-10 uppercase p-2 rounded-r-full rounded-l-full text-sm font-bold text-[#A275EA]">
                Atz Analytics
              </span>
            </div>
            <div>
              <span className="font-black text-[40px] text-[#353535] dark:text-neutral-50">
                Maximize Clicks, Optimize Results: Atz Analytics
              </span>
            </div>
            <div>
              <span className="text-xl font-medium text-[#353535] dark:text-neutral-50 space-y-2">
                Looking to optimize your marketing campaigns with real-time
                analytics?
                <br />
                <span className="flex flex-col space-y-2 items-center text-lg">
                  <div className="flex items-center gap-x-2">
                    <svg
                      width="24"
                      height="24"
                      className="flex-shrink-0"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="5.5"
                        fill="#A275EA"
                        stroke="white"
                      />
                      <path
                        d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                        fill="white"
                      />
                    </svg>
                    <span>
                      With Atz Analytics, you can track, analyze, and optimize
                      all your links and QR codes in one place. <br />
                    </span>
                  </div>
                  <div className="flex items-center gap-x-2">
                    <svg
                      width="24"
                      height="24"
                      className="flex-shrink-0"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="5.5"
                        fill="#A275EA"
                        stroke="white"
                      />
                      <path
                        d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                        fill="white"
                      />
                    </svg>
                    <span>
                      Our platform provides detailed insights into user
                      behavior, geographical data, device performance, and much
                      more, empowering you to make smarter decisions and{" "}
                      <strong> improve your marketing ROI.</strong>
                    </span>
                  </div>
                </span>
              </span>
            </div>
            <div className="flex items-center">
              <button
                className="border-2 border-[#A275EA] text-[#A275EA] rounded-r-full font-semibold text-lg rounded-l-full px-6 py-2.5 hover:drop-shadow-lg"
                onClick={() => (window.location.href = url)}
              >
                <span className="italic"> Start Tracking! </span>
              </button>
            </div>
          </div>
          <div className="">
            <img
              src={require("../../assets/landing/analyticsPage.png")}
              alt="linkshorten"
              className="w-auto"
            />
          </div>
        </div>
        {/* hero close */}
      </div>
      <div className="bg-[#A275EA] dark:bg-opacity-25 bg-opacity-10 lg:px-28 flex flex-col items-center lg:py-10 p-4">
        {/* benefits */}
        <div className="flex flex-col flex-1 justify-center gap-y-10">
          <div className="flex justify-center items-center dark:text-white font-black text-5xl">
            <span className="text-center">
              Examples of How{"\u00a0"}
              <span className="bg-[#A275EA] text-white z-[-1]">
                Atz Analytics
              </span>{" "}
              Works
            </span>
          </div>
          <div className="grid grid-cols-1 gap-x-6 gap-y-6">
            <div className="flex min-h-[162px]  rounded-3xl drop-shadow-lg bg-white dark:bg-neutral-800 p-[30px] gap-x-[29px]">
              <div>
                <img src={require("../../assets/landing/linksQr.png")} />
              </div>
              <div className="flex flex-col ">
                <div className="text-[#353535] dark:text-neutral-50 text-2xl font-bold">
                  Track Audience by Location
                </div>
                <div className="text-[#353535] dark:text-neutral-50 text-2xl">
                  Let’s say your primary traffic is coming from India—you can
                  drill down further to see how each state and city performs,
                  helping you optimize for specific regions like Gujarat or
                  Delhi.
                </div>
              </div>
            </div>
            <div className="flex min-h-[162px]  rounded-3xl drop-shadow-lg bg-white dark:bg-neutral-800 p-[30px] gap-x-[29px]">
              <div>
                {" "}
                <img src={require("../../assets/landing/customers.png")} />
              </div>
              <div className="flex flex-col ">
                <div className="text-[#353535] dark:text-neutral-50 text-2xl font-bold">
                  Optimize Campaign Timing
                </div>
                <div className="text-[#353535] dark:text-neutral-50 text-2xl">
                  By analyzing your link clicks by time, you can adjust your
                  campaign schedules to target peak traffic periods, increasing
                  your chances of higher engagement.
                </div>
              </div>
            </div>
            <div className="flex min-h-[162px] rounded-3xl items-center drop-shadow-lg bg-white dark:bg-neutral-800 p-[30px] gap-x-[29px]">
              <div>
                <img src={require("../../assets/landing/analyticsScan.png")} />
              </div>
              <div className="flex flex-col ">
                <div className="text-[#353535] dark:text-neutral-50 text-2xl font-bold">
                  Target the Right Platforms
                </div>
                <div className="text-[#353535] dark:text-neutral-50 text-2xl">
                  Understand which browsers and devices your audience uses
                  most—whether it’s Chrome on Android or Safari on iOS—and
                  fine-tune your content for those platforms.
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* benefits close */}
      </div>
      <div className="lg:px-28 flex flex-col lg:py-10 p-4 bg-gradient-to-b dark:bg-gradient-to-b from-[#F1EDF9] to-white dark:from-neutral-950 dark:to-blue-950/50">
        {/* features */}
        <div className="flex flex-1 items-center">
          <div className="flex flex-col flex-1 gap-y-10 dark:text-white justify-center">
            <div className="flex font-black text-5xl">
              <span className="relative text-5xl dark:text-neutral-50 font-black text-center ">
                Key Features
              </span>
            </div>
            <div class="hs-accordion-group space-y-8 min-w-full">
              <div
                class="hs-accordion active hs-accordion-active:border-l-4 hs-accordion-active:border-l-[#A275EA] hs-accordion-active:pl-2"
                id="hs-basic-no-arrow-heading-one"
              >
                <button
                  class="hs-accordion-toggle hs-accordion-active:text-[#A275EA] py-3 inline-flex justify-between items-center gap-x-3 w-full font-bold text-2xl  text-start text-[#0A0B1E]  hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
                  aria-expanded="true"
                  aria-controls="hs-basic-no-arrow-collapse-one"
                  onMouseDown={() => {
                    console.log("clcik");
                    setVisibleImg(0);
                  }}
                >
                  Track Total and Unique Clicks
                </button>
                <div
                  id="hs-basic-no-arrow-collapse-one"
                  class="hs-accordion-content w-full overflow-hidden transition-[height] duration-300"
                  role="region"
                  aria-labelledby="hs-basic-no-arrow-heading-one"
                >
                  <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] font-medium flex flex-col">
                    <div className="flex gap-x-2.5 items-center">
                      <div className="flex flex-none">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="23"
                            height="23"
                            rx="5.5"
                            fill="#A275EA"
                            stroke="white"
                          />
                          <path
                            d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                        Get precise information on both total clicks and unique
                        visitors for each of your links.
                      </div>
                    </div>
                    <div className="flex gap-x-2.5 items-center">
                      <div className="flex flex-none">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="23"
                            height="23"
                            rx="5.5"
                            fill="#A275EA"
                            stroke="white"
                          />
                          <path
                            d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                        Measure the effectiveness of your campaigns and see how
                        much traffic each link is driving with real-time data.
                      </div>
                    </div>
                  </p>
                </div>
              </div>

              <div
                class="hs-accordion hs-accordion-active:border-l-4 hs-accordion-active:border-l-[#A275EA] hs-accordion-active:pl-2"
                id="hs-basic-no-arrow-heading-two"
              >
                <button
                  class="hs-accordion-toggle hs-accordion-active:text-[#A275EA] py-3 inline-flex justify-between items-center gap-x-3 w-full font-bold text-2xl  text-start text-[#0A0B1E]  hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
                  aria-expanded="false"
                  aria-controls="hs-basic-no-arrow-collapse-two"
                  onMouseDown={() => {
                    console.log("clcik");
                    setVisibleImg(1);
                  }}
                >
                  Geographic Insights and Location Tracking
                </button>
                <div
                  id="hs-basic-no-arrow-collapse-two"
                  class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
                  role="region"
                  aria-labelledby="hs-basic-no-arrow-heading-two"
                >
                  <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] font-medium flex flex-col">
                    <div className="flex gap-x-2.5 items-center">
                      <div className="flex flex-none">
                        <svg
                          width="24"
                          s
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="23"
                            height="23"
                            rx="5.5"
                            fill="#A275EA"
                            stroke="white"
                          />
                          <path
                            d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                        Understand where your clicks are coming from with
                        detailed geographic analytics.
                      </div>
                    </div>
                    <div className="flex gap-x-2.5 items-center">
                      <div className="flex flex-none">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="23"
                            height="23"
                            rx="5.5"
                            fill="#A275EA"
                            stroke="white"
                          />
                          <path
                            d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                        Track performance down to the country, state, and even
                        city level, allowing you to create region-specific
                        marketing strategies.
                      </div>
                    </div>
                    <div className="flex gap-x-2.5 items-center">
                      <div className="flex flex-none">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="23"
                            height="23"
                            rx="5.5"
                            fill="#A275EA"
                            stroke="white"
                          />
                          <path
                            d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                        Whether your audience is in India, the United States, or
                        Europe, Atz helps you focus on key performing regions.
                      </div>
                    </div>
                  </p>
                </div>
              </div>

              <div
                class="hs-accordion hs-accordion-active:border-l-4 hs-accordion-active:border-l-[#A275EA] hs-accordion-active:pl-2"
                id="hs-basic-no-arrow-heading-three"
              >
                <button
                  class="hs-accordion-toggle hs-accordion-active:text-[#A275EA] py-3 inline-flex justify-between items-center gap-x-3 w-full font-bold text-2xl  text-start text-[#0A0B1E]  hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
                  aria-expanded="false"
                  aria-controls="hs-basic-no-arrow-collapse-three"
                  onMouseDown={() => {
                    console.log("clcik");
                    setVisibleImg(2);
                  }}
                >
                  Device, Browser, and OS Analytics
                </button>
                <div
                  id="hs-basic-no-arrow-collapse-three"
                  class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
                  role="region"
                  aria-labelledby="hs-basic-no-arrow-heading-three"
                >
                  <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] font-medium flex flex-col">
                    <div className="flex gap-x-2.5 items-center">
                      <div className="flex flex-none">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="23"
                            height="23"
                            rx="5.5"
                            fill="#A275EA"
                            stroke="white"
                          />
                          <path
                            d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                        Know what devices your audience is using.
                      </div>
                    </div>
                    <div className="flex gap-x-2.5 items-center">
                      <div className="flex flex-none">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="23"
                            height="23"
                            rx="5.5"
                            fill="#A275EA"
                            stroke="white"
                          />
                          <path
                            d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                        Track clicks based on the most popular browsers,
                        operating systems, and devices such as Android, iOS,
                        Windows, and more.
                      </div>
                    </div>
                    <div className="flex gap-x-2.5 items-center">
                      <div className="flex flex-none">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="23"
                            height="23"
                            rx="5.5"
                            fill="#A275EA"
                            stroke="white"
                          />
                          <path
                            d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                        With this information, you can optimize your content
                        delivery for specific platforms.
                      </div>
                    </div>
                  </p>
                </div>
              </div>
              <div
                class="hs-accordion hs-accordion-active:border-l-4 hs-accordion-active:border-l-[#A275EA] hs-accordion-active:pl-2"
                id="hs-basic-no-arrow-heading-four"
              >
                <button
                  class="hs-accordion-toggle hs-accordion-active:text-[#A275EA] py-3 inline-flex justify-between items-center gap-x-3 w-full font-bold text-2xl  text-start text-[#0A0B1E]  hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
                  aria-expanded="false"
                  aria-controls="hs-basic-no-arrow-collapse-four"
                  onMouseDown={() => {
                    console.log("clcik");
                    setVisibleImg(3);
                  }}
                >
                  Time-Based Click Scans
                </button>
                <div
                  id="hs-basic-no-arrow-collapse-four"
                  class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
                  role="region"
                  aria-labelledby="hs-basic-no-arrow-heading-four"
                >
                  <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] font-medium flex flex-col">
                    <div className="flex gap-x-2.5 items-center">
                      <div className="flex flex-none">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="23"
                            height="23"
                            rx="5.5"
                            fill="#A275EA"
                            stroke="white"
                          />
                          <path
                            d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                        With click scan by time periods, you can see when your
                        audience is most active.
                      </div>
                    </div>
                    <div className="flex gap-x-2.5 items-center">
                      <div className="flex flex-none">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="23"
                            height="23"
                            rx="5.5"
                            fill="#A275EA"
                            stroke="white"
                          />
                          <path
                            d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                        Monitor traffic patterns by hour, day, or month to
                        schedule your campaigns for maximum impact.
                      </div>
                    </div>
                  </p>
                </div>
              </div>
              <div
                class="hs-accordion hs-accordion-active:border-l-4 hs-accordion-active:border-l-[#A275EA] hs-accordion-active:pl-2"
                id="hs-basic-no-arrow-heading-four"
              >
                <button
                  class="hs-accordion-toggle hs-accordion-active:text-[#A275EA] py-3 inline-flex justify-between items-center gap-x-3 w-full font-bold text-2xl  text-start text-[#0A0B1E]  hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
                  aria-expanded="false"
                  aria-controls="hs-basic-no-arrow-collapse-four"
                  onMouseDown={() => {
                    console.log("clcik");
                    setVisibleImg(4);
                  }}
                >
                  Downloadable Reports
                </button>
                <div
                  id="hs-basic-no-arrow-collapse-four"
                  class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
                  role="region"
                  aria-labelledby="hs-basic-no-arrow-heading-four"
                >
                  <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] font-medium flex flex-col">
                    <div className="flex gap-x-2.5 items-center">
                      <div className="flex flex-none">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="23"
                            height="23"
                            rx="5.5"
                            fill="#A275EA"
                            stroke="white"
                          />
                          <path
                            d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                        Create and download reports with a single click.
                      </div>
                    </div>
                    <div className="flex gap-x-2.5 items-center">
                      <div className="flex flex-none">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="0.5"
                            width="23"
                            height="23"
                            rx="5.5"
                            fill="#A275EA"
                            stroke="white"
                          />
                          <path
                            d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                        Whether you need to present data to a client or discuss
                        performance with your team, you can download analytics
                        as PDFs for easy sharing.
                      </div>
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden md:block">
            <img
              src={require("../../assets/landing/analytics.png")}
              alt="linkshorten"
              className={`max-w-[400px] sm:max-w-[650px] transition-all ${
                visibleImg == 0 ? `` : `hidden`
              }`}
            />
            <img
              src={require("../../assets/landing/scanLocation.png")}
              alt="linkshorten"
              className={`w-auto transition-all ${
                visibleImg == 1 ? `` : `hidden`
              }`}
            />
            <img
              src={require("../../assets/landing/chartDevice.png")}
              alt="linkshorten"
              className={`w-auto transition-all ${
                visibleImg == 2 ? `` : `hidden`
              }`}
            />
            <img
              src={require("../../assets/landing/chart5Body.png")}
              alt="linkshorten"
              className={`w-auto transition-all ${
                visibleImg == 3 ? `` : `hidden`
              }`}
            />
            <img
              src={require("../../assets/landing/scanCountry.png")}
              alt="linkshorten"
              className={`w-auto transition-all ${
                visibleImg == 4 ? `` : `hidden`
              }`}
            />
          </div>
        </div>
      </div>
      {/* features close */}
      {/* benefits */}
      <div className="grid grid-cols-1 gap-y-10 lg:px-28 py-10 p-4 bg-white dark:bg-neutral-950">
        <div className="flex flex-col w-auto rounded-[50px] bg-gradient-to-l from-[#144EE3] via-[#EB568E] via-[#A353AA] to-[#144EE3] p-1">
          <div className="flex flex-1 lg:flex-row flex-col bg-white dark:bg-neutral-950 rounded-[50px]  p-10 items-center text-center">
            <div className="flex flex-col gap-y-5">
              <div>
                <div className="font-black text-[40px] text-[#353535] dark:text-neutral-50 ">
                  How{"\u00a0"}
                  <span className="bg-clip-text bg-gradient-to-l from-[#144EE3] via-[#EB568E] via-[#A353AA] to-[#144EE3] text-transparent">
                    Atz Analytics
                  </span>{" "}
                  Can Improve Your Campaigns?
                </div>
              </div>
              <div className="flex flex-col gap-y-3">
                <div className="flex gap-x-2.5 items-center">
                  <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                    Atz Analytics is designed to help you measure, monitor, and
                    improve the performance of your marketing campaigns. Whether
                    you're focused on local or global outreach, our analytics
                    tools give you the data you need to make informed decisions.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-auto rounded-[50px]">
          <div className="flex flex-1 lg:flex-row flex-col bg-[#C4E0FD] rounded-[50px] bg-opacity-30 p-10 items-center ">
            <div className="flex flex-col flex-1 gap-y-5">
              <div className="flex flex-1 justify-center">
                <div className="font-black text-[40px] text-[#353535] dark:text-neutral-50 ">
                  Why Use Atz Analytics?
                </div>
              </div>
              <div className="flex flex-col gap-y-3">
                <div className="flex gap-x-2.5 items-center">
                  <div className="flex flex-none">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="5.5"
                        fill="#3F9DFF"
                        stroke="#353535"
                      />
                      <path
                        d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                    Data-Driven Campaign Optimization : With our real-time
                    analytics, you’ll always have the information you need to
                    refine your strategy. Focus on what works and pivot quickly
                    when needed.
                  </div>
                </div>
                <div className="flex gap-x-2.5 items-center">
                  <div className="flex flex-none">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="5.5"
                        fill="#3F9DFF"
                        stroke="#353535"
                      />
                      <path
                        d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                    User-Friendly Interface : Our dashboard is designed for ease
                    of use, allowing you to view critical metrics like total
                    clicks, unique visitors, and location data in a matter of
                    seconds.
                  </div>
                </div>
                <div className="flex gap-x-2.5 items-center">
                  <div className="flex flex-none">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="5.5"
                        fill="#3F9DFF"
                        stroke="#353535"
                      />
                      <path
                        d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                    Real-Time Reporting : Get real-time updates on how your
                    campaigns are performing. React quickly to changes and take
                    advantage of real-time data to improve your results.
                  </div>
                </div>
                <div className="flex gap-x-2.5 items-center">
                  <div className="flex flex-none">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="5.5"
                        fill="#3F9DFF"
                        stroke="#353535"
                      />
                      <path
                        d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                    Enhanced Audience Targeting : Use location-based insights to
                    refine your targeting. Whether your clicks are coming from
                    major cities in India or regions in North America, you can
                    adapt your messaging to better engage your audience.
                  </div>
                </div>
                <div className="flex gap-x-2.5 items-center">
                  <div className="flex flex-none">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="5.5"
                        fill="#3F9DFF"
                        stroke="#353535"
                      />
                      <path
                        d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                    Cross-Platform and Device Insights : Track your audience’s
                    behavior across multiple platforms and devices. Whether your
                    users are on mobile, desktop, or using various operating
                    systems, you’ll know exactly how to optimize your content
                    for them.
                  </div>
                </div>
                <div className="flex gap-x-2.5 items-center">
                  <div className="flex flex-none">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="5.5"
                        fill="#3F9DFF"
                        stroke="#353535"
                      />
                      <path
                        d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                    Customizable and Shareable Reports : Customize your reports
                    and download them for easy sharing with clients or internal
                    teams. Turn data into insights that drive better
                    decision-making.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-auto rounded-[50px]">
          <div className="flex flex-1 lg:flex-row flex-col bg-[#A275EA]/10 rounded-[50px] bg-opacity-30 p-10 items-center ">
            <div className="flex flex-col flex-1 gap-y-5">
              <div className="flex flex-1 items-center justify-center">
                <div className="font-black text-[40px] text-[#353535] dark:text-neutral-50 ">
                  Start Optimizing Your Links Today with Atz Analytics
                </div>
              </div>
              <div className="flex flex-col gap-y-3 text-xl text-center dark:text-neutral-50">
                Unlock the full potential of your marketing campaigns with Atz
                Analytics. Gain access to real-time data and actionable insights
                that help you improve performance, refine your targeting, and
                drive better results.
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* benefits close*/}

      <div className="min-h-96 md:px-28 md:py-10 p-4 pb-12 flex flex-col items-center bg-white dark:bg-neutral-950 gap-y-8">
        <span className="relative text-5xl dark:text-neutral-50 font-black text-center ">
          FAQs
          <img
            src={require("../../assets/landing/Vector3.png")}
            className="absolute inset-0"
          />
        </span>
        <div class="hs-accordion-group space-y-6">
          <div
            class="hs-accordion  bg-white border border-transparent rounded-xl dark:bg-neutral-800 dark:border-transparent drop-shadow-lg rounded-r-[24.06px] rounded-l-[24.06px]"
            id="hs-active-bordered-heading-one"
          >
            <button
              class="hs-accordion-toggle inline-flex justify-between items-center gap-x-3 w-full font-semibold text-2xl  text-start text-[#0A0B1E] py-7 px-6 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
              aria-expanded="false"
              aria-controls="hs-basic-active-bordered-collapse-one"
            >
              What is Atz Analytics?
              <svg
                class="hs-accordion-active:hidden block size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5v14"></path>
              </svg>
              <svg
                class="hs-accordion-active:block hidden size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
              </svg>
            </button>
            <div
              id="hs-basic-active-bordered-collapse-one"
              class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
              role="region"
              aria-labelledby="hs-active-bordered-heading-one"
            >
              <div class="pb-4 px-5">
                <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] ">
                  Atz Analytics is a powerful link and QR code analytics tool
                  that allows you to track, analyze, and optimize the
                  performance of your marketing campaigns in real-time. With Atz
                  Analytics, you can measure total clicks, unique clicks,
                  geographic data, device preferences, and more.
                </p>
              </div>
            </div>
          </div>

          <div
            class="hs-accordion  bg-white border border-transparent rounded-xl dark:bg-neutral-800 dark:border-transparent drop-shadow-lg rounded-r-[24.06px] rounded-l-[24.06px]"
            id="hs-active-bordered-heading-two"
          >
            <button
              class="hs-accordion-toggle inline-flex justify-between items-center gap-x-3 w-full font-semibold text-2xl  text-start text-[#0A0B1E] py-7 px-6 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
              aria-expanded="true"
              aria-controls="hs-basic-active-bordered-collapse-two"
            >
              How can I use Atz Analytics to improve my marketing campaigns?
              <svg
                class="hs-accordion-active:hidden block size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5v14"></path>
              </svg>
              <svg
                class="hs-accordion-active:block hidden size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
              </svg>
            </button>
            <div
              id="hs-basic-active-bordered-collapse-two"
              class="hs-accordion-content w-full overflow-hidden transition-[height] duration-300"
              role="region"
              aria-labelledby="hs-active-bordered-heading-two"
            >
              <div class="pb-4 px-5">
                <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] ">
                  Atz Analytics helps you understand how your audience interacts
                  with your content. With insights into clicks, geographic
                  location, devices, and time of engagement, you can refine your
                  marketing strategies, optimize your ad spend, and improve
                  overall campaign performance.
                </p>
              </div>
            </div>
          </div>

          <div
            class="hs-accordion  bg-white border border-transparent rounded-xl dark:bg-neutral-800 dark:border-transparent drop-shadow-lg rounded-r-[24.06px] rounded-l-[24.06px]"
            id="hs-active-bordered-heading-three"
          >
            <button
              class="hs-accordion-toggle inline-flex justify-between items-center gap-x-3 w-full font-semibold text-2xl  text-start text-[#0A0B1E] py-7 px-6 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
              aria-expanded="false"
              aria-controls="hs-basic-active-bordered-collapse-three"
            >
              Can I track where my clicks are coming from?
              <svg
                class="hs-accordion-active:hidden block size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5v14"></path>
              </svg>
              <svg
                class="hs-accordion-active:block hidden size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
              </svg>
            </button>
            <div
              id="hs-basic-active-bordered-collapse-three"
              class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
              role="region"
              aria-labelledby="hs-active-bordered-heading-three"
            >
              <div class="pb-4 px-5">
                <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] ">
                  Yes! Atz Analytics allows you to track the geographic location
                  of your clicks, from the country level down to the city level.
                  This helps you better understand your audience’s location and
                  target specific regions more effectively.
                </p>
              </div>
            </div>
          </div>
          <div
            class="hs-accordion  bg-white border border-transparent rounded-xl dark:bg-neutral-800 dark:border-transparent drop-shadow-lg rounded-r-[24.06px] rounded-l-[24.06px]"
            id="hs-active-bordered-heading-four"
          >
            <button
              class="hs-accordion-toggle inline-flex justify-between items-center gap-x-3 w-full font-semibold text-2xl  text-start text-[#0A0B1E] py-7 px-6 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
              aria-expanded="false"
              aria-controls="hs-basic-active-bordered-collapse-four"
            >
              What kind of device and browser information can I track?
              <svg
                class="hs-accordion-active:hidden block size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5v14"></path>
              </svg>
              <svg
                class="hs-accordion-active:block hidden size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
              </svg>
            </button>
            <div
              id="hs-basic-active-bordered-collapse-four"
              class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
              role="region"
              aria-labelledby="hs-active-bordered-heading-four"
            >
              <div class="pb-4 px-5">
                <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] ">
                  Atz Analytics provides detailed insights into the devices,
                  operating systems, and browsers your audience uses. This
                  allows you to optimize your content for the most popular
                  platforms, ensuring a seamless user experience.
                </p>
              </div>
            </div>
          </div>
          <div
            class="hs-accordion  bg-white border border-transparent rounded-xl dark:bg-neutral-800 dark:border-transparent drop-shadow-lg rounded-r-[24.06px] rounded-l-[24.06px]"
            id="hs-active-bordered-heading-five"
          >
            <button
              class="hs-accordion-toggle inline-flex justify-between items-center gap-x-3 w-full font-semibold text-2xl  text-start text-[#0A0B1E] py-7 px-6 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
              aria-expanded="false"
              aria-controls="hs-basic-active-bordered-collapse-five"
            >
              How can I download reports from Atz Analytics?
              <svg
                class="hs-accordion-active:hidden block size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5v14"></path>
              </svg>
              <svg
                class="hs-accordion-active:block hidden size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
              </svg>
            </button>
            <div
              id="hs-basic-active-bordered-collapse-five"
              class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
              role="region"
              aria-labelledby="hs-active-bordered-heading-five"
            >
              <div class="pb-4 px-5">
                <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] ">
                  You can easily download your analytics data as PDFs directly
                  from the dashboard. These reports can be customized and shared
                  with clients or internal teams for further analysis.
                </p>
              </div>
            </div>
          </div>
          <div
            class="hs-accordion  bg-white border border-transparent rounded-xl dark:bg-neutral-800 dark:border-transparent drop-shadow-lg rounded-r-[24.06px] rounded-l-[24.06px]"
            id="hs-active-bordered-heading-six"
          >
            <button
              class="hs-accordion-toggle inline-flex justify-between items-center gap-x-3 w-full font-semibold text-2xl  text-start text-[#0A0B1E] py-7 px-6 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
              aria-expanded="false"
              aria-controls="hs-basic-active-bordered-collapse-six"
            >
              How do I monitor clicks by time periods?
              <svg
                class="hs-accordion-active:hidden block size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5v14"></path>
              </svg>
              <svg
                class="hs-accordion-active:block hidden size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
              </svg>
            </button>
            <div
              id="hs-basic-active-bordered-collapse-six"
              class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
              role="region"
              aria-labelledby="hs-active-bordered-heading-six"
            >
              <div class="pb-4 px-5">
                <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] ">
                  Atz Analytics allows you to track clicks by specific time
                  periods, including by hour, day, or month. This feature helps
                  you identify peak activity times so you can optimize your
                  campaign schedules accordingly.
                </p>
              </div>
            </div>
          </div>
          <div
            class="hs-accordion  bg-white border border-transparent rounded-xl dark:bg-neutral-800 dark:border-transparent drop-shadow-lg rounded-r-[24.06px] rounded-l-[24.06px]"
            id="hs-active-bordered-heading-seven"
          >
            <button
              class="hs-accordion-toggle inline-flex justify-between items-center gap-x-3 w-full font-semibold text-2xl  text-start text-[#0A0B1E] py-7 px-6 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
              aria-expanded="false"
              aria-controls="hs-basic-active-bordered-collapse-seven"
            >
              Do I need technical skills to use Atz Analytics?
              <svg
                class="hs-accordion-active:hidden block size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5v14"></path>
              </svg>
              <svg
                class="hs-accordion-active:block hidden size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
              </svg>
            </button>
            <div
              id="hs-basic-active-bordered-collapse-seven"
              class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
              role="region"
              aria-labelledby="hs-active-bordered-heading-seven"
            >
              <div class="pb-4 px-5">
                <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] ">
                  Not at all! Atz Analytics is designed with user-friendliness
                  in mind. Our intuitive dashboard makes it easy for anyone to
                  navigate and gain actionable insights without needing advanced
                  technical knowledge.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" bg-gradient-to-b dark:bg-gradient-to-b dark:from-neutral-950 dark:to-[#A275EA]/50 from-white to-[#A275EA]/20 flex flex-1 flex-col items-center justify-center p-4 lg:px-28 lg:py-10">
        <div className="flex flex-1 flex-col items-center gap-y-5 text-center">
          <span className="font-black text-5xl dark:text-neutral-100 ">
            Don't Miss Out
          </span>
          <span className="font-medium text-4xl text-center dark:text-neutral-100">
            <a href={url} className="hover:underline">
              Sign Up and Start Tracking
            </a>{" "}
          </span>
        </div>
      </div>

      <Footer1 />
    </>
  );
};

export default Analytics1;
