import React, { useEffect, useState } from "react";
import Header from "../Landing/Header";
import { useLocation } from "react-router-dom";
import Footer1 from "../Landing/Footer1";

const QR1 = () => {
  const location = useLocation();
  const url =
    process.env.REACT_APP_IDP_URL +
    "/oauth2/authorize?response_type=code&client_id=atz&scope=openid&redirect_uri=" +
    process.env.REACT_APP_ATZ_CALLBACK_URL;
  useEffect(() => {
    // Reinitialize Preline components every time the location changes
    if (
      window.HSStaticMethods &&
      typeof window.HSStaticMethods.autoInit === "function"
    ) {
      window.HSStaticMethods.autoInit();
    }
  }, [location.pathname]);
  return (
    <>
      <Header url={url} />
      <div className="bg-white dark:bg-neutral-950 flex flex-col flex-1  items-center justify-center lg:px-28 p-4 pt-20 lg:pt-4">
        {/* hero open*/}
        <div className="grid grid-cols-1 md:grid-cols-2 place-items-center min-h-screen">
          <div className="flex flex-col gap-y-5">
            <div>
              <span className="relative bg-[#EEEEEE] bg-opacity-40 uppercase p-2 rounded-r-full rounded-l-full text-sm font-bold text-[#3F4469]">
                Atz QR Codes
              </span>
            </div>
            <div>
              <span className="font-black text-[40px] text-[#353535] dark:text-neutral-50">
                Atz QR Code Generator: Transform Your Links into Engaging QR
                Codes
              </span>
            </div>
            <div>
              <span className="text-xl font-medium text-[#353535] dark:text-neutral-50">
                Tired of boring old links? Turn them into interactive QR codes
                that pop! With Atz, you can create fully customizable codes that
                scream your brand and make a splash with your audience.
              </span>
            </div>
            <div className="flex flex-col gap-y-2.5">
              <span className="text-xl font-black dark:text-neutral-50">
                Why choose Atz QR codes?
              </span>
              <div className="flex gap-x-2.5 items-center">
                <div className="flex flex-none">
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="1.44029"
                      width="23"
                      height="23"
                      rx="5.5"
                      fill="#EEEEEE"
                      stroke="#3F4469"
                    />
                    <path
                      d="M19.2872 10.2869L11.6002 17.9734C11.0309 18.5427 10.1075 18.5427 9.53776 17.9734L5.56967 14.005C5.00022 13.4356 5.00022 12.5121 5.56967 11.9427C6.13922 11.3731 7.06254 11.3731 7.63184 11.9425L10.5693 14.8799L17.2247 8.22448C17.7942 7.65492 18.7176 7.65535 19.287 8.22448C19.8563 8.79393 19.8563 9.7171 19.2872 10.2869Z"
                      fill="#3F4469"
                    />
                  </svg>
                </div>
                <div className="flex flex-1  text-base dark:text-neutral-50 text-[#353535]">
                  Customizable: Match your brand’s style to a tee.
                </div>
              </div>
              <div className="flex gap-x-2.5 items-center">
                <div className="flex flex-none">
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="1.44029"
                      width="23"
                      height="23"
                      rx="5.5"
                      fill="#EEEEEE"
                      stroke="#3F4469"
                    />
                    <path
                      d="M19.2872 10.2869L11.6002 17.9734C11.0309 18.5427 10.1075 18.5427 9.53776 17.9734L5.56967 14.005C5.00022 13.4356 5.00022 12.5121 5.56967 11.9427C6.13922 11.3731 7.06254 11.3731 7.63184 11.9425L10.5693 14.8799L17.2247 8.22448C17.7942 7.65492 18.7176 7.65535 19.287 8.22448C19.8563 8.79393 19.8563 9.7171 19.2872 10.2869Z"
                      fill="#3F4469"
                    />
                  </svg>
                </div>
                <div className="flex flex-1  text-base dark:text-neutral-50 text-[#353535]">
                  Trackable: See how many people are scanning and clicking.
                </div>
              </div>
              <div className="flex gap-x-2.5 items-center">
                <div className="flex flex-none">
                  <svg
                    width="24"
                    height="25"
                    viewBox="0 0 24 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect
                      x="0.5"
                      y="1.44029"
                      width="23"
                      height="23"
                      rx="5.5"
                      fill="#EEEEEE"
                      stroke="#3F4469"
                    />
                    <path
                      d="M19.2872 10.2869L11.6002 17.9734C11.0309 18.5427 10.1075 18.5427 9.53776 17.9734L5.56967 14.005C5.00022 13.4356 5.00022 12.5121 5.56967 11.9427C6.13922 11.3731 7.06254 11.3731 7.63184 11.9425L10.5693 14.8799L17.2247 8.22448C17.7942 7.65492 18.7176 7.65535 19.287 8.22448C19.8563 8.79393 19.8563 9.7171 19.2872 10.2869Z"
                      fill="#3F4469"
                    />
                  </svg>
                </div>
                <div className="flex flex-1  text-base dark:text-neutral-50 text-[#353535]">
                  Engaging: Make your content more interactive and fun.
                </div>
              </div>
            </div>
            <div className="flex items-center">
              <button
                className="border-2 border-[#3F4469] text-[#3F4469] rounded-r-full font-semibold text-lg rounded-l-full px-6 py-2.5 hover:drop-shadow-lg"
                onClick={() => (window.location.href = url)}
              >
                Generate Your <span className="italic">QR Code</span> Now!
              </button>
            </div>
          </div>
          <div>
            <img
              src={require("../../assets/landing/qrPage.png")}
              alt="linkshorten"
              className="w-auto"
            />
          </div>
        </div>
        {/* hero close */}
      </div>
      <div className="bg-[#EEEEEE] dark:bg-opacity-20  lg:px-28 flex flex-col items-center lg:py-10 p-4">
        {/* benefits */}
        <div className="flex flex-col flex-1 justify-center gap-y-10">
          <div className="flex justify-center items-center dark:text-white font-black text-5xl">
            <span className="text-center">
              Who Can Benefit from{" "}
              <span className="bg-[#C4E0FD] dark:text-black z-[-1]">
                Atz QR Codes?
              </span>{" "}
            </span>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-6 gap-y-6">
            <div className="flex min-h-[162px]  rounded-3xl drop-shadow-lg bg-white dark:bg-neutral-800 p-[30px] gap-x-[29px]">
              <div>
                <img src={require("../../assets/landing/linksQr.png")} />
              </div>
              <div className="flex flex-col ">
                <div className="text-[#353535] dark:text-neutral-50 text-2xl font-bold">
                  Marketing Agencies:
                </div>
                <div className="text-[#353535] dark:text-neutral-50 text-2xl">
                  Run data-driven campaigns with trackable QR codes that provide
                  deep insights into user engagement.
                </div>
              </div>
            </div>
            <div className="flex min-h-[162px]  rounded-3xl drop-shadow-lg bg-white dark:bg-neutral-800 p-[30px] gap-x-[29px]">
              <div>
                {" "}
                <img src={require("../../assets/landing/customers.png")} />
              </div>
              <div className="flex flex-col ">
                <div className="text-[#353535] dark:text-neutral-50 text-2xl font-bold">
                  Event Organizers:
                </div>
                <div className="text-[#353535] dark:text-neutral-50 text-2xl">
                  Share event details, tickets, and promotional offers via
                  scannable QR codes that attendees can access easily.
                </div>
              </div>
            </div>
            <div className=" flex min-h-[162px] rounded-3xl drop-shadow-lg bg-white dark:bg-neutral-800 p-[30px] gap-x-[29px]">
              <div>
                <img src={require("../../assets/landing/analyticsScan.png")} />
              </div>
              <div className="flex flex-col ">
                <div className="text-[#353535] dark:text-neutral-50 text-2xl font-bold">
                  E-commerce Stores:
                </div>
                <div className="text-[#353535] dark:text-neutral-50 text-2xl">
                  Link directly to product pages, promotional discounts, or
                  customer reviews with branded QR codes that drive conversions.
                </div>
              </div>
            </div>
            <div className=" flex min-h-[162px] rounded-3xl drop-shadow-lg bg-white dark:bg-neutral-800 p-[30px] gap-x-[29px]">
              <div>
                <img src={require("../../assets/landing/analyticsScan.png")} />
              </div>
              <div className="flex flex-col ">
                <div className="text-[#353535] dark:text-neutral-50 text-2xl font-bold">
                  Restaurants & Cafes:
                </div>
                <div className="text-[#353535] dark:text-neutral-50 text-2xl">
                  Use QR codes to provide easy access to your menu,
                  reservations, and special offers, enhancing the customer
                  experience.
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* benefits close */}
      </div>
      <div className="lg:px-28 flex flex-col lg:py-10 p-4 bg-gradient-to-b dark:bg-gradient-to-b to-white from-[#EEEEEE] dark:to-neutral-950 dark:from-[#EEEEEE]/20">
        {/* features */}
        <div className="flex flex-1 lg:flex-row flex-col items-center">
          <div className="flex flex-col flex-1 gap-y-10 dark:text-white ">
            <div className="flex font-black text-5xl">
              <span className="relative text-5xl dark:text-neutral-50 font-black  ">
                Features of Atz QR Code Generator
              </span>
            </div>
            <div class="hs-accordion-group space-y-8 min-w-full">
              <div
                class="hs-accordion active hs-accordion-active:border-l-4 hs-accordion-active:border-l-[#3F4469] hs-accordion-active:pl-2"
                id="hs-basic-no-arrow-heading-one"
              >
                <button
                  class="hs-accordion-toggle hs-accordion-active:text-[#3F4469] py-3 inline-flex justify-between items-center gap-x-3 w-full font-bold text-2xl  text-start text-[#0A0B1E]  hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
                  aria-expanded="true"
                  aria-controls="hs-basic-no-arrow-collapse-one"
                >
                  Customizable Design
                </button>
                <div
                  id="hs-basic-no-arrow-collapse-one"
                  class="hs-accordion-content w-full overflow-hidden transition-[height] duration-300"
                  role="region"
                  aria-labelledby="hs-basic-no-arrow-heading-one"
                >
                  <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] font-medium flex flex-col ">
                    <div className="flex gap-x-2.5 items-center">
                      <div className="flex flex-none">
                        <svg
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="1.44029"
                            width="23"
                            height="23"
                            rx="5.5"
                            fill="#EEEEEE"
                            stroke="#3F4469"
                          />
                          <path
                            d="M19.2872 10.2869L11.6002 17.9734C11.0309 18.5427 10.1075 18.5427 9.53776 17.9734L5.56967 14.005C5.00022 13.4356 5.00022 12.5121 5.56967 11.9427C6.13922 11.3731 7.06254 11.3731 7.63184 11.9425L10.5693 14.8799L17.2247 8.22448C17.7942 7.65492 18.7176 7.65535 19.287 8.22448C19.8563 8.79393 19.8563 9.7171 19.2872 10.2869Z"
                            fill="#3F4469"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                        Personalize your QR codes with different styles, colors,
                        and branded logos to make them uniquely yours.
                      </div>
                    </div>
                    <div className="flex gap-x-2.5 items-center">
                      <div className="flex flex-none">
                        <svg
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="1.44029"
                            width="23"
                            height="23"
                            rx="5.5"
                            fill="#EEEEEE"
                            stroke="#3F4469"
                          />
                          <path
                            d="M19.2872 10.2869L11.6002 17.9734C11.0309 18.5427 10.1075 18.5427 9.53776 17.9734L5.56967 14.005C5.00022 13.4356 5.00022 12.5121 5.56967 11.9427C6.13922 11.3731 7.06254 11.3731 7.63184 11.9425L10.5693 14.8799L17.2247 8.22448C17.7942 7.65492 18.7176 7.65535 19.287 8.22448C19.8563 8.79393 19.8563 9.7171 19.2872 10.2869Z"
                            fill="#3F4469"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                        Create a QR code that matches your brand identity in
                        just a few clicks.
                      </div>
                    </div>
                  </p>
                </div>
              </div>

              <div
                class="hs-accordion hs-accordion-active:border-l-4 hs-accordion-active:border-l-[#3F4469] hs-accordion-active:pl-2"
                id="hs-basic-no-arrow-heading-two"
              >
                <button
                  class="hs-accordion-toggle hs-accordion-active:text-[#3F4469] py-3 inline-flex justify-between items-center gap-x-3 w-full font-bold text-2xl  text-start text-[#0A0B1E]  hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
                  aria-expanded="false"
                  aria-controls="hs-basic-no-arrow-collapse-two"
                >
                  Dynamic QR Codes
                </button>
                <div
                  id="hs-basic-no-arrow-collapse-two"
                  class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
                  role="region"
                  aria-labelledby="hs-basic-no-arrow-heading-two"
                >
                  <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] font-medium flex flex-col">
                    <div className="flex gap-x-2.5 items-center">
                      <div className="flex flex-none">
                        <svg
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="1.44029"
                            width="23"
                            height="23"
                            rx="5.5"
                            fill="#EEEEEE"
                            stroke="#3F4469"
                          />
                          <path
                            d="M19.2872 10.2869L11.6002 17.9734C11.0309 18.5427 10.1075 18.5427 9.53776 17.9734L5.56967 14.005C5.00022 13.4356 5.00022 12.5121 5.56967 11.9427C6.13922 11.3731 7.06254 11.3731 7.63184 11.9425L10.5693 14.8799L17.2247 8.22448C17.7942 7.65492 18.7176 7.65535 19.287 8.22448C19.8563 8.79393 19.8563 9.7171 19.2872 10.2869Z"
                            fill="#3F4469"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                        Update the destination URL behind your QR code anytime
                        without needing to create a new one.
                      </div>
                    </div>
                    <div className="flex gap-x-2.5 items-center">
                      <div className="flex flex-none">
                        <svg
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="1.44029"
                            width="23"
                            height="23"
                            rx="5.5"
                            fill="#EEEEEE"
                            stroke="#3F4469"
                          />
                          <path
                            d="M19.2872 10.2869L11.6002 17.9734C11.0309 18.5427 10.1075 18.5427 9.53776 17.9734L5.56967 14.005C5.00022 13.4356 5.00022 12.5121 5.56967 11.9427C6.13922 11.3731 7.06254 11.3731 7.63184 11.9425L10.5693 14.8799L17.2247 8.22448C17.7942 7.65492 18.7176 7.65535 19.287 8.22448C19.8563 8.79393 19.8563 9.7171 19.2872 10.2869Z"
                            fill="#3F4469"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                        This feature ensures that your QR codes stay relevant,
                        even as your content evolves.
                      </div>
                    </div>
                  </p>
                </div>
              </div>

              <div
                class="hs-accordion hs-accordion-active:border-l-4 hs-accordion-active:border-l-[#3F4469] hs-accordion-active:pl-2"
                id="hs-basic-no-arrow-heading-three"
              >
                <button
                  class="hs-accordion-toggle hs-accordion-active:text-[#3F4469] py-3 inline-flex justify-between items-center gap-x-3 w-full font-bold text-2xl  text-start text-[#0A0B1E]  hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
                  aria-expanded="false"
                  aria-controls="hs-basic-no-arrow-collapse-three"
                >
                  Advanced Tracking & Analytics
                </button>
                <div
                  id="hs-basic-no-arrow-collapse-three"
                  class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
                  role="region"
                  aria-labelledby="hs-basic-no-arrow-heading-three"
                >
                  <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] font-medium flex flex-col">
                    <div className="flex gap-x-2.5 items-center">
                      <div className="flex flex-none">
                        <svg
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="1.44029"
                            width="23"
                            height="23"
                            rx="5.5"
                            fill="#EEEEEE"
                            stroke="#3F4469"
                          />
                          <path
                            d="M19.2872 10.2869L11.6002 17.9734C11.0309 18.5427 10.1075 18.5427 9.53776 17.9734L5.56967 14.005C5.00022 13.4356 5.00022 12.5121 5.56967 11.9427C6.13922 11.3731 7.06254 11.3731 7.63184 11.9425L10.5693 14.8799L17.2247 8.22448C17.7942 7.65492 18.7176 7.65535 19.287 8.22448C19.8563 8.79393 19.8563 9.7171 19.2872 10.2869Z"
                            fill="#3F4469"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                        Measure the success of your campaigns with real-time
                        analytics.
                      </div>
                    </div>
                    <div className="flex gap-x-2.5 items-center">
                      <div className="flex flex-none">
                        <svg
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="1.44029"
                            width="23"
                            height="23"
                            rx="5.5"
                            fill="#EEEEEE"
                            stroke="#3F4469"
                          />
                          <path
                            d="M19.2872 10.2869L11.6002 17.9734C11.0309 18.5427 10.1075 18.5427 9.53776 17.9734L5.56967 14.005C5.00022 13.4356 5.00022 12.5121 5.56967 11.9427C6.13922 11.3731 7.06254 11.3731 7.63184 11.9425L10.5693 14.8799L17.2247 8.22448C17.7942 7.65492 18.7176 7.65535 19.287 8.22448C19.8563 8.79393 19.8563 9.7171 19.2872 10.2869Z"
                            fill="#3F4469"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                        Track scans, locations, devices, and times to understand
                        user behavior and optimize your strategy.
                      </div>
                    </div>
                  </p>
                </div>
              </div>
              <div
                class="hs-accordion hs-accordion-active:border-l-4 hs-accordion-active:border-l-[#3F4469] hs-accordion-active:pl-2"
                id="hs-basic-no-arrow-heading-four"
              >
                <button
                  class="hs-accordion-toggle hs-accordion-active:text-[#3F4469] py-3 inline-flex justify-between items-center gap-x-3 w-full font-bold text-2xl  text-start text-[#0A0B1E]  hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
                  aria-expanded="false"
                  aria-controls="hs-basic-no-arrow-collapse-four"
                >
                  High-Resolution QR Codes for Print & Digital
                </button>
                <div
                  id="hs-basic-no-arrow-collapse-four"
                  class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
                  role="region"
                  aria-labelledby="hs-basic-no-arrow-heading-four"
                >
                  <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] font-medium flex flex-col">
                    <div className="flex gap-x-2.5 items-center">
                      <div className="flex flex-none">
                        <svg
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="1.44029"
                            width="23"
                            height="23"
                            rx="5.5"
                            fill="#EEEEEE"
                            stroke="#3F4469"
                          />
                          <path
                            d="M19.2872 10.2869L11.6002 17.9734C11.0309 18.5427 10.1075 18.5427 9.53776 17.9734L5.56967 14.005C5.00022 13.4356 5.00022 12.5121 5.56967 11.9427C6.13922 11.3731 7.06254 11.3731 7.63184 11.9425L10.5693 14.8799L17.2247 8.22448C17.7942 7.65492 18.7176 7.65535 19.287 8.22448C19.8563 8.79393 19.8563 9.7171 19.2872 10.2869Z"
                            fill="#3F4469"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                        Generate high-quality QR codes that can be used across
                        both digital platforms and printed materials like
                        flyers, brochures, and business cards.
                      </div>
                    </div>
                  </p>
                </div>
              </div>
              <div
                class="hs-accordion hs-accordion-active:border-l-4 hs-accordion-active:border-l-[#3F4469] hs-accordion-active:pl-2"
                id="hs-basic-no-arrow-heading-five"
              >
                <button
                  class="hs-accordion-toggle hs-accordion-active:text-[#3F4469] py-3 inline-flex justify-between items-center gap-x-3 w-full font-bold text-2xl  text-start text-[#0A0B1E]  hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
                  aria-expanded="false"
                  aria-controls="hs-basic-no-arrow-collapse-five"
                >
                  Secure & Password-Protected QR Codes
                </button>
                <div
                  id="hs-basic-no-arrow-collapse-five"
                  class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
                  role="region"
                  aria-labelledby="hs-basic-no-arrow-heading-five"
                >
                  <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] font-medium flex flex-col">
                    <div className="flex gap-x-2.5 items-center">
                      <div className="flex flex-none">
                        <svg
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <rect
                            x="0.5"
                            y="1.44029"
                            width="23"
                            height="23"
                            rx="5.5"
                            fill="#EEEEEE"
                            stroke="#3F4469"
                          />
                          <path
                            d="M19.2872 10.2869L11.6002 17.9734C11.0309 18.5427 10.1075 18.5427 9.53776 17.9734L5.56967 14.005C5.00022 13.4356 5.00022 12.5121 5.56967 11.9427C6.13922 11.3731 7.06254 11.3731 7.63184 11.9425L10.5693 14.8799L17.2247 8.22448C17.7942 7.65492 18.7176 7.65535 19.287 8.22448C19.8563 8.79393 19.8563 9.7171 19.2872 10.2869Z"
                            fill="#3F4469"
                          />
                        </svg>
                      </div>
                      <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                        Protect sensitive information with password-protected QR
                        codes, ensuring only authorized users can access the
                        content.
                      </div>
                    </div>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div>
            <img
              src={require("../../assets/landing/qrcodesremovebg.png")}
              alt="linkshorten"
              className="w-auto"
            />
          </div>
        </div>
      </div>
      {/* features close */}
      {/* benefits */}
      <div className="grid grid-cols-1 gap-y-10 lg:px-28 py-10 p-4 bg-white dark:bg-neutral-950">
        <div className="flex flex-col w-auto rounded-[50px] bg-gradient-to-tr from-[#EEEEEE]  to-[#6482AD] p-1">
          <div className="flex flex-1 lg:flex-row flex-col bg-white dark:bg-neutral-950 rounded-[50px]  p-10 items-center text-center">
            <div className="flex flex-col gap-y-5">
              <div>
                <div className="font-black text-[40px] text-[#353535] dark:text-neutral-50 ">
                  What is <span className="text-[#6482AD]">Atz QR Code</span>{" "}
                  Generator?
                </div>
              </div>
              <div className="flex flex-col gap-y-2.5">
                <div className="flex gap-x-2.5 items-center">
                  <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                    Atz QR Code Generator allows you to convert any URL into a
                    scannable QR code that’s easy to share and track. Whether
                    for marketing campaigns, business cards, or product
                    packaging, our QR codes provide a modern solution for
                    seamless customer interaction.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-auto rounded-[50px]">
          <div className="flex flex-1 lg:flex-row flex-col bg-[#C4E0FD] rounded-[50px] bg-opacity-30 p-10 items-center">
            <div className="flex flex-col flex-1 gap-y-5">
              <div className="flex flex-1 justify-center">
                <div className="font-black text-[40px] text-[#353535] dark:text-neutral-50 ">
                  Why Choose Atz QR Code Generator?
                </div>
              </div>
              <div className="flex flex-col gap-y-2.5">
                <div className="flex gap-x-2.5 items-center">
                  <div className="flex flex-none">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="5.5"
                        fill="#3F9DFF"
                        stroke="#353535"
                      />
                      <path
                        d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                    Custom Branding: Create QR codes with your brand’s colors
                    and logos to build a cohesive customer experience.
                  </div>
                </div>
                <div className="flex gap-x-2.5 items-center">
                  <div className="flex flex-none">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="5.5"
                        fill="#3F9DFF"
                        stroke="#353535"
                      />
                      <path
                        d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                    Easy Integration: Use our QR codes across websites, social
                    media, print marketing, and product packaging.
                  </div>
                </div>
                <div className="flex gap-x-2.5 items-center">
                  <div className="flex flex-none">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="5.5"
                        fill="#3F9DFF"
                        stroke="#353535"
                      />
                      <path
                        d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                    Real-Time Analytics: Understand how users interact with your
                    QR codes and make data-driven decisions for your business.
                  </div>
                </div>
                <div className="flex gap-x-2.5 items-center">
                  <div className="flex flex-none">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="5.5"
                        fill="#3F9DFF"
                        stroke="#353535"
                      />
                      <path
                        d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                    Effortless Updates: With dynamic QR codes, you can easily
                    change the destination URL without reprinting or reissuing
                    the QR code.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col w-auto rounded-[50px]">
          <div className="flex flex-1 lg:flex-row flex-col bg-[#cffdc4] rounded-[50px] bg-opacity-30 p-10 items-center">
            <div className="flex flex-col flex-1 gap-y-5">
              <div className="flex flex-1 justify-center">
                <div className="font-black text-[40px] text-[#353535] dark:text-neutral-50 ">
                  How to Create Your Custom QR Code with Atz
                </div>
              </div>
              <div className="flex flex-col gap-y-2.5">
                <div className="flex gap-x-2.5 items-center">
                  <div className="flex flex-none">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="5.5"
                        fill="#D5FCC5"
                        stroke="#353535"
                      />
                      <path
                        d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                        fill="#353535"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                    Enter Your URL: Paste the link you want to convert into a QR
                    code.
                  </div>
                </div>
                <div className="flex gap-x-2.5 items-center">
                  <div className="flex flex-none">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="5.5"
                        fill="#D5FCC5"
                        stroke="#353535"
                      />
                      <path
                        d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                        fill="#353535"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                    Customize Your QR Code: Choose the design, colors, and logo
                    that match your brand.
                  </div>
                </div>
                <div className="flex gap-x-2.5 items-center">
                  <div className="flex flex-none">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="0.5"
                        y="0.5"
                        width="23"
                        height="23"
                        rx="5.5"
                        fill="#D5FCC5"
                        stroke="#353535"
                      />
                      <path
                        d="M19.2872 9.34658L11.6002 17.0331C11.0309 17.6024 10.1075 17.6024 9.53776 17.0331L5.56967 13.0647C5.00022 12.4953 5.00022 11.5718 5.56967 11.0024C6.13922 10.4328 7.06254 10.4328 7.63184 11.0022L10.5693 13.9396L17.2247 7.28418C17.7942 6.71463 18.7176 6.71506 19.287 7.28418C19.8563 7.85363 19.8563 8.7768 19.2872 9.34658Z"
                        fill="#353535"
                      />
                    </svg>
                  </div>
                  <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                    Download & Share: Your QR code is ready for immediate use
                    across platforms.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col w-auto rounded-[50px]">
          <div className="flex flex-1 lg:flex-row flex-col bg-[#d8d8d8] rounded-[50px] bg-opacity-30 p-10 items-center text-center">
            <div className="flex flex-col gap-y-5">
              <div>
                <div className="font-black text-[40px] text-[#353535] dark:text-neutral-50 ">
                  Maximize Engagement with AtZ’s AI-Powered Content
                </div>
              </div>
              <div className="flex flex-col gap-y-2.5">
                <div className="flex gap-x-2.5 items-center">
                  <div className="flex flex-1 text-xl dark:text-neutral-50 text-[#353535]">
                    Boost the effectiveness of your QR codes with AI-generated
                    content suggestions. Our integrated AI can help you create
                    compelling SMS and email messages to complement your QR code
                    campaigns.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* benefits close*/}

      <div className="min-h-96 md:px-28 md:py-10 p-4 pb-12 flex flex-col items-center bg-white dark:bg-neutral-950 gap-y-8">
        <span className="relative text-5xl dark:text-neutral-50 font-black text-center ">
          FAQs
          <img
            src={require("../../assets/landing/Vector3.png")}
            className="absolute inset-0"
          />
        </span>
        <div class="hs-accordion-group space-y-6">
          <div
            class="hs-accordion  bg-white border border-transparent rounded-xl dark:bg-neutral-800 dark:border-transparent drop-shadow-lg rounded-r-[24.06px] rounded-l-[24.06px]"
            id="hs-active-bordered-heading-one"
          >
            <button
              class="hs-accordion-toggle inline-flex justify-between items-center gap-x-3 w-full font-semibold text-2xl  text-start text-[#0A0B1E] py-7 px-6 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
              aria-expanded="false"
              aria-controls="hs-basic-active-bordered-collapse-one"
            >
              Can I update the URL behind my QR code?
              <svg
                class="hs-accordion-active:hidden block size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5v14"></path>
              </svg>
              <svg
                class="hs-accordion-active:block hidden size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
              </svg>
            </button>
            <div
              id="hs-basic-active-bordered-collapse-one"
              class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
              role="region"
              aria-labelledby="hs-active-bordered-heading-one"
            >
              <div class="pb-4 px-5">
                <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] ">
                  Yes! With our dynamic QR codes, you can easily update the URL
                  without needing to create a new code.
                </p>
              </div>
            </div>
          </div>

          <div
            class="hs-accordion  bg-white border border-transparent rounded-xl dark:bg-neutral-800 dark:border-transparent drop-shadow-lg rounded-r-[24.06px] rounded-l-[24.06px]"
            id="hs-active-bordered-heading-two"
          >
            <button
              class="hs-accordion-toggle inline-flex justify-between items-center gap-x-3 w-full font-semibold text-2xl  text-start text-[#0A0B1E] py-7 px-6 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
              aria-expanded="true"
              aria-controls="hs-basic-active-bordered-collapse-two"
            >
              How do I track QR code performance?
              <svg
                class="hs-accordion-active:hidden block size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5v14"></path>
              </svg>
              <svg
                class="hs-accordion-active:block hidden size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
              </svg>
            </button>
            <div
              id="hs-basic-active-bordered-collapse-two"
              class="hs-accordion-content w-full overflow-hidden transition-[height] duration-300"
              role="region"
              aria-labelledby="hs-active-bordered-heading-two"
            >
              <div class="pb-4 px-5">
                <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] ">
                  Atz provides real-time analytics, including data on the number
                  of scans, locations, devices, and user engagement metrics.
                </p>
              </div>
            </div>
          </div>

          <div
            class="hs-accordion  bg-white border border-transparent rounded-xl dark:bg-neutral-800 dark:border-transparent drop-shadow-lg rounded-r-[24.06px] rounded-l-[24.06px]"
            id="hs-active-bordered-heading-three"
          >
            <button
              class="hs-accordion-toggle inline-flex justify-between items-center gap-x-3 w-full font-semibold text-2xl  text-start text-[#0A0B1E] py-7 px-6 hover:text-gray-500 disabled:opacity-50 disabled:pointer-events-none dark:text-neutral-200 dark:hover:text-neutral-400 dark:focus:outline-none dark:focus:text-neutral-400"
              aria-expanded="false"
              aria-controls="hs-basic-active-bordered-collapse-three"
            >
              Are Atz QR codes secure?
              <svg
                class="hs-accordion-active:hidden block size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
                <path d="M12 5v14"></path>
              </svg>
              <svg
                class="hs-accordion-active:block hidden size-3.5"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              >
                <path d="M5 12h14"></path>
              </svg>
            </button>
            <div
              id="hs-basic-active-bordered-collapse-three"
              class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
              role="region"
              aria-labelledby="hs-active-bordered-heading-three"
            >
              <div class="pb-4 px-5">
                <p class="text-[#0A0B1E] dark:text-neutral-200 text-[19.53px] ">
                  Absolutely. You can enable password protection for any QR code
                  to ensure only authorized users can access the link.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" bg-gradient-to-b dark:bg-gradient-to-b dark:from-neutral-950 dark:to-[#019ca2]/50 from-white to-[#019CA2]/20 flex flex-1 flex-col items-center justify-center p-4 lg:px-28 lg:py-10">
        <div className="flex flex-1 flex-col items-center gap-y-5 text-center">
          <span className="font-black text-5xl dark:text-neutral-100 ">
            Get Started with
            <br /> Atz QR Code Generator Today!
          </span>
          <span className=" text-xl text-center dark:text-neutral-100">
            Start creating custom, trackable QR codes for your business in
            minutes. Whether you're looking to boost conversions, track user
            behavior, or strengthen your brand, Atz has the tools you need.
          </span>
        </div>
      </div>
      <Footer1 />
    </>
  );
};

export default QR1;
