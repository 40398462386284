import React from "react";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../instances/axiosInstance";
const Logout = () => {
  const navigate = useNavigate();
  const revokeTokenAPI = async () => {
    const data = JSON.stringify({
      data: {
        clientName: "atz",
      },
    });
    const token = JSON.parse(localStorage.getItem("token"));
    // console.log(token);
    const { access_token: accessToken } = token;

    const config = {
      method: "post",
      url: "/secured/revoke-token",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: data,
    };

    try {
      const revokeResponse = await axiosInstance(config);
    } catch (error) {
      console.log(error);
    } finally {
      localStorage.clear();
      navigate("/");
    }
  };
  revokeTokenAPI();

  return <div></div>;
};

export default Logout;
